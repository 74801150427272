<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <DxToolbar>
        <DxItem 
          widget="dxButton"
          :options="btnOptBarcode"
          location="before"/>
        <DxItem
          widget="dxButton"
          :options="btnOptStartInvIsp"
          location="after"/>
        <DxItem
          widget="dxButton"
          :options="btnOptEndInvIsp"
          location="after"/>        
      </DxToolbar>
      
      <div>
        <qrcode-stream 
            @decode="onDecode"
            @init="onInit"
            v-if="!destroyed">
            <div class="loading-indicator"
                v-if="loading">
                바코드 스캐너 로딩 중....
            </div>
        </qrcode-stream>
      </div>
      
      <DxForm :ref="formRefName"
              :form-data="formData">
        <DxSimpleItem 
          data-field="searchLot"
          editor-type="dxTextBox"
          :editor-options="{inputAttr: {'style': 'text-transform: uppercase'}, onKeyUp:keyUp}">
          <DxLabel text="Lot 조회"/>
        </DxSimpleItem>
        
        <DxGroupItem caption="Lot 정보">
          <DxSimpleItem name="invIspId">
            <DxLabel text="재물 조사 ID"/>
            <template #default>
              <DxTextBox
                :read-only="true"
                :value="formData.invIspId"/>
            </template>
          </DxSimpleItem>

          <DxSimpleItem name="lotNumber">
            <DxLabel text="Lot No."/>
            <template #default>
              <DxTextBox
                :read-only="true"
                :value="formData.lotNumber"/>
            </template>
          </DxSimpleItem>

          <DxSimpleItem name="code">
            <DxLabel text="자재" />
            <template #default>
              <DxSelectBox
                placeholder=""
                value-expr="code"
                display-expr="codeName"
                readOnly="true"  
                :value="formData.code"
                :data-source="materialList"/>
            </template>
          </DxSimpleItem>              

          <DxSimpleItem name="quantity">
            <DxLabel text="수량"/>
            <template #default>
              <DxTextBox
                :read-only="true"
                :value="formData.quantity + ' ' + formData.unit"/>
            </template>
          </DxSimpleItem>

          <DxSimpleItem name="operation">
            <DxLabel text="현재 공정" />
            <template #default>
              <DxSelectBox
                placeholder=""
                value-expr="operation"
                display-expr="codeName"
                readOnly="true"  
                :value="formData.operation"
                :data-source="operationList"/>
            </template>
            </DxSimpleItem>
        </DxGroupItem>
      </DxForm>
      <DxButton id="button"
                text="확인 완료"
                type="success"
                styling-mode="contained"
                @click="confirm($event)"/>

    </DxScrollView>
  </div>
</template>
<script>
  import DxToolbar,
         {DxItem} from "devextreme-vue/toolbar";
  import {DxForm,
          DxGroupItem,
          DxSimpleItem,
          DxLabel} from "devextreme-vue/form";
  import {DxSelectBox} from "devextreme-vue/select-box";
  import {DxScrollView} from "devextreme-vue/scroll-view";
  import {DxTextBox} from "devextreme-vue/text-box";
  import DxButton from "devextreme-vue/button";
  import {QrcodeStream} from "vue-qrcode-reader";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function"

  export default {
    components: {
      DxForm,
      DxGroupItem,
      DxSimpleItem,
      DxLabel,
      DxToolbar,
      DxItem,
      DxSelectBox,
      DxTextBox,
      DxScrollView,
      DxButton,
      QrcodeStream},
    data: function() {
      return {
        formRefName: 'form',
        loading: false,
        destroyed: true,
        formData: {searchLot: '', quantity: '', unit: '', lotNumber: ''},
        materialList: [],
        operationList: [],      
        btnOptBarcode: {
            type: 'custom',
            text: '바코드',
            stylingMode:"outlined",
            onClick: this.toggle
        },
        btnOptStartInvIsp: {
            type: 'custom',
            text: '재물 조사 시작',
            stylingMode: "outlined",
            onClick: this.startInvIsp.bind(this)
        },
        btnOptEndInvIsp: {
            type: 'custom',
            text: '재물 조사 종료',
            stylingMode: "outlined",
            onClick: this.endInvIsp.bind(this)
        }
      };
    },
    mounted(){
      dsu.getAxiosMaterialList().then(res => {this.materialList = res.data.data});
      dsu.getAxiosOperationList({eqpOper : 'N'}).then(res => {this.operationList = res.data.data});
    },
    methods: {
      clear(){
        this.$refs[this.formRefName].instance.resetValues();
        Object.keys(this.formData).map((key) => {
            this.formData[key] ='';
        });
      },
      async onInit(promise) {
        this.loading = true
        try {
          await promise
        } catch (error) {
          console.error(error)
        } finally {
          this.loading = false
        }
      },
      async toggle(){
        this.destroyed = !this.destroyed;
        await this.$nextTick();
      },
      async show(){
        this.destroyed = false;
        await this.$nextTick();
      },
      async hide(){
        this.destroyed = true
        await this.$nextTick();
      },
      onDecode(result) {
        this.clear();
        this.formData.searchLot = result;
        this.getLotInfo();
        this.toggle();
      },
      keyUp(e)
      {
        if (e.event.key == 'Enter'){
          this.getLotInfo();
        }
      },
      startInvIsp(){
        dsu.getAxiosOBJPost('/api/mes/MIIP/startInvIsp').then(res => {
            let response = res.data;
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);
            }else {
              fn.notifyError(response.msg);
            }
        });        
      },
      endInvIsp(){
        dsu.getAxiosOBJPost('/api/mes/MIIP/endInvIsp').then(res => {
            let response = res.data;
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);
            }else {
              fn.notifyError(response.msg);
            }
        });  
      },
      getLotInfo(){
        var params = {};

        if(!this.formData.searchLot){
            fn.notifyError('Lot No. 입력 후 검색필요');
            return;
        }

        params.lotNumber = this.formData.searchLot.toUpperCase();

        dsu.getAxiosOBJGet('/api/mes/MIIP/select', params).then(res => {
            let response = res.data;
            if(response.result == 'SUCCESS'){
              this.formData = res.data.data;
              this.hide();
            }else {
              fn.notifyError(response.msg);
            }
        });
      },
      confirm(){
        if(!this.formData.lotNumber){
            fn.notifyError("Lot 조회 후 진행 필요");
            return;
        }

        dsu.getAxiosOBJPost('/api/mes/MIIP/confirmInvIspLot', this.formData).then(res => {
            let response = res.data;
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);
              this.clear();
              this.show();
            }else {
              fn.notifyError(response.msg);
            }
        });         
      }
    }
  };
</script>

<style scoped>
  #Container{
    height: calc( 100vh - 50px );
    width: 100%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background-color:white;
  }
  #button{
    width: 100%;
  }
  .loading-indicator {
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
  }
</style>