<template>
  <div>
    <DxDataGrid
        :ref="gridRefName"
        :show-borders="true"
        :show-column-lines="true"
        :columnAutoWidth="true"
        @row-prepared="onRowPrepared"
        @cell-prepared="onCellPrepared">
      <DxSorting mode="none"/>

      <DxColumn
        data-field="step1Status"
        caption="영업 담당자 지정"
        :width="columnWidth"
        alignment="center"
        cell-template="emptyCellTemplate">
      </DxColumn>

      <DxColumn
        data-field="step2Status"
        caption="고객 Contact"
        :width="columnWidth"
        alignment="center"
        cell-template="emptyCellTemplate">
      </DxColumn>

      <DxColumn
        data-field="step3Status"
        caption="제품 등록"
        :width="columnWidth"
        alignment="center"
        cell-template="emptyCellTemplate">
      </DxColumn>    

      <DxColumn
        data-field="step4Status"
        caption="설계 요청"
        :width="columnWidth"
        alignment="center"
        cell-template="emptyCellTemplate">
      </DxColumn>    

      <DxColumn
        data-field="step5Status"
        caption="설계"
        :width="columnWidth"
        alignment="center"
        cell-template="emptyCellTemplate">
      </DxColumn>    

      <DxColumn
        data-field="step6Status"
        caption="설계 납품"
        :width="columnWidth"
        alignment="center"
        cell-template="emptyCellTemplate">
      </DxColumn>    

      <DxColumn
        data-field="step7Status"
        caption="설계 납품 확인"
        :width="columnWidth"
        alignment="center"
        cell-template="emptyCellTemplate">
      </DxColumn>                

      <template #emptyCellTemplate="{}"/>
    </DxDataGrid>
  </div>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxSorting} from "devextreme-vue/data-grid";
  
  export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxSorting
    },
    props: {
      data: Object
    },    
    data: function() {
      return {
        gridRefName: "grid",
        columnWidth: 130
      };
    },
    mounted(){
      var dataSource = [];
      dataSource.push(this.data);
      this.grid.option('dataSource', dataSource);
    },
    computed: {
      grid: function () {return this.$refs[this.gridRefName].instance;}
    },
    methods: {
      onCellPrepared(e){
        if(e.rowType == 'data'){
          var dataField = e.column.dataField;
          var data = e.data[dataField];

          if(dataField){
            if(dataField.toUpperCase().includes('STEP') && dataField.toUpperCase().includes('STATUS')){
              if(data == 'COMPLETE'){
                e.cellElement.bgColor = '#439c59';
              }else if(data == 'INCOMPLETE'){
                e.cellElement.bgColor = '#da434d';
              }
            }
          }
        }
      },
      onRowPrepared(e){  
        e.rowElement.style.height = "50px";  
      }  
    }
  };
</script>
