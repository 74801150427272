<template>
  <div id="Container">
      <div class="dx-field">
        <div class="dx-field-label">재물 조사 ID</div>
        <div class="dx-field-value">
          <DxSelectBox
            :search-enabled="true"
            placeholder=""
            value-expr="invIspId"
            display-expr="invIspId"
            width="300"
            :data-source="invIspIdList"
            @value-changed="changedInvIspId"/>
        </div>
      </div>

      <DxForm :ref="formRefName"
              :form-data="invIsp">
        <DxGroupItem :col-count="2">
          <DxSimpleItem data-field="startTime"
                        :editor-options="{readOnly: true, width: '350px'}">      
            <DxLabel text="시작"
                    location="left"/>
          </DxSimpleItem>
          
          <DxSimpleItem data-field="endTime"
                        :editor-options="{readOnly: true, width: '350px'}">      
            <DxLabel text="종료"
                    location="left"/>
          </DxSimpleItem>
        </DxGroupItem>
      </DxForm>      

      <DxDataGrid
        id="grid"
        :ref="gridRefName"
        :columnAutoWidth="true"
        :show-borders="true"
        :show-column-lines="true"
        :focused-row-enabled="true"
        @exporting="onExporting">
        
        <DxExport :enabled="true"/>
        <DxColumnChooser :enabled="true" mode="select" />
        <DxSearchPanel :visible="true"/>
        <DxHeaderFilter :visible="true"/>
        <DxScrolling mode="virtual"/>
        <DxSorting mode="multiple"/>
        
        <DxColumn data-field="operation" caption="공정"/>
        <DxColumn data-field="codeName" caption="자재 코드"/>
        <DxColumn data-field="lotNumber" caption="Lot No."/>
        <DxColumn data-field="quantity" caption="수량"/>
        <DxColumn data-field="unit" caption="단위"/>
        <DxColumn data-field="userId" caption="확인 사용자"/>
        <DxColumn data-field="transTime" caption="확인 시간"/>
  
      </DxDataGrid>
  </div>
</template>

<script>
  import {DxForm,
          DxGroupItem,
          DxSimpleItem,
          DxLabel} from "devextreme-vue/form";
  import {DxDataGrid,
          DxColumn,
          DxSorting,
          DxSearchPanel,
          DxHeaderFilter,
          DxScrolling,
          DxExport,
          DxColumnChooser} from "devextreme-vue/data-grid";
  import {DxSelectBox} from "devextreme-vue/select-box";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";

  export default {
      components: {
        DxForm,
        DxGroupItem,
        DxSimpleItem,
        DxLabel,
        DxDataGrid,
        DxColumn,
        DxSorting,
        DxSearchPanel,
        DxHeaderFilter,
        DxScrolling,
        DxSelectBox,
        DxExport,
        DxColumnChooser
      },
      data: function() {
        return {
          formRefName: 'form',
          gridRefName: 'grid',
          invIspIdList: [],
          invIsp: {invIspId: '', startTime: '', endTime: ''}
        };
      },
      mounted(){
        dsu.getAxiosInvIspList().then(res => {this.invIspIdList = res.data.data});
      },
      methods: {
        onExporting(e) {
          e.fileName = this.$route.meta.transName;
        },
        setGridData(data){
          const grid = this.$refs[this.gridRefName].instance;
          grid.option('dataSource', data);
          grid.option('keyExpr', 'lotNumber');
        },
        changedInvIspId(e){
          let strInvIspId = e.value;
          
          for (var i = 0; i < this.invIspIdList.length; i++) {
            if (this.invIspIdList[i].invIspId == strInvIspId){
              Object.assign(this.invIsp, this.invIspIdList[i]);
              break;
            } 
          }

          let params = {invIspId: strInvIspId};

          dsu.getAxiosOBJGet('/api/mes/MIIR/selectInvIspData', params).then(res =>{
            let response = res.data;
            if(response.result == 'SUCCESS'){
              this.setGridData(response.data);
            }else{
              fn.notifyError(response.msg);
            }
          });
        }
      }
    };
</script>

<style scoped>
  #Container{
    height: calc( 100vh - 50px );
    width: 100%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background-color:white;
  }
  
  #grid{
    width: 100%;
    height: calc( 100vh - 210px );
  }
  .dx-field-label{
    width: 100px;
    font-size: 14px;
    font-weight: 700;
    color: black;
  }
  .dx-field{
    margin-top: 10px;
  }
</style>