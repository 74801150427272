<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <ProjectList :ref="projectListRefName"
                   @loadSelectedData="loadSelectedData"
                   :isHideRegWeek="true"
                   :isHideBranch="true"
                   :isHideRank1Address="true"
                   :isHideSuccessfulBidAmount="true"
                   :isHideResultsAnnouncementDate="true"
                   :isHideGrossArea="true"
                   :isHideCustomerContact="true"/>
      <DxForm id="form"
              :ref="formRefName"
              :form-data="formData">
        <DxGroupItem caption="입력 정보" >                   
          <DxSimpleItem 
            data-field="engrUserId"
            editor-type="dxSelectBox"
            :editor-options="{dataSource: this.userList,
                              valueExpr: 'id',
                              displayExpr: 'userNameId',
                              searchEnabled: true}">
            <DxRequiredRule message="기술 담당자 필수 입력"/>
            <DxLabel text="기술 담당자"/>
          </DxSimpleItem>

          <DxSimpleItem
            data-field="designAmount"
            editor-type="dxTextBox">
            <DxLabel text="설계 금액"/>
            <DxRequiredRule message="설계 금액 필수 입력"/>
          </DxSimpleItem>          
        </DxGroupItem>
      </DxForm>

      <DxToolbar>
        <DxItem 
          widget="dxButton"
          :options="{type: 'custom', icon: 'save', text: '저장', stylingMode: 'outlined', onClick: this.save.bind(this)}"
          location="center"/>
      </DxToolbar>

      <DesignManager 
        :ref="designManagerRefName"
        :showAdding="false"
        :showUpdating="true"
        :showDeleting="false"
        :requireSeq="true"
        :allowEditingRequest="false"
        :allowEditingDesign="true"
        :allowEditingDelivery="false"/>
    </DxScrollView>
  </div>
</template>

<script>
  import DxScrollView from "devextreme-vue/scroll-view";
  import ProjectList from "../_common/PROJECT_LIST";
  import DesignManager from "../_common/DESIGN_MANAGER";
  import {DxForm,
          DxGroupItem,
          DxSimpleItem,
          DxLabel,
          DxRequiredRule} from "devextreme-vue/form";
  import DxToolbar,
         {DxItem} from "devextreme-vue/toolbar";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  export default {
    components:{
      DxScrollView,
      ProjectList,
      DesignManager,
      DxForm,
      DxGroupItem,
      DxSimpleItem,
      DxLabel,
      DxToolbar,
      DxItem,
      DxRequiredRule
    },    
    data(){
      return {
        step: 5,
        projectInfo: {},
        projectListRefName: 'projectList',
        designManagerRefName: 'design',
        formRefName: 'form',
        userList: [],
        formData: {engrUserId: '', designAmount: ''},        
      };
    },
    mounted(){
        dsu.getAxiosUserList({dept: '기술'}).then(res => {this.userList = res.data.data});
    }, 
    computed: {
      form: function () { return this.$refs[this.formRefName].instance; }
    },    
    methods:{
      loadSelectedData(params){
        this.reset();
        params.step = this.step;
        this.projectInfo = params;
        this.$refs[this.designManagerRefName].refreshData(params);
        dsu.getAxiosOBJGet('/api/erp/PREG/selectProjectData', params).then(res =>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            this.inputedBranch = response.data.branch;
            for(var key in this.formData){
              if(response.data[key]){
                this.formData[key] = response.data[key];
              }
            }
          }else{
            fn.notifyError(response.msg);
          }
        });
      },
      save(){
        if(!this.projectInfo.projectId){
          fn.notifyError('Project 선택 후 진행 필요');
          return;
        }

        let validationResult =  this.form.validate();
        if( validationResult.status == 'valid'){
          var params = this.projectInfo;
          params.step = this.step;
          
          var finalData = JSON.parse(JSON.stringify(this.formData));
          finalData.status = 'COMPLETE';
          params.projectDatas = JSON.stringify(finalData);

          dsu.getAxiosOBJPost('/api/erp/PREG/updateProjectData', params).then(res => {
              let response = res.data;
              if(response.result == 'SUCCESS'){
                fn.notifySuccess(response.msg);
                this.$refs[this.projectListRefName].search();
              }else {
                fn.notifyError(response.msg);
              }
          });
        }
      },
      reset(){
        this.form.resetValues();
      }
    }
  };
</script>

<style scoped>
  #Container {
    height: calc( 100vh - 50px );
    width: 100%;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background-color:white;
  }

  #form{
    margin-top: 40px;
  }
</style>