export const default_message = {
  Yes: "예",
  No: "아니오",
  Cancel: "취소",
  Clear: "초기화",
  Done: "완료",
  Loading: "로딩 중...",
  Select: "선택",
  Search: "검색",
  Back: "뒤로",
  OK: "확인",
  "dxCollectionWidget-noDataText": "데이터 없음",
  "dxDropDownEditor-selectLabel": "Select",
  "validation-required": "필수 입력",
  "validation-required-formatted": "{0} 값 필수 입력",
  "validation-numeric": "값은 숫자여야 함",
  "validation-numeric-formatted": "{0} 값은 숫자여야 함",
  "validation-range": "값의 범위를 벗어남",
  "validation-range-formatted": "{0}의 범위를 벗어남",
  "validation-stringLength": "값의 길이가 올바르지 않음",
  "validation-stringLength-formatted": "{0} 값의 길이가 올바르지 않음",
  "validation-custom": "값이 잘못됨",
  "validation-custom-formatted": "{0} 값이 잘못됨",
  "validation-async": "값이 잘못됨",
  "validation-async-formatted": "{0} 값이 잘못됨",
  "validation-compare": "값이 일치하지 않음",
  "validation-compare-formatted": "{0} 값이 일치하지 않음",
  "validation-pattern": "값이 패턴과 일치하지 않음",
  "validation-pattern-formatted": "{0} 값이 패턴과 일치하지 않음",
  "validation-email": "Email 이 잘못됨",
  "validation-email-formatted": "{0} 값이 잘못됨",
  "validation-mask": "값이 잘못됨",
  "dxLookup-searchPlaceholder": "최소 문자 수: {0}",
  "dxList-pullingDownText": "아래로 당겨 새로고침",
  "dxList-pulledDownText": "새로고침을 하려면 손을 뗌",
  "dxList-refreshingText": "새로 고침 중...",
  "dxList-pageLoadingText": "로딩 중...",
  "dxList-nextButtonText": "더 보기",
  "dxList-selectAll": "전체 선택",
  "dxListEditDecorator-delete": "삭제",
  "dxListEditDecorator-more": "더 보기",
  "dxScrollView-pullingDownText": "아래로 당겨 새로고침",
  "dxScrollView-pulledDownText": "새로 고침을 하려면 손을 뗌",
  "dxScrollView-refreshingText": "새로 고침 중...",
  "dxScrollView-reachBottomText": "로딩 중...",
  "dxDateBox-simulatedDataPickerTitleTime": "시간 선택",
  "dxDateBox-simulatedDataPickerTitleDate": "날짜 선택",
  "dxDateBox-simulatedDataPickerTitleDateTime": "날짜와 시간 선택",
  "dxDateBox-validation-datetime": "값은 날짜 또는 시간이어야함",
  "dxFileUploader-selectFile": "파일 선택",
  "dxFileUploader-dropFile": "또는 여기에 파일 드롭",
  "dxFileUploader-bytes": "bytes",
  "dxFileUploader-kb": "kb",
  "dxFileUploader-Mb": "Mb",
  "dxFileUploader-Gb": "Gb",
  "dxFileUploader-upload": "업로드",
  "dxFileUploader-uploaded": "업로드 됨",
  "dxFileUploader-readyToUpload": "업로드 준비 완료",
  "dxFileUploader-uploadFailedMessage": "업로드 실패",
  "dxFileUploader-invalidFileExtension": "허용되지 않은 파일 형식",
  "dxFileUploader-invalidMaxFileSize": "파일이 너무 큼",
  "dxFileUploader-invalidMinFileSize": "파일이 너무 작음",
  "dxRangeSlider-ariaFrom": "폼",
  "dxRangeSlider-ariaTill": "까지",
  "dxSwitch-switchedOnText": "ON",
  "dxSwitch-switchedOffText": "OFF",
  "dxForm-optionalMark": "선택 사항",
  "dxForm-requiredMessage": "{0} 값 필수 입력",
  "dxNumberBox-invalidValueMessage": "값은 숫자여야 함",
  "dxNumberBox-noDataText": "데이터 없음",
  "dxDataGrid-columnChooserTitle": "열 선택",
  "dxDataGrid-columnChooserEmptyText": "열을 숨기려면 여기로 드래그해야 함",
  "dxDataGrid-groupContinuesMessage": "다음 페이지에 계속",
  "dxDataGrid-groupContinuedMessage": "이전 페이지에서 계속",
  "dxDataGrid-groupHeaderText": "이 열을 기준으로 그룹화",
  "dxDataGrid-ungroupHeaderText": "그룹 해제",
  "dxDataGrid-ungroupAllText": "모든 그룹 해제",
  "dxDataGrid-editingEditRow": "편집",
  "dxDataGrid-editingSaveRowChanges": "저장",
  "dxDataGrid-editingCancelRowChanges": "취소",
  "dxDataGrid-editingDeleteRow": "삭제",
  "dxDataGrid-editingUndeleteRow": "삭제 취소",
  "dxDataGrid-editingConfirmDeleteMessage": "정말 삭제 하시겠습니까?",
  "dxDataGrid-validationCancelChanges": "변경 취소",
  "dxDataGrid-groupPanelEmptyText": "열 머리글을 여기로 끌어 해당 열을 기준으로 그룹화",
  "dxDataGrid-noDataText": "데이터 없음",
  "dxDataGrid-searchPanelPlaceholder": "전체 조회",
  "dxDataGrid-filterRowShowAllText": "(전체)",
  "dxDataGrid-filterRowResetOperationText": "초기화",
  "dxDataGrid-filterRowOperationEquals": "같음",
  "dxDataGrid-filterRowOperationNotEquals": "같지 않음",
  "dxDataGrid-filterRowOperationLess": "이하",
  "dxDataGrid-filterRowOperationLessOrEquals": "작거나 같음",
  "dxDataGrid-filterRowOperationGreater": "보다 큰",
  "dxDataGrid-filterRowOperationGreaterOrEquals": "보다 크거나 같음",
  "dxDataGrid-filterRowOperationStartsWith": "로 시작",
  "dxDataGrid-filterRowOperationContains": "포함",
  "dxDataGrid-filterRowOperationNotContains": "포함되지 않음",
  "dxDataGrid-filterRowOperationEndsWith": "로 끝남",
  "dxDataGrid-filterRowOperationBetween": "사이",
  "dxDataGrid-filterRowOperationBetweenStartText": "시작",
  "dxDataGrid-filterRowOperationBetweenEndText": "End",
  "dxDataGrid-applyFilterText": "필터 적용",
  "dxDataGrid-trueText": "true",
  "dxDataGrid-falseText": "false",
  "dxDataGrid-sortingAscendingText": "오름차순 정렬",
  "dxDataGrid-sortingDescendingText": "내림차순 정렬",
  "dxDataGrid-sortingClearText": "정렬 초기화",
  "dxDataGrid-editingSaveAllChanges": "변경내용 저장",
  "dxDataGrid-editingCancelAllChanges": "변경내용 취소",
  "dxDataGrid-editingAddRow": "행 추가",
  "dxDataGrid-summaryMin": "최소: {0}",
  "dxDataGrid-summaryMinOtherColumn": "{1} 의 최소값은 {0}",
  "dxDataGrid-summaryMax": "최대: {0}",
  "dxDataGrid-summaryMaxOtherColumn": "{1} 의 최대값은 {0}",
  "dxDataGrid-summaryAvg": "평규: {0}",
  "dxDataGrid-summaryAvgOtherColumn": "{1} 의 평균은 {0}",
  "dxDataGrid-summarySum": "합계: {0}",
  "dxDataGrid-summarySumOtherColumn": "{1}의 합은 {0}",
  "dxDataGrid-summaryCount": "개수: {0}",
  "dxDataGrid-columnFixingFix": "고정",
  "dxDataGrid-columnFixingUnfix": "고정 해제",
  "dxDataGrid-columnFixingLeftPosition": "왼쪽으로",
  "dxDataGrid-columnFixingRightPosition": "오른쪽으로",
  "dxDataGrid-exportTo": "내보내기",
  "dxDataGrid-exportToExcel": "엑셀로 내보내기",
  "dxDataGrid-exporting": "내보내기 중...",
  "dxDataGrid-excelFormat": "엑셀 파일",
  "dxDataGrid-selectedRows": "선택된 행",
  "dxDataGrid-exportSelectedRows": "Export selected rows",
  "dxDataGrid-exportAll": "모든 데이터 내보내기",
  "dxDataGrid-headerFilterEmptyValue": "(공백)",
  "dxDataGrid-headerFilterOK": "확인",
  "dxDataGrid-headerFilterCancel": "취소",
  "dxDataGrid-ariaColumn": "열",
  "dxDataGrid-ariaValue": "값",
  "dxDataGrid-ariaFilterCell": "필터 셀",
  "dxDataGrid-ariaCollapse": "접기",
  "dxDataGrid-ariaExpand": "펼치기",
  "dxDataGrid-ariaDataGrid": "데이터 그리드",
  "dxDataGrid-ariaSearchInGrid": "데이터 그리드에서 검색",
  "dxDataGrid-ariaSelectAll": "전체 선택",
  "dxDataGrid-ariaSelectRow": "행 선택",
  "dxDataGrid-filterBuilderPopupTitle": "필터 빌터",
  "dxDataGrid-filterPanelCreateFilter": "필터 생성",
  "dxDataGrid-filterPanelClearFilter": "초기화",
  "dxDataGrid-filterPanelFilterEnabledHint": "필터 활성화",

  
  "dxTreeList-ariaTreeList": "Tree list",
  "dxTreeList-editingAddRowToNode": "Add",
  "dxPager-infoText": "Page {0} of {1} ({2} items)",
  "dxPager-pagesCountText": "of",
  "dxPivotGrid-grandTotal": "Grand Total",
  "dxPivotGrid-total": "{0} Total",
  "dxPivotGrid-fieldChooserTitle": "Field Chooser",
  "dxPivotGrid-showFieldChooser": "Show Field Chooser",
  "dxPivotGrid-expandAll": "Expand All",
  "dxPivotGrid-collapseAll": "Collapse All",
  "dxPivotGrid-sortColumnBySummary": 'Sort "{0}" by This Column',
  "dxPivotGrid-sortRowBySummary": 'Sort "{0}" by This Row',
  "dxPivotGrid-removeAllSorting": "Remove All Sorting",
  "dxPivotGrid-dataNotAvailable": "N/A",
  "dxPivotGrid-rowFields": "Row Fields",
  "dxPivotGrid-columnFields": "Column Fields",
  "dxPivotGrid-dataFields": "Data Fields",
  "dxPivotGrid-filterFields": "Filter Fields",
  "dxPivotGrid-allFields": "All Fields",
  "dxPivotGrid-columnFieldArea": "Drop Column Fields Here",
  "dxPivotGrid-dataFieldArea": "Drop Data Fields Here",
  "dxPivotGrid-rowFieldArea": "Drop Row Fields Here",
  "dxPivotGrid-filterFieldArea": "Drop Filter Fields Here",
  "dxScheduler-editorLabelTitle": "Subject",
  "dxScheduler-editorLabelStartDate": "Start Date",
  "dxScheduler-editorLabelEndDate": "End Date",
  "dxScheduler-editorLabelDescription": "Description",
  "dxScheduler-editorLabelRecurrence": "Repeat",
  "dxScheduler-openAppointment": "Open appointment",
  "dxScheduler-recurrenceNever": "Never",
  "dxScheduler-recurrenceMinutely": "Every minute",
  "dxScheduler-recurrenceHourly": "Hourly",
  "dxScheduler-recurrenceDaily": "Daily",
  "dxScheduler-recurrenceWeekly": "Weekly",
  "dxScheduler-recurrenceMonthly": "Monthly",
  "dxScheduler-recurrenceYearly": "Yearly",
  "dxScheduler-recurrenceRepeatEvery": "Repeat Every",
  "dxScheduler-recurrenceRepeatOn": "Repeat On",
  "dxScheduler-recurrenceEnd": "End repeat",
  "dxScheduler-recurrenceAfter": "After",
  "dxScheduler-recurrenceOn": "On",
  "dxScheduler-recurrenceRepeatMinutely": "minute(s)",
  "dxScheduler-recurrenceRepeatHourly": "hour(s)",
  "dxScheduler-recurrenceRepeatDaily": "day(s)",
  "dxScheduler-recurrenceRepeatWeekly": "week(s)",
  "dxScheduler-recurrenceRepeatMonthly": "month(s)",
  "dxScheduler-recurrenceRepeatYearly": "year(s)",
  "dxScheduler-switcherDay": "Day",
  "dxScheduler-switcherWeek": "Week",
  "dxScheduler-switcherWorkWeek": "Work Week",
  "dxScheduler-switcherMonth": "Month",
  "dxScheduler-switcherAgenda": "Agenda",
  "dxScheduler-switcherTimelineDay": "Timeline Day",
  "dxScheduler-switcherTimelineWeek": "Timeline Week",
  "dxScheduler-switcherTimelineWorkWeek": "Timeline Work Week",
  "dxScheduler-switcherTimelineMonth": "Timeline Month",
  "dxScheduler-recurrenceRepeatOnDate": "on date",
  "dxScheduler-recurrenceRepeatCount": "occurrence(s)",
  "dxScheduler-allDay": "All day",
  "dxScheduler-confirmRecurrenceEditMessage": "Do you want to edit only this appointment or the whole series?",
  "dxScheduler-confirmRecurrenceDeleteMessage": "Do you want to delete only this appointment or the whole series?",
  "dxScheduler-confirmRecurrenceEditSeries": "Edit series",
  "dxScheduler-confirmRecurrenceDeleteSeries": "Delete series",
  "dxScheduler-confirmRecurrenceEditOccurrence": "Edit appointment",
  "dxScheduler-confirmRecurrenceDeleteOccurrence": "Delete appointment",
  "dxScheduler-noTimezoneTitle": "No timezone",
  "dxScheduler-moreAppointments": "{0} more",
  "dxCalendar-todayButtonText": "Today",
  "dxCalendar-ariaWidgetName": "Calendar",
  "dxColorView-ariaRed": "Red",
  "dxColorView-ariaGreen": "Green",
  "dxColorView-ariaBlue": "Blue",
  "dxColorView-ariaAlpha": "Transparency",
  "dxColorView-ariaHex": "Color code",
  "dxTagBox-selected": "{0} selected",
  "dxTagBox-allSelected": "All selected ({0})",
  "dxTagBox-moreSelected": "{0} more",
  "vizExport-printingButtonText": "Print",
  "vizExport-titleMenuText": "Exporting/Printing",
  "vizExport-exportButtonText": "{0} file",
  "dxFilterBuilder-and": "And",
  "dxFilterBuilder-or": "Or",
  "dxFilterBuilder-notAnd": "Not And",
  "dxFilterBuilder-notOr": "Not Or",
  "dxFilterBuilder-addCondition": "Add Condition",
  "dxFilterBuilder-addGroup": "Add Group",
  "dxFilterBuilder-enterValueText": "<enter a value>",
  "dxFilterBuilder-filterOperationEquals": "같음",
  "dxFilterBuilder-filterOperationNotEquals": "같지 않음",
  "dxFilterBuilder-filterOperationLess": "Is less than",
  "dxFilterBuilder-filterOperationLessOrEquals": "Is less than or equal to",
  "dxFilterBuilder-filterOperationGreater": "Is greater than",
  "dxFilterBuilder-filterOperationGreaterOrEquals": "Is greater than or equal to",
  "dxFilterBuilder-filterOperationStartsWith": "로 시작",
  "dxFilterBuilder-filterOperationContains": "포함",
  "dxFilterBuilder-filterOperationNotContains": "포함되지 않음",
  "dxFilterBuilder-filterOperationEndsWith": "로 끝남",
  "dxFilterBuilder-filterOperationIsBlank": "공백",
  "dxFilterBuilder-filterOperationIsNotBlank": "공백 아님",
  "dxFilterBuilder-filterOperationBetween": "사이에",
  "dxFilterBuilder-filterOperationAnyOf": "Is any of",
  "dxFilterBuilder-filterOperationNoneOf": "Is none of",
  "dxHtmlEditor-dialogColorCaption": "Change Font Color",
  "dxHtmlEditor-dialogBackgroundCaption": "Change Background Color",
  "dxHtmlEditor-dialogLinkCaption": "Add Link",
  "dxHtmlEditor-dialogLinkUrlField": "URL",
  "dxHtmlEditor-dialogLinkTextField": "Text",
  "dxHtmlEditor-dialogLinkTargetField": "Open link in new window",
  "dxHtmlEditor-dialogImageCaption": "Add Image",
  "dxHtmlEditor-dialogImageUrlField": "URL",
  "dxHtmlEditor-dialogImageAltField": "Alternate text",
  "dxHtmlEditor-dialogImageWidthField": "Width (px)",
  "dxHtmlEditor-dialogImageHeightField": "Height (px)",
  "dxHtmlEditor-heading": "Heading",
  "dxHtmlEditor-normalText": "Normal text",
  "dxFileManager-newDirectoryName": "Untitled directory",
  "dxFileManager-rootDirectoryName": "Files",
  "dxFileManager-errorNoAccess": "Access Denied. Operation could not be completed.",
  "dxFileManager-errorDirectoryExistsFormat": "Directory '{0}' already exists.",
  "dxFileManager-errorFileExistsFormat": "File '{0}' already exists.",
  "dxFileManager-errorFileNotFoundFormat": "File '{0}' not found.",
  "dxFileManager-errorDirectoryNotFoundFormat": "Directory '{0}' not found.",
  "dxFileManager-errorWrongFileExtension": "File extension is not allowed.",
  "dxFileManager-errorMaxFileSizeExceeded": "File size exceeds the maximum allowed size.",
  "dxFileManager-errorInvalidSymbols": "This name contains invalid characters.",
  "dxFileManager-errorDefault": "Unspecified error.",
  "dxFileManager-errorDirectoryOpenFailed": "The directory cannot be opened",
  "dxFileManager-commandCreate": "New directory",
  "dxFileManager-commandRename": "Rename",
  "dxFileManager-commandMove": "Move to",
  "dxFileManager-commandCopy": "Copy to",
  "dxFileManager-commandDelete": "삭제",
  "dxFileManager-commandDownload": "Download",
  "dxFileManager-commandUpload": "Upload files",
  "dxFileManager-commandRefresh": "Refresh",
  "dxFileManager-commandThumbnails": "Thumbnails View",
  "dxFileManager-commandDetails": "Details View",
  "dxFileManager-commandClearSelection": "Clear selection",
  "dxFileManager-commandShowNavPane": "Toggle navigation pane",
  "dxFileManager-dialogDirectoryChooserMoveTitle": "Move to",
  "dxFileManager-dialogDirectoryChooserMoveButtonText": "Move",
  "dxFileManager-dialogDirectoryChooserCopyTitle": "Copy to",
  "dxFileManager-dialogDirectoryChooserCopyButtonText": "Copy",
  "dxFileManager-dialogRenameItemTitle": "Rename",
  "dxFileManager-dialogRenameItemButtonText": "저장",
  "dxFileManager-dialogCreateDirectoryTitle": "New directory",
  "dxFileManager-dialogCreateDirectoryButtonText": "Create",
  "dxFileManager-dialogDeleteItemTitle": "삭제",
  "dxFileManager-dialogDeleteItemButtonText": "삭제",
  "dxFileManager-dialogDeleteItemSingleItemConfirmation": "Are you sure you want to delete  {0}?",
  "dxFileManager-dialogDeleteItemMultipleItemsConfirmation": "Are you sure you want to delete  {0} items?",
  "dxFileManager-dialogButtonCancel": "취소",
  "dxFileManager-editingCreateSingleItemProcessingMessage": "Creating a directory inside {0}",
  "dxFileManager-editingCreateSingleItemSuccessMessage": "Created a directory inside {0}",
  "dxFileManager-editingCreateSingleItemErrorMessage": "Directory was not created",
  "dxFileManager-editingCreateCommonErrorMessage": "Directory was not created",
  "dxFileManager-editingRenameSingleItemProcessingMessage": "Renaming an item inside {0}",
  "dxFileManager-editingRenameSingleItemSuccessMessage": "Renamed an item inside {0}",
  "dxFileManager-editingRenameSingleItemErrorMessage": "Item was not renamed",
  "dxFileManager-editingRenameCommonErrorMessage": "Item was not renamed",
  "dxFileManager-editingDeleteSingleItemProcessingMessage": "Deleting an item from {0}",
  "dxFileManager-editingDeleteMultipleItemsProcessingMessage": "Deleting {0} items from {1}",
  "dxFileManager-editingDeleteSingleItemSuccessMessage": "Deleted an item from {0}",
  "dxFileManager-editingDeleteMultipleItemsSuccessMessage": "Deleted {0} items from {1}",
  "dxFileManager-editingDeleteSingleItemErrorMessage": "Item was not deleted",
  "dxFileManager-editingDeleteMultipleItemsErrorMessage": "{0} items were not deleted",
  "dxFileManager-editingDeleteCommonErrorMessage": "Some items were not deleted",
  "dxFileManager-editingMoveSingleItemProcessingMessage": "Moving an item to {0}",
  "dxFileManager-editingMoveMultipleItemsProcessingMessage": "Moving {0} items to {1}",
  "dxFileManager-editingMoveSingleItemSuccessMessage": "Moved an item to {0}",
  "dxFileManager-editingMoveMultipleItemsSuccessMessage": "Moved {0} items to {1}",
  "dxFileManager-editingMoveSingleItemErrorMessage": "Item was not moved",
  "dxFileManager-editingMoveMultipleItemsErrorMessage": "{0} items were not moved",
  "dxFileManager-editingMoveCommonErrorMessage": "Some items were not moved",
  "dxFileManager-editingCopySingleItemProcessingMessage": "Copying an item to {0}",
  "dxFileManager-editingCopyMultipleItemsProcessingMessage": "Copying {0} items to {1}",
  "dxFileManager-editingCopySingleItemSuccessMessage": "Copied an item to {0}",
  "dxFileManager-editingCopyMultipleItemsSuccessMessage": "Copied {0} items to {1}",
  "dxFileManager-editingCopySingleItemErrorMessage": "Item was not copied",
  "dxFileManager-editingCopyMultipleItemsErrorMessage": "{0} items were not copied",
  "dxFileManager-editingCopyCommonErrorMessage": "Some items were not copied",
  "dxFileManager-editingUploadSingleItemProcessingMessage": "Uploading an item to {0}",
  "dxFileManager-editingUploadMultipleItemsProcessingMessage": "Uploading {0} items to {1}",
  "dxFileManager-editingUploadSingleItemSuccessMessage": "Uploaded an item to {0}",
  "dxFileManager-editingUploadMultipleItemsSuccessMessage": "Uploaded {0} items to {1}",
  "dxFileManager-editingUploadSingleItemErrorMessage": "Item was not uploaded",
  "dxFileManager-editingUploadMultipleItemsErrorMessage": "{0} items were not uploaded",
  "dxFileManager-editingUploadCanceledMessage": "Canceled",
  "dxFileManager-listDetailsColumnCaptionName": "Name",
  "dxFileManager-listDetailsColumnCaptionDateModified": "Date Modified",
  "dxFileManager-listDetailsColumnCaptionFileSize": "File Size",
  "dxFileManager-listThumbnailsTooltipTextSize": "Size",
  "dxFileManager-listThumbnailsTooltipTextDateModified": "Date Modified",
  "dxFileManager-notificationProgressPanelTitle": "Progress",
  "dxFileManager-notificationProgressPanelEmptyListText": "No operations",
  "dxFileManager-notificationProgressPanelOperationCanceled": "Canceled",
  "dxDiagram-categoryGeneral": "General",
  "dxDiagram-categoryFlowchart": "Flowchart",
  "dxDiagram-categoryOrgChart": "Org Chart",
  "dxDiagram-categoryContainers": "Containers",
  "dxDiagram-categoryCustom": "Custom",
  "dxDiagram-commandExportToSvg": "SVG 로 내보내기",
  "dxDiagram-commandExportToPng": "PNG 로 내보내기",
  "dxDiagram-commandExportToJpg": "JPG 로 내보내기",
  "dxDiagram-commandUndo": "실행 취소",
  "dxDiagram-commandRedo": "Redo",
  "dxDiagram-commandFontName": "글꼴 이름",
  "dxDiagram-commandFontSize": "글꼴 크기",
  "dxDiagram-commandBold": "Bold",
  "dxDiagram-commandItalic": "Italic",
  "dxDiagram-commandUnderline": "Underline",
  "dxDiagram-commandTextColor": "글꼴 색상",
  "dxDiagram-commandLineColor": "선 색상",
  "dxDiagram-commandLineWidth": "선 넓이",
  "dxDiagram-commandLineStyle": "선 스타일",
  "dxDiagram-commandLineStyleSolid": "Solid",
  "dxDiagram-commandLineStyleDotted": "Dotted",
  "dxDiagram-commandLineStyleDashed": "Dashed",
  "dxDiagram-commandFillColor": "채우기 색상",
  "dxDiagram-commandAlignLeft": "왼쪽 정렬",
  "dxDiagram-commandAlignCenter": "가운데 정렬r",
  "dxDiagram-commandAlignRight": "오른쪽 정렬",
  "dxDiagram-commandConnectorLineType": "커넥터 선 종류",
  "dxDiagram-commandConnectorLineStraight": "Straight",
  "dxDiagram-commandConnectorLineOrthogonal": "Orthogonal",
  "dxDiagram-commandConnectorLineStart": "Connector Line Start",
  "dxDiagram-commandConnectorLineEnd": "Connector Line End",
  "dxDiagram-commandConnectorLineNone": "None",
  "dxDiagram-commandConnectorLineArrow": "Arrow",
  "dxDiagram-commandFullscreen": "Full Screen",
  "dxDiagram-commandUnits": "Units",
  "dxDiagram-commandPageSize": "Page Size",
  "dxDiagram-commandPageOrientation": "Page Orientation",
  "dxDiagram-commandPageOrientationLandscape": "Landscape",
  "dxDiagram-commandPageOrientationPortrait": "Portrait",
  "dxDiagram-commandPageColor": "Page Color",
  "dxDiagram-commandShowGrid": "Show Grid",
  "dxDiagram-commandSnapToGrid": "Snap to Grid",
  "dxDiagram-commandGridSize": "Grid Size",
  "dxDiagram-commandZoomLevel": "Zoom Level",
  "dxDiagram-commandAutoZoom": "Auto Zoom",
  "dxDiagram-commandFitToContent": "Fit to Content",
  "dxDiagram-commandFitToWidth": "Fit to Width",
  "dxDiagram-commandAutoZoomByContent": "Auto Zoom by Content",
  "dxDiagram-commandAutoZoomByWidth": "Auto Zoom by Width",
  "dxDiagram-commandSimpleView": "Simple View",
  "dxDiagram-commandCut": "Cut",
  "dxDiagram-commandCopy": "Copy",
  "dxDiagram-commandPaste": "Paste",
  "dxDiagram-commandSelectAll": "전체 선택",
  "dxDiagram-commandDelete": "삭제",
  "dxDiagram-commandBringToFront": "Bring to Front",
  "dxDiagram-commandSendToBack": "Send to Back",
  "dxDiagram-commandLock": "Lock",
  "dxDiagram-commandUnlock": "Unlock",
  "dxDiagram-commandInsertShapeImage": "Insert Image...",
  "dxDiagram-commandEditShapeImage": "Change Image...",
  "dxDiagram-commandDeleteShapeImage": "Delete Image",
  "dxDiagram-commandLayoutLeftToRight": "Left-to-right",
  "dxDiagram-commandLayoutRightToLeft": "Right-to-left",
  "dxDiagram-commandLayoutTopToBottom": "Top-to-bottom",
  "dxDiagram-commandLayoutBottomToTop": "Bottom-to-top",
  "dxDiagram-unitIn": "in",
  "dxDiagram-unitCm": "cm",
  "dxDiagram-unitPx": "px",
  "dxDiagram-dialogButtonOK": "확인",
  "dxDiagram-dialogButtonCancel": "취소",
  "dxDiagram-dialogInsertShapeImageTitle": "이미지 삽입",
  "dxDiagram-dialogEditShapeImageTitle": "이미지 변경",
  "dxDiagram-dialogEditShapeImageSelectButton": "이미지 선택",
  "dxDiagram-dialogEditShapeImageLabelText": "또는 여기에 파일 드롭",
  "dxDiagram-uiExport": "내보내기",
  "dxDiagram-uiProperties": "속성",
  "dxDiagram-uiSettings": "설정",
  "dxDiagram-uiShowToolbox": "도구 상자 표시",
  "dxDiagram-uiSearch": "검색",
  "dxDiagram-uiStyle": "Style",
  "dxDiagram-uiLayout": "Layout",
  "dxDiagram-uiLayoutTree": "Tree",
  "dxDiagram-uiLayoutLayered": "Layered",
  "dxDiagram-uiDiagram": "Diagram",
  "dxDiagram-uiText": "Text",
  "dxDiagram-uiObject": "Object",
  "dxDiagram-uiConnector": "Connector",
  "dxDiagram-uiPage": "Page",
  "dxDiagram-shapeText": "Text",
  "dxDiagram-shapeRectangle": "Rectangle",
  "dxDiagram-shapeEllipse": "Ellipse",
  "dxDiagram-shapeCross": "Cross",
  "dxDiagram-shapeTriangle": "Triangle",
  "dxDiagram-shapeDiamond": "Diamond",
  "dxDiagram-shapeHeart": "Heart",
  "dxDiagram-shapePentagon": "Pentagon",
  "dxDiagram-shapeHexagon": "Hexagon",
  "dxDiagram-shapeOctagon": "Octagon",
  "dxDiagram-shapeStar": "Star",
  "dxDiagram-shapeArrowLeft": "Left Arrow",
  "dxDiagram-shapeArrowUp": "Up Arrow",
  "dxDiagram-shapeArrowRight": "Right Arrow",
  "dxDiagram-shapeArrowDown": "Down Arrow",
  "dxDiagram-shapeArrowUpDown": "Up Down Arrow",
  "dxDiagram-shapeArrowLeftRight": "Left Right Arrow",
  "dxDiagram-shapeProcess": "Process",
  "dxDiagram-shapeDecision": "Decision",
  "dxDiagram-shapeTerminator": "Terminator",
  "dxDiagram-shapePredefinedProcess": "Predefined Process",
  "dxDiagram-shapeDocument": "Document",
  "dxDiagram-shapeMultipleDocuments": "Multiple Documents",
  "dxDiagram-shapeManualInput": "Manual Input",
  "dxDiagram-shapePreparation": "Preparation",
  "dxDiagram-shapeData": "Data",
  "dxDiagram-shapeDatabase": "Database",
  "dxDiagram-shapeHardDisk": "Hard Disk",
  "dxDiagram-shapeInternalStorage": "Internal Storage",
  "dxDiagram-shapePaperTape": "Paper Tape",
  "dxDiagram-shapeManualOperation": "Manual Operation",
  "dxDiagram-shapeDelay": "Delay",
  "dxDiagram-shapeStoredData": "Stored Data",
  "dxDiagram-shapeDisplay": "Display",
  "dxDiagram-shapeMerge": "Merge",
  "dxDiagram-shapeConnector": "Connector",
  "dxDiagram-shapeOr": "Or",
  "dxDiagram-shapeSummingJunction": "Summing Junction",
  "dxDiagram-shapeContainerDefaultText": "Container",
  "dxDiagram-shapeVerticalContainer": "Vertical Container",
  "dxDiagram-shapeHorizontalContainer": "Horizontal Container",
  "dxDiagram-shapeCardDefaultText": "Person's Name",
  "dxDiagram-shapeCardWithImageOnLeft": "Card with Image on the Left",
  "dxDiagram-shapeCardWithImageOnTop": "Card with Image on the Top",
  "dxDiagram-shapeCardWithImageOnRight": "Card with Image on the Right",
  "dxGantt-dialogTitle": "Title",
  "dxGantt-dialogStartTitle": "시작",
  "dxGantt-dialogEndTitle": "End",
  "dxGantt-dialogProgressTitle": "Progress",
  "dxGantt-dialogResourcesTitle": "Resources",
  "dxGantt-dialogResourceManagerTitle": "Resource Manager",
  "dxGantt-dialogTaskDetailsTitle": "Task Details",
  "dxGantt-dialogEditResourceListHint": "Edit Resource List",
  "dxGantt-dialogEditNoResources": "No resources",
  "dxGantt-dialogButtonAdd": "Add",
  "dxGantt-contextMenuNewTask": "New Task",
  "dxGantt-contextMenuNewSubtask": "New Subtask",
  "dxGantt-contextMenuDeleteTask": "Delete Task",
  "dxGantt-contextMenuDeleteDependency": "Delete Dependency",
  "dxGantt-dialogTaskDeleteConfirmation": "Deleting a task also deletes all its dependencies and subtasks. Are you sure you want to delete  this task?",
  "dxGantt-dialogDependencyDeleteConfirmation": "Are you sure you want to delete  the dependency from the task?",
  "dxGantt-dialogResourcesDeleteConfirmation": "Deleting a resource also deletes it from tasks to which this resource is assigned. Are you sure you want to delete  these resources? Resource: {0}",
  "dxGantt-dialogConstraintCriticalViolationMessage": "The task you are attempting to move is linked to a second task by a dependency relation. This change would conflict with dependency rules. How would you like to proceed?",
  "dxGantt-dialogConstraintViolationMessage": "The task you are attempting to move is linked to a second task by a dependency relation. How would you like to proceed?",
  "dxGantt-dialogCancelOperationMessage": "Cancel the operation",
  "dxGantt-dialogDeleteDependencyMessage": "Delete the dependency",
  "dxGantt-dialogMoveTaskAndKeepDependencyMessage": "Move the task and keep the dependency",
  "dxGantt-undo": "실행 취소",
  "dxGantt-redo": "Redo",
  "dxGantt-expandAll": "Expand All",
  "dxGantt-collapseAll": "Collapse All",
  "dxGantt-addNewTask": "Add New Task",
  "dxGantt-deleteSelectedTask": "Delete Selected Task",
  "dxGantt-zoomIn": "Zoom In",
  "dxGantt-zoomOut": "Zoom Out",
  "dxGantt-fullScreen": "Full Screen"
}