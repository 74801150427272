<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <div class="dx-field">
        <div class="dx-field-label">건설 현장</div>
        <div class="dx-field-value">
          <DxSelectBox
            :search-enabled="true"
            placeholder=""
            value-expr="code"
            display-expr="nameCode"
            width="350"
            :data-source="constructionSiteList"
            @value-changed="changedConstructionSiteCode"/>
        </div>
      </div>

      <DxToolbar>
        <DxItem
          :options="btnOptDownloadCOA"
          location="after"
          widget="dxButton"
        />
        <!-- <DxItem
          :options="btnOptPrintCOA"
          location="after"
          widget="dxButton"
        /> -->
      </DxToolbar>
      <pdf id="coa" :src="pdfdata" :ref="pdfRefName"></pdf>
    </DxScrollView>
  </div>
</template>

<script>
  import {DxScrollView} from "devextreme-vue/scroll-view";
  import {DxSelectBox} from "devextreme-vue/select-box";   
  import DxToolbar, {DxItem} from "devextreme-vue/toolbar";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  import {inspType} from "../../utils/syscodedata";
  import jsPDF from "jspdf"; 
  import "jspdf-autotable";
  import pdf from "vue-pdf"

  var doc;
      
  export default {
      components: {
        DxScrollView,
        DxSelectBox,
        pdf,
        DxToolbar,
        DxItem
      },
      data: function() {
        return {
          constructionSiteList: [],
          constructionSiteCode: '',
          inspType: inspType,
          pdfRefName: 'pdfCOA',
          pdfdata: undefined,
          pdfTitle: '',
          btnOptDownloadCOA: {
            icon: 'download',
            onClick: this.downloadCOA.bind(this)
          },
          btnOptPrintCOA: {
            icon: 'print',
            onClick: this.printCOA.bind(this)
          }          
        };
      },
      mounted(){
        dsu.getAxiosConstructionSiteList().then(res => {this.constructionSiteList = res.data.data});
      },    
      methods: {
        changedConstructionSiteCode(e){
          this.constructionSiteCode = e.value;
          var params = {constructionSiteCode: this.constructionSiteCode};
          
          this.clearPDFViewer();

          dsu.getAxiosOBJGet('/api/mes/MISC/selectConstructionSiteInspectionResult', params).then(res=>{
            let response = res.data;
            if(response.result == 'SUCCESS'){
              
              let data = response.data;
              
              if(data.length == 0){
                fn.notifyError('성적서 데이터 없음');
                return;
              }else{

                let inspTypeStr = "건설 현장 검사"
                let inspDocNo = 'IK-QP-708-02';
                
                const margin1st = 50;
                const margin2nd = 70;
                  
                const title = inspTypeStr + ' 성적서';
                this.pdfTitle = title + '_' + data[0].name + '_' + this.constructionSiteCode + '.pdf';
                
                var finalResult = '합격';
                if(data.filter(function(e){ return e['judgement'] === 'FAIL';})[0]){
                  finalResult = '불합격';
                }

                doc.addFont(require('@/assets/malgun.ttf'), 'malgun', 'normal');
                doc.setFont('malgun');
                
                doc.autoTable({
                  theme: 'plain',
                  styles: {halign: 'center', fontSize: 20, font: "malgun"} ,
                  body: [{ content: title}]
                });

                doc.text('Lot 정보', 50, doc.lastAutoTable.finalY + margin1st);
                doc.autoTable({
                  theme: 'grid',
                  styles: {font: "malgun"},
                  startY: doc.lastAutoTable.finalY + margin2nd, 
                  head: [['항목', '값']],
                  body: [
                    ['건설 현장 코드', this.constructionSiteCode],
                    ['건설 현장 명', data[0].name],
                    ['고객(발주 처)', data[0].customer],
                    ['현장 관리부 팀장 ', data[0].userId],
                    ['시공 시작 일', data[0].startDate],
                    ['시공 완료 일 ', data[0].endDate],
                    ['감리 담당', '']
                  ],
                })
          
                doc.text('설계 Spec/실측 확인 결과', 50, doc.lastAutoTable.finalY + margin1st);
                
                doc.autoTable({
                  theme: 'grid',
                  styles: {font: "malgun"},
                  startY: doc.lastAutoTable.finalY + margin2nd, 
                  columns: [
                    { header: '항목', dataKey: 'item' },
                    { header: 'LSL', dataKey: 'lsl' },
                    { header: 'USL', dataKey: 'usl' },
                    { header: '결과', dataKey: 'result' },
                    { header: '단위', dataKey: 'unit' },
                    { header: '판정', dataKey: 'judgement' }
                  ],
                  body: data,
                });
        
                doc.autoTable({
                  theme: 'plain',
                  styles: {halign: 'center', fontSize: 25, font: "malgun"},
                  startY: doc.lastAutoTable.finalY + margin2nd, 
                  body: [{ content: '최종 결과  : ' + finalResult}]
                });

                doc.text('(주)일강케이스판', 30, 800);
                doc.text('표준문서 : ' +  inspDocNo, 385, 800);
                                      
                this.pdfdata =  doc.output('bloburl', {filename: this.pdfTitle});
              }

            }else{
              fn.notifyError(response.msg);
            }
          });
        },  
        clearPDFViewer(){
          doc = new jsPDF('p', 'pt', 'a4');
          this.pdfdata = undefined;
          this.pdfTitle = '';
        },
        downloadCOA(){
          if(this.pdfdata){
            doc.save(this.pdfTitle);
          }
        },
        printCOA(){
          if(this.pdfdata){
            this.$refs.pdfCOA.print();
          }
        }
      }
    };
</script>

<style scoped>
  #Container{
    height: calc( 100vh - 50px );
    width: 100%;
    float: left;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background-color:white;
  }
  
  .dx-field-label{
    width: 75px;
    font-size: 14px;
    font-weight: 700;
    color: black;
  }
  .coa{
    margin-top: 10px;
    width: 100%;
    max-width: 800px;
    height: 100vh;
  }
 
</style>