<template>
  <div>
    <DxDataGrid
      id="gridContainer"
      :ref="gridRefName"
      :dataSource="lotList"
      :show-borders="true"
      :show-column-lines="true"
      :columnAutoWidth="true"
      :selection="{ mode: 'single' }"
      @toolbar-preparing="onToolbarPreparing($event)"
      @row-inserting="onRowInserting"
      @row-updating="onRowUpdating"
      @init-new-row="onInitNewRow"
      @editing-start="onEditingStart">
    
      <DxScrolling mode="infinite"/>
      <DxSorting mode="none"/>
      <DxEditing mode="popup"
        :allow-adding="true"
        :allow-updating="true"
        :allow-deleting="true"/>
      <DxColumn type="buttons">
        <DxButton name="edit"/>
      </DxColumn>

      <DxColumn
        data-field="mainLot"
        caption="공급업체 Lot">
        <DxRequiredRule/>
        <DxPatternRule
          pattern="^[A-Z0-9-_]{0,30}$"
          message="영문 대문자 숫자 '-(대쉬)' '_(언더 바)' 로만 구성, 30자 까지 등록 가능"/>
      </DxColumn>

      <DxColumn
        data-field="code"
        caption="자재 코드(자재 명)"
        :set-cell-value="setCellValueMaterial">  
        <DxRequiredRule/>
        <DxLookup
          :data-source="materialList"
          value-expr="code"
          display-expr="codeName"/>
      </DxColumn>

      <DxColumn
        data-field="quantity"
        caption="수량"
        data-type="number">
        <DxRequiredRule/>
        <DxPatternRule
          pattern="^[1-9]{1}[0-9]{0,9}$"
          message="1 ~ 9999999999 까지 등록 가능"/>  
      </DxColumn>

      <DxColumn
        data-field="unit"
        caption="단위"
        :allowEditing="false">  
      </DxColumn>

      <DxColumn
        data-field="operation"
        caption="공정">  
        <DxRequiredRule/>
        <DxLookup
          :data-source="operationList"
          value-expr="operation"
          display-expr="codeName"/>
      </DxColumn>

      <DxColumn
        data-field="lotType"
        caption="Lot Type">  
        <DxRequiredRule/>
        <DxLookup
          :data-source="lotType"
          value-expr="ID"
          display-expr="Name"/>
      </DxColumn>

      <DxColumn
        data-field="supplier"
        caption="공급업체"> 
        <DxRequiredRule/>
        <DxLookup
          :data-source="supplierList"
          value-expr="name"
          display-expr="name"/>
      </DxColumn>

      <DxColumn
        data-field="shipTo"
        caption="건설 현장"
        :set-cell-value="setCellValueShipTo"> 
        <DxLookup
          :data-source="constructionSiteList"
          :allow="true"
          value-expr="code"
          display-expr="name"
          :allow-clearing="true"/>
      </DxColumn>    

      <DxColumn
        data-field="requestMaterialSeq"
        caption="청구 번호"
        cell-template="requestMaterialSeqCellTemplate"> 
        <DxLookup
          :data-source="requestSeqList"
          :allow="true"
          value-expr="seq"
          display-expr="seq"
          :allow-clearing="true"/>
      </DxColumn>    

      <DxColumn
        data-field="category"
        caption="품목"
        :allowEditing="false">  
      </DxColumn>
      
      <DxColumn
        data-field="memo"
        caption="Memo">
        <DxPatternRule
          pattern="^.{0,300}$"
          message="300자 까지 등록 가능"/>  
      </DxColumn>

      <DxColumn
        data-field="certNo"
        caption="증명서 번호">
        <DxPatternRule
          pattern="^.{0,50}$"
          message="50자 까지 등록 가능"/>  
      </DxColumn>

      <DxColumn
        data-field="millSheet"
        caption="원자재 성적서"
        cell-template="mill-sheet-cell-template"
        edit-cell-template="file-upload-template-millsheet">
      </DxColumn>
      
      <DxColumn
        data-field="shippingCertificate"
        caption="출하 성적서"
        cell-template="shipping-Certificate-cell-template"
        edit-cell-template="file-upload-templateShippingCertificate">
      </DxColumn>

      <template #requestMaterialSeqCellTemplate="{ data }">
          {{data.data.requestMaterialSeq}}
      </template>

      <template #mill-sheet-cell-template="{ data }">
          {{getFileName(data.data.millSheet)}}
      </template>

      <template #shipping-Certificate-cell-template="{ data }">
          {{getFileName(data.data.shippingCertificate)}}
      </template>

      <template #file-upload-template-millsheet="{data}">
        <div>
          <div class="uploadedFileName">{{getFileName(selectedFileIdMillSheet)}}
            <DxButton v-if="selectedFileIdMillSheet"
              class="button"
              text="삭제"
              icon="remove"
              width="90"
              @click="deleteMillSheet($event, data)"/>
          </div>
          <DxFileUploader
            :ref="fileUploaderMillSheetRefName"
            width="400"
            name="file"
            :uploadHeaders="requestHeadersMillSheet"
            @uploaded="onUploadedMillSheet($event, data)"
            upload-url="/api/mes/MMIS/uploadLotFile"
            select-button-text="PDF 파일 선택"
            accept=".pdf"
            :max-file-size="4800000"/>
          </div>
      </template>  

      <template #file-upload-templateShippingCertificate="{data}">
        <div>
          <div class="uploadedFileName">{{getFileName(selectedFileIdShippingCertificate)}}
            <DxButton v-if="selectedFileIdShippingCertificate"
              class="button"
              text="삭제"
              icon="remove"
              width="90"
              @click="deleteShippingCertificate($event, data)"/>
          </div>
          <DxFileUploader
            :ref="fileUploaderShippingCertificateRefName"
            width="400"
            name="file"
            :uploadHeaders="requestHeadersShippingCertificate"
            @uploaded="onUploadedShippingCertificate($event, data)"
            upload-url="/api/mes/MMIS/uploadLotFile"
            select-button-text="PDF 파일 선택"
            accept=".pdf"
            :max-file-size="4800000"/>
          </div>
      </template>  
    </DxDataGrid>

    <DxPopup :ref="popupRefName"
          :drag-enabled="false"
          :close-on-outside-click="true"
          :show-title="true"
          :width="1100"
          :height="600"
          :title="'건설 현장 사용 자재'">
      
      <div class="dx-field">
        <div class="dx-field-label">건설 현장</div>
        <div class="dx-field-value">
          <DxSelectBox
            :search-enabled="true"
            placeholder=""
            value-expr="code"
            display-expr="nameCode"
            width="500"
            :data-source="constructionSiteList"
            @value-changed="changedConstructionSiteCode"/>
        </div>
      </div>

      <DxDataGrid
          :ref="gridReImportRefName"
          :dataSource="reImportLotList"
          :show-borders="true"
          :show-column-lines="true"
          :columnAutoWidth="true"
          :selection="{mode: 'single'}"
          :height="390">
        
        <DxEditing :allow-updating="true" mode="row"/>
        <DxScrolling mode="infinite"/>
        <DxSorting mode="multiple"/>
        
        <DxColumn
          data-field="lotNumber"
          caption="Lot No."
          :allowEditing="false">
        </DxColumn>

        <DxColumn
          data-field="code"
          caption="자재 코드"
          :allowEditing="false">
          <DxLookup
            :data-source="materialList"
            value-expr="code"
            display-expr="codeName"/>
        </DxColumn>    
        
        <DxColumn
          data-field="quantity"
          caption="수량"
          :allowEditing="false">
        </DxColumn>

        <DxColumn
          data-field="unit"
          caption="단위"
          :allowEditing="false">
        </DxColumn>
        
        <DxColumn
          data-field="reImportQuantity"
          caption="재입고 수량"
          data-type="number">
        </DxColumn>

        <DxColumn
          data-field="reImportOperation"
          caption="재입고 공정"
          :width="260">
          <DxLookup
            :data-source="operationList"
            value-expr="operation"
            display-expr="codeName"/>
        </DxColumn> 
      </DxDataGrid>    

      <div class='buttonContainer'>
        <DxButton
          id="btnAdd"
          class="button"
          text="추가"
          icon="add"
          width="130"
          @click="addReImportLots"/>
      </div>      
    </DxPopup>
  </div>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxEditing,
          DxRequiredRule,
          DxLookup,
          DxPatternRule
        } from "devextreme-vue/data-grid";
  import DxPopup from "devextreme-vue/popup";
  import {DxSelectBox} from "devextreme-vue/select-box";   
  import DxButton from "devextreme-vue/button";
  import { DxFileUploader } from "devextreme-vue/file-uploader";     
  import {lotType} from "../../utils/syscodedata"; 
  import dsu from "../../utils/dataSourceUtils"; 
  import fn from "../../utils/function";

  export default {
    components: {
          DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxEditing,
          DxRequiredRule,
          DxLookup,
          DxPatternRule,
          DxPopup,
          DxSelectBox,
          DxButton,
          DxFileUploader
    },
    data() {
      return {
        lotList: [],
        gridRefName: 'lotList',
        gridReImportRefName: 'reImportLotList',
        materialList: [],
        operationList: [],
        supplierList: [],
        constructionSiteList: [],
        requestSeqList: [],
        constructionSiteCode: '',
        lotType: lotType,
        copyCnt: 1,
        reImportLotList: [],
        popupRefName: 'popup',
        mTransTime: null,
        formData: {},
        requestHeadersMillSheet: { 'token': dsu.getHeaders().token, 'userId': dsu.getHeaders().userId, 'fileType': 'MILL_SHEET'},
        requestHeadersShippingCertificate: { 'token': dsu.getHeaders().token, 'userId': dsu.getHeaders().userId, 'fileType': 'SHIPPING_CERTIFICATE'},
        selectedFileIdMillSheet: '',
        selectedFileIdShippingCertificate: '',
        fileUploaderMillSheetRefName: 'fuMillSheeet',
        fileUploaderShippingCertificateRefName: 'fuShippingCertificate'
      };
    },
    mounted(){
      dsu.getAxiosMaterialList().then(res => {this.materialList = res.data.data});
      dsu.getAxiosSupplierList().then(res => {this.supplierList = res.data.data});
      dsu.getAxiosOperationList({eqpOper: 'N'}).then(res => {this.operationList = res.data.data}); 
      dsu.getAxiosConstructionSiteList().then(res => {this.constructionSiteList = res.data.data});
    },
    computed: {
      grid: function () { return this.$refs[this.gridRefName].instance; },
      fileUploaderMillSheet: function () { return this.$refs[this.fileUploaderMillSheetRefName].instance; },
      fileUploaderShippingCertificate: function () { return this.$refs[this.fileUploaderShippingCertificateRefName].instance; }
    },
    methods: {
        onUploadedMillSheet(e, data) {
          const response = JSON.parse(e.request.responseText);
          if(response.result == 'SUCCESS'){
            this.selectedFileIdMillSheet = response.msg;
            data.data.millSheet = response.msg;
          }else{
            fn.notifyError('업로드 실패');
            e.component.option('uploadedMessage', "업로드 실패");
          }
          e.component.reset();
        },
        onUploadedShippingCertificate(e, data) {
          const response = JSON.parse(e.request.responseText);
          if(response.result == 'SUCCESS'){
            this.selectedFileIdShippingCertificate = response.msg;
            data.data.shippingCertificate = response.msg;
          }else{
            fn.notifyError('업로드 실패');
            e.component.option('uploadedMessage', "업로드 실패");
          }
          e.component.reset();
        },
        onToolbarPreparing(e) {
          e.toolbarOptions.items.unshift(
           {location: 'before', 
            widget: 'dxNumberBox',
            options: {
              width: 45,
              text: '복사 수량 : ',
              value: this.copyCnt,
              min: 1,
              max: 99,
              showSpinButtons: true,
              onValueChanged: this.setCopyCnt.bind(this)
           }},
           {location: 'before',
            widget: 'dxButton',
            options: {
              width: 100,
              icon: 'copy',
              text: '복사',
              stylingMode:"outlined",
              onClick: this.copyLot.bind(this)
              }
            },
            {location: 'before',
              widget: 'dxDateBox',
              options: {
                placeholder: '입고 시간',
                width: 220,
                type: 'datetime',
                displayFormat:'yyyy-MM-dd HH:mm:ss',
                showClearButton: true,
                onValueChanged: this.setMTransTime.bind(this)
           }},
           {location: 'before',
            widget: 'dxButton',
            options: {
              width: 100,
              icon: 'save',
              text: '입고',
              stylingMode:"outlined",
              onClick: this.executeImport.bind(this)
           }},
           {location: 'after',
            widget: 'dxButton',
            options: {
              width: 80,
              text: '재입고',
              stylingMode:"outlined",
              onClick: this.showReImport.bind(this)
            }}
          );
        },
        setCopyCnt(event){
          this.copyCnt = event.value;
        },
        setMTransTime(event) {
          this.mTransTime = event.value;
        },
        copyLot(){
          var newLotList = [];
          const grid = this.$refs[this.gridRefName].instance; 
          let selectedRows = grid.getSelectedRowsData();

          if(selectedRows.length == 0){
            fn.notifyError('Lot 선택 후 진행 필요');
            return;
          }

          for (var i = 0; i < this.lotList.length; i++) {
            newLotList.push(this.lotList[i]);
          }

          for (var j = 0; j < this.copyCnt; j++){
            let obj = JSON.parse(JSON.stringify(selectedRows[0]));
            obj.__KEY__ = Math.random();
            newLotList.push(obj);
          }

          this.lotList = JSON.parse(JSON.stringify(newLotList));
          grid.option('dataSource', this.lotList);

        },
        async executeImport(e){
          if(this.lotList.length == 0)
          {
            fn.notifyError('입고할 Lot 정보를 입력 후 진행 필요');
            return;
          }

          console.log(this.lotList);
          e.component.option('disabled', true);

          var params = {};
          if(this.mTransTime instanceof Date){
            params.mTransTime = dsu.getConvTransTime(this.mTransTime);
          } else {
            params.mTransTime = '';
          }
          params.lotList = JSON.stringify(this.lotList);

          await dsu.getAxiosOBJPost('/api/mes/MMIS/createLot', params).then(res => {
              let response = res.data;

              if(response.result == 'SUCCESS')
              {
                fn.alert(response.msg);
                const grid = this.$refs[this.gridRefName].instance;
                this.lotList = [];
                grid.option('dataSource', this.lotList);
              }
              else
              {
                fn.alert(response.msg);
              }
              e.component.option('disabled', false);
          });
        },  
        setCellValueMaterial(newData, value) {
          newData.code = value;
          const materialInfo = this.materialList.filter(function(e){
                                    return e['code'] === value;
                              })[0];
          newData.unit = materialInfo.unit;
          newData.category = materialInfo.category;
          newData.codeName = materialInfo.codeName;
        },
        setCellValueShipTo(newData, value) {
          newData.shipTo = value;
          newData.requestMaterialSeq = '';
          this.requestSeqList = [];
          
          if (value){ 
            var params = {constructionSiteCode: value};

            dsu.getAxiosOBJGet('/api/erp/EEHM/selectRequestMaterialNoList', params).then(res=>{
              let response = res.data;
              if(response.result == 'SUCCESS'){
                this.requestSeqList = response.data;
                this.requestSeqList.unshift({seq: '0'});
              }else{
                fn.notifyError(response.msg);
              }
            });
          }
   
        },
        onRowInserting(e){
          if(!this.checkValue(e.data)){
            e.cancel = true;
          }

          e.data.millSheet = this.selectedFileIdMillSheet;
          e.data.shippingCertificate = this.selectedFileIdShippingCertificate;
        },
        onRowUpdating(e){
          let data = {};
          Object.assign(data, e.oldData);
          Object.assign(data, e.newData);

          if(!this.checkValue(data)){
            e.cancel = true;
          }
        },
        checkValue(data){
          if(data.operation == '9000'){
            if(!data.shipTo){
              fn.notifyError('9000번 입고 시 건설 현장 선택 필요');
              return false;
            }

            if(!data.requestMaterialSeq){
              fn.notifyError('9000번 입고 시 청구 번호 선택 필요');
              return false;
            }
          }else{
            if(data.shipTo){
              fn.notifyError('9000번 입고 시 에만 건설 현장 선택 필요');
              return false;
            }

            if(data.requestMaterialSeq){
              fn.notifyError('9000번 입고 시 에만 청구 번호 선택 필요');
              return false;
            }
          }

          return true;
        },
        showReImport(){
          this.$refs[this.popupRefName].instance.show();
        },
        changedConstructionSiteCode(e){
          var params = {shipTo: e.value, withTerminateLot: 'Y'};
          this.reImportLotList = [];

          dsu.getAxiosOBJGet('/api/mes/MVLM/select', params).then(res=>{
            let response = res.data;
            if(response.result == 'SUCCESS'){
              this.reImportLotList = response.data;
            }else{
              fn.notifyError(response.msg);
            }
          });
        },
        addReImportLots(){
          var finaReImportLotList = [];

          for (var i = 0; i < this.reImportLotList.length; i++) {
            if(this.reImportLotList[i].reImportQuantity || this.reImportLotList[i].reImportOperation){ 
              if(!this.reImportLotList[i].reImportOperation){ 
                fn.notifyError(this.reImportLotList[i].lotNumber + ' 재입고 공정 입력 필요');
                return;
              }
              
              if(!this.reImportLotList[i].reImportQuantity){ 
                fn.notifyError(this.reImportLotList[i].lotNumber + ' 재입고 수량 입력 필요');
                return;
              }

              if(this.reImportLotList[i].reImportQuantity < 1){ 
                fn.notifyError(this.reImportLotList[i].lotNumber + ' 1 이상의 재입고 수량 입력 필요');
                return;
              }

              var reImportLot = {};

              reImportLot.mainLot = this.reImportLotList[i].lotNumber;
              reImportLot.code = this.reImportLotList[i].code;
              reImportLot.unit = this.reImportLotList[i].unit;
              reImportLot.supplier = this.reImportLotList[i].supplier;
              reImportLot.category = this.reImportLotList[i].category;
              reImportLot.lotType = 'R';
              reImportLot.quantity = this.reImportLotList[i].reImportQuantity;
              reImportLot.operation = this.reImportLotList[i].reImportOperation;
              
              finaReImportLotList.unshift(reImportLot);
            }
          }

          if(finaReImportLotList.length == 0){
            fn.notifyError('재입고 정보 입력 후 진행 필요');
            return;
          }

          for (i = 0; i < finaReImportLotList.length; i++) {
            this.lotList.unshift(finaReImportLotList[i]);
          }
          
          this.$refs[this.popupRefName].instance.hide();
      },
      onInitNewRow(){
        this.selectedFileIdMillSheet = '';
        this.selectedFileIdShippingCertificate = '';
      },
      onEditingStart(e){
        this.selectedFileIdMillSheet = e.data.millSheet;
        this.selectedFileIdShippingCertificate = e.data.shippingCertificate;
      },
      getFileName(fileInfo) {
        if(fileInfo){
          return fileInfo.substring(0, fileInfo.lastIndexOf('/'))
        } else {
          return '';
        }
      },
      deleteMillSheet(e, data) {
        this.fileUploaderMillSheet.reset();  
        data.data.millSheet = '';
        this.selectedFileIdMillSheet = '';
      },
      deleteShippingCertificate(e, data) {
        this.fileUploaderShippingCertificate.reset();  
        data.data.shippingCertificate = '';
        this.selectedFileIdShippingCertificate = '';
      }
    }
  }
</script>

<style lang="scss">
  #gridContainer {
    height: calc( 100vh - 50px );
  }
  
  .dx-field-label{
    width: 120px;
    float: right;
    font-size: 16px;
    font-weight: 500;
    color: black;
  }

  .buttonContainer{
    padding-top: 15px;
    padding-right: 15px;
  }

  #btnAdd{
    float: right;
  }

  .uploadedFileName{
    padding-left: 12px;
    padding-right: 300px;
    padding-top: 10px;
    font-size: 16px;
    font-weight: bold;
  }
</style>