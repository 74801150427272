import {
    alert,
    confirm
} from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import ds from './dataSourceUtils';
import axios from 'axios';
export default {
    notifySuccess(msg) {
        notify({ message: msg, position: 'top' }, "success", 2000);
    },
    notifyError(msg) {
        notify({ message: msg, position: 'top' }, "error", 2500);
    },
    alert(msg) {
        return alert(msg, '결과');
    },
    confirm(msg) {
        return confirm(msg, '확인');
    },
    printLotLabel(lotList) {
        var params

        if (Array.isArray(lotList)) {
            params = { lotList: JSON.stringify(lotList) };
        } else {
            params = { lotList: JSON.stringify([lotList]) };
        }
        ds.getAxiosOBJPost('/api/printLotLabel', params).then(res => {
            let response = res.data;

            if (response.result == 'SUCCESS') {
                this.notifySuccess(response.msg);
            }
            else {
                this.notifyError(response.msg);
            }
        });
    },
    printBatchLabel(batchId) {
        var params = { batchId: batchId };

        ds.getAxiosOBJPost('/api/printBatchLabel', params).then(res => {
            let response = res.data;
            if (response.result != 'SUCCESS') {
                this.notifyError(response.msg);
            }
        });
    },
    downLoadFile(e) {
        var columnName = e.column.dataField;
        if (columnName) {
            if (columnName.toUpperCase().includes('FILE') && e.value) {
                var filePath = e.data[columnName + 'Path'];
                window.location.href = '/fileDownload?file=' + encodeURIComponent(filePath);
            }
        }
    },
    async downLoadFileFromId(e) {
        var columnName = e.column.dataField;
        if (columnName) {
            if (columnName.toUpperCase().includes('FILEID') && e.value) {
                var params = {};
                ds.setDefaultParams(params);
                params.fileId = e.data[columnName];
                const response = await axios({
                    url: '/fileDownloadFromId', // 파일 URL을 여기에 넣으세요
                    method: 'GET',
                    params: params,
                    headers: ds.getHeaders(),
                    responseType: 'blob', // 파일 데이터를 Blob 형식으로 받음
                });
                console.log(response);
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = decodeURIComponent(response.headers['filename']); // 다운로드할 파일명을 여기에 넣으세요
                link.click();
            }
        }
    },
    getRandomInRange(min, tg, max, decimalPlaces) {
        // if (tg) {
        //     decimalPlaces = this.getDecimalPlaces(tg);
        //     let factor = Math.pow(10, decimalPlaces);
        //     let minTg = ((tg * factor) - 1) / factor;
        //     let maxTg = ((tg * factor) + 1) / factor;

        //     if (min < minTg) {
        //         min = minTg;
        //     }

        //     if (max > maxTg) {
        //         max = maxTg;
        //     }

        //     decimalPlaces = decimalPlaces + 1;
        // }
        const randomValue = Math.random() * (parseFloat(max) - parseFloat(min)) + parseFloat(min);
        return parseFloat(randomValue).toFixed(decimalPlaces);
    },
    getDecimalPlaces(num) {
        // 숫자를 문자열로 변환
        const numStr = num.toString();

        // 소수점이 있는지 확인
        if (numStr.includes('.')) {
            // 소수점 위치를 찾고, 소수점 이하의 길이를 반환
            return numStr.split('.')[1].length;
        } else {
            // 소수점이 없는 경우 소수 자릿수는 0
            return 0;
        }
    }
}