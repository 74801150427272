<template>
  <DxDataGrid
    :data-source="historyStore"
    :show-borders="true"
    :show-column-lines="true"
    :columnAutoWidth="true"
    :focused-row-enabled="true">
  
    <DxColumn data-field="loginTimeStr" caption="로그인 시간"/>
  </DxDataGrid>
</template>

<script>
  import {DxDataGrid,
           DxColumn} from "devextreme-vue/data-grid";
  import {createStore} from "devextreme-aspnet-data-nojquery";
  import store from "../../store";
  
  export default {
    components: {
      DxDataGrid,
      DxColumn
    },
    props: {
      masterDetailData: {
          type: Object,
          default: () => ({})
      }      
    },
    computed: {
      historyStore() {
          return this.masterDetailData.key === null ? null : {
          store: createStore({
            loadUrl: `/api/mes/MVUL/selectLoginHst`,
            key: "loginTimeStr",
            loadParams: {searchUserId: this.masterDetailData.key},
            onBeforeSend: (method, ajaxOptions) => {
               ajaxOptions.headers = { token: store.state.token, userId: store.state.userId };  
            }
          })
        };
      }
    }     
  };
</script>
