<template>
  <DxDataGrid
    id="gridContainer"
    :ref="gridRefName"
    :columnAutoWidth="true"
    :show-borders="true"
    :show-column-lines="true"
    :selection="{ mode: 'single' }"
    @exporting="onExporting">
    
    <DxSearchPanel :visible="true"/>
    <DxHeaderFilter :visible="true"/>
    <DxScrolling mode="infinite"/>
    <DxSorting mode="multiple"/>
    <DxExport :enabled="true"/>
        
    <DxColumn
      data-field="projectId"
      caption="Project ID"
      :header-filter="{ allowSearch: true }">
    </DxColumn>

    <DxColumn
      data-field="projectName"
      caption="공고 명"
      :header-filter="{ allowSearch: true }">
    </DxColumn>
 
    <DxColumn
      data-field="rank1"
      caption="1순위"
      :header-filter="{ allowSearch: true }">
    </DxColumn>
    
    <DxColumn
      data-field="successfulBidAmount"
      caption="낙찰 금액"
      :header-filter="{ allowSearch: true }">
    </DxColumn>

    <DxColumn
      data-field="resultsAnnouncementDate"
      caption="결과 발표일"
      data-type="date"
      :header-filter="{ allowSearch: true }"
      format="yyyy/MM/dd">
    </DxColumn>

    <DxColumn
      data-field="grossArea"
      caption="연면적"
      :header-filter="{ allowSearch: true }">
    </DxColumn>

    <DxMasterDetail
        :enabled="true"
        :auto-expand-all="true"  
        template="master-detail"/>
        <template #master-detail="{data}">
          <ProjectStatusDetail :data="data.data"/>
        </template>      
  </DxDataGrid>

</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxSearchPanel,
          DxMasterDetail} from "devextreme-vue/data-grid";
  import ProjectStatusDetail from "../../views/_common/PROJECT_STATUS_DETAIL.vue";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  
  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxSearchPanel,
      DxMasterDetail,
      ProjectStatusDetail
    },
    data() {
      return {
        gridRefName: 'grid'
      };
    },  
    mounted(){
      this.refreshData();
    },
    computed: {
      grid: function() { return this.$refs[this.gridRefName].instance; }
    },
    methods:{
      onExporting(e) {
        e.fileName = this.$route.meta.transName;
      },
      refreshData(){
        dsu.getAxiosOBJGet('/api/mes/MVPS/selectProjectSummaryInfo').then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            this.grid.option("dataSource", response.data);
          }else{
            fn.notifyError(response.msg);
          }
        });
      }
    }
  };
</script>

<style scoped>
  #gridContainer {
      height: calc( 100vh - 50px );
      width: 100%;
  }
</style>