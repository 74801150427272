<template>
  <DxDataGrid
    id="gridContainer"
    :data-source="dataSource"
    :selection="{ mode: 'single' }"
    :columnAutoWidth="true"
    :show-borders="true"
    :show-column-lines="true"
    @exporting="onExporting"
    @toolbar-preparing="onToolbarPreparing($event)"
    @selectionChanged="selectedRow"
    @init-new-row="onInitNewRow"
    @editing-start="onEditingStart"
    @cellDblClick="onDblClick">
    
    <DxSearchPanel :visible="true"/>
    <DxHeaderFilter :visible="true"/>
    <DxScrolling mode="infinite"/>
    <DxSorting mode="multiple"/>
    <DxExport :enabled="true"/>
    <DxEditing :allow-updating="true"
               :allow-adding="true"
               :allow-deleting="true"
               mode="popup"/>
     
    <DxColumn type="buttons">
      <DxButton name="edit"/>
    </DxColumn>
    
    <DxColumn
      data-field="id"
      caption="ID"
      :header-filter="{ allowSearch: true }">
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^[A-Z0-9-]{1,20}$"
        message="영문 대문자 숫자 - 로만 구성, 20자 까지 등록 가능"
      />
    </DxColumn>

    <DxColumn
      data-field="userName"
      caption="이름"
      :header-filter="{ allowSearch: true }">
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^.{2,10}$"
        message="10자 까지 등록 가능"
      />
    </DxColumn>
    
    <DxColumn
      data-field="branch"
      caption="지사">  
      <DxRequiredRule/>
      <DxLookup :data-source="branch"/>
    </DxColumn>
    
    <DxColumn
      data-field="dept"
      caption="부서">  
      <DxRequiredRule/>
      <DxLookup :data-source="dept"/>
    </DxColumn>
    
    <DxColumn
      data-field="jobGrade"
      caption="직급">  
      <DxRequiredRule/>
      <DxLookup :data-source="jobGrade" />
    </DxColumn>

    <DxColumn
      data-field="auth"
      caption="권한">
      <DxRequiredRule/>
      <DxLookup
        :data-source="userAuth"
        value-expr="ID"
        display-expr="Name"/>          
    </DxColumn>

    <DxColumn
      data-field="telephone"
      caption="유선 전화"
      :header-filter="{ allowSearch: true }">
      <DxPatternRule
        pattern="^.{0,12}$"
        message="12자까지 등록 가능"
      />
    </DxColumn> 

    <DxColumn
      data-field="cellphone"
      caption="휴대폰"
      :header-filter="{ allowSearch: true }">
      <DxPatternRule
        pattern="^.{0,13}$"
        message="13자까지 등록 가능"
      />
    </DxColumn> 

    <DxColumn
       data-field="signImageFileId"
       caption="서명"
       width="250px"
       :header-filter="{ allowSearch: false }"
       cellTemplate="image-cell-template"
      edit-cell-template="image-edit-template">
    </DxColumn>
  
    <DxMasterDetail
      :enabled="true"
      template="master-detail"/>
      <template #master-detail="{ data }">
        <History :master-detail-data="data"
                :url= "`/api/erp/EUSR/selectHistory`"
                :keyName="operation"
                :columns="historyColumns"/>
      </template>    


      <template #image-cell-template="{data}">
      <img v-if="data.data.signImageFileId"
              class='signImage' 
              :src="getImageURL(data.data.signImageFileId)"/>
      </template>

      <template #image-edit-template="{data}">
        <div>
          <img v-if="selectedSignImageFileId" 
              class='signImage'
              :src="getImageURL(selectedSignImageFileId)"/>
          <div class="actionContainer">
            <DxButton v-if="selectedSignImageFileId"
                  text="삭제"
                  icon="remove"
                  width="90"
                  @click="deleteSignImageImage(data)"/>
            <DxFileUploader
                width="400"
                name="file"
                :ref="fileUploaderSignFileRefName"
                :uploadHeaders="signUploadHeader"
                @uploaded="onUploadSignImageImage($event, data)"
                upload-url="/api/erp/EUSR/uploadSignImage"
                select-button-text="이미지 선택"
                accept="image/*"
                :max-file-size="4800000"/>
          </div>
        </div> 
      </template>  
    </DxDataGrid>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxLookup,
          DxRequiredRule,
          DxPatternRule,
          DxMasterDetail} from "devextreme-vue/data-grid";
  import History from "../_common/SIMPLE_HISTORY";
  import {jobGrade,
          branch,
          dept,
          userAuth} from "../../utils/syscodedata";
  import {DxFileUploader} from "devextreme-vue/file-uploader";     
  import DxButton from "devextreme-vue/button";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxLookup,
      DxRequiredRule,
      DxPatternRule,
      DxMasterDetail,
      History,
      DxFileUploader,
      DxButton
    },
    data() {
      return {
        dataSource: dsu.getComDSGrid('/api/erp/EUSR', 'id', '', 'ID'),
        jobGrade: jobGrade,
        dept: dept,
        userAuth: userAuth,
        branch: branch,
        selectedUserInfo: {},
        selectedSignImageFileId: '',
        fileUploaderSignFileRefName: 'fileUploaderSignFile',
        signUploadHeader: { 'token': dsu.getHeaders().token, 'userId': dsu.getHeaders().userId, 'fileType': 'SIGN_IMAGE' },
        historyColumns: [{dataField : "transTime", caption : "변경 시간"},
                         {dataField: "userName", caption: "이름"},
                         {dataField: "branch", caption: "지사"},
                         {dataField: "dept", caption: "부서"},
                         {dataField: "jobGrade", caption: "직급"},
                         {dataField: "auth", caption: "권한", lookup: {dataSource: userAuth, valueExpr: "ID", displayExpr: "Name"}},
                         {dataField: "telephone", caption: "유선 전화"},
                         {dataField: "cellphone", caption: "휴대폰"},
                         {dataField: "password", caption: "비밀번호" },
                         {dataField: "signImageFileId", caption: "서명", cellTemplate: "imageCellTemplate" },
                         {dataField: "userId", caption: "사용자"},
                         {dataField: "dml", caption: "Action"}]
      };
    },
    computed: {
      fileUploadersignImage: function () { return this.$refs[this.sign].fileUploaderSignFileRefName; }
    },
    methods:{
      onExporting(e) {
          e.fileName = this.$route.meta.transName;
      },
      onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',
            text: '비밀번호 초기화',
            stylingMode: "outlined",
            onClick: this.resetPassword.bind()
          }             
          
        });
      },
      selectedRow(event){
        this.selectedUserInfo = event.selectedRowsData[0];
      },
      resetPassword(){
        if(!this.selectedUserInfo.id){
          fn.notifyError('사용자 선택 후 진행 필요');
          return;
        }
        
        var params = {id: this.selectedUserInfo.id, password: 'ilkang'};

        dsu.getAxiosOBJGet('/api/erp/EUSR/update', params).then(res =>{
          let rtnMsg = res.data.msg;
          if(res.data.result == 'SUCCESS'){
            fn.notifySuccess('비밀번호 초기화 완료');
          }else{
            fn.notifyError(rtnMsg);
          }        
        });
      },
      getImageURL(fileId) {
        return encodeURI('/api/displayImage?fileId=' + fileId);
      },
      onUploadSignImageImage(e, data) {
        const response = JSON.parse(e.request.responseText);
        if (response.result == 'SUCCESS') {
          data.setValue(response.msg, response.msg);
          this.selectedSignImageFileId = response.msg;
        } else {
          fn.notifyError('업로드 실패');
          e.component.option('uploadedMessage', "업로드 실패");
        }
        e.component.reset();
      },
      deleteSignImageImage(data) {
        data.setValue('');
        this.selectedSignImageFileId = '';
        this.fileUploadersignImage.reset();
      },
      onInitNewRow() {
        this.selectedSignImageFileId = '';
      },
      onEditingStart(e) {
        this.selectedSignImageFileId = e.data.signImageFileId;
      },
      onDblClick(e){
        fn.downLoadFileFromId(e);
      },
    }
  };
</script>

<style scoped>
  #gridContainer {
      height: calc( 100vh - 50px );
      width: 100%;
  }

  .signImage {
    max-width: 350px;
  }

  .actionContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
    
</style>