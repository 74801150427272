<template>
  <DxDataGrid
    id="gridContainer"
    :data-source="dataSource"
    :focused-row-enabled="true"
    :columnAutoWidth="true"
    :show-borders="true"
    :show-column-lines="true"
    @exporting="onExporting">
    
    <DxSearchPanel :visible="true"/>
    <DxHeaderFilter :visible="true"/>
    <DxScrolling mode="infinite"/>
    <DxSorting mode="multiple"/>
    <DxExport :enabled="true"/>
    <DxEditing
      :allow-updating="true"
      :allow-adding="true"
      :allow-deleting="true"
      mode="popup">
      <DxTexts :confirmDeleteTitle = "'확인'" 
               :confirm-delete-message= "'각 Step별 입력된 데이터도 함께 삭제됩니다. 정말 삭제하시겠습니까?'"/>
    </DxEditing>
    
    <DxColumn type="buttons">
      <DxButton name="edit"/>
    </DxColumn>
        
    <DxColumn
      data-field="projectId"
      caption="Project ID"
      :allowEditing="false"
      :header-filter="{ allowSearch: true }">
    </DxColumn>

    <DxColumn
      data-field="regWeek"
      caption="주차"
      :allowEditing="false"
      :header-filter="{ allowSearch: true }">
      <DxFormItem :visible="false">
    </DxColumn>

    <DxColumn
      data-field="projectName"
      caption="공고 명"
      :header-filter="{ allowSearch: true }">
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^.{1,30}$"
        message="30자까지 등록 가능"/>
    </DxColumn>

    <DxColumn
      data-field="salesUserId"
      caption="영업 담당자"
      :allowEditing="false"
      :header-filter="{ allowSearch: true }">
      <DxFormItem :visible="false">
    </DxColumn>

    <DxColumn
      data-field="engrUserId"
      caption="기술 담당자"
      :allowEditing="false"
      :header-filter="{ allowSearch: true }">
      <DxFormItem :visible="false">
    </DxColumn>

    <DxColumn
      data-field="rank1"
      caption="1순위"
      :header-filter="{ allowSearch: true }">
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^.{1,20}$"
        message="20자까지 등록 가능"/>
    </DxColumn>
    
    <DxColumn
      data-field="branch"
      caption="지사">  
      <DxRequiredRule/>
      <DxLookup :data-source="branch"/>
    </DxColumn>
    
    <DxColumn
      data-field="rank1Address"
      caption="1순위 주소"
      :header-filter="{ allowSearch: true }">
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^.{1,70}$"
        message="70자까지 등록 가능"/>
    </DxColumn>

    <DxColumn
      data-field="rank1Tel"
      caption="1순위 연락처"
      :header-filter="{ allowSearch: true }">
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^.{1,15}$"
        message="15자까지 등록 가능"/>
    </DxColumn>

    <DxColumn
        data-field="designAmount"
        caption="설계 금액(원)"
        data-type="number"
        :format="{precision: 3}"
        :header-filter="{ allowSearch: true }"
        :allowEditing="false">
        <DxFormItem :visible="false">
    </DxColumn>

    <DxColumn
      data-field="successfulBidAmount"
      caption="낙찰 금액(원)"
      data-type="number"
      :format="{precision: 3}"
      :header-filter="{ allowSearch: true }"
      :editor-options="{format: {precision: 3}}">
      <DxRequiredRule/>
    </DxColumn>

    <DxColumn
      data-field="resultsAnnouncementDate"
      caption="결과 발표일"
      data-type="date"
      :header-filter="{ allowSearch: true }"
      format="yyyy/MM/dd">
      <DxRequiredRule/>
    </DxColumn>

    <DxColumn
      data-field="grossArea"
      caption="연면적(m2)"
      :header-filter="{ allowSearch: true }">
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^.{1,10}$"
        message="10자까지 등록 가능"/>
    </DxColumn>

    <DxColumn
      data-field="customerManager"
      caption="발주처 담당자"
      :header-filter="{ allowSearch: true }">
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^.{1,10}$"
        message="10자까지 등록 가능"/>
    </DxColumn>

    <DxColumn
      data-field="customerContact"
      caption="발주처 연락처"
      :header-filter="{ allowSearch: true }">
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^.{1,20}$"
        message="20자까지 등록 가능"/>
    </DxColumn>

    <DxMasterDetail
      :enabled="true"
      template="master-detail"
    />
      <template #master-detail="{ data }">
        <History :master-detail-data="data"
                :url= "`/api/erp/PREG/selectHistory`"
                :keyName="transTime"
                :columns="historyColumns"/>
      </template>    
    </DxDataGrid>

</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxRequiredRule,
          DxPatternRule,
          DxMasterDetail,
          DxLookup,
          DxTexts,
          DxFormItem} from "devextreme-vue/data-grid";
  import History from "../_common/SIMPLE_HISTORY";
  import dsu from "../../utils/dataSourceUtils";
  import {branch} from "../../utils/syscodedata";

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxRequiredRule,
      DxPatternRule,
      DxMasterDetail,
      History,
      DxLookup,
      DxTexts,
      DxFormItem
    },
    data() {
      return {
        dataSource: dsu.getComDSGrid('/api/erp/PREG', 'projectId', '', 'Project ID'),
        historyColumns: [{dataField: "transTime", caption : "변경 시간"},
                         {dataField: "projectId", caption : "Project ID"},
                         {dataField: "projectName", caption: "공고 명"},
                         {dataField: "rank1", caption: "1순위"},
                         {dataField: "branch", caption: "지사"},
                         {dataField: "rank1Address", caption: "1순위 주소"},
                         {dataField: "rank1Tel", caption: "1순위 연락처"},
                         {dataField: "successfulBidAmount", caption: "낙찰 금액"},
                         {dataField: "resultsAnnouncementDate", caption: "결과 발표일",},
                         {dataField: "grossArea", caption: "연면적"},
                         {dataField: "customerManager", caption: "발주처 담당자"},
                         {dataField: "customerContact", caption: "발주처 연락처"},
                         {dataField: "userId", caption: "사용자"},
                         {dataField: "dml", caption: "Action"}],
        branch: branch
      };
    },
    methods:{
       onExporting(e) {
          e.fileName = this.$route.meta.transName;
        }
    }
  };
</script>

<style scoped>
  #gridContainer {
      height: calc( 100vh - 50px );
      width: 100%;
  }
</style>