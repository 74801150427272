<template>
  <div>
    <DxToolbar>
      <DxItem
        widget="dxButton"
        :options="btnOptBarcode"
        location="before"/>
      <DxItem
        widget="dxButton"
        :options="btnOptPrintLabel"
        location="after"/>
      <DxItem
        widget="dxButton"
        :options="btnOptNewBatch"
        location="after"/>
    </DxToolbar>
    
    <div>
      <qrcode-stream 
          @decode="onDecode"
          @init="onInit"
          v-if="!destroyed">
          <div class="loading-indicator"
              v-if="loading">
              바코드 스캐너 로딩 중....
          </div>
      </qrcode-stream>
    </div>
    
    <DxForm :ref="formRefName"
            :form-data="formData">
      <DxSimpleItem 
        data-field="search"
        editor-type="dxTextBox"
        :editor-options="{inputAttr: {'style': 'text-transform: uppercase'}, onKeyUp:keyUp}">
        <DxLabel text="조회"/>
      </DxSimpleItem>
      
      <DxGroupItem caption="Batch 정보">
        <DxSimpleItem name="batchId">
          <template #default>
            <DxLabel text="Batch ID"/>  
            <DxTextBox
              :read-only="true"
              :value="formData.batchId"/>
          </template>
        </DxSimpleItem>

      </DxGroupItem>
    </DxForm>
  </div>
</template>
<script>
  import DxToolbar,
         {DxItem} from "devextreme-vue/toolbar";
  import {DxForm,
          DxGroupItem,
          DxSimpleItem,
          DxLabel} from "devextreme-vue/form";
  import {QrcodeStream} from "vue-qrcode-reader";
  import {DxTextBox} from "devextreme-vue/text-box";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function"

  export default {
    components: {
      DxForm,
      DxGroupItem,
      DxSimpleItem,
      DxLabel,
      DxToolbar,
      DxItem,
      DxTextBox,
      QrcodeStream},
    data: function() {
      return {
        formRefName: 'form',
        loading: false,
        destroyed: true,
        formData: {search: '', batchId: ''},
        searchBatchId: '',
        btnOptBarcode: {
            type: 'custom',
            text: '바코드',
            stylingMode:"outlined",
            onClick: this.toggle
        },
        btnOptPrintLabel: {
            type: 'custom',
            text: '라벨 발행',
            stylingMode:"outlined",
            onClick: this.printLabel
        },
        btnOptNewBatch: {
            type: 'custom',
            text: '신규 Batch 생성',
            stylingMode:"outlined",
            onClick: this.createNewBatch
        }
      };
    },
    methods: {
      clear(action){
        if(action == 'ALL'){
          this.$refs[this.formRefName].instance.resetValues();
          Object.keys(this.formData).map((key) => {
              this.formData[key] ='';
          });
          this.$emit('clearBatchInfoExt');    
        }else if(action == 'SEARCH'){
          this.formData.search = '';
        }
      },
      onDecode(result) {
        this.formData.search = result;
        this.searchBatchId = result;
        this.getBatchInfo();
      },
      async toggle(){
        this.destroyed = !this.destroyed;
        await this.$nextTick();
      },
      keyUp(e)
      {
        if (e.event.key == 'Enter'){
          this.getBatchInfo();
        }
      },
      createNewBatch(){
        dsu.getAxiosOBJGet('/api/mes/MCBT/createNewBatch').then(res => {
          
          let response = res.data;
          if(response.result == "SUCCESS"){
            if(response.data.length == 0){
              fn.notifyError('Batch 생성 실패');
              this.clear('ALL');
            }else{
              fn.notifySuccess(response.msg);
              this.formData.search = response.data;
              fn.printBatchLabel(response.data);
              this.getBatchInfo();
            }
          }else{
            fn.notifyError(response.msg);
            this.clear('ALL');
          }
        });
      },
      printLabel(){
        if(!this.formData.batchId){
            fn.notifyError('Batch 검색 후 진행필요');
            return;
        }
        fn.printBatchLabel(this.formData.batchId);
      },
      getBatchInfo(){
        var params = {};

        if(!this.formData.search){
            fn.notifyError('검색 값 입력 후 검색필요');
            return;
        }
        
        let search = this.formData.search.toUpperCase();
        this.formData.search = '';

        if(search.substring(0,1) == 'B'){
          params.batchId = search;
          dsu.getAxiosOBJGet('/api/mes/MCBT/selectBatchInfo', params).then(res => {
            var data = res.data.data;
            if(data.length ==0){
              fn.notifyError('존재하지 않는 Batch ID');
              this.clear('ALL');
            }else{
              fn.notifySuccess('Batch 조회 성공');
              this.formData.batchId = search;  
              
              this.$emit('setBatchInfoExt', data[0]);
            }
          });  
        }else{
          if(!this.formData.batchId){
              fn.notifyError('Batch 선택 후 진행 필요');
              return;
          }

          params.lotNumber = search;

          dsu.getAxiosOBJGet('/api/mes/MVLM/select', params).then(res => {
            if(res.data.data.length ==0){
              fn.notifyError('존재하지 않는 Lot No.');
              this.clear('SEARCH');
            }else{
              let currentBatchId = res.data.data[0].batchId;
              let operation = res.data.data[0].operation;

              if(operation != '4000'){
                fn.notifyError('Batch 구성은 4000번 공정 자재 창고에서만 가능');
                return;
              }

              if(currentBatchId){
                if(currentBatchId == this.formData.batchId){
                  fn.notifyError('이미 추가된 Lot');
                }else{
                  fn.notifyError('다른 Batch에 구성된 Lot');
                }
                return;
              }

              res.data.data[0].batchId = this.formData.batchId;
              this.$emit('addLot', res.data.data[0]);
            }
          }); 
        }
      }
    }
  };
</script>

<style scoped>
  .loading-indicator {
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
  }
</style>