<template>
  <div>
    <DxToolbar>
      <DxItem 
       v-if="!hideBarcode"
        widget="dxButton"
        :options="btnOptBarcode"
        location="before"/>
      <DxItem
        v-if="!hideExecute"
        widget="dxButton"
        :options="btnOptExecute"
        location="after"/>
    </DxToolbar>
    
    <div>
      <qrcode-stream 
          @decode="onDecode"
          @init="onInit"
          v-if="!destroyed">
          <div class="loading-indicator"
              v-if="loading">
              바코드 스캐너 로딩 중....
          </div>
      </qrcode-stream>
    </div>
    
    <DxForm :ref="formRefName"
            :form-data="formData">
      <DxSimpleItem 
        data-field="searchLot"
        editor-type="dxTextBox"
        :editor-options="{inputAttr: {'style': 'text-transform: uppercase'}, onKeyUp:keyUp}">
        <DxLabel text="Lot 조회"/>
      </DxSimpleItem>
      
      <DxGroupItem caption="Lot 정보">
        <DxSimpleItem name="lotNumber">
          <DxLabel text="Lot No."/>
          <template #default>
            <DxTextBox
              :read-only="true"
              :value="formData.lotNumber"/>
          </template>
        </DxSimpleItem>

        <DxSimpleItem  v-if="!hideCode" name="code">
          <DxLabel text="자재" />
          <template #default>
            <DxSelectBox
              placeholder=""
              value-expr="code"
              display-expr="codeName"
              readOnly="true"  
              :value="formData.code"
              :data-source="materialList"/>
          </template>
        </DxSimpleItem>              

        <DxSimpleItem v-if="!hideQuantity" name="quantity">
          <DxLabel text="수량"/>
          <template #default>
            <DxTextBox
              :read-only="true"
              :value="formData.quantity + ' ' + formData.unit"/>
          </template>
        </DxSimpleItem>

        <DxSimpleItem v-if="!hideOperation" name="operation">
          <DxLabel text="현재 공정" />
          <template #default>
            <DxSelectBox
              placeholder=""
              value-expr="operation"
              display-expr="codeName"
              readOnly="true"  
              :value="formData.operation"
              :data-source="operationList"/>
          </template>
          </DxSimpleItem>
      </DxGroupItem>
      <DxGroupItem caption="진행 시간 변경" :visible="!hideTimeEdit">
        <DxSimpleItem :visible="!hideTimeEdit" data-field="mTransTime" editor-type="dxDateBox" :editorOptions="{ type:'datetime', displayFormat:'yyyy-MM-dd HH:mm:ss', showClearButton: true}">
          <DxLabel text="진행 시간"/>
        </DxSimpleItem>
      </DxGroupItem>
    </DxForm>
  </div>
</template>
<script>
  import DxToolbar,
         {DxItem} from "devextreme-vue/toolbar";
  import {DxForm,
          DxGroupItem,
          DxSimpleItem,
          DxLabel} from "devextreme-vue/form";
  import {DxSelectBox} from "devextreme-vue/select-box";
  import {QrcodeStream} from "vue-qrcode-reader";
  import {DxTextBox} from "devextreme-vue/text-box";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function"

  export default {
    components: {
      DxForm,
      DxGroupItem,
      DxSimpleItem,
      DxLabel,
      DxToolbar,
      DxItem,
      DxSelectBox,
      DxTextBox,
      QrcodeStream},
    props: {
      hideCode: Boolean,
      hideQuantity: Boolean,
      hideOperation: Boolean,
      hideExecute: Boolean,
      hideBarcode: Boolean,
      hideTimeEdit: Boolean,
      withTerminateLot: Boolean
    },
    data: function() {
      return {
        formRefName: 'form',
        loading: false,
        destroyed: true,
        formData: {searchLot: '', quantity: '', unit: ''},
        materialList: [],
        operationList: [],      
        btnOptBarcode: {
            type: 'custom',
            text: '바코드',
            stylingMode:"outlined",
            onClick: this.toggle
        },
        btnOptExecute: {
            type: 'custom',
            text: '실행',
            stylingMode: "outlined",
            onClick: this.execute.bind(this)
        }
      };
  },
   mounted() {
      dsu.getAxiosMaterialList().then(res => {this.materialList = res.data.data});
      dsu.getAxiosOperationList({eqpOper : 'N'}).then(res => {this.operationList = res.data.data});
    },
    methods: {
      clear(){
        this.$refs[this.formRefName].instance.resetValues();
        Object.keys(this.formData).map((key) => {
            this.formData[key] ='';
        });
        this.$emit('clearLotInfoExt');    
      },
      execute(e){
        if (!this.formData.lotNumber){
            fn.notifyError('Lot 조회 후 진행 필요');
            return;
        }

        var params = JSON.parse(JSON.stringify(this.formData));
        
        if(this.formData.mTransTime instanceof Date){
          params.mTransTime = dsu.getConvTransTime(this.formData.mTransTime);
        } else {
          params.mTransTime = '';
        }
        
        this.$emit('execute', params, e);
      },
      async onInit(promise) {
        this.loading = true
        try {
          await promise
        } catch (error) {
          fn.alert(error);
          console.error(error)
        } finally {
          this.loading = false
        }
      },
      onDecode(result) {
        this.clear();
        this.formData.searchLot = result;
        this.getLotInfo();
        this.toggle();
      },
      async toggle(){
        this.destroyed = !this.destroyed;
        await this.$nextTick();
      },
      keyUp(e)
      {
        if (e.event.key == 'Enter'){
          this.getLotInfo();
        }
      },
      getLotInfo(){
        var params = {};

        if(!this.formData.searchLot){
            fn.notifyError('Lot No. 입력 후 검색필요');
            return;
        }

        params.lotNumber = this.formData.searchLot.toUpperCase();

        if(this.withTerminateLot){
          params.withTerminateLot = true;
        }

        dsu.getAxiosOBJGet('/api/mes/MVLM/select', params).then(res => {
          if(res.data.data.length == 0){
            fn.notifyError('존재하지 않는 Lot');
            this.clear();
          }else{
            this.formData = res.data.data[0];
            this.$emit('setLotInfoExt', this.formData);
          }
        });
      }
    }
  };
</script>

<style scoped>
  .loading-indicator {
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
  }
</style>