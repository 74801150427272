<template>
  <DxDataGrid
    id="gridContainer"
    :data-source="dataSource"
    :focused-row-enabled="true"
    :columnAutoWidth="true"
    :show-borders="true"
    :show-column-lines="true"
    @exporting="onExporting">
     
    <DxSearchPanel :visible="true"/>
    <DxHeaderFilter :visible="true"/>
    <DxScrolling mode="infinite"/>
    <DxSorting mode="multiple"/>
    <DxExport :enabled="true"/>
    <DxEditing
      :allow-updating="true"
      :allow-adding="true"
      :allow-deleting="true"
      mode="popup"/>
    
    <DxColumn type="buttons">
      <DxButton name="edit"/>
    </DxColumn>
    
    <DxColumn
      data-field="equipmentId"
      caption="장비 코드"
      :header-filter="{ allowSearch: true }">
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^[A-Z0-9-]{1,20}$"
        message="영문 대문자 숫자 - 로만 구성, 20자 까지 등록 가능"
      />
    </DxColumn>
    
    <DxColumn
      data-field="name"
      caption="장비 명"
      :header-filter="{ allowSearch: true }">
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^.{1,30}$"
        message="30자 까지 등록 가능"
      />
    </DxColumn>

    <DxColumn
      data-field="installDate"
      caption="설치 일"
      data-type="date"
      :header-filter="{ allowSearch: true }"
      format="yyyy/MM/dd"
      :editor-options="{ showClearButton: true}">
    </DxColumn>

    <DxColumn
      data-field="manufacturer"
      caption="제조사"
      :header-filter="{ allowSearch: true }">
      <DxPatternRule
        pattern="^.{1,30}$"
        message="30자 까지 등록 가능"
      />
    </DxColumn>

    <DxColumn
      data-field="model"
      caption="모델 명"
      :header-filter="{ allowSearch: true }">
      <DxPatternRule
        pattern="^.{1,20}$"
        message="20자 까지 등록 가능"
      />
    </DxColumn>
  
    <DxColumn
      data-field="serialNo"
      caption="Serial No."
      :header-filter="{ allowSearch: true }">
      <DxPatternRule
        pattern="^.{1,20}$"
        message="20자 까지 등록 가능"
      />
    </DxColumn>  

    <DxColumn
      data-field="manufactureDate"
      caption="제작 일"
      data-type="date"
      :header-filter="{ allowSearch: true }"
      format="yyyy/MM/dd"
      :editor-options="{ showClearButton: true}">
    </DxColumn>    
    
    <DxMasterDetail
      :enabled="true"
      template="master-detail"
    />
      <template #master-detail="{ data }">
        <History :master-detail-data="data"
                :url= "`/api/erp/EMEQ/selectHistory`"
                :keyName="equipmentId"
                :columns="historyColumns"/>
      </template>
    </DxDataGrid>
</template>

<script>
  import { DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxRequiredRule,
          DxPatternRule,
          DxMasterDetail} from "devextreme-vue/data-grid";
  import dsu from "../../utils/dataSourceUtils";
  import History from "../_common/SIMPLE_HISTORY";

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxRequiredRule,
      DxPatternRule,
      DxMasterDetail,
      History
    },
    data() {
      return {
        dataSource: dsu.getComDSGrid('/api/erp/EMEQ', 'equipmentId', '', '장비 코드'),
        historyColumns: [{dataField : "transTime", caption : "변경 시간"},
                         {dataField : "name", caption : "장비 명"},
                         {dataField : "installDate", caption : "설치 일"},
                         {dataField : "manufactureDate", caption : "제조 일"},
                         {dataField : "model", caption : "Model"},
                         {dataField : "serialNo", caption : "Serial No."},
                         {dataField : "userId", caption : "사용자"},
                         {dataField : "dml", caption : "Action"}]
      };
    },
    methods:{
      onExporting(e){
          e.fileName = this.$route.meta.transName;
      }
    }
  };
</script>

<style scoped>
  #gridContainer {
      height: calc( 100vh - 50px );
      width: 100%;
  }
</style>