<template>
  <DxDataGrid
    id="gridContainer"
    :data-source="dataSource"
    :selection="{ mode: 'single' }"
    :columnAutoWidth="true"
    :show-borders="true"
    :show-column-lines="true"
    @row-inserting="onRowInserting"
    @row-updating="onRowUpdating"
    @exporting="onExporting">
    
    <DxSearchPanel :visible="true"/>
    <DxHeaderFilter :visible="true"/>
    <DxScrolling mode="infinite"/>
    <DxSorting mode="multiple"/>
    <DxExport :enabled="true"/>
    <DxEditing :allow-updating="true"
               :allow-adding="true"
               :allow-deleting="true"
               mode="popup"/>
     
    <DxColumn type="buttons">
      <DxButton name="edit"/>
    </DxColumn>
    
    <DxColumn
      data-field="itemName"
      caption="항목"
      :header-filter="{ allowSearch: true }">
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^.{1,50}$"
        message="50자 까지 등록 가능"/>
    </DxColumn>

    <DxColumn
      data-field="necessary"
      caption="필수 입력">  
      <DxRequiredRule/>
      <DxLookup :data-source="listYN"/>
    </DxColumn>
      
    <DxColumn
      data-field="type"
      caption="유형">  
      <DxRequiredRule/>
      <DxLookup
        :data-source="checkItemType"
        value-expr="ID"
        display-expr="Name"/>
    </DxColumn>
   
    <DxColumn
      data-field="list"
      caption="목록"
      :header-filter="{ allowSearch: true }">
      <DxPatternRule
        pattern="^.{1,100}$"
        message="100자 까지 등록 가능"/>
    </DxColumn>
   
    <DxMasterDetail
      :enabled="true"
      template="master-detail"/>
      <template #master-detail="{ data }">
        <History :master-detail-data="data"
                :url= "`/api/erp/ERCI/selectHistory`"
                :keyName="itemName"
                :columns="historyColumns"/>
      </template>    
    </DxDataGrid>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxLookup,
          DxRequiredRule,
          DxPatternRule,
          DxMasterDetail} from "devextreme-vue/data-grid";
  import History from "../_common/SIMPLE_HISTORY";
  import {checkItemType, listYN} from "../../utils/syscodedata";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxLookup,
      DxRequiredRule,
      DxPatternRule,
      DxMasterDetail,
      History
    },
    data() {
      return {
        dataSource: dsu.getComDSGrid('/api/erp/ERCI', 'itemName', '', '항목'),
        checkItemType: checkItemType,
        listYN: listYN,
        historyColumns: [{dataField: "necessary", caption: "필수 입력"},
                         {dataField: "type", caption: "유형", lookup: {dataSource: checkItemType, valueExpr: "ID", displayExpr: "Name"}},
                         {dataField : "list", caption : "목록"},
                         {dataField : "userId", caption : "사용자"},
                         {dataField : "dml", caption : "Action"}]
      };
    },
    methods:{
      onExporting(e) {
          e.fileName = this.$route.meta.transName;
      },
      onRowInserting(e){
        if(!this.validationValue(e.data)){
           e.cancel = true;
        }
      },
      onRowUpdating(e){
        let data = {};
       
        Object.assign(data, e.oldData);
        Object.assign(data, e.newData);
      
        if(!this.validationValue(data)){
           e.cancel = true;
        }
      },
      validationValue(data){
        if(data.type == 'OPTION' || data.type == 'CHECK'){
          if(!data.list){
            fn.notifyError('목록 미 입력 필요');
            return false;
          }

        if(!data.list.includes('|')){
          fn.notifyError('목록 값은 | 으로 구분하여 입력 필요');
          return false;
        }

        }else{
          if(data.list){
            fn.notifyError('유형이 선택(단일), 선택(다중) 만 목록 입력 가능');
            return false;
          }
        }
        
        return true;
      }
    }
  };
</script>

<style scoped>
  #gridContainer {
      height: calc( 100vh - 50px );
      width: 100%;
  }
</style>