<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <DxToolbar>
        <DxItem
          widget="dxButton"
          :options="btnOptSave"
          location="after"/>
      </DxToolbar>      
      
      <div class="dx-field">
        <div class="dx-field-label">Mail Type</div>
        <div class="dx-field-value">
          <DxSelectBox
            :search-enabled="true"
            placeholder=""
            value-expr="mailType"
            display-expr="mailType"
            width="300"
            :data-source="mailMasterList"
            @value-changed="changedMailType"/>
        </div>
      </div>

      <DxForm :ref="formRefName"
              :form-data="mailMaster">
        <DxSimpleItem 
          data-field="description"
          editor-type="dxTextBox">
          <DxLabel text="설명"/>
        </DxSimpleItem>
        
        <DxSimpleItem 
          data-field="subject"
          editor-type="dxTextBox">
          <DxLabel text="제목"/>
        </DxSimpleItem>        
      </DxForm>      

      <DxDataGrid
        :ref="gridRefName"
        :columnAutoWidth="true"
        :show-borders="true"
        :show-column-lines="true"
        :focused-row-enabled="true"
        @row-inserting="onRowInserting"
        @row-updating="onRowUpdating">

        <DxSearchPanel :visible="true"/>
        <DxHeaderFilter :visible="true"/>
        <DxScrolling mode="infinite"/>
        <DxSorting mode="multiple"/>
        <DxEditing
          :allow-updating="true"
          :allow-adding="true"
          :allow-deleting="true"
          mode="popup"/>
        
        <DxColumn type="buttons">
          <DxButton name="edit"/>
        </DxColumn>
        
        <DxColumn
          data-field="name"
          caption="이름">
          <DxRequiredRule/>
          <DxPatternRule
            pattern="^.{1,10}$"
            message="10자까지 등록 가능"/>                    
        </DxColumn>

        <DxColumn
          data-field="address"
          caption="E-Mail">  
          <DxRequiredRule/>
          <DxEmailRule/>
          <DxPatternRule
            pattern="^.{1,50}$"
            message="50자까지 등록 가능"/>          
        </DxColumn>

        <DxColumn
          data-field="receiveFlag"
          caption="수신 유형">
          <DxRequiredRule/>
          <DxLookup
            :data-source="[{'ID': 'TO', 'Name': '수신'}, {'ID': 'CC', 'Name': '참조'}, {'ID': 'BCC', 'Name': '숨은 참조'}]"
            value-expr="ID"
            display-expr="Name"/>          
        </DxColumn>
      </DxDataGrid>
    </DxScrollView>
  </div>
</template>

<script>
  import {DxScrollView} from "devextreme-vue/scroll-view";
  import DxToolbar,
        {DxItem} from "devextreme-vue/toolbar";
  import {DxForm,
          DxSimpleItem,
          DxLabel} from "devextreme-vue/form";
  import {DxDataGrid,
          DxColumn,
          DxSorting,
          DxLookup,
          DxRequiredRule,
          DxEmailRule,
          DxSearchPanel,
          DxHeaderFilter,
          DxScrolling,
          DxEditing,
          DxPatternRule} from "devextreme-vue/data-grid";
  import {DxSelectBox} from "devextreme-vue/select-box";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";

  export default {
      components: {
        DxScrollView,
        DxToolbar,
        DxItem,
        DxForm,
        DxSimpleItem,
        DxLabel,
        DxDataGrid,
        DxColumn,
        DxSorting,
        DxLookup,
        DxRequiredRule,
        DxEmailRule,
        DxSearchPanel,
        DxHeaderFilter,
        DxScrolling,
        DxEditing, 
        DxPatternRule,     
        DxSelectBox
      },
      data: function() {
        return {
          formRefName: 'form',
          gridRefName: 'grid',
          mailMasterList: [],
          mailMaster: {mailType: '', subject: '', description: ''},
          btnOptSave: {
              type: 'custom',
              text: '저장',
              icon: 'save',
              stylingMode:"outlined",
              onClick: this.save.bind(this)
          }        
        };
      },
      mounted(){
        dsu.getAxiosMailMasterList().then(res => {this.mailMasterList = res.data.data});
      },
      methods: {
        async save(){
          var params = {};
          let mailSubList = this.getGridData();

          if(!this.mailMaster.mailType){
            fn.notifyError('Mail Type 선택 후 진행 필요');
            return;
          }

          if(mailSubList.length == 0){
            fn.notifyError('Mail 수신자 지정 후 진행 필요');
            return;
          }
          
          Object.assign(params, this.mailMaster);
          params.mailSubList = JSON.stringify(mailSubList);
          
          await dsu.getAxiosOBJPost('/api/erp/EEMI/updateMailListInfo', params).then(res=>{
              let response = res.data;
              if(response.result == 'SUCCESS'){
                fn.notifySuccess(response.msg);
              }else{
                fn.notifyError(response.msg);
              }
          });
        },
        setGridData(data){
          const grid = this.$refs[this.gridRefName].instance;
          grid.option('dataSource', data);
          grid.option('keyExpr', 'address');
        },
        getGridData(){
          const dataSource = this.$refs[this.gridRefName].instance.getDataSource();
          let mailSubList = dataSource.items();  
          return mailSubList;      
        },
        changedMailType(e){
          let mailType = e.value;
          
          for (var i = 0; i < this.mailMasterList.length; i++) {
            if (this.mailMasterList[i].mailType == mailType){
              Object.assign(this.mailMaster, this.mailMasterList[i]);
              break;
            } 
          }

          let params = {mailType: mailType};

          dsu.getAxiosOBJGet('/api/erp/EEMI/selectMailSubInfo', params).then(res =>{
            let response = res.data;
            if(response.result == 'SUCCESS'){
              this.setGridData(response.data);
            }else{
              fn.notifyError(response.msg);
            }
          });
        },
        onRowInserting(e){
          if(!this.checkDuplicateValue(e.data, true)){
            e.cancel = true;
          }
        },
        onRowUpdating(e){
          let data = {};
          let chkKey = false;
          Object.assign(data, e.oldData);
          Object.assign(data, e.newData);

          if((e.newData.address &&  e.newData.address != e.oldData.address)){
            chkKey = true;
          }

          if(!this.checkDuplicateValue(data, chkKey)){
            e.cancel = true;
          }
        },  
        checkDuplicateValue(data, chkKey){
          let mailSubList = this.getGridData();
          
          for (var i = 0; i < mailSubList.length; i++) {
            if(chkKey){
              if (data.address == mailSubList[i].address){
                    fn.notifyError('중복 데이터, E-Mail 확인 필요');
                    return false;
              }
            }
          }
          return true;
        }          
      }
    };
  </script>

  <style scoped>
    #Container{
      height: calc( 100vh - 50px );
      width: 100%;
      float: left;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      background-color:white;
      
    }
    .dx-field-label{
      width: 100px;
      font-size: 14px;
      font-weight: 700;
      color: black;
    }
    .dx-field{
      margin-top: 10px;
    }
</style>