<template>
  <div id="Container">
    <ProductManager 
      :showEditing="true"
      :showCheckListId="true"
      :showOptionGroupId="true"
      :showExport="true"
      :showHistory="true" />
  </div>
</template>

<script>
  import ProductManager from "../../views/_common/PRODUCT_MANAGER";

  export default {
    components: {
      ProductManager
    }
  };
</script>

<style scoped>
  #Container {
      height: calc( 100vh - 50px );
      width: 100%;
  }
</style>