<template>
  <DxPopup :ref="popupRefName"
          :drag-enabled="false"
          :close-on-outside-click="true"
          :show-title="true"
          :width="550"
          :height="300"
          :title="title">
      
        <div class="dx-fieldset">
          <div class="dx-field">
            <div class="dx-field-label">{{originalLebel}}:</div>
            <DxSelectBox
                :ref="originalRefName"
                :search-enabled="true"
                :data-source="originalList"
                :value-expr="originalValueExpr"
                :display-expr="originalDisplayExpr"
                width="400"/>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">{{targetLebel}}:</div>
            <DxSelectBox
                  :ref="targetRefName"
                  :search-enabled="true"
                  :data-source="targetList"
                  :value-expr="targetValueExpr"
                  :display-expr="targetDisplayExpr"
                  width="400"/>
          </div>
        </div>

        <div class="buttonContainer">
          <DxButton
            id="btnExecute"
            class="button"
            text="실행"
            icon="copy"
            width="130"
            @click="execute"/>
        </div>
     
  </DxPopup>
</template>
<script>
  import DxPopup from "devextreme-vue/popup";
  import {DxSelectBox} from "devextreme-vue/select-box";
  import DxButton from "devextreme-vue/button";
  import fn from "../../utils/function"

  export default {
    components: {
      DxPopup,
      DxSelectBox,
      DxButton},
    props: {
      title: String,
      originalLebel: String,
      originalList: [],
      originalValueExpr: String,
      originalDisplayExpr: String,
      targetLebel: String,
      targetList: [],
      targetValueExpr: String,
      targetDisplayExpr: String
    },
    data: function() {
      return {
        popupRefName: "popup",
        originalRefName: "original",
        targetRefName: "target"
      };
    },
    methods: {
      show(){
        this.$refs[this.popupRefName].instance.show();
      },
      execute(){
        var copyInfo = {};
        const originalCode = this.$refs[this.originalRefName].instance;
        const targetCode = this.$refs[this.targetRefName].instance;

        copyInfo.originalCode = originalCode.option("value");
        copyInfo.targetCode = targetCode.option("value");
        
        if(!copyInfo.originalCode){
          fn.notifyError(this.originalLebel + " 선택 후 진행 필요");
          return;
        }

        if(!copyInfo.targetCode){
          fn.notifyError(this.targetLebel + " 선택 후 진행 필요");
          return;
        }

        if(copyInfo.originalCode == copyInfo.targetCode){
          fn.notifyError("동일 값 선택 불가");
          return;
        }
        this.$refs[this.popupRefName].instance.hide();
        this.$emit("executeCopy", copyInfo);
      }
    }
  };
</script>

<style scoped>
  .dx-field-label{
    width: 120px;
    float: right;
    font-size: 16px;
    font-weight: 500;
    color: black;
  }

  .buttonContainer{
    padding-right: 15px;
  }

  #btnExecute{
    float: right;
  }
</style>