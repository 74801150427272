<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <Transaction :ref="transactionRefName" 
                   @setLotInfoExt="setLotInfoExt"
                   @clearLotInfoExt="clearLotInfoExt"
                   @execute="execute"/>
      <DxForm>
        <DxGroupItem caption="선택 정보">

          <DxSimpleItem name="userCmtNew">
            <DxLabel text="부적합 사유" />
            <template #default>
              <DxTextArea
                :value="formData.userCmtNew"
                :height="300"
                :max-length="300"
                @value-changed="changedUserCmtNew"/>
            </template>
          </DxSimpleItem>
        </DxGroupItem>
      </DxForm>
    </DxScrollView>
  </div>
</template>

<script>
  import {DxScrollView} from "devextreme-vue/scroll-view";
  import Transaction from "../_common/TRANSACTION";
  import {DxForm,
          DxGroupItem,
          DxSimpleItem,
          DxLabel} from "devextreme-vue/form";
  import {DxTextArea} from "devextreme-vue/text-area";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function"

  export default {
    components: {
      DxScrollView,
      Transaction,
      DxForm,
      DxGroupItem,
      DxSimpleItem,
      DxLabel,
      DxTextArea
    },
    data: function() {
      return {
        transactionRefName: 'transaction',
        formData: {userCmtNew: ''}
        
      };
    },
    methods: {
      // setLotInfoExt(lotInfo){
        
      // },
      clearLotInfoExt(){
        Object.keys(this.formData).map((key) => {
            this.formData[key] ='';
        });
      },
      changedUserCmtNew(e){
        this.formData.userCmtNew = e.value;
      },
      async execute(transInfo){
        transInfo.userCmtNew = this.formData.userCmtNew;
  
        if(!transInfo.userCmtNew){
          fn.notifyError("부적합 사유 미입력");
          return;
        }

        if(transInfo.status == '02'){
          fn.notifyError("이미 등록된 부적합 자재");
          return;
        }

        await dsu.getAxiosOBJPost(`/api/mes/MPHD/regAbnormalLot`,transInfo).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            fn.notifySuccess(response.msg);
            this.$refs[this.transactionRefName].clear();
          }else{
            fn.notifyError(response.msg);
          }
        });
      }
    }
  };
</script>

<style scoped>
  #Container{
    height: calc( 100vh - 50px );
    width: 100%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background-color:white;
  }
</style>