<template>
  <DxDataGrid
    id="gridContainer"
    :data-source="dataSource"
    :selection="{ mode: 'single' }"
    :columnAutoWidth="true"
    :show-borders="true"
    :show-column-lines="true"
    @exporting="onExporting">
    
    <DxSearchPanel :visible="true"/>
    <DxHeaderFilter :visible="true"/>
    <DxScrolling mode="infinite"/>
    <DxSorting mode="multiple"/>
    <DxExport :enabled="true"/>
    <DxEditing :allow-adding="true"
               :allow-deleting="true"
               mode="popup"/>
     
    <DxColumn type="buttons">
      <DxButton name="edit"/>
    </DxColumn>
    
    <DxColumn
      data-field="optionName"
      caption="항목"
      :header-filter="{ allowSearch: true }">
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^.{1,20}$"
        message="20자 까지 등록 가능"/>
    </DxColumn>
      
    <DxMasterDetail
      :enabled="true"
      template="master-detail"/>
      <template #master-detail="{ data }">
        <History :master-detail-data="data"
                :url= "`/api/erp/ERPO/selectHistory`"
                :keyName="optionName"
                :columns="historyColumns"/>
      </template>    
    </DxDataGrid>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxRequiredRule,
          DxPatternRule,
          DxMasterDetail} from "devextreme-vue/data-grid";
  import History from "../_common/SIMPLE_HISTORY";
  import {checkItemType} from "../../utils/syscodedata";
  import dsu from "../../utils/dataSourceUtils";

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxRequiredRule,
      DxPatternRule,
      DxMasterDetail,
      History
    },
    data() {
      return {
        dataSource: dsu.getComDSGrid('/api/erp/ERPO', 'optionName', '', '항목'),
        checkItemType: checkItemType,
        historyColumns: [{dataField : "userId", caption : "사용자"},
                         {dataField : "dml", caption : "Action"}]
      };
    },
    methods:{
      onExporting(e) {
          e.fileName = this.$route.meta.transName;
      }
    }
  };
</script>

<style scoped>
  #gridContainer {
      height: calc( 100vh - 50px );
      width: 100%;
  }
</style>