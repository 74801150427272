<template>
  <div>
    <DxDataGrid
        id="gridContainer"
        :ref="gridRefName"
        :focused-row-enabled="true"
        :show-borders="true"
        :show-column-lines="true"
        :columnAutoWidth="true"
        @row-click="selectedData">

        <DxSearchPanel :visible="true"/>
        <DxHeaderFilter :visible="true"/>
        <DxScrolling mode="infinite"/>
        <DxSorting mode="multiple"/>
        
      <DxColumn
        data-field="projectId"
        caption="Project ID"
        :header-filter="{ allowSearch: true }">
      </DxColumn>

      <DxColumn
        data-field="regWeek"
        caption="주차"
        :allowEditing="false"
        :header-filter="{ allowSearch: true }"
        :visible="!isHideRegWeek">
      </DxColumn>

      <DxColumn
        data-field="projectName"
        caption="공고 명"
        :header-filter="{ allowSearch: true }"
        :visible="!isHideProjectName">
      </DxColumn>

      <DxColumn
        data-field="salesUserId"
        caption="영업 담당자"
        :header-filter="{ allowSearch: true }"
        :visible="!isHideSalesUserId">
      </DxColumn>

      <DxColumn
        data-field="engrUserId"
        caption="기술 담당자"
        :header-filter="{ allowSearch: true }"
        :visible="!isHideEngrUserId">
      </DxColumn>

      <DxColumn
        data-field="rank1"
        caption="1순위"
        :header-filter="{ allowSearch: true }"
        :visible="!isHideRank1">
      </DxColumn>
          
      <DxColumn
        data-field="branch"
        caption="지사"
        :header-filter="{ allowSearch: true }"
        :visible="!isHideBranch">
      </DxColumn>
    
      <DxColumn
        data-field="rank1Address"
        caption="1순위 주소"
        :header-filter="{ allowSearch: true }"
        :visible="!isHideRank1Address">
      </DxColumn>

      <DxColumn
        data-field="rank1Tel"
        caption="1순위 연락처"
        :header-filter="{ allowSearch: true }"
        :visible="!isHideRank1Tel">
      </DxColumn>

      <DxColumn
        data-field="designAmount"
        caption="설계 금액(원)"
        data-type="number"
        :format="{precision: 3}"
        :header-filter="{ allowSearch: true }"
        :visible="!isHideDesignAmount">
      </DxColumn>

      <DxColumn
        data-field="successfulBidAmount"
        caption="낙찰 금액(원)"
        data-type="number"
        :format="{precision: 3}"
        :header-filter="{ allowSearch: true }"
        :visible="!isHideSuccessfulBidAmount">
      </DxColumn>

      <DxColumn
        data-field="resultsAnnouncementDate"
        caption="결과 발표일"
        data-type="date"
        format="yyyy/MM/dd"
        :header-filter="{ allowSearch: true }"
        :visible="!isHideResultsAnnouncementDate">
      </DxColumn>

      <DxColumn
        data-field="grossArea"
        caption="연면적"
        :header-filter="{ allowSearch: true }"
        :visible="!isHideGrossArea">
      </DxColumn>

      <DxColumn
        data-field="customerContact"
        caption="발주처 연락처"
        :header-filter="{ allowSearch: true }"
        :visible="!isHideCustomerContact">
      </DxColumn>
    </DxDataGrid>

    <DxForm id="form"
              :ref="formRefName"
              :form-data="formData">
      <DxGroupItem caption="Project 정보"
                    col-count="2">
        <DxSimpleItem
          data-field="projectId"
          editor-type="dxTextBox"
          readOnly="true"
          :editor-options="{readOnly: true}">
          <DxLabel text="Project ID"/>
        </DxSimpleItem>
        
        <DxSimpleItem
          data-field="projectName"
          editor-type="dxTextBox"
          :editor-options="{readOnly: true}">
          <DxLabel text="공고 명"/>
        </DxSimpleItem>
      </DxGroupItem>
    </DxForm>

  </div>
</template>
<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxHeaderFilter,
          DxSearchPanel} from "devextreme-vue/data-grid";
  import {DxForm,
          DxGroupItem,
          DxSimpleItem,
          DxLabel} from "devextreme-vue/form";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";

  export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxScrolling,
        DxSorting,
        DxHeaderFilter,
        DxSearchPanel,
        DxForm,
        DxGroupItem,
        DxSimpleItem,
        DxLabel
    },
    props: {
      isHideRegWeek: Boolean,
      isHideProjectName: Boolean,
      isHideSalesUserId: Boolean,
      isHideEngrUserId: Boolean,
      isHideRank1: Boolean,
      isHideBranch: Boolean,
      isHideRank1Address: Boolean,
      isHideRank1Tel: Boolean,
      isHideDesignAmount: Boolean,
      isHideSuccessfulBidAmount: Boolean,
      isHideResultsAnnouncementDate: Boolean,
      isHideGrossArea: Boolean,
      isHideCustomerContact: Boolean
    },
    data: function() {
      return {
        gridRefName: "grid",
        formData: {}
      };
    },
    mounted(){
      this.search();
    },
    computed: {
      grid: function () {
        return this.$refs[this.gridRefName].instance;
      }
    },
    methods: {
      search(){
        dsu.getAxiosOBJGet("/api/erp/PREG/select").then(res=>{
          let response = res.data;
          if(response.result == "SUCCESS"){
            this.grid.option("keyExpr", "projectId");
            this.grid.option("dataSource", response.data);
          }else{
            fn.notifyError(response.msg);
          }
        });
      },
      selectedData(e){
        this.formData = e.data;
        this.$emit("loadSelectedData", e.data);
      }
    }
  };
</script>

<style scoped>
  #gridContainer{
    height: 300px;
  }
  #form{
    margin-top: 40px;
  }
</style>