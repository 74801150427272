import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)

export default new Vuex.Store({
  plugins:[createPersistedState()],
  state: {
    token: '',
    userId: '',
    userName: '',
    auth:''
  },
  mutations: {
    setToken (state, newToken) {
      state.token = newToken
    },
    setUserId (state, newUserId) {
      state.userId = newUserId
    },
    setUserName (state, newUserName) {
      state.userName = newUserName
    },
    setAuth (state, newAuth) {
      state.auth = newAuth
    }
  },
  getters: {
    getToken (state) {
      return `${state.token}`
    },
    getUserId (state) {
      return `${state.userId}`
    },
    getUserName (state) {
      return `${state.userName}`
    },
    getAuth (state) {
      return `${state.auth}`
    }
  }
  
})