<template>
  <div id="root">
    <div :class="cssClasses">
      <router-view
        name="layout"
        :title="title"
        :functionName='functionName'
        :is-x-small="screen.isXSmall"
        :is-large="screen.isLarge"
      >
        <div class="content">
          <router-view name="content" />
        </div>
        
      </router-view>
    </div>
  </div>
</template> 

<script>
import { sizes, subscribe, unsubscribe } from "./utils/media-query";
import { loadMessages, locale } from "devextreme/localization";
import { default_message } from "./utils/message_kr";
import auth from "./auth";

function getScreenSizeInfo() {
  const screenSizes = sizes();

  return {
    isXSmall: screenSizes["screen-x-small"],
    isLarge: screenSizes["screen-large"],
    cssClasses: Object.keys(screenSizes
    ).filter(cl => screenSizes[cl])
  };
}

setInterval(function(){auth.loggedIn('', 'N');}, 60 * 60 * 1000); //1시간

export default {
  name: "app",
  data() {
    return {
      title: '',
      functionName:'',
      screen: getScreenSizeInfo()
    };
  },
  created() {
    locale('ko-KR');
    loadMessages({
        "ko-KR": default_message
    });
  },
  computed: {
    cssClasses() {
      return ["app"].concat(this.screen.cssClasses);
    }
  },
  methods: {
    screenSizeChanged() {
      this.screen = getScreenSizeInfo();
    }
  },
  mounted() {
    subscribe(this.screenSizeChanged);
  },
  beforeDestroy() {
    unsubscribe(this.screenSizeChanged);
  },
  watch: {
    $route(to) {
       let toSite = to.meta.site;
       if (toSite){
          this.title = to.meta.site;
       }
       this.functionName = to.meta.transName;     
    }
  },
  head: {
    meta: [
      { name: 'mobile-web-app-capable"', content: 'yes' },
      { name: 'apple-mobile-web-app-capable', content: 'yes' }
    ]
  }
};
</script>

<style lang="scss">
html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.app {
  @import "./themes/generated/variables.base.scss";
  background-color: darken($base-bg, 5);
  display: flex;
  height: 100%;
  width: 100%;
}
</style>
