<template>
    <DxDataGrid
      id="gridContainer"
      :ref="gridRefName"
      :data-source="dataSource"
      :focused-row-enabled="true"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      :selection="{ mode: 'single' }"
      @exporting="onExporting"
      @cellDblClick="onDblClick">
      
      <DxSearchPanel :visible="true"/>
      <DxHeaderFilter :visible="true"/>
      <DxScrolling mode="infinite"/>
      <DxSorting mode="multiple"/>
      <DxExport :enabled="true"/>
      <DxColumnChooser :enabled="true" mode="select" />

      <DxEditing
        :allow-updating="true"
        :allow-adding="true"
        :allow-deleting="true"
        mode="popup"/>

      <DxColumn type="buttons">
        <DxButton name="edit"/>
      </DxColumn>

      <DxColumn
        data-field="constructionSiteCode"
        caption="건설현장 코드"
        :header-filter="{ allowSearch: true }">
        <DxRequiredRule/>
        <DxLookup
          :data-source="constructionSiteList"
          value-expr="code"
          display-expr="nameCode"/>
      </DxColumn>
      
      <DxColumn
        data-field="specArea"
        caption="Spec 나비(mm)"
        data-type="number">
        <DxRequiredRule/>
        <DxPatternRule
          pattern="^[1-9]{1}[0-9]{0,9}$" 
          message="1 ~ 9999999999 까지 등록 가능"/>  
      </DxColumn>

      <DxColumn
        data-field="specLength"
        caption="Spec 길이(mm)"
        data-type="number">
        <DxRequiredRule/>        
        <DxPatternRule
          pattern="^[1-9]{1}[0-9]{0,9}$" 
          message="1 ~ 9999999999 까지 등록 가능(소수점 불가)"/>  
      </DxColumn>

      <DxColumn
        data-field="specThickness"
        caption="Spec 두께(mm)"
        data-type="number">
        <DxRequiredRule/>        
        <DxPatternRule
          pattern="^[1-9]{1}[0-9]{0,9}$" 
          message="1 ~ 9999999999 까지 등록 가능(소수점 불가)"/>  
      </DxColumn>      

      <DxColumn
        data-field="specDiagonalDifference"
        caption="Spec 대각선의 차(mm)"
        data-type="number">
        <DxRequiredRule/>        
        <DxPatternRule
          pattern="^[1-9]{1}[0-9]{0,9}$" 
          message="1 ~ 9999999999 까지 등록 가능(소수점 불가)"/>  
      </DxColumn>        

      <DxMasterDetail 
        :enabled="true"
        template="master-detail"/>
        <template #master-detail="{ data }">
          <History :master-detail-data="data"
                  :url= "`/api/erp/ECSI/selectHistory`"
                  :keyName="code"
                  :columns="historyColumns"/>
        </template>
    </DxDataGrid>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxRequiredRule,
          DxLookup,
          DxPatternRule,
          DxMasterDetail,
          DxColumnChooser} from "devextreme-vue/data-grid";
  import dsu from "../../utils/dataSourceUtils";
  import History from "../_common/SIMPLE_HISTORY";
  
  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxRequiredRule,
      DxLookup,
      DxPatternRule,
      DxMasterDetail,
      History,
      DxColumnChooser
    },
    data() {
      return {
        constructionSiteCode: '',
        constructionSiteName: '',
        dataSource: dsu.getComDSGrid('/api/erp/ECSI', 'constructionSiteCode', '', '건설현장 코드'),
        userList: [],
        constructionSiteList: [],
        gridRefName: 'grid',
        historyColumns: [{dataField : "transTime", caption : "변경 시간"},
                        {dataField : "specArea", caption : "Spec 나비(mm)"},
                        {dataField : "specLength", caption : "Spec 길이(mm)"},
                        {dataField : "specThickness", caption : "Spec 두께(mm)"},
                        {dataField : "specDiagonalDifference", caption : "Spec 대각선의 차(mm)"},
                        {dataField : "userId", caption : "사용자"},
                        {dataField : "dml", caption : "Action"}]
      };
    },
    mounted(){
        dsu.getAxiosUserList({dept: '영업'}).then(res => {this.userList = res.data.data});
        dsu.getAxiosConstructionSiteList().then(res => {this.constructionSiteList = res.data.data});
    },  
    methods:{
      onExporting(e) {
          e.fileName = this.$route.meta.transName;
      }
    }
  };
</script>

<style scoped>
  #gridContainer {
    height: calc( 100vh - 50px );
    width: 100%;}
</style>