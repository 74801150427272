<template>
  <div id="gridContainer">
    <DxToolbar>
      <DxItem :options="selectBoxOptionsIOType"
              location="center"
              locate-in-menu="auto"
              widget="dxSelectBox">
      </DxItem>
      <DxItem :options="selectBoxOptionsLotType"
              location="center"
              locate-in-menu="auto"
              widget="dxSelectBox"/>       
      <DxItem :options="dateBoxOptionsStart"
              location="center"
              locate-in-menu="auto"
              widget="dxDateBox"/>        
      <DxItem :options="dateBoxOptionsEnd"
              location="center"
              locate-in-menu="auto"
              widget="dxDateBox"/>   
      <DxItem :options="buttonOptionsSearch"
              location="center"
              locate-in-menu="never"
              widget="dxButton"/>                    
    </DxToolbar>

    <DxPivotGrid id ="grid" 
                :ref="gridRefName"
                :allow-sorting-by-summary="true"
                :allow-sorting="true"
                :allow-filtering="true"
                :allow-expand-all="true"
                :show-borders="true"
                @exporting="onExporting"
                @cell-click="onCellClick">
      <DxFieldChooser :enabled="true"/>
      <DxFieldPanel :visible="true"/>
      <DxHeaderFilter
        :allow-search="true"
        :show-relevant-values="true"
        :width="300"
        :height="400"/>      
      <DxScrolling mode="virtual"/>
      <DxExport :enabled="true"/>
    </DxPivotGrid>

    <DxPopup id="popup"
             :title="popupTitle"
             :visible="popupVisible"
             @shown="onShown">
        <DxDataGrid id="rawData"
                    :ref="ataGridRefName"
                    :data-source="drillDownDataSource"
                    :columnAutoWidth="true"
                    @exporting="onExporting">

        <DxSearchPanel :visible="true"/>
        <DxSorting mode="multiple"/>
        <DxExport :enabled="true"/>
        <DxColumn data-field="transTimeStr" caption="시간"/>
        <DxColumn data-field="codeNameOrg" caption="자재 코드"/>
        <DxColumn data-field="lotNumber" caption="Lot No."/>
        <DxColumn data-field="transactionName" caption="Action"/>
        <DxColumn data-field="quantity" caption="수량"/>
        <DxColumn data-field="unit" caption="단위"/>
      </DxDataGrid>
    </DxPopup>    
  </div>
</template>

<script>
  import DxPivotGrid,{
          DxFieldPanel,
          DxHeaderFilter,
          DxFieldChooser,
          DxScrolling,
          DxExport} from "devextreme-vue/pivot-grid";
  import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
  import DxToolbar, {DxItem} from "devextreme-vue/toolbar";
  import {DxPopup} from "devextreme-vue/popup";
  import {DxDataGrid,
          DxColumn,
          DxSorting,
          DxSearchPanel} from "devextreme-vue/data-grid";
  import {lotTypeWithAll} from "../../utils/syscodedata";
  import fn from "../../utils/function";
  import dsu from "../../utils/dataSourceUtils";


  var defaultTrancation = 'MMIS';
  var defaultLotType = 'N';
  var endDate = new Date();
  var startDate = new Date();
    startDate.setDate(endDate.getDate() - 7);
  var defaultStartDate = startDate;
  var defaultEndDate = endDate;
 
  export default {
    components: {
      DxPivotGrid,
      DxFieldPanel,
      DxHeaderFilter,
      DxFieldChooser,
      DxExport,
      DxScrolling,
      DxToolbar,
      DxItem,
      DxPopup,
      DxDataGrid,
      DxColumn,
      DxSorting,
      DxSearchPanel
    },
    data() {
      return {
        gridRefName: 'dataGrid',
        dataGridRefName: 'rawData',
        searchCondition: {transaction: defaultTrancation, lotType: defaultLotType, startDate: defaultStartDate, endDate: defaultEndDate},
        drillDownDataSource: null,
        popupTitle: '',
        popupVisible: false,
        selectBoxOptionsIOType: {
          width: 120,
          items: [{'ID': 'MMIS', 'Name': ' 구분 : 입고'}, {'ID': 'MMOS', 'Name': ' 구분 : 출고'}],
          valueExpr: 'ID',
          displayExpr: 'Name',
          value: defaultTrancation,
          onValueChanged: (args) => {
            this.searchCondition.transaction = args.value;
          }
        }, 
        selectBoxOptionsLotType: {
          width: 180,
          items: lotTypeWithAll,
          valueExpr: 'ID',
          displayExpr: 'Name',
          value: defaultLotType,
          onValueChanged: (args) => {
            this.searchCondition.lotType = args.value;
          }
        },   
        dateBoxOptionsStart: {
          width: 180,
          value: defaultStartDate,
          text: "시작",
          displayFormat:" 시작 :  yyyy/MM/dd",
          onValueChanged: (args) => {
            this.searchCondition.startDate = args.value;
          }
        },   
        dateBoxOptionsEnd: {
          width: 180,
          value: defaultEndDate,
          text: "시작",
          displayFormat:" 종료 :  yyyy/MM/dd",
          onValueChanged: (args) => {
            this.searchCondition.endDate = args.value;
          }
        },
        buttonOptionsSearch: {
          icon: 'search',
          text: '조회',
          stylingMode: "outlined",
          onClick: this.search.bind()
        }       
      };
    },
    methods: {
      search(){
        var params = JSON.parse(JSON.stringify(this.searchCondition));
        params.startDate = dsu.getFormatDate(this.searchCondition.startDate);
        params.endDate = dsu.getFormatDate(this.searchCondition.endDate);
        
        dsu.getAxiosOBJGet('/api/mes/MVIO/select', params).then(res =>{
                let data = res.data.data;
                if(res.data.result == 'SUCCESS'){
                    if(data.length == 0){
                      fn.notifyError('데이터 없음');
                      return;
                    }else{
                      const grid = this.$refs[this.gridRefName].instance;
                      
                      var dataSource =  new PivotGridDataSource({
                        fields: [
                          {
                            caption: '품목',
                            width: 120,
                            dataField: 'category',
                            area: 'row',
                            expanded: true
                          },
                          {
                            caption: '자재 코드',
                            width: 120,
                            dataField: 'codeName',
                            area: 'row',
                            expanded: true
                          },
                          {
                            caption: '날짜',
                            dataField: 'trDt',
                            dataType: 'text',
                            area: 'column',
                            expanded: true
                          },
                          {
                            caption: '합계',
                            dataField: 'quantity',
                            dataType: 'number',
                            summaryType: 'sum',
                            area: 'data'
                          }
                        ],
                        store: data });

                      grid.option('dataSource', dataSource);
                    }

                }else{
                  fn.notifyError(res.data.msg);
                  this.clearLotInfoExt();
                }
        });
      },
      onExporting(e) {
        e.fileName = this.$route.meta.transName;
      },
      onCellClick(e) {
        if (e.area == 'data') {
          var pivotGridDataSource = e.component.getDataSource(),
            rowPathLength = e.cell.rowPath.length,
            rowPathName = e.cell.rowPath[rowPathLength - 1];
          this.drillDownDataSource = pivotGridDataSource.createDrillDownDataSource(e.cell);
          this.popupTitle = `${
            rowPathName ? rowPathName : 'Total'
          } Raw Data`;
          this.popupVisible = !this.popupVisible;
        }
      },
      onShown() {
        this.dataGrid.updateDimensions();
      }      
    },
    computed: {
      dataGrid: function() {
        return this.$refs[this.dataGridRefName].instance;
      }
    }    
  };
</script>

<style scoped>
  #gridContainer {
    height: calc( 100vh - 50px );
    width: 100%;}
  #grid {
    width: 100%;
    height: calc( 100vh - 100px )}
  #popup {
    width: 100%;
    height: 100%}
  #rawData {
    width: 100%;
    height: 100%}    
</style>