<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <Transaction @execute="execute"/>
    </DxScrollView>
  </div>
</template>

<script>
  import {DxScrollView} from "devextreme-vue/scroll-view";
  import Transaction from "../_common/TRANSACTION";
  import fn from "../../utils/function"

  export default {
    components: {
      DxScrollView,
      Transaction   
    },
    methods: {
      async execute(transInfo){
        await fn.printLotLabel(transInfo);
      }
    }
  };
</script>

<style scoped>
  #Container{
    height: calc( 100vh - 50px );
    width: 100%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background-color:white;
  }
</style>