<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <DxForm :ref="formRefNameSearch"
              id='formSearch'
              :form-data="searchData">
        <DxGroupItem caption="조회"
                     :col-count="3">
          <DxSimpleItem 
            data-field="groupId"
            editor-type="dxSelectBox"
            :editor-options="{dataSource: this.optionGroupList,
                              valueExpr: 'groupId',
                              displayExpr: 'groupName',
                              searchEnabled: true,
                              onSelectionChanged: this.changeGroupId}">
            <DxLabel text="Option Group"/>
          </DxSimpleItem>          
        </DxGroupItem>
      </DxForm>

      <DxToolbar>
        <DxItem
          widget="dxButton"
          :options="btnOptSave"
          location="after"/>
        <DxItem
          widget="dxButton"
          :options="btnOptDelete"
          location="after"/>          
      </DxToolbar>      

      <DxForm :ref="formRefNameData"
              :form-data="optionGroupInfo">
        <DxGroupItem caption="Option Group 정보"
                    :col-count="3">
          <DxSimpleItem
            data-field="groupId"
            editor-type="dxTextBox"
            :editor-options="{readOnly: true}">
            <DxRequiredRule message="Option Group ID 필수 입력"/>
            <DxLabel text="ID"/>
          </DxSimpleItem>        
      
          <DxSimpleItem
            data-field="groupName"
            editor-type="dxTextBox">
            <DxRequiredRule message="Option Group 이름 필수 입력"/>
            <DxPatternRule
              pattern="^.{1,20}$"
              message="20자까지 등록 가능"/>    
            <DxLabel text="이름"/>
          </DxSimpleItem>                  
        </DxGroupItem>
      </DxForm>      

      <DxDataGrid
        :ref="gridRefName"
        :columnAutoWidth="true"
        :show-borders="true"
        :show-column-lines="true"
        :selection="{ mode: 'multiple' }">

        <DxSearchPanel :visible="true"/>
        <DxHeaderFilter :visible="true"/>
        <DxScrolling mode="infinite"/>
        <DxSorting mode="multiple"/>
        <DxEditing mode="cell"/>

        <DxColumn
          data-field="optionName"
          caption="항목"/>

      </DxDataGrid>
    </DxScrollView>
  </div>
</template>
<script>

import {DxScrollView} from "devextreme-vue/scroll-view";
import DxToolbar,
       {DxItem} from "devextreme-vue/toolbar";
import {DxForm,
        DxGroupItem,
        DxSimpleItem,
        DxLabel} from "devextreme-vue/form";
import {DxDataGrid,
        DxColumn,
        DxSorting,
        DxRequiredRule,
        DxSearchPanel,
        DxHeaderFilter,
        DxScrolling,
        DxPatternRule,
        DxEditing} from "devextreme-vue/data-grid";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";

export default {
    components: {
      DxScrollView,
      DxToolbar,
      DxItem,
      DxForm,
      DxGroupItem,
      DxSimpleItem,
      DxLabel,
      DxDataGrid,
      DxColumn,
      DxSorting,
      DxRequiredRule,
      DxSearchPanel,
      DxHeaderFilter,
      DxScrolling,
      DxPatternRule,
      DxEditing
    },
    data: function() {
      return {
        formRefNameSearch: 'formSearch',
        searchData: {groupId: ''},
        optionGroupList: [],
        formRefNameData: 'formData',
        optionGroupInfo: {groupId: '', groupName: ''},
        gridRefName: 'grid',
        btnOptSave: {
            type: 'custom',
            text: '저장',
            icon: 'save',
            stylingMode:"outlined",
            onClick: this.save.bind(this)
        },
        btnOptDelete: {
            type: 'custom',
            text: '삭제',
            icon: 'trash',
            stylingMode:"outlined",
            onClick: this.delete.bind(this)
        }       
      };
    },
    mounted(){
      this.refreshOptionGroupList();
      this.setGridData([]);
    },
    computed: {
      formSearch: function () {return this.$refs[this.formRefNameSearch].instance;},
      formData: function () {return this.$refs[this.formRefNameData].instance;},
      gridItemList: function () {return this.$refs[this.gridRefName].instance;}
    },
    methods: {
      setGridData(data){
        this.gridItemList.option('dataSource', data);
        this.gridItemList.option('keyExpr', 'optionName');
        var selectedItems = [];
        for (var i = 0; i < data.length; i++) {
          if(data[i].use == 'Y'){
            selectedItems.push(data[i].optionName);
          }
        }
        this.gridItemList.selectRows(selectedItems, true);
      },
      getGridData(){
        let itemList = this.gridItemList.items();  
        return itemList;      
      },
      changeGroupId(e){
        this.clearData();
        this.optionGroupInfo.groupId = e.selectedItem.groupId;

        if(this.optionGroupInfo.groupId != '신규'){
          this.optionGroupInfo.groupName = e.selectedItem.groupName;
        }
         
        var params = {groupId: this.optionGroupInfo.groupId};
        
        dsu.getAxiosOBJGet('/api/erp/EROG/selectOptionGroupItem', params).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            this.setGridData(response.data);
          }else{
            fn.notifyError(response.msg);
          }
        });
      },
      clearData(){
        this.formData.resetValues();
        Object.keys(this.optionGroupInfo).map((key) => {
            this.optionGroupInfo[key] ='';
        });
        this.setGridData([]);
      },
      refreshOptionGroupList(groupId){
        dsu.getAxiosOptionGroupList().then(res => {
          this.optionGroupList = res.data.data;
          this.optionGroupList.unshift({groupId: '신규', groupName: '신규'});
          this.searchData.groupId = groupId;
        });
      },
      save(){
        let validationResult =  this.formData.validate();
        if( validationResult.status != 'valid'){
          fn.notifyError("Option Group 정보 입력 후 진행 필요");
          return;
        }

        var params = JSON.parse(JSON.stringify(this.optionGroupInfo));
        var OptionGroupItems = this.gridItemList.getSelectedRowsData();
        
        if(OptionGroupItems.length == 0){
          fn.notifyError("Option 선택 후 진행 필요");
          return;
        }

        params.optionGroupItems = JSON.stringify(OptionGroupItems);

        dsu.getAxiosOBJPost('/api/erp/EROG/updateOptionGroupInfo', params).then(res=>{
            let response = res.data;
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);
              if(params.groupId == '신규'){
                this.refreshOptionGroupList(response.data);
              }
            }else{
              fn.notifyError(response.msg);
            }
        });
      },
      async delete(){
        if(!this.optionGroupInfo.groupId || this.optionGroupInfo.groupId == '신규'){
          fn.notifyError("Option Group 선택 후 진행 필요");
          return;
        }
             
        if(await fn.confirm('정말 삭제 하시겠습니까?') == false){
          return;
        }        

        var params = {groupId: this.optionGroupInfo.groupId};

        dsu.getAxiosOBJPost('/api/erp/EROG/deleteOptionGroupInfo', params).then(res=>{
            let response = res.data;
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);
              this.refreshOptionGroupList();
              this.clearData();
            }else{
              fn.notifyError(response.msg);
            }
        });
      }
    }
  };
</script>

<style scoped>
  #Container{
    height: calc( 100vh - 50px );
    width: 100%;
    float: left;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    background-color:white;
  }
  #formSearch{
    padding-top: 5px;
  }
</style>