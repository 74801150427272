<template>
  <DxDataGrid
    id="gridContainer"
    :data-source="manufacturingList"
    :show-borders="true"
    :show-column-lines="true"
    :columnAutoWidth="true"
    :selection="{ mode: 'single' }"
    @row-inserting="onRowInserting"
    @row-updating="onRowUpdating"
    @exporting="onExporting"
    @toolbar-preparing="onToolbarPreparing($event)">
    
    <DxSearchPanel :visible="true"/>
    <DxHeaderFilter :visible="true"/>
    <DxScrolling mode="infinite"/>
    <DxSorting mode="multiple"/>
    <DxExport :enabled="true"/>
    <DxEditing
      :allow-updating="true"
      :allow-adding="true"
      :allow-deleting="true"
      mode="popup"/>
   
    <DxColumn type="buttons">
      <DxButton name="edit"/>
    </DxColumn>
   
    <DxColumn
      data-field="operation"
      caption="공정">  
      <DxRequiredRule/>
      <DxLookup
        :data-source="operationList"
        value-expr="operation"
        display-expr="codeName"/>
    </DxColumn>
    
    <DxColumn
      data-field="fromCode"
      caption="원자재 코드"
      :header-filter="{ allowSearch: true }">  
      <DxRequiredRule/>
      <DxLookup
        :data-source="materialList"
        value-expr="code"
        display-expr="codeName"/>
    </DxColumn>

    <DxColumn
      data-field="toCode"
      caption="가공 자재 코드"
      :header-filter="{ allowSearch: true }">  
      <DxRequiredRule/>
      <DxLookup
        :data-source="materialList"
        value-expr="code"
        display-expr="codeName"/>
    
    </DxColumn>
      <DxColumn
      data-field="useAll"
      caption="전량 가공">  
      <DxLookup
        :data-source="[{'ID': 'Y', 'Name': 'Y'}]"
        value-expr="ID"
        display-expr="Name"
        :allow-clearing="true"/>
    </DxColumn>
    </DxDataGrid>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxLookup,
          DxRequiredRule}from "devextreme-vue/data-grid";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxLookup,
      DxRequiredRule},
    data(){
      return {
        manufacturingList: [],
        materialList: [],
        operationList: []
      };
    },
    async mounted(){
      await dsu.getAxiosOperationList({eqpOper : 'N'}).then(res => {this.operationList = res.data.data});
      await dsu.getAxiosMaterialList().then(res => {this.materialList = res.data.data});
      await this.refreshData();
    },
    methods:{
        onToolbarPreparing(e) {
          e.toolbarOptions.items.unshift(
           {location: 'before',
            widget: 'dxButton',
            options: {
              width: 100,
              icon: 'save',
              text: '저장',
              stylingMode:"outlined",
              onClick: this.save.bind(this)
            }}
          );
        },
      refreshData() {
        var params = {};
        params.useFlag = 'Y';
        dsu.getAxiosOBJGet('/api/erp/EMMM/select', params).then(res => {
          this.manufacturingList = res.data.data;
        });
      },
      onRowInserting(e){
        if(!this.checkDuplicateValue(e.data, true)){
           e.cancel = true;
        }
      },
      onRowUpdating(e){
        let data = {};
        let chkKey = false;

        Object.assign(data, e.oldData);
        Object.assign(data, e.newData);

        if((e.newData.operation &&  e.newData.operation != e.oldData.operation)
          || (e.newData.fromCode &&  e.newData.fromCode != e.oldData.fromCode)
          || (e.newData.toCode &&  e.newData.toCode != e.oldData.toCode)){
           chkKey = true;
        }

        if(!this.checkDuplicateValue(data, chkKey)){
           e.cancel = true;
        }
      },
      onExporting(e){
        e.fileName = this.$route.meta.transName;
      },
      checkDuplicateValue(data, chkKey){
        for (var i = 0; i < this.manufacturingList.length; i++) {
          if(chkKey){
            if (data.operation == this.manufacturingList[i].operation 
                && data.fromCode == this.manufacturingList[i].fromCode
                && data.toCode == this.manufacturingList[i].toCode){
                  fn.notifyError('중복 데이터, 동일 입력 값 확인필요');
                  return false;
            }
          }
        }
        return true;
      },
      save(){
          if(this.manufacturingList.length == 0)
          {
            fn.notifyError('가공정보 입력 후 진행 필요');
            return;
          }
          
          dsu.getAxiosOBJPost('/api/erp/EMMM/update', {manufacturingList: JSON.stringify(this.manufacturingList)}).then(res => {
              let response = res.data;

              if(response.result == 'SUCCESS'){
                fn.notifySuccess(response.msg);
                this.refreshData;
              }else {
                fn.notifyError(response.msg);
              }
          });
      }
    }
  };
</script>

<style scoped>
  #gridContainer {
      height: calc( 100vh - 50px );
      width: 100%;
  }
</style>