<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <ProjectList :ref="projectListRefName"
                   @loadSelectedData="loadSelectedData"/>

      <DxForm id="form"
              :ref="formRefName"
              :form-data="formData">
        <DxGroupItem caption="입력 정보" >                   
          <DxSimpleItem 
            data-field="salesUserId"
            editor-type="dxSelectBox"
            :editor-options="{dataSource: this.userList,
                              valueExpr: 'id',
                              displayExpr: 'userNameId',
                              searchEnabled: true,
                              onSelectionChanged: this.changedSalesUserId}">
            <DxRequiredRule message="영업 담당자 필수 입력"/>
            <DxLabel text="영업 담당자"/>
          </DxSimpleItem>

          <DxSimpleItem
            data-field="branch"
            editor-type="dxTextBox"
            :editor-options="{readOnly: true}">
            <DxRequiredRule message="지사 필수 입력"/>
            <DxLabel text="지사"/>
          </DxSimpleItem>          
        </DxGroupItem>
      </DxForm>

      <DxToolbar>
        <DxItem 
          widget="dxButton"
          :options="{type: 'custom', icon: 'save', text: '완료', stylingMode: 'outlined', onClick: this.complete.bind(this)}"
          location="center"/>
        <DxItem
          widget="dxButton"
          :options="{type: 'custom', icon: 'remove', text: '미 완료', stylingMode: 'outlined', onClick: this.inComplete.bind(this)}"
          location="center"/>
      </DxToolbar>
    </DxScrollView>
  </div>
</template>

<script>
  import DxScrollView from "devextreme-vue/scroll-view";
  import ProjectList from "../_common/PROJECT_LIST";
  import {DxForm,
          DxGroupItem,
          DxSimpleItem,
          DxLabel,
          DxRequiredRule} from "devextreme-vue/form";
  import DxToolbar,
         {DxItem} from "devextreme-vue/toolbar";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";

  export default {
    components:{
      DxScrollView,
      ProjectList,
      DxForm,
      DxGroupItem,
      DxSimpleItem,
      DxLabel,
      DxToolbar,
      DxItem,
      DxRequiredRule
    },
    data(){
      return {
        step: 1,
        projectListRefName: 'projectList',
        formRefName: 'form',
        userList: [],
        formData: {salesUserId: '', branch: ''},
        projectInfo: {},
        inputedBranch: '',
      };
    },
    mounted(){
      dsu.getAxiosUserList({dept: '영업'}).then(res => {this.userList = res.data.data});
    }, 
    computed: {
      form: function () { return this.$refs[this.formRefName].instance; }
    },
    methods:{
      loadSelectedData(params){
        this.reset();
        params.step = this.step;
        this.projectInfo = params;

        dsu.getAxiosOBJGet('/api/erp/PREG/selectProjectData', params).then(res =>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            this.inputedBranch = response.data.branch;
            for(var key in this.formData){
              if(response.data[key]){
                this.formData[key] = response.data[key];
              }
            }
          }else{
            fn.notifyError(response.msg);
          }
        });
      },
      changedSalesUserId(e){
        if(e.selectedItem && !this.inputedBranch){
          this.formData.branch = e.selectedItem.branch;
        }
        this.inputedBranch = '';
      },
      complete(){
        if(!this.projectInfo.projectId){
          fn.notifyError('Project 선택 후 진행 필요');
          return;
        }

        let validationResult =  this.form.validate();
        if( validationResult.status == 'valid'){
          var params = this.projectInfo;
          params.step = this.step;
          
          var finalData = JSON.parse(JSON.stringify(this.formData));
          finalData.status = 'COMPLETE';
          params.projectDatas = JSON.stringify(finalData);
      
          dsu.getAxiosOBJPost('/api/erp/PREG/updateProjectData', params).then(res => {
              let response = res.data;
              if(response.result == 'SUCCESS'){
                fn.notifySuccess(response.msg);
                this.$refs[this.projectListRefName].search();
              }else {
                fn.notifyError(response.msg);
              }
          });
        }
      },
      inComplete(){
        if(!this.projectInfo.projectId){
          fn.notifyError('Project 선택 후 진행 필요');
          return;
        }

        var params = this.projectInfo;
        params.step = this.step;
        params.projectDatas = JSON.stringify({status: 'INCOMPLETE'});
        
        dsu.getAxiosOBJPost('/api/erp/PREG/updateProjectData', params).then(res => {
            let response = res.data;
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);
              this.reset();
            }else {
              fn.notifyError(response.msg);
            }
        });
      },
      reset(){
        this.form.resetValues();
      }
    }
  };
</script>

<style scoped>
  #Container {
      height: calc( 100vh - 50px );
      width: 100%;
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      background-color:white;
  }
  #form{
    margin-top: 40px;
  }
</style>