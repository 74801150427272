var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DxDataGrid',{attrs:{"data-source":_vm.historyStore,"show-borders":true,"show-column-lines":true,"columns":_vm.columns,"columnAutoWidth":true},on:{"cellDblClick":_vm.onDblClick},scopedSlots:_vm._u([{key:"colorsCellTemplate",fn:function(ref){
var data = ref.data;
return [(data.data.color != null)?_c('div',[_c('img',{staticClass:"status-icon-cell middle",attrs:{"src":require('@/assets/colors/' + data.data.color + '.png')}}),_c('span',{staticClass:"middle"},[_vm._v(_vm._s(data.data.color))])]):_vm._e()]}},{key:"fileCellTemplate",fn:function(ref){
var data = ref.data;
return [(data.column.dataField.includes('file') && data.data[data.column.dataField])?_c('span',{staticClass:"dx-icon-download"}):_vm._e()]}},{key:"fileIdCellTemplate",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.getFileName(data))+" ")]}},{key:"imageCellTemplate",fn:function(ref){
var data = ref.data;
return [(data.value)?_c('img',{staticClass:"imageCell",attrs:{"src":_vm.getImageURL(data.value)}}):_vm._e()]}}])},[_c('DxSorting',{attrs:{"mode":"none"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }