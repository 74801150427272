<template>
  <DxDataGrid
    id="gridContainer"
    :ref="gridRefName"
    :data-source="dataSource"
    :focused-row-enabled="true"
    :show-borders="true"
    :show-column-lines="true"
    :columnAutoWidth="true"
    @exporting="onExporting">

    <DxSearchPanel :visible="true"/>
    <DxHeaderFilter :visible="true"/>
    <DxScrolling mode="infinite"/>
    <DxSorting mode="multiple"/>
    <DxExport :enabled="true"/>
    <DxColumnChooser :enabled="true" mode="select" />
        
    <DxColumn
      data-field="equipmentId"
      caption="장비 코드"
      :header-filter="{ allowSearch: true }">
    </DxColumn>

    <DxColumn
      data-field="name"
      caption="장비 명"
      :header-filter="{ allowSearch: true }">
    </DxColumn> 

    <DxColumn
      data-field="operation"
      caption="공정">
      <DxLookup
        :data-source="operationList"
        value-expr="operation"
        display-expr="codeName"/>      
    </DxColumn>

    <DxColumn
      data-field="constructionSiteCode"
      caption="건설 현장">
      <DxLookup
        :data-source="constructionSiteList"
        value-expr="code"
        display-expr="nameCode"/>      
    </DxColumn>

    <DxColumn
      data-field="constructionSiteManager"
      caption="현장 소장"
      :header-filter="{ allowSearch: true }">
    </DxColumn> 

    <DxColumn
      data-field="status"
      caption="상태">
      <DxLookup
        :data-source="eqpStatus"
        value-expr="ID"
        display-expr="Name"/>      
    </DxColumn>

     <DxColumn
      data-field="testCheckStatus"
      caption="시험 점검 상태">
      <DxLookup
        :data-source="eqpTestCheckStatus"
        value-expr="ID"
        display-expr="Name"/>      
    </DxColumn>

    <DxMasterDetail
      id='detail'
      :enabled="true"
      template="master-detail"/>
      <template #master-detail="{data}">
        <History :master-detail-data="data"/>
      </template>
    
  </DxDataGrid>

</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxSearchPanel,
          DxLookup,
          DxMasterDetail,
          DxColumnChooser} from "devextreme-vue/data-grid";
  import dsu from "../../utils/dataSourceUtils";
  import {eqpStatus,
          eqpTestCheckStatus} from "../../utils/syscodedata";
  import History from "../_common/EQPHISTORY";

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxSearchPanel,
      DxLookup,
      DxMasterDetail,
      DxColumnChooser,
      History
    },
    data() {
      return {
        dataSource: dsu.getComDSGrid('/api/mes/MVEM', 'equipmentId'),
        gridRefName: 'dataGrid',
        operationList: [],
        constructionSiteList: [],
        eqpStatus: eqpStatus,
        eqpTestCheckStatus: eqpTestCheckStatus 
      };
    },
    mounted(){
      dsu.getAxiosOperationList().then(res => {this.operationList = res.data.data});
      dsu.getAxiosConstructionSiteList().then(res => {this.constructionSiteList = res.data.data});      
    },
    methods:{
      onExporting(e) {
        e.fileName = this.$route.meta.transName;
      }
    }
  };
</script>

<style scoped>
  #gridContainer {
      height: calc( 100vh - 50px );
      width: 100%;}
  #detail {
      width: 100%;}
</style>