<template>
  <div>
    <DxForm :ref="formRefName"
            :form-data="formData"
            class="form">
       <DxGroupItem :col-count="3">
        <DxSimpleItem v-if="showStartDate"
          data-field="startDate"
          editor-type="dxDateBox"
          :editor-options="{displayFormat: 'yyyy/MM/dd', value: defaultStartDate}">
          <DxRequiredRule message="시작 일 필수 입력"/>
          <DxLabel text="시작 일"/>
        </DxSimpleItem>>
      
        <DxSimpleItem v-if="showEndDate"
          data-field="endDate"
          editor-type="dxDateBox"
          :editor-options="{displayFormat: 'yyyy/MM/dd', value: defaultEndDate}">
          <DxRequiredRule message="종료 일 필수 입력"/>
          <DxLabel text="종료 일"/>
        </DxSimpleItem>>
        
        <DxSimpleItem v-if="showCategory"
          data-field="category"
          editor-type="dxSelectBox"
          :editor-options="{dataSource: this.materialCategory,
                            valueExpr: 'category',
                            displayExpr: 'category',
                            showClearButton: true,
                            searchEnabled: true}">
          <DxLabel text="품목"/>
        </DxSimpleItem>
         
        <DxSimpleItem v-if="showCode"
          data-field="code"
          editor-type="dxSelectBox"
          :editor-options="{dataSource: this.materialList,
                            valueExpr: 'code',
                            displayExpr: 'codeName',
                            showClearButton: true,
                            searchEnabled: true}">
          <DxLabel text="자재 코드"/>
        </DxSimpleItem>

        <DxSimpleItem v-if="showOperation"
          data-field="operation"
          editor-type="dxSelectBox"
          :editor-options="{dataSource: this.operationList,
                            valueExpr: 'operation',
                            displayExpr: 'codeName',
                            showClearButton: true,
                            searchEnabled: true}">
          <DxLabel text="공정"/>
        </DxSimpleItem>
      
      </DxGroupItem>
    </DxForm>
    
    <DxToolbar>
      <DxItem 
        widget="dxButton"
        :options="{type: 'custom', icon: 'search', text: '조회', stylingMode: 'outlined', onClick: this.search.bind(this)}"
        location="center"/>
      <DxItem
        widget="dxButton"
        :options="{type: 'custom', icon: 'remove', text: '초기화', stylingMode: 'outlined', onClick: this.reset.bind(this)}"
        location="center"/>
    </DxToolbar>
  </div>
</template>
<script>
  import {DxForm,
          DxGroupItem,
          DxSimpleItem,
          DxLabel,
          DxRequiredRule} from "devextreme-vue/form";
  import DxToolbar,
         {DxItem} from "devextreme-vue/toolbar";
  import dsu from "../../utils/dataSourceUtils";

  export default {
    components: {
      DxForm,
      DxGroupItem,
      DxSimpleItem,
      DxLabel,
      DxRequiredRule,
      DxToolbar,
      DxItem},
    props: {
      showStartDate: Boolean,
      showEndDate: Boolean,
      showCategory: Boolean,
      showCode: Boolean,
      showOperation: Boolean
    },
    data: function() {
      return {
        formRefName: 'form',       
        formData: {},
        materialCategory: [],
        materialList: [],
        operationList: [],
        constructionSiteList: [],
        defaultStartDate: null,
        defaultEndDate: null,
      };
    },
    mounted(){
      dsu.getAxiosMaterialCategoryList().then(res => {this.materialCategory = res.data.data});
      dsu.getAxiosMaterialList().then(res => {this.materialList = res.data.data});
      dsu.getAxiosOperationList({eqpOper : 'N'}).then(res => {this.operationList = res.data.data});
      dsu.getAxiosConstructionSiteList().then(res => {this.constructionSiteList = res.data.data});
      this.reset();
    },
    computed: {
      form: function () {
        return this.$refs[this.formRefName].instance;
      }
    },
    methods: {
      search(){
        let validationResult =  this.form.validate();
        if( validationResult.status == 'valid'){
          var params = JSON.parse(JSON.stringify(this.formData));
          params.startDate = dsu.getFormatDate(this.formData.startDate);
          params.endDate = dsu.getFormatDate(this.formData.endDate);
          this.$emit('search', params);
        }
      },
      reset(){
        this.form.resetValues();
        this.defaultEndDate = new Date();
        var startDate = new Date();
        startDate.setDate(this.defaultEndDate .getDate() - 7);
        this.defaultStartDate = startDate;
      }
    }
  };
</script>
<style scoped>
  .form{
    padding-top: 20px;
    padding-bottom: 20px;
  }
</style>