<template>
  <DxDataGrid
    id="gridContainer"
    :data-source="dataSource"
    :focused-row-enabled="true"
    :columnAutoWidth="true"
    :show-borders="true"
    :show-column-lines="true"
    @exporting="onExporting">
    
    <DxSearchPanel :visible="true"/>
    <DxHeaderFilter :visible="true"/>
    <DxScrolling mode="infinite"/>
    <DxSorting mode="multiple"/>
    <DxExport :enabled="true"/>
    <DxEditing
      :allow-updating="true"
      :allow-adding="true"
      :allow-deleting="true"
      mode="popup"/>
   
    <DxColumn type="buttons">
      <DxButton name="edit"/>
    </DxColumn>
   
    <DxColumn
      data-field="operation"
      caption="공정 코드"
      :header-filter="{ allowSearch: true }">
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^[0-9]{1,4}$"
        message="숫자 4자까지 등록 가능"/>
    </DxColumn>
    
    <DxColumn
      data-field="name"
      caption="공정 명"
      :header-filter="{ allowSearch: true }">
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^.{1,15}$"
        message="15자까지 등록 가능"/>
    </DxColumn>

    <DxColumn
      data-field="location"
      caption="위치">  
      <DxRequiredRule/>
      <DxLookup
        :data-source="operationLocation"
        value-expr="ID"
        display-expr="Name"/>
    </DxColumn>

    <DxColumn
      data-field="ship"
      caption="출고">  
      <DxLookup
        :data-source="[{'ID': 'Y', 'Name': 'Y'}]"
        value-expr="ID"
        display-expr="Name"
        :allow-clearing="true"/>
    </DxColumn>

    <DxColumn
      data-field="inspType"
      caption="검사 구분">  
      <DxLookup
        :data-source="inspType"
        value-expr="ID"
        display-expr="Name"
        :allow-clearing="true"/>
    </DxColumn>

    <DxColumn
      data-field="eqpOper"
      caption="장비 전용">  
      <DxLookup
        :data-source="[{'ID': 'Y', 'Name': 'Y'}]"
        value-expr="ID"
        display-expr="Name"
        :allow-clearing="true"/>
    </DxColumn>

    <DxMasterDetail
      :enabled="true"
      template="master-detail"/>
      <template #master-detail="{ data }">
        <History :master-detail-data="data"
                :url= "`/api/erp/EMOG/selectHistory`"
                :keyName="operation"
                :columns="historyColumns"/>
      </template>    
    </DxDataGrid>
</template>

<script>
  import { DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxLookup,
          DxRequiredRule,
          DxPatternRule,
          DxMasterDetail} from "devextreme-vue/data-grid";
  import dsu from "../../utils/dataSourceUtils";
  import {operationLocation,
          inspType } from "../../utils/syscodedata";
  import History from "../_common/SIMPLE_HISTORY";

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxLookup,
      DxRequiredRule,
      DxPatternRule,
      DxMasterDetail,
      History
    },
    data() {
      return {
        dataSource: dsu.getComDSGrid('/api/erp/EMOG', 'operation', '', '공정 코드'),
        operationLocation: operationLocation,
        inspType: inspType,
        historyColumns: [{dataField : "transTime", caption : "변경 시간"},
                  {dataField: "name", caption: "공정 명"},
                  {dataField: "location", caption: "위치", lookup: {dataSource: operationLocation, valueExpr: "ID", displayExpr: "Name"}},
                  {dataField: "ship", caption: "출고"},
                  {dataField: "userId", caption: "사용자"},
                  {dataField: "dml", caption: "Action"}]
      };
    },
    methods:{
       onExporting(e) {
          e.fileName = this.$route.meta.transName;
        }
    }
  };
</script>

<style scoped>
  #gridContainer {
      height: calc( 100vh - 50px );
      width: 100%;
  }
</style>