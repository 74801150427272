<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <TransactionBatch :ref="transactionRefName"
                   @setBatchInfoExt="setBatchInfoExt"
                   @clearBatchInfoExt="clearBatchInfoExt"
                   @addLot="addLot"/>

      <DxDataGrid
        :ref="gridRefName"
        :columnAutoWidth="true"
        :show-borders="true"
        :show-column-lines="true"
        @row-removing="deleteBatchLot">
      
        <DxEditing :allow-deleting="true"/>
        <DxSorting mode="none"/>

        <DxColumn
          data-field="name"
          caption="자재 명">
        </DxColumn>

        <DxColumn
          data-field="quantity"
          caption="수량"
          alignment="right">  
        </DxColumn>

        <DxColumn
          data-field="lotNumber"
          caption="Lot No.">
        </DxColumn>
      </DxDataGrid>
    </DxScrollView>
  </div>
</template>

<script>
  import {DxScrollView} from "devextreme-vue/scroll-view";
  import TransactionBatch from "../_common/TRANSACTION_BATCH";
  import {DxDataGrid,
          DxColumn,
          DxEditing,
          DxSorting} from "devextreme-vue/data-grid";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";

export default {
    components: {
      DxScrollView,
      TransactionBatch,
      DxDataGrid,
      DxColumn,
      DxEditing,
      DxSorting
    },
    data: function() {
      return {
        transactionRefName: 'transaction',
        gridRefName: 'grid',
        batchInfo: {},
        batchLotList: []
        
      };
    },
    methods: {
      setBatchInfoExt(batchInfo){
        this.batchInfo = batchInfo;
        dsu.getAxiosOBJGet('/api/mes/MCBT/selectBatchDetail', batchInfo).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            const grid = this.$refs[this.gridRefName].instance;
            grid.option('dataSource', response.data);
          }else{
            fn.notifyError(response.msg);
          }
        });
      },
      clearBatchInfoExt()
      {
        const grid = this.$refs[this.gridRefName].instance;
        grid.option('dataSource', []);
      },
      async addLot(lotInfo){
        if(this.batchInfo.status != '00'){
          fn.notifyError("출하된 Batch 는 수정 불가");
          return;
        }
        await dsu.getAxiosOBJPost('/api/mes/MCBT/assignBatId', lotInfo).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            fn.notifySuccess(response.msg);
            this.setBatchInfoExt(lotInfo);
          }else{
            fn.notifyError(response.msg);
          }
        });
      },
      async deleteBatchLot(event){
        if(this.batchInfo.status != '00'){
          fn.notifyError("출하된 Batch 는 수정 불가");
          event.cancel = true;
          return;
        }

        await dsu.getAxiosOBJPost('/api/mes/MCBT/deleteLotInBatch', event.data).then(res =>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            fn.notifysuccess(response.msg);
          }else{
            fn.notifyError(response.msg);
          }
          this.setBatchInfoExt(event.data);
        });
      }
    }
  };
</script>

<style scoped>
  #Container{
    height: calc( 100vh - 50px );
    width: 100%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background-color:white;
  }
</style>