<template>
  <div>
    <DxToolbar>
      <DxItem 
       v-if="!hideBarcode"
        widget="dxButton"
        :options="btnOptBarcode"
        location="before"/>
      <DxItem
        v-if="!hideExecute"
        widget="dxButton"
        :options="btnOptExecute"
        location="after"/>
    </DxToolbar>
    
    <div>
      <qrcode-stream 
          @decode="onDecode"
          @init="onInit"
          v-if="!destroyed">
          <div class="loading-indicator"
              v-if="loading">
              바코드 스캐너 로딩 중....
          </div>
      </qrcode-stream>
    </div>
    
    <DxForm :ref="formRefName"
            :form-data="formData">
      <DxSimpleItem 
        data-field="searchEqp"
        editor-type="dxTextBox"
        :editor-options="{inputAttr: {'style': 'text-transform: uppercase'}, onKeyUp:keyUp}">
        <DxLabel text="장비 조회"/>
      </DxSimpleItem>
      
      <DxGroupItem caption="장비 정보">
        <DxSimpleItem name="equipmentId">
          <DxLabel text="장비 코드"/>
          <template #default>
            <DxTextBox
              :read-only="true"
              :value="formData.equipmentId"/>
          </template>
        </DxSimpleItem>

        <DxSimpleItem name="name">
          <DxLabel text="장비 명"/>
          <template #default>
            <DxTextBox
              :read-only="true"
              :value="formData.name"/>
          </template>
        </DxSimpleItem>

        <DxSimpleItem v-if="!hideOperation" name="operation">
          <DxLabel text="현재 공정" />
          <template #default>
            <DxSelectBox
              placeholder=""
              value-expr="operation"
              display-expr="codeName"
              readOnly="true"  
              :value="formData.operation"
              :data-source="operationList"/>
          </template>
        </DxSimpleItem>

        <DxSimpleItem name="constructionSiteCode">
          <DxLabel text="현재 건설 현장" />
          <template #default>
            <DxSelectBox
              placeholder=""
              value-expr="code"
              display-expr="nameCode"
              readOnly="true"  
              :value="formData.constructionSiteCode"
              :data-source="constructionSiteList"/>
          </template>
        </DxSimpleItem>  

        <DxSimpleItem v-if="!hideConstructionSiteManager" name="constructionSiteManager">
          <DxLabel text="현재 현장 소장" />
          <template #default>
            <DxTextBox
              :read-only="true"
              :value="formData.constructionSiteManager"/>
          </template>
        </DxSimpleItem>        
       
        <DxSimpleItem v-if="!hideTestCheckStatus" name="testCheckStatus">
          <DxLabel text="현재 시험 점검 상태" />
          <template #default>
            <DxSelectBox
              placeholder=""
              value-expr="ID"
              display-expr="Name"
              readOnly="true"  
              :value="formData.testCheckStatus"
              :data-source="eqpTestCheckStatus"/>
          </template>
        </DxSimpleItem>        
      </DxGroupItem>
    </DxForm>
  </div>
</template>
<script>
  import DxToolbar,
         {DxItem} from "devextreme-vue/toolbar";
  import {DxForm,
          DxGroupItem,
          DxSimpleItem,
          DxLabel} from "devextreme-vue/form";
  import {DxSelectBox} from "devextreme-vue/select-box";
  import {QrcodeStream} from "vue-qrcode-reader";
  import {DxTextBox} from "devextreme-vue/text-box";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  import {eqpTestCheckStatus} from "../../utils/syscodedata";

  export default {
    components: {
      DxForm,
      DxGroupItem,
      DxSimpleItem,
      DxLabel,
      DxToolbar,
      DxItem,
      DxSelectBox,
      DxTextBox,
      QrcodeStream},
    props: {
      hideOperation: Boolean,
      hideConstructionSiteManager: Boolean,
      hideTestCheckStatus: Boolean,
      hideExecute: Boolean,
      hideBarcode: Boolean
    },      
    data: function() {
      return {
        formRefName: 'form',
        loading: false,
        destroyed: true,
        formData: {searchEqp: '', name: '', operation: '', constructionSiteCode: '', constructionSiteManager: '', testCheckStatus: ''},
        operationList: [],    
        constructionSiteList: [],
        eqpTestCheckStatus: eqpTestCheckStatus,  
        btnOptBarcode: {
            type: 'custom',
            text: '바코드',
            stylingMode:"outlined",
            onClick: this.toggle
        },
        btnOptExecute: {
            type: 'custom',
            text: '실행',
            stylingMode: "outlined",
            onClick: this.execute.bind(this)
        }
      };
    },
    mounted(){
      dsu.getAxiosOperationList().then(res => {this.operationList = res.data.data});
      dsu.getAxiosConstructionSiteList().then(res => {this.constructionSiteList = res.data.data});
    },
    methods: {
      clear(){
        this.$refs[this.formRefName].instance.resetValues();
        Object.keys(this.formData).map((key) => {
            this.formData[key] ='';
        });
        this.$emit('clearEqpInfoExt');    
      },
      execute(e){
        if (!this.formData.equipmentId){
            fn.notifyError('장비 조회 후 진행 필요');
            return;
        }
        this.$emit('execute', this.formData, e);
      },
      async onInit(promise) {
        this.loading = true
        try {
          await promise
        } catch (error) {
          console.error(error)
        } finally {
          this.loading = false
        }
      },
      onDecode(result) {
        this.clear();
        this.formData.searchEqp = result;
        this.getEqpInfo();
        this.toggle();
      },
      async toggle(){
        this.destroyed = !this.destroyed;
        await this.$nextTick();
      },
      keyUp(e)
      {
        if (e.event.key == 'Enter'){
          this.getEqpInfo();
        }
      },
      getEqpInfo(){
        var params = {};

        if(!this.formData.searchEqp){
            fn.notifyError('장비 코드 입력 후 검색필요');
            return;
        }

        params.equipmentId = this.formData.searchEqp.toUpperCase();

        dsu.getAxiosOBJGet('/api/mes/MVEM/select', params).then(res => {
          if(res.data.data.length == 0){
            fn.notifyError('존재하지 않는 장비');
            this.clear();
          }else{
            this.formData = res.data.data[0];
            this.$emit('setEqpInfoExt', this.formData);
          }
        });
      }
    }
  };
</script>

<style scoped>
  .loading-indicator {
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
  }
</style>