<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <DxToolbar>
        <DxItem
          widget="dxButton"
          :options="btnOptExecute"
          location="after"/>
      </DxToolbar>      
                     
      <div class="dx-field">
        <div class="dx-field-label">건설 현장</div>
        <div class="dx-field-value">
          <DxSelectBox
            :search-enabled="true"
            placeholder=""
            value-expr="code"
            display-expr="nameCode"
            width="350"
            :data-source="constructionSiteList"
            @value-changed="changedConstructionSiteCode"/>
        </div>
      </div>

      <DxDataGrid
        :ref="gridRefName"
        :columnAutoWidth="true"
        :show-borders="true"
        :show-column-lines="true"
        :key-expr="item"
        :selection="{ mode: 'single' }"
        @rowDblClick="onDblClick">

        <DxSorting mode="none"/>

        <DxColumn
          data-field="item"
          caption="검사 항목">
        </DxColumn>

        <DxColumn
          data-field="result"
          caption="결과"
          alignment="center">  
        </DxColumn>

        <DxColumn
          data-field="unit"
          caption="단위"
          alignment="center">  
        </DxColumn>
        
        <DxColumn
          data-field="lsl"
          caption="LSL"
          alignment="center">  
        </DxColumn>

        <DxColumn
          data-field="usl"
          caption="USL"
          alignment="center">  
        </DxColumn>

        <DxColumn
          data-field="judgement"
          caption="판정"
          alignment="center">  
        </DxColumn>        
       </DxDataGrid>

       <DxPopup :ref="popupNumberRefName"
                :drag-enabled="false"
                :close-on-outside-click="true"
                :show-title="true"
                :width="300"
                :height="200"
                title="결과 입력">
                <div class='Item'>항목: {{currentRow.item}}</div>
                <DxNumberBox :value="numberValue"
                             @valueChanged="setNumberValue"/> 
                <div class='ButtonBox'>
                  <DxButton class='Button'
                      :width="120"
                      text="입력"
                      type="success"
                      styling-mode="contained"
                      @click="setResult('')"/>
                </div>
       </DxPopup>

       <DxPopup :ref="popupPassFailRefName"
                :drag-enabled="false"
                :close-on-outside-click="true"
                :show-title="true"
                :width="300"
                :height="230"
                title="결과 입력">
                <div class='Item'>항목 : {{currentRow.item}}</div>
                <div class='ButtonBox'>
                  <DxButton class='Button'
                    :width="120"
                    text="Pass"
                    type="success"
                    styling-mode="contained"
                    @click="setResult('PASS')"/>
                  <DxButton class='Button'
                    :width="120"
                    text="Fail"
                    type="danger"
                    styling-mode="contained"
                    @click="setResult('FAIL')"/>
                </div>
       </DxPopup>
    </DxScrollView>
  </div>
</template>

<script>
  import {DxScrollView} from "devextreme-vue/scroll-view";
  import DxToolbar,
         {DxItem} from "devextreme-vue/toolbar";
  import {DxDataGrid,
          DxColumn,
          DxSorting} from "devextreme-vue/data-grid";
  import DxPopup from "devextreme-vue/popup";
  import {DxSelectBox} from "devextreme-vue/select-box";   
  import {DxNumberBox} from "devextreme-vue/number-box";
  import DxButton from "devextreme-vue/button";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function"

  export default {
    components: {
      DxScrollView,
      DxDataGrid,
      DxColumn,
      DxSorting,
      DxPopup,
      DxNumberBox,
      DxButton,
      DxSelectBox,
      DxToolbar,
      DxItem 
    },
    data: function() {
      return {
        gridRefName: 'aaaa',
        popupNumberRefName: 'popupNumber',
        popupPassFailRefName: 'popupPassFail',
        currentRow:{},
        numberValue: '',
        constructionSiteList: [],
        constructionSiteCode: '',
        btnOptExecute: {
            type: 'custom',
            text: '저장',
            stylingMode:"outlined",
            onClick: this.execute.bind(this)
        }  
      };
    },
    mounted(){
      dsu.getAxiosConstructionSiteList().then(res => {this.constructionSiteList = res.data.data});
    },    
    methods: {
      changedConstructionSiteCode(e){
        this.constructionSiteCode = e.value;
        var params = {constructionSiteCode: this.constructionSiteCode};
        this.setGridData([]);

        dsu.getAxiosOBJGet('/api/mes/MISC/selectConstructionSiteInspecSpec', params).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            this.setGridData(response.data);
          }else{
            fn.notifyError(response.msg);
          }
        });
      },      
      onDblClick(e){
        this.currentRow = e.data;
        if(this.currentRow.method == 'PASS/FAIL'){
          this.$refs[this.popupPassFailRefName].instance.show();
        }else{
          this.$refs[this.popupNumberRefName].instance.show();
        }
      },
      async execute(){
        var params = {constructionSiteCode: this.constructionSiteCode};
        var inspectionResultList = this.getGridData();
       
        for (var i = 0; i < inspectionResultList.length; i++) {
          if(!inspectionResultList[i].lsl){
            fn.notifyError(inspectionResultList[i].item + " Spec 미 설정");
            return;
          }          
          
          if(!inspectionResultList[i].result){
            fn.notifyError(inspectionResultList[i].item + " 항목 결과 미 입력");
            return;
          }
        }

        params.inspectionResultList = JSON.stringify(inspectionResultList);

        await dsu.getAxiosOBJPost(`/api/mes/MISC/updateConstructionSiteInspectionResult`, params).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            fn.notifySuccess(response.msg);
          }else{
            fn.notifyError(response.msg);
          }
        });
      },
      setGridData(data){
        var grid = this.$refs[this.gridRefName].instance;
        grid.option('dataSource', data);
      },
      getGridData(){
        var grid = this.$refs[this.gridRefName].instance;
        var dataSource = grid.getDataSource();
        return dataSource.items();
      },
      setResult(result){
        let finalResult = '';

        this.$refs[this.popupNumberRefName].instance.hide();
        this.$refs[this.popupPassFailRefName].instance.hide();
        
        if(result == ''){
          finalResult = this.numberValue;
          this.numberValue ='';
        }else{
          finalResult = result;
        }
       
        var inspectionResultList = this.getGridData();
        for (var i = 0; i < inspectionResultList.length; i++) {
          if(inspectionResultList[i].item == this.currentRow.item){
            inspectionResultList[i].result = finalResult;

            if(inspectionResultList[i].method == 'PASS/FAIL'){
              inspectionResultList[i].judgement = finalResult;
            }else if(inspectionResultList[i].method == 'MEASURE'){
              if(finalResult){
                if(inspectionResultList[i].lsl < finalResult && inspectionResultList[i].usl > finalResult){
                  inspectionResultList[i].judgement = 'PASS';
                }else{
                  inspectionResultList[i].judgement = 'FAIL';
                }
              }else{
                inspectionResultList[i].judgement = '';
              }
            }
          }
        }
        
        this.setGridData(inspectionResultList);
      },
      setNumberValue(e){
        this.numberValue = e.value;
      }
    }
  };
</script>

<style scoped>
  #Container{
    height: calc( 100vh - 50px );
    width: 100%;
    float: left;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background-color:white;
  }

  .Item{
    margin-bottom: 15px;
  }

  .ButtonBox{
    text-align: center;
  }

  .Button{
    margin: 7px;
  }
  
  .dx-field-label{
    width: 75px;
    font-size: 14px;
    font-weight: 700;
    color: black;
  }
</style>