<template>
  <div>
   
  </div>
</template>
<script>

import auth from "../auth";

export default {
  created() {
        auth.logOut();
        this.$router.push({
            path: "/login-form"
        });
    }
}

</script>