<template>
  <DxDataGrid
    :ref="gridRefName"
    :data-source="historyStore"
    :show-borders="true"
    :show-column-lines="true"
    :columnAutoWidth="true"
    :focused-row-enabled="true"
    :columns="historyColumns">
    
    <DxSorting mode="none"/>
 
  </DxDataGrid>
</template>

<script>
  import {DxDataGrid,
          DxSorting} from "devextreme-vue/data-grid";
  import {createStore} from "devextreme-aspnet-data-nojquery";
  import {lotStatus,
          lotType,
          pmCycle,
          dayTolerance} from "../../utils/syscodedata"; 
  import store from "../../store";

  export default {
    components: {
      DxDataGrid,
      DxSorting
    },
    props: {
      masterDetailData: {
          type: Object,
          default: () => ({})
      }      
    },
    data() {
      return {
        lotStatus: lotStatus,
        gridRefName: "grid",
        lotType: lotType,
        pmCycle: pmCycle,
        dayTolerance: dayTolerance

      };
    },
    computed: {
      historyStore() {
        let {transaction} = this.masterDetailData.data;
        var dataSource;
        var key = "";
        var loadUrl = "";
        var loadParams = {};

        if(transaction == "MISP"){
          let {lotNumber,
              transTime} = this.masterDetailData.data;
          key = "item";
          loadUrl = "/api/mes/MISP/selectInspectionResult";
          loadParams = {lotNumber: lotNumber,
                        transTime: transTime};          
        }else if(transaction == "EEPM"){
          let {equipmentId,
               transTime} = this.masterDetailData.data;
          key = "transTime";
          loadUrl = "/api/mes/MVPR/selectPmResult";
          loadParams = {equipmentId: equipmentId,
                        transTime: transTime};          
        }else{
          return;
        }
        
        if(transaction){
          dataSource = {store: createStore({
                          key: key,
                          loadUrl: loadUrl,
                          loadParams: loadParams,
                          onBeforeSend: (method, ajaxOptions) => {
                                ajaxOptions.headers = { token: store.state.token, userId: store.state.userId };  
                              }
                          })
                        }; 
        }       

        return dataSource
      },
      historyColumns(){
        let {transaction} = this.masterDetailData.data;
        
        let columns;
        if(transaction == "MISP"){
          columns = [{dataField : "item", caption : "검사 항목"},
                     {dataField : "unit", caption : "단위"},
                     {dataField : "lsl", caption : "LSL"},
                     {dataField : "tg", caption : "기준 값"},
                     {dataField: "usl", caption: "USL" },
                     {dataField : "tolerance", caption: "허용 오차"},
                     {dataField : "result", caption: "결과 1"},
                     {dataField : "result2", caption: "결과 2"},
                     {dataField : "result3", caption: "결과 3"},
                     {dataField : "average", caption : "평균"},
                     {dataField : "judgement", caption : "판정"}];
        }else if(transaction == "EEPM"){
          columns = [{dataField : "item", caption : "PM 항목"},
                     {dataField : "result", caption : "결과"},
                     {dataField : "cycle", caption : "주기", lookup: {dataSource: this.pmCycle, valueExpr: "ID", displayExpr: "Name"}},
                     {dataField : "tolerance", caption : "Tolerance", lookup: {dataSource: this.dayTolerance, valueExpr: "ID", displayExpr: "Name"}},
                     {dataField : "method", caption : "점검 방법"},
                     {dataField : "spec", caption : "Spec"},
                     {dataField : "reActionPlan", caption : "Re-Action Plan"},
                     {dataField : "userCmt", caption : "예외 사유"}];
        }
        return columns
      }
    }
  };
</script>
