<template>
  <div id="gridContainer">
    <DxToolbar>
      <DxItem :options="dateBoxOptions"
              location="center"
              locate-in-menu="auto"
              widget="dxDateBox"/>        
      <DxItem :options="buttonOptionsSearch"
              location="center"
              locate-in-menu="never"
              widget="dxButton"/>         
      <DxItem :options="buttonOptionsPrint"
              location="center"
              locate-in-menu="never"
              widget="dxButton"/>                            
    </DxToolbar>

    <DxDataGrid
      id="grid"
      :dataSource="dataSource"
      :ref="gridRefName"
      :show-borders="true"
      :show-column-lines="true"
      :columnAutoWidth="true">

      <DxSearchPanel :visible="false"/>
      <DxHeaderFilter :visible="false"/>
      <DxScrolling mode="infinite"/>
      <DxSorting mode="none"/>
      
      <DxColumn
        data-field="lotNumber"
        caption="Lot No.">
      </DxColumn>

    </DxDataGrid>
   
  </div>
</template>

<script>
  import DxToolbar, {DxItem} from "devextreme-vue/toolbar";
  import {DxDataGrid,
          DxScrolling,
          DxColumn,
          DxSorting,
          DxSearchPanel,
          DxHeaderFilter} from "devextreme-vue/data-grid";
  import fn from "../../utils/function";
  import dsu from "../../utils/dataSourceUtils";
 
  var defaultDate = new Date();
  export default {
    components: {
      DxToolbar,
      DxItem,
      DxDataGrid,
      DxScrolling,
      DxColumn,
      DxSorting,
      DxSearchPanel,
      DxHeaderFilter
    },
    data() {
      return {
        gridRefName: 'dataGrid',
        date: defaultDate,
        dataSource: [],
        dateBoxOptions: {
          width: 180,
          value: defaultDate,
          displayFormat:" 날짜 :  yyyy/MM/dd",
          onValueChanged: (args) => {
            this.date = args.value;
          }
        },
        buttonOptionsSearch: {
          icon: 'search',
          text: '조회',
          stylingMode: "outlined",
          onClick: this.search.bind()
        },
        buttonOptionsPrint: {
          text: '라벨 발행',
          stylingMode: "outlined",
          onClick: this.print.bind()
        }              
      };
    },
    methods: {
      search(){
        var params = {searchDate: this.date};
       
        params.searchDate = dsu.getFormatDate(params.searchDate);
      
        dsu.getAxiosOBJGet('/api/mes/MPLS/selectLabelPrintHst', params).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            this.dataSource = response.data;
          }else{
            fn.notifyError(response.msg);
          }
        });
      },
      print(){
        fn.printLotLabel(this.dataSource);
      }
    }   
  };
</script>

<style scoped>
  #gridContainer {
    height: calc( 100vh - 50px );
    width: 100%;}
  #grid {
    width: 100%;
    height: calc( 100vh - 101px )}
</style>