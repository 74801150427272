<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <Transaction :ref="transactionRefName" 
                   @clearLotInfoExt="clearLotInfoExt"
                   @execute="execute"/>
    
      <DxForm :form-data="formData">
        <DxGroupItem caption="선택 정보">
          <DxSimpleItem name="quantityNew">
            <DxLabel text="보정 수량" />
            <template #default>
              <DxNumberBox 
                :value="formData.quantityNew"
                @value-changed="changedQuantityNew"
                min="0"
                max="9999999999"/>
            </template>
          </DxSimpleItem>        

          <DxSimpleItem name="reasonCodeNew">
            <DxLabel text="사유 코드" />
            <template #default>
              <DxSelectBox
                placeholder=""
                value-expr="ID"
                display-expr="Name"
                :value="formData.reasonCodeNew"
                :data-source="reasonCode"
                @value-changed="changedReasonCodeNew"/>
            </template>
          </DxSimpleItem>          
        </DxGroupItem>
      </DxForm>
    </DxScrollView>
  </div>
</template>
<script>
  import {DxScrollView} from "devextreme-vue/scroll-view";
  import {DxForm,
          DxGroupItem,
          DxSimpleItem,
          DxLabel} from "devextreme-vue/form";
  import {DxNumberBox} from "devextreme-vue/number-box";
  import {DxSelectBox} from "devextreme-vue/select-box";
  import Transaction from "../_common/TRANSACTION";
  import {reasonCode} from "../../utils/syscodedata";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function"

  export default {
    components: {
      DxScrollView,
      Transaction,
      DxForm,
      DxGroupItem,
      DxSimpleItem,
      DxLabel,
      DxNumberBox,
      DxSelectBox
    },
    data: function() {
      return {
        transactionRefName: 'transaction',
        reasonCode: reasonCode,
        formData: {quantityNew: undefined, reasonCodeNew: ''}
      };
    },
    methods: {
      clearLotInfoExt(){
        Object.keys(this.formData).map((key) => {
            this.formData[key] ='';
        });
      },
      changedQuantityNew(e){
        this.formData.quantityNew = e.value;
      }, 
      changedReasonCodeNew(e){
        this.formData.reasonCodeNew = e.value;
      }, 
      async execute(transInfo){
        if(!this.formData.quantityNew && this.formData.quantityNew != 0){
          fn.notifyError("보정 수량 입력 후 진행 필요");
          return;
        }

        if(!this.formData.reasonCodeNew){
          fn.notifyError("사유 코드 입력 후 진행 필요");
          return;
        }

        if(this.formData.quantityNew == 0){
          transInfo.status = '99';
        }
        transInfo.quantityNew = this.formData.quantityNew;
        transInfo.reasonCodeNew = this.formData.reasonCodeNew;
        await dsu.getAxiosOBJPost('/api/mes/MCQT/changeQuantity', transInfo).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            fn.notifySuccess(response.msg);
            fn.printLotLabel(transInfo);
            this.$refs[this.transactionRefName].clear();
          }else{
            fn.notifyError(response.msg);
          }
        });
      }
    }
  };
</script>

<style scoped>
  #Container{
    height: calc( 100vh - 50px );
    width: 100%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background-color:white;
  }
</style>