<template>
  <DxDataGrid
    :data-source="historyStore"
    :show-borders="true"
    :show-column-lines="true"
    :columns="columns"
    :columnAutoWidth="true"
    :selection="{ mode: 'single' }"
    @cellDblClick="onDblClick">
    <DxSorting mode="multiple"/>
    
    <DxSearchPanel :visible="true"/>
    <DxHeaderFilter :visible="true"/>
    <DxScrolling mode="infinite"/>
    
    <template #fileCellTemplate="{ data }">
      <span v-if="data.column.dataField.includes('file') && data.data[data.column.dataField]" class="dx-icon-download"/>
    </template>
  </DxDataGrid>
</template>

<script>
  import {DxDataGrid,
          DxSorting,
          DxSearchPanel,
          DxHeaderFilter,
          DxScrolling} from "devextreme-vue/data-grid";
  import {createStore} from "devextreme-aspnet-data-nojquery";
  import store from "../../store";
  import fn from "../../utils/function";

  export default {
    components: {
      DxDataGrid,
      DxSorting,
      DxSearchPanel,
      DxHeaderFilter,
      DxScrolling
    },
    props: {
      masterDetailData: {
          type: Object,
          default: () => ({})
      },
      url: String,
      keyName: String,
      columns: Object,
      conKeyName1: String,
      conKeyValue1: String
    },
    computed: {
      historyStore() {
        var params = {};
        params.key = this.masterDetailData.key;
        params[this.conKeyName1] = this.conKeyValue1;
  
        return this.masterDetailData.key === null ? null : {
          store: createStore({
            key: this.keyName,
            loadUrl: this.url,
            loadParams: params,
            onBeforeSend: (method, ajaxOptions) => {
               ajaxOptions.headers = { token: store.state.token, userId: store.state.userId  };  
            }
          })
        };
      }
    },
    methods:{
      onDblClick(e){
        fn.downLoadFile(e);
      }
    }
  };
</script>