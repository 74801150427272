<template>
  <DxDataGrid
    :ref="gridRefName"
    :data-source="historyStore"
    :show-borders="true"
    :show-column-lines="true"
    :columnAutoWidth="true"
    :focused-row-enabled="true"
     @exporting="onExporting">
    
    <DxSorting mode="none"/>
    <DxExport :enabled="true"/>
    <DxScrolling mode="infinite"/>

    <DxColumn data-field="transTimeStr" caption="시간"/>
    <DxColumn data-field="equipmentId" caption="장비 코드"/>
    <DxColumn data-field="name" caption="장비 명"/>
    <DxColumn data-field="transactionName" caption="Action"/>
    <DxColumn data-field="operationNew" caption="공정"/>
    <DxColumn data-field="constructionSiteCode" caption="건설 현장"/>
    <DxColumn data-field="constructionSiteManager" caption="현장 소장"/>
    <DxColumn data-field="status" caption="상태">
      <DxLookup
        :data-source="eqpStatus"
        value-expr="ID"
        display-expr="Name"/>
    </DxColumn>
    <DxColumn data-field="testCheckStatus" caption="시험 점검 상태">
      <DxLookup
        :data-source="eqpTestCheckStatus"
        value-expr="ID"
        display-expr="Name"/>
    </DxColumn>
    <DxColumn data-field="userCmt" caption="비고"/>
    <DxColumn data-field="userId" caption="사용자"/>


    <DxMasterDetail
      id="detail"
      :enabled="true"
      template="master-detail"/>
      <template #master-detail="{data}">
        <ActionHistory :master-detail-data="data"/>
      </template>
  </DxDataGrid>
</template>

<script>
  import {DxDataGrid,
           DxSorting,
           DxColumn,
           DxLookup,
           DxExport,
           DxMasterDetail,
           DxScrolling} from "devextreme-vue/data-grid";
  import ActionHistory from"./ACTION_HISTORY";
  import {createStore} from "devextreme-aspnet-data-nojquery";
  import store from "../../store";
  import {eqpStatus,
          eqpTestCheckStatus} from "../../utils/syscodedata"; 
  import dsu from "../../utils/dataSourceUtils";

  export default {
    components: {
      DxDataGrid,
      DxSorting,
      DxColumn,
      DxLookup,
      DxExport,
      DxMasterDetail,
      ActionHistory,
      DxScrolling
    },
    props: {
      masterDetailData: {
          type: Object,
          default: () => ({})
      }      
    },
    data() {
      return {
        eqpStatus: eqpStatus,
        eqpTestCheckStatus: eqpTestCheckStatus,
        gridRefName: "grid",
        equipmentId: ""    
      };
    },
    computed: {
      historyStore() {
          return this.masterDetailData.key === null ? null : {
          store: createStore({
            key: "transTime",
            loadUrl: `/api/mes/MVEH/select`,
            loadParams: {equipmentId: this.masterDetailData.key},
            onBeforeSend: (method, ajaxOptions) => {
               ajaxOptions.headers = { token: store.state.token, userId: store.state.userId };  
            }
          })
        };
      }
    },    
    methods: {
      searchEqphistory(equipmentId){
        this.equipmentId = equipmentId;
        dsu.getAxiosOBJGet(`/api/mes/MVEH/select`, {equipmentId: equipmentId}).then(res =>{
          this.setHistoryData(res.data.data);
        });
      },
      clear(){
        this.setHistoryData([]);
      },
      setHistoryData(data)
      {
        const grid = this.$refs[this.gridRefName].instance;
        grid.option("keyExpr", "transTime");
        grid.option("dataSource", data);
      },
      onExporting(e) {
        var equipmentId = this.masterDetailData.key;

        if(!equipmentId){
          equipmentId =  this.equipmentId ;
        }
     
        e.fileName = "장비 이력_" +  equipmentId;
      },
    }
  };
</script>
