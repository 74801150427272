<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <ProjectList @loadSelectedData="loadSelectedData"/>

      <DxForm id="form"
              :ref="formRefName"
              :form-data="formData">
        <DxGroupItem caption="입력 정보" >                   
           <DxSimpleItem
            data-field="designDeliveryResult"
            editor-type="dxTextArea"
            :editor-options="{height: '200px', maxLength: 1000}">
            <DxRequiredRule message="Contact 결과"/>
            <DxLabel text="설계 납품 결과"/>
          </DxSimpleItem>          
        </DxGroupItem>
      </DxForm>

      <DxToolbar>
        <DxItem 
          widget="dxButton"
          :options="{type: 'custom', icon: 'save', text: '완료', stylingMode: 'outlined', onClick: this.complete.bind(this)}"
          location="center"/>
        <DxItem
          widget="dxButton"
          :options="{type: 'custom', icon: 'remove', text: '미 완료', stylingMode: 'outlined', onClick: this.inComplete.bind(this)}"
          location="center"/>
      </DxToolbar>
    </DxScrollView>
  </div>
</template>

<script>
  import DxScrollView from "devextreme-vue/scroll-view";
  import ProjectList from "../_common/PROJECT_LIST";
  import {DxForm,
          DxGroupItem,
          DxSimpleItem,
          DxLabel,
          DxRequiredRule} from "devextreme-vue/form";
  import DxToolbar,
         {DxItem} from "devextreme-vue/toolbar";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";

  export default {
    components:{
      DxScrollView,
      ProjectList,
      DxForm,
      DxGroupItem,
      DxSimpleItem,
      DxLabel,
      DxToolbar,
      DxItem,
      DxRequiredRule
    },
    data(){
      return {
        step: 7,
        formRefName: 'form',
        formData: {customerContactMethod: '', customerContactResult: ''},
        projectInfo: {}
      };
    }, 
    computed: {
      form: function () {
        return this.$refs[this.formRefName].instance;
      }
    },
    methods:{
      loadSelectedData(params){
        this.reset();
        params.step = this.step;
        this.projectInfo = params;

        dsu.getAxiosOBJGet('/api/erp/PREG/selectProjectData', params).then(res =>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            for(var key in this.formData){
              if(response.data[key]){
                this.formData[key] = response.data[key];
              }
            }
          }else{
            fn.notifyError(response.msg);
          }
        });
      },
      complete(){
        if(!this.projectInfo.projectId){
          fn.notifyError('Project 선택 후 진행 필요');
          return;
        }

        let validationResult =  this.form.validate();
        if( validationResult.status == 'valid'){
          var params = this.projectInfo;
          params.step = this.step;

          var finalData = JSON.parse(JSON.stringify(this.formData));
          finalData.status = 'COMPLETE';
          params.projectDatas = JSON.stringify(finalData);
      
          dsu.getAxiosOBJPost('/api/erp/PREG/updateProjectData', params).then(res => {
              let response = res.data;
              if(response.result == 'SUCCESS'){
                fn.notifySuccess(response.msg);
              }else {
                fn.notifyError(response.msg);
              }
          });
        }
      },
      inComplete(){
        if(!this.projectInfo.projectId){
          fn.notifyError('Project 선택 후 진행 필요');
          return;
        }

        var params = this.projectInfo;
        params.step = this.step;
        params.projectDatas = JSON.stringify({status: 'INCOMPLETE'});
        
        dsu.getAxiosOBJPost('/api/erp/PREG/updateProjectData', params).then(res => {
            let response = res.data;
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);
              this.reset();
            }else {
              fn.notifyError(response.msg);
            }
        });
      },
      reset(){
        this.form.resetValues();
      }
    }
  };
</script>

<style scoped>
  #Container {
      height: calc( 100vh - 50px );
      width: 100%;
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      background-color:white;
  }
  
  #form{
    margin-top: 40px;
  }
</style>