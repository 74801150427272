<template>
  <div id="Container">
    <DxDataGrid
      id="gridContainer"
      :ref="gridRefName"
      :data-source="unitPriceList"
      :key-expr="targetMonth"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      :selection="{ mode: 'single' }"
      @toolbar-preparing="onToolbarPreparing($event)"
      @cellDblClick="onDblClick">
      
      <DxSearchPanel :visible="true"/>
      <DxHeaderFilter :visible="true"/>
      <DxScrolling mode="infinite"/>
      <DxSorting mode="multiple"/>

      <DxColumn
        data-field="targetMonth"
        caption="년/월"
        :header-filter="{ allowSearch: true }"/>

      <DxColumn
        data-field="userId"
        caption="등록자"
        :header-filter="{ allowSearch: true }"/>

      <DxColumn
        data-field="transTime"
        caption="등록 시간"
        :allowHeaderFiltering="false"/>
    
      <DxColumn
        data-field="fileUnitPrice"
        caption="단가 파일"
        alignment="center"
        cell-template="fileCellTemplate"
        :allowHeaderFiltering="false"/>   

      <DxMasterDetail
        :enabled="true"
        template="master-detail"/>
        <template #master-detail="{ data }">
          <Detail :master-detail-data="data"
                  :url= "`/api/erp/ERUP/selectUnitPriceInfoMonthDetail`"
                  :keyName="code"
                  :conKeyName1="'targetMonth'"
                  :conKeyValue1="data.data.targetMonth"
                  :columns="detailColumns"/>
        </template> 

      <template #fileCellTemplate="{}">
          <span class="dx-icon-download"/>
      </template>   
    </DxDataGrid>
  
    <DxPopup :ref="popupRefName"
            :drag-enabled="false"
            :close-on-outside-click="true"
            :show-title="true"
            :width="400"
            :height="385"
            title="단가 정보 등록">
      <form id="form"
            :ref="formRefName"
            method="post"
            enctype="multipart/form-data">
        <div class="fileuploader-container">
          <div class="dx-fieldset">
            <div class="dx-field">
              <div class="dx-field-label">년/월</div>
              <DxDateBox
                  :ref="targetMonthRefName"
                  type="date"
                  display-format="yyyy/MM"/>
            </div> 
            <div class="dx-field">
              <DxFileUploader
                :ref="unitPriceFileRefName"
                name="file"
                select-button-text="단가 파일 선택"
                upload-mode="useForm"/>
            </div>
          </div>
        </div>
        <div class='buttonContainer'>
          <DxButton
            text="등록"
            icon="save"
            width="200"
            @click="saveUnitPrice"/>
        </div>
      </form>
    </DxPopup>
  </div>  
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxHeaderFilter,
          DxSearchPanel,
          DxMasterDetail} from "devextreme-vue/data-grid";
  import DxPopup from "devextreme-vue/popup";
  import DxDateBox from "devextreme-vue/date-box";
  import {DxFileUploader} from "devextreme-vue/file-uploader";     
  import DxButton from "devextreme-vue/button";
  import Detail from "../_common/DETAIL";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxHeaderFilter,
      DxSearchPanel,
      DxMasterDetail,
      DxPopup,
      DxFileUploader,
      DxDateBox,
      DxButton,
      Detail
    },
    data() {
      return {
        unitPriceList: [],
        gridRefName: 'grid',
        formRefName: 'form',
        popupRefName: 'popup',
        targetMonthRefName: 'targetMonth',
        unitPriceFileRefName: 'unitPriceFile',
        saveButtonRefName: 'saveButton',
        detailColumns: [{dataField : "item", caption : "품명"},
                        {dataField : "unitPrice", caption : "단가(원)", format: {precision: 3}}]
      };
    }, 
    mounted(){
      this.refreshData();
    },
    computed: {
      grid: function () {return this.$refs[this.gridRefName].instance;},
      popupInst: function () {return this.$refs[this.popupRefName].instance;},
      targetMonthInst: function () {return this.$refs[this.targetMonthRefName].instance;},
      unitPriceFileInst: function () {return this.$refs[this.unitPriceFileRefName].instance;}
    },
    methods:{
      refreshData(){
        dsu.getAxiosOBJGet('/api/erp/ERUP/selectUnitPriceInfoMonth').then(res => {
          this.unitPriceList = res.data.data;
        });
      },
      onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
          {location: 'before',
          widget: 'dxButton',
          options: {
            icon: 'upload',
            text: '단가 등록',
            stylingMode:"outlined",
            onClick: this.shoRegUnitPrice.bind(this)
          }},
          {location: 'before',
          widget: 'dxButton',
          options: {
            icon: 'remove',
            text: '단가 삭제',
            stylingMode:"outlined",
            onClick: this.deleteUnitPrice.bind(this)
          }},
          {location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'download',
            text: '등록 양식',
            stylingMode:"outlined",
            onClick: this.downloadRegFile.bind(this)
          }}
        );
      },
      shoRegUnitPrice(){
        this.targetMonthInst.reset();
        this.targetMonthInst.option('value', new Date())
        this.unitPriceFileInst.reset();
        this.popupInst.show();
      },
      async deleteUnitPrice(){
        let selectedRows = this.grid.getSelectedRowsData();

        if(selectedRows.length == 0){
          fn.notifyError("대상 선택 후진행 필요");
          return;
        }

        if(await fn.confirm('정말 삭제 하시겠습니까?<br><br><br>년/월 : ' + selectedRows[0].targetMonth) == false){
           return;
        } 
        
        await dsu.getAxiosOBJPost('/api/erp/ERUP/deleteUnitPriceInfoMonth', selectedRows[0]).then(res=>{
            let response = res.data;
            if(response.result == 'SUCCESS'){
              this.refreshData();
              fn.notifySuccess(response.msg);
              this.popupUpload.hide();
            }else{
              fn.notifyError(response.msg);
            }
        });
      },
      saveUnitPrice(e){
        var params = {};
        
        var targetMonth = this.targetMonthInst.option('value');
        if(!targetMonth){
          fn.notifyError('년/월 미 선택');
          return;
        }

        var fileObj = this.unitPriceFileInst.option('value');
        if(fileObj.length  == 0){
          fn.notifyError('파일 미 선택');
          return;
        }
        if (fileObj[0].size > 104857600){
          fn.notifyError('최대용량 초과(100mb)');
          return;
        }

        params.targetMonth = dsu.getFormatDate(targetMonth).substr(0,6);

        const form = this.$refs[this.formRefName];
        e.component.option('disabled', true);
        dsu.getAxiosOBJPostMultiPart('/api/erp/ERUP/saveUnitPriceInfoMonth', params, form).then(res=>{
            e.component.option('disabled', false);
            let response = res.data;
            if(response.result == 'SUCCESS'){
              fn.alert(response.msg);
              this.popupInst.hide();
              this.refreshData();
            }else{
              fn.alert(response.msg);
            }
        });
      },
      onDblClick(e){
        fn.downLoadFile(e);
      },
      downloadRegFile(){
        window.location.href = 'https://kspan.kr/download/REG_UNIT_PRICE.xlsx';
      }
    }
  };
</script>

<style scoped>
  #Container {
    height: calc( 100vh - 50px );
    width: 100%;
  }
  
  #gridContainer {
    height: calc( 100vh - 50px );
    width: 100%;
  }

  .dx-field-label{
    width: 120px;
    font-size: 14px;
    font-weight: 400;
    color: black;
  }

  .dx-field-value{
    font-size: 14px;
    font-weight: 400;
    color: black;
  }
  
  #form {
    max-width: 600px;
    margin: auto;
  }

  .fileuploader-container{
    border: 1px solid #d3d3d3;
    text-align: center;
    height: 240px;
  }
  
  .buttonContainer{
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
  }
</style>