<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <Transaction :ref="transactionRefName" 
                   @setEqpInfoExt="setEqpInfoExt"
                   @clearEqpInfoExt="clearEqpInfoExt"
                   @execute="execute"/>
      <DxForm>
        <DxGroupItem caption="선택 정보">
          <DxSimpleItem name="nextOperation">
            <DxLabel text="다음 공정" />
            <template #default>
              <DxSelectBox
                placeholder=""
                value-expr="operation"
                display-expr="codeName"
                :value="formData.nextOperation"
                :data-source="operationList"
                @value-changed="changedNextOperation"/>
                
            </template>
          </DxSimpleItem>
          <DxSimpleItem name="nextConstructionSiteCode">
               <DxLabel text="다음 건설 현장" />
            <template #default>
           
              <DxSelectBox
                placeholder=""
                value-expr="code"
                display-expr="nameCode"
                search-enabled="true"
                :value="formData.nextConstructionSiteCode"
                :data-source="constructionSiteList"
                @value-changed="changedNextConstructionSiteCode"
                :show-clear-button="true"/>
            </template>
          </DxSimpleItem>              
          <DxSimpleItem name="nextConstructionSiteManager">
          <DxLabel text="다음 현장 소장" />
            <template #default>
              
              <DxTextBox
                :value="formData.nextConstructionSiteManager"
                :max-length="6"
                @value-changed="changedNextConstructionSiteManager"/>
            </template>
          </DxSimpleItem>          
        </DxGroupItem>
      </DxForm>
    </DxScrollView>
  </div>
</template>

<script>
  import {DxScrollView} from "devextreme-vue/scroll-view";
  import Transaction from "../_common/TRANSACTION_EQP";
  import {DxForm,
          DxGroupItem,
          DxSimpleItem,
          DxLabel} from "devextreme-vue/form";
  import {DxSelectBox} from "devextreme-vue/select-box";
  import {DxTextBox} from "devextreme-vue/text-box";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function"

  export default {
    components: {
      DxScrollView,
      Transaction,
      DxForm,
      DxGroupItem,
      DxSimpleItem,
      DxLabel,
      DxSelectBox,
      DxTextBox
    },
    data: function() {
      return {
        transactionRefName: 'transaction',
        formData: {nextOperation: '', nextConstructionSiteCode: '', nextConstructionSiteManager: ''},
        operationList: [],
        constructionSiteList: []
      };
    },
    mounted(){
      dsu.getAxiosOperationList().then(res => {this.operationList = res.data.data});
      dsu.getAxiosConstructionSiteList().then(res => {
          this.constructionSiteList = res.data.data;
          this.constructionSiteList.unshift({code: '', nameCode: ''});
      });
    },
    methods: {
      clearEqpInfoExt(){
        Object.keys(this.formData).map((key) => {
            this.formData[key] ='';
        });
      },
      changedNextOperation(e){
        this.formData.nextOperation = e.value;
      },
      changedNextConstructionSiteCode(e){
        this.formData.nextConstructionSiteCode = e.value;
      },      
      changedNextConstructionSiteManager(e){
        this.formData.nextConstructionSiteManager = e.value;
      },
      async execute(transInfo){
        var params = {};
        Object.assign(params, transInfo);

        params.nextOperation = this.formData.nextOperation;
        
        if(!params.nextOperation){
          fn.notifyError("다음 공정 선택 필요");
          return;
        }        

        params.constructionSiteCode = this.formData.nextConstructionSiteCode;
        params.constructionSiteManager = this.formData.nextConstructionSiteManager;

        if(params.nextOperation == '9000' && !params.constructionSiteCode && !params.constructionSiteManager){
          fn.notifyError("9000번 이동 시 건설 현장 또는 현장 소장 입력 필요 ");
          return;
        }

        if(params.nextOperation != '9000' && params.constructionSiteCode){
          fn.notifyError("9000번 이동 시 에만 건설 현장 입력 가능 ");
          return;
        }

        if(params.nextOperation != '9000' && params.constructionSiteManager){
          fn.notifyError("9000번 이동 시 에만 현장 소장 입력 가능 ");
          return;
        }
       
        if(params.operation == params.nextOperation && params.nextOperation != '9000'){
          fn.notifyError("같은 공정으로 이동 불가");
          return;
        }

        if(transInfo.constructionSiteCode == params.constructionSiteCode && transInfo.constructionSiteManager == params.constructionSiteManager && params.nextOperation == '9000'){
          fn.notifyError("같은 건설현장, 현장 소장으로 이동 불가");
          return;
        }
        
        await dsu.getAxiosOBJPost(`/api/mes/EPOU/moveOper`, params).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            fn.notifySuccess(response.msg);
            this.$refs[this.transactionRefName].clear();
          }else{
            fn.notifyError(response.msg);
          }
        });
      }
    }
  };
</script>

<style scoped>
  #Container{
    height: calc( 100vh - 50px );
    width: 100%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background-color:white;
  }
</style>