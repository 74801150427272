<template>
  <DxDataGrid
    :data-source="historyStore"
    :show-borders="true"
    :show-column-lines="true"
    :columnAutoWidth="true"
    @cellDblClick="onDblClick">>

    <DxSorting mode="none"/>

    <DxColumn data-field="codeName" caption="자재 코드"/>
    <DxColumn data-field="category" caption="품목"/>
    <DxColumn data-field="spec" caption="규격"/>
    <DxColumn data-field="unit" caption="단위"/>
    <DxColumn data-field="requestQuantity" caption="요청 수량"/>
    <DxColumn data-field="shipQuantity" caption="출고 수량"/>
    <DxColumn data-field="balanceShip" caption="Balance(출고)"/>
    <DxColumn data-field="prepareQuantity" caption="준비 완료 수량"/>
    <DxColumn data-field="balancePrepare" caption="Balance(준비)"/>
    <DxColumn data-field="procurementMethod" caption="조달 방법">
      <DxLookup
        :data-source="procurementMethodList"
        value-expr="ID"
        display-expr="Name"/>
    </DxColumn>
    <DxColumn data-field="remark" caption="비고"/>
    <DxColumn data-field="file0001" caption="성적서" alignment="center" cell-template="fileCellTemplate"/>
    <template #fileCellTemplate="{ data }">
        <span v-if="data.column.dataField.includes('file') && data.data[data.column.dataField]" class="dx-icon-download"/>
    </template>
  </DxDataGrid>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxLookup,
          DxSorting} from "devextreme-vue/data-grid";
  import {createStore} from "devextreme-aspnet-data-nojquery";
  import store from "../../store";
  import {procurementMethodList} from "../../utils/syscodedata";
  import fn from "../../utils/function";
      
  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxLookup,
      DxSorting
    },
    data() {
      return {
        procurementMethodList: procurementMethodList
      };
    },    
    props: {
      masterDetailData: {
          type: Object,
          default: () => ({})
      },
      columns: Object
    },
    computed: {
      historyStore() {
        return this.masterDetailData.key === null ? null : {
          store: createStore({
            key: 'code',
            loadUrl: '/api/mes/MVRM/selectRequestMaterialVSShip',
            loadParams: {constructionSiteCode: this.masterDetailData.data.constructionSiteCode,
                         seq: this.masterDetailData.data.seq},
            onBeforeSend: (method, ajaxOptions) => {
               ajaxOptions.headers = { token: store.state.token, userId: store.state.userId  };  
            }
          })
        };
      }
    },
    methods:{
      onDblClick(e){
        fn.downLoadFile(e);
      }
    }
  };
</script>