<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <div class="dx-field">
        <div class="dx-field-label">건설 현장</div>
        <div class="dx-field-value">
          <DxSelectBox
            :search-enabled="true"
            placeholder=""
            value-expr="code"
            display-expr="nameCode"
            width="350"
            :data-source="constructionSiteList"
            @value-changed="changedConstructionSiteCode"/>
        </div>
      </div>
  
      <DxDataGrid
        id="Grid"
        :ref="gridRefName"
        :key-expr="seq"
        :focused-row-enabled="true"
        :show-borders="true"
        :show-column-lines="true"
        :columnAutoWidth="true"
        @exporting="onExporting">

        <DxSearchPanel :visible="true"/>
        <DxHeaderFilter :visible="true"/>
        <DxScrolling mode="infinite"/>
        <DxSorting mode="multiple"/>
        <DxExport :enabled="true"/>
        <DxColumnChooser :enabled="true" mode="select" />

        <DxColumn data-field="seq" caption="청구 번호"/>
        <DxColumn data-field="requestDate" caption="청구 일자"/>
        <DxColumn data-field="reviewDate" caption="검토 일자"/>
        <DxColumn data-field="expectedDeliveryDate" caption="납기 예정일"/>
        <DxColumn data-field="requestUserId" caption="청구자"/>
        <DxColumn data-field="remarkSpecial" caption="특기 사항"/>
        <DxColumn data-field="remarkConstructionSite" caption="현장 협의 사항"/>
        <DxColumn data-field="useFlag" caption="출고"/>

        <DxMasterDetail :enabled="true"
                        template="master-detail"/>
          <template #master-detail="{ data }">
            <Detail :master-detail-data="data"/>
          </template>    
      </DxScrollView>
  </div>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxSearchPanel,
          DxColumnChooser,
          DxMasterDetail} from "devextreme-vue/data-grid";
  import {DxSelectBox} from "devextreme-vue/select-box";        
  import {DxScrollView} from "devextreme-vue/scroll-view";
  import {lotStatus,
          lotType,
          procurementMethodList} from "../../utils/syscodedata";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  import Detail from "../_common/REQUEST_MATERIAL_LIST";

export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxSearchPanel,
      DxColumnChooser,
      DxSelectBox,
      Detail,
      DxMasterDetail,
      DxScrollView
    },
    data() {
      return {
        gridRefName: 'dataGrid',
        constructionSiteList: [],
        lotStatus: lotStatus,
        lotType: lotType,
        procurementMethodList: procurementMethodList                                                
      };
    },
    mounted(){
      dsu.getAxiosConstructionSiteList().then(res => {this.constructionSiteList = res.data.data});
    },
    methods:{
      onExporting(e) {
        e.fileName = this.$route.meta.transName;
      },
      setGridData(data){
        const grid = this.$refs[this.gridRefName].instance;
        grid.option('keyExpr', 'seq');
        grid.option('dataSource', data);
      },
      changedConstructionSiteCode(e){
        var params = {constructionSiteCode: e.value};
        this.setGridData([]);

        dsu.getAxiosOBJGet('/api/erp/EEHM/selectRequestMaterialInfo', params).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            this.setGridData(response.data);
          }else{
            fn.notifyError(response.msg);
          }
        });
      }
    }
  };
</script>

<style scoped>
  #Container{
    height: calc( 100vh - 50px );
    width: 100%;
    float: left;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background-color:white;}
 
  .dx-field-label{
    width: 75px;
    font-size: 14px;
    font-weight: 700;
    color: black;
  }
</style>