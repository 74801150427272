
<template>
  <div id="Container">
    <DxDataGrid
      id="gridContainer"
      :data-source="pmSpecList"
      :show-borders="true"
      :show-column-lines="true"
      :columnAutoWidth="true"
      :selection="{ mode: 'single' }"
      @row-inserting="onRowInserting"
      @row-updating="onRowUpdating"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)">
      
      <DxSearchPanel :visible="true"/>
      <DxHeaderFilter :visible="true"/>
      <DxScrolling mode="infinite"/>
      <DxSorting mode="multiple"/>
      <DxExport :enabled="true"/>
      <DxEditing
        :allow-updating="true"
        :allow-adding="true"
        :allow-deleting="true"
        mode="popup"/>
    
      <DxColumn type="buttons">
        <DxButton name="edit"/>
      </DxColumn>

      <DxColumn
        data-field="equipmentId"
        caption="장비 코드"
        :header-filter="{ allowSearch: true }">  
        <DxRequiredRule/>
        <DxLookup
          :data-source="equipmentList"
          value-expr="equipmentId"
          display-expr="nameCode"/>
      </DxColumn>
      
      <DxColumn
        data-field="item"
        caption="PM 항목"
        :header-filter="{ allowSearch: true }">
          
        <DxRequiredRule/>
        <DxPatternRule
          pattern="^[\S*].{1,13}[\S*]$|^\S{1,15}$"
          message="15자까지 입력 가능, 좌우 공백 불가"/>
      </DxColumn>

      <DxColumn
        data-field="cycle"
        caption="주기">  
        <DxRequiredRule/>
        <DxLookup
          :data-source="pmCycle"
          value-expr="ID"
          display-expr="Name"/>
      </DxColumn>

      <DxColumn
        data-field="day"
        caption="날짜">
        <DxRequiredRule/>
        <DxLookup
          :data-source="day"
          value-expr="ID"
          display-expr="Name"/>     
      </DxColumn>
      
      <DxColumn
        data-field="tolerance"
        caption="Tolerance">
        <DxLookup
          :data-source="dayTolerance"
          value-expr="ID"
          display-expr="Name"
          :allow-clearing="true"/>     
      </DxColumn>

      <DxColumn
        data-field="method"
        caption="점검 방법"
        :header-filter="{ allowSearch: true }">  
        <DxRequiredRule/>
        <DxPatternRule
          pattern="^[\S*].{1,13}[\S*]$|^\S{1,15}$"
          message="15자까지 입력 가능, 좌우 공백 불가"/>
      </DxColumn>

      <DxColumn
        data-field="spec"
        caption="Spec">  
        <DxRequiredRule/>
        <DxPatternRule
          pattern="^[\S*].{1,13}[\S*]$|^\S{1,15}$"
          message="15자까지 입력 가능, 좌우 공백 불가"/>
      </DxColumn>   
      
      <DxColumn
        data-field="reActionPlan"
        caption="Re-Action Plan"
        :header-filter="{ allowSearch: true }">  
        <DxRequiredRule/>
        <DxPatternRule
          pattern="^[\S*].{1,13}[\S*]$|^\S{1,15}$"
          message="15자까지 입력 가능, 좌우 공백 불가"/>
      </DxColumn>      

      <DxColumn
        data-field="startDate"
        caption="시작 일"
        data-type="date"
        format="yyyy/MM/dd">
        <DxRequiredRule/>
      </DxColumn>

      <DxColumn
        data-field="inspMethod"
        caption="입력 방법">  
        <DxRequiredRule/>
        <DxLookup
          :data-source="[{'ID': 'MEASURE', 'Name': 'MEASURE'}, {'ID': 'PASS/FAIL', 'Name': 'PASS/FAIL'}]"
          value-expr="ID"
          display-expr="Name"/>
      </DxColumn>

      <DxColumn
        data-field="unit"
        caption="단위">  
        <DxLookup
          :data-source="[{'ID': 'V', 'Name': 'V'}]"          
          value-expr="ID"
          display-expr="Name"
          :allow-clearing="true"/>
      </DxColumn>

      <DxColumn
        data-field="lsl"
        caption="LSL"
        data-type="number">
        <DxPatternRule
          pattern="^.{0,15}$"
          message="15자 까지 등록 가능"/>        
      </DxColumn>

      <DxColumn
        data-field="usl"
        caption="USL"
        data-type="number">
        <DxPatternRule
          pattern="^.{0,15}$"
          message="15자 까지 등록 가능"/>        
      </DxColumn>

    </DxDataGrid>
    <PopupCopy
      :ref="popupCopyRefName"
      :title="'PM 정보 복사'"
      :originalLebel="'원본 장비'"
      :originalList="equipmentList"
      :originalValueExpr="'equipmentId'"
      :originalDisplayExpr="'nameCode'"
      :targetLebel="'대상 장비'"
      :targetList="equipmentList"
      :targetValueExpr="'equipmentId'"
      :targetDisplayExpr="'nameCode'"
      @executeCopy="executeCopy"/>
  </div>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxLookup,
          DxRequiredRule
          }from "devextreme-vue/data-grid";
  import dsu from "../../utils/dataSourceUtils";
  import {pmCycle,
          day,
          dayTolerance} from "../../utils/syscodedata";
  import PopupCopy from "../../views/_common/POPUP_COPY";
  import fn from "../../utils/function";

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxLookup,
      DxRequiredRule,
      PopupCopy},
    data(){
      return {
        pmSpecList: [],
        equipmentList: [],
        pmCycle: pmCycle,
        day: day,
        dayTolerance: dayTolerance,
        popupCopyRefName: 'popupCopy'
      };
    },
    async mounted(){
      await dsu.getAxiosEquipmentList().then(res => {this.equipmentList = res.data.data});
      await this.refreshData();
    },
    methods:{
      onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
          {location: 'before',
          widget: 'dxButton',
          options: {
            width: 100,
            icon: 'copy',
            text: '복사',
            stylingMode:"outlined",
            onClick: this.showCopy.bind(this)
          }},
          {location: 'before',
          widget: 'dxButton',
          options: {
            width: 100,
            icon: 'save',
            text: '저장',
            stylingMode:"outlined",
            onClick: this.save.bind(this)
          }}
        );
      },
      refreshData(){
        dsu.getAxiosOBJGet('/api/erp/EEPS/select').then(res => {
          this.pmSpecList = res.data.data;
        });
      },
      onRowInserting(e){
        if(!this.validationValue(e.data, true)){
           e.cancel = true;
        }
      },
      onRowUpdating(e){
        let data = {};
        let chkKey = false;

        Object.assign(data, e.oldData);
        Object.assign(data, e.newData);
      
        if((e.newData.equipmentId &&  e.newData.equipmentId != e.oldData.equipmentId)
          || (e.newData.item &&  e.newData.item != e.oldData.item)){
           chkKey = true;
        }

        if(!this.validationValue(data, chkKey)){
           e.cancel = true;
        }
      },
      onExporting(e){
        e.fileName = this.$route.meta.transName;
      },
      validationValue(data, chkKey){
        for (var i = 0; i < this.pmSpecList.length; i++) {
          if(chkKey){
            if (data.equipmentId == this.pmSpecList[i].equipmentId 
                && data.item == this.pmSpecList[i].item){
                  fn.notifyError('중복 데이터, 장비 코드, PM 항목 동일 입력 값 확인필요');
                  return false;
            }
          }

          if(data.inspMethod == 'PASS/FAIL'){
            if(data.unit){
              fn.notifyError('입력 방법이 PASS/FAIL일 경우 단위 입력 불가');
              return false;
            }

            if(data.lsl){
              fn.notifyError('입력 방법이 PASS/FAIL일 경우 LSL 입력 불가');
              return false;
            }

            if(data.usl){
              fn.notifyError('입력 방법이 PASS/FAIL일 경우 USL 입력 불가');
              return false;
            }
          }else{
            if(!data.unit){
              fn.notifyError('단위 미 입력');
              return false;
            }

            if(!data.lsl){
              fn.notifyError('LSL 미 입력');
              return false;
            }

            if(!data.usl){
              fn.notifyError('USL 미 입력');
              return false;
            }
          }          
        }
        return true;
      },
      save(){
          if(this.pmSpecList.length == 0)
          {
            fn.notifyError('PM 기준 입력 후 진행 필요');
            return;
          }
          
          for (var i = 0; i < this.pmSpecList.length; i++) {
        
            this.pmSpecList[i].startDate = dsu.getFormatDate(this.pmSpecList[i].startDate);
          }
        
          dsu.getAxiosOBJPost('/api/erp/EEPS/update', {pmSpecList: JSON.stringify(this.pmSpecList)}).then(res => {
              let response = res.data;

              if(response.result == 'SUCCESS'){
                fn.notifySuccess(response.msg);
                this.refreshData;
              }else {
                fn.notifyError(response.msg);
              }
          });
      },
      showCopy(){
        this.$refs[this.popupCopyRefName].show();
      },
      executeCopy(copyInfo){
        var finalData = [];
        var existsOriginalData = 0;
        
        for (var i = 0; i < this.pmSpecList.length; i++) {
          if(this.pmSpecList[i].equipmentId != copyInfo.targetCode){ 
            finalData.push(this.pmSpecList[i]);
          }

          if(this.pmSpecList[i].equipmentId == copyInfo.originalCode){ 
            var tempData = {};
            Object.assign(tempData, this.pmSpecList[i]);
            tempData.equipmentId = copyInfo.targetCode;
            finalData.unshift(tempData);
            existsOriginalData += 1;
          }
        }
        
        if(existsOriginalData == 0){
          fn.notifyError('원본 장비 데이터 없음');
          return;
        }
     
        this.pmSpecList = finalData;
      }
    }
  };
</script>

<style scoped>
  #gridContainer {
      height: calc( 100vh - 50px );
      width: 100%;
  }
</style>