<template>
  <DxDataGrid
    id="gridContainer"
    :ref="gridRefName"
    :data-source="dataSource"
    :focused-row-enabled="true"
    :show-borders="true"
    :show-column-lines="true"
    :columnAutoWidth="true"
    @exporting="onExporting"
    @toolbar-preparing="onToolbarPreparing($event)">

    <DxSearchPanel :visible="true"/>
    <DxHeaderFilter :visible="true"/>
    <DxScrolling mode="infinite"/>
    <DxSorting mode="multiple"/>
    <DxExport :enabled="true"/>
    <DxColumnChooser :enabled="true" mode="select" />
    <DxGrouping :auto-expand-all="expanded"/>
    
    <DxColumn
      data-field="lotNumber"
      caption="Lot No."
      :header-filter="{ allowSearch: true }">
    </DxColumn>

    <DxColumn
      data-field="code"
      caption="자재 코드"
      :header-filter="{ allowSearch: true }">
      <DxLookup
        :data-source="materialListStore"
        value-expr="code"
        display-expr="codeName"/>
    </DxColumn>    
    
    <DxColumn
      data-field="color"
      caption="색상"
      cell-template="colorsCellTemplate">
    </DxColumn>

    <DxColumn
      data-field="quantity"
      caption="수량">
    </DxColumn>

    <DxColumn
      data-field="unit"
      caption="단위">
    </DxColumn>
    
    <DxColumn
      data-field="operation"
      caption="공정"
      :group-index="0">
      <DxLookup
        :data-source="operationList"
        value-expr="operation"
        display-expr="codeName"/>      
    </DxColumn>

    <DxColumn
      data-field="status"
      caption="상태">
      <DxLookup
        :data-source="lotStatus"
        value-expr="ID"
        display-expr="Name"/>      
    </DxColumn>

    <DxColumn
      data-field="category"
      caption="품목"
      :header-filter="{ allowSearch: true }">
    </DxColumn>

    <DxColumn
      data-field="mainLot"
      caption="공급업체 Lot"
      :header-filter="{ allowSearch: true }">
    </DxColumn>
 
    <DxColumn
      data-field="lotType"
      caption="Lot Type">
      <DxLookup
        :data-source="lotType"
        value-expr="ID"
        display-expr="Name"
      />
    </DxColumn>    

    <DxColumn
      data-field="supplier"
      caption="공급업체">  
    </DxColumn>

    <DxColumn
      data-field="inspectionResult"
      caption="검사 결과"
      :header-filter="{ allowSearch: true }">
    </DxColumn>

    <DxColumn
      data-field="batchId"
      caption="Batch ID"
      :header-filter="{ allowSearch: true }">
    </DxColumn>

    <DxColumn
      data-field="importTimeStr"
      caption="입고 일">
    </DxColumn>
    
    <DxColumn
      data-field="memo"
      caption="Memo"
      width="300">
    </DxColumn>

    <DxColumn
      data-field="userCmt"
      caption="비고"
      width="300">
    </DxColumn>

    <DxColumn
      data-field="importGroupId"
      caption="입고 Group ID">
    </DxColumn>

    <DxColumn
      data-field="prvConstructionSite"
      caption="이전 건설 현장">
    </DxColumn>

    <DxColumn
      data-field="reserveShipToName"
      caption="출고 예정 건설 현장">
    </DxColumn>

    <DxColumn
      data-field="certNo"
      caption="증명서 번호">
    </DxColumn>

    <DxSummary>
        <DxGroupItem
          column="quantity"
          summary-type="sum"
          display-format=" 총 수량: {0} "
        />
    </DxSummary>    
    <DxMasterDetail
      id='detail'
      :enabled="true"
      template="master-detail"/>
      <template #master-detail="{data}">
        <History :master-detail-data="data"/>
      </template>

      <template #colorsCellTemplate="{ data }">
        <div v-if="data.data.color != null">
          <img class="status-icon-cell middle" :src="require('@/assets/colors/' + data.data.color + '.png')">
          <span class="middle">{{ data.data.color }}</span>
        </div>           
      </template>
    </DxDataGrid>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxSearchPanel,
          DxLookup,
          DxMasterDetail,
          DxColumnChooser,
          DxGrouping,
          DxSummary,
          DxGroupItem} from "devextreme-vue/data-grid";
  import dsu from "../../utils/dataSourceUtils";
  import {materialListStore,
          lotStatus,
          lotType} from "../../utils/syscodedata";
  import History from "../_common/LOTHISTORY";

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxSearchPanel,
      DxLookup,
      DxMasterDetail,
      DxColumnChooser,
      DxGrouping,
      DxSummary,
      DxGroupItem,
      History
    },
    data() {
      return {
        dataSource: dsu.getComDSGrid('/api/mes/MVLM', 'lotNumber'),
        gridRefName: 'dataGrid',
        materialListStore: materialListStore,
        operationList: [],
        lotStatus: lotStatus,
        lotType: lotType,
        expanded: true
      };
    },
    mounted(){
      dsu.getAxiosOperationList({eqpOper : 'N'}).then(res => {this.operationList = res.data.data});
    },
    methods:{
      onExporting(e) {
        e.fileName = this.$route.meta.transName;
      },
      onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
          location: 'before',
          widget: 'dxSelectBox',
          options: {
            items: [{
              value: 'operation',
              text: '그룹 : 공정'
            },
            {
              value: 'code',
              text: '그룹 : 자재 코드'
            },
            {
              value: 'category',
              text: '그룹 : 품목'
            },
            {
              value: 'importTimeStr',
              text: '그룹 : 입고 일'
            }],
            displayExpr: 'text',
            valueExpr: 'value',
            value: 'operation',
            onValueChanged: this.groupChanged.bind(this)
          }
        });
      },
      groupChanged(e) {
        this.$refs[this.gridRefName].instance.clearGrouping();
        this.$refs[this.gridRefName].instance.columnOption(e.value, 'groupIndex', 0);
      }
    }
  };
</script>

<style scoped>
  #gridContainer {
      height: calc( 100vh - 50px );
      width: 100%;}

  #detail {
      width: 100%;}

  .status-icon-cell {
    height: 25px;
    display: inline-block;
    margin-right: 8px;
  }

  .middle {
    vertical-align: middle;
  }      
</style>