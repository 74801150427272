<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <TransactionBatchOrLot :ref="transactionRefName"
                   @setLotList="setLotList"
                   @execute="execute"/>

      <DxForm :ref="formRefNameShipTo"
              id='formShipTo'>
        <DxGroupItem :col-count="2">
          <DxSimpleItem name="constructionSiteCode">
            <DxLabel text="건설 현장" />
            <template #default>
              <DxSelectBox :search-enabled="true"
                            placeholder=""
                            value-expr="code"
                            display-expr="nameCode"
                            width="350"
                            :data-source="constructionSiteList"
                            @value-changed="changedConstructionSiteCode"/>
            </template>
          </DxSimpleItem>              

          <DxSimpleItem name="requestSeq">
            <DxLabel text="청구 번호" />
            <template #default>
              <DxSelectBox :search-enabled="true"
                            :ref="selectBoxRefName"
                            placeholder=""
                            value-expr="seq"
                            display-expr="seq"
                            width="350"
                            :data-source="requestSeqList"
                            @value-changed="changedRequestSeq"/>
            </template>
          </DxSimpleItem>           

        </DxGroupItem>
      </DxForm>

      <DxDataGrid
        id="grid"
        :ref="gridRefName"
        :columnAutoWidth="true"
        :show-borders="true"
        :dataSource="targetLotList"
        :show-column-lines="true">

        <DxScrolling mode="infinite"/>
        <DxSorting mode="none"/>

        <DxColumn
          data-field="name"
          caption="자재 명">
        </DxColumn>

        <DxColumn
          data-field="quantityUnit"
          caption="수량"
          alignment="right">  
        </DxColumn>

        <DxColumn
          data-field="lotNumber"
          caption="Lot No.">
        </DxColumn>
      </DxDataGrid>
    </DxScrollView>
  </div>
</template>
<script>
  import {DxScrollView} from "devextreme-vue/scroll-view";
  import TransactionBatchOrLot from "../_common/TRANSACTION_BATCH_OR_LOT";
  import {DxDataGrid,
          DxScrolling,
          DxColumn,
          DxSorting} from "devextreme-vue/data-grid";
  import {DxForm,
          DxGroupItem,
          DxSimpleItem,
          DxLabel} from "devextreme-vue/form";
  import {DxSelectBox} from "devextreme-vue/select-box";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";

  export default {
      components: {
        DxScrollView,
        TransactionBatchOrLot,
        DxDataGrid,
        DxScrolling,
        DxColumn,
        DxSorting,
        DxForm,
        DxGroupItem,
        DxSimpleItem,
        DxLabel,
        DxSelectBox
      },
      data: function() {
        return {
          transactionRefName: 'transaction',
          formRefNameShipTo: 'formShipTo',
          gridRefName: 'grid',
          targetLotList: [],
          constructionSiteList: [],
          constructionSiteCode: '',
          requestSeqList: '',
          requestSeq: ''
        };
      },
      mounted(){
        dsu.getAxiosConstructionSiteList({complete: 'N'}).then(res => {this.constructionSiteList = res.data.data});
      },
      methods: {
        setLotList(lotList){
     
          for (let i = 0; i < lotList.length; i++) {
            for (let j = 0; j < this.targetLotList.length; j++) {
              if(lotList[i].batchId){
                if (lotList[i].batchId == this.targetLotList[j].batchId){
                  fn.notifyError('이미 추가된 Batch');
                  return;         
                }
              } 
              
              if (lotList[i].lotNumber == this.targetLotList[j].lotNumber){
                fn.notifyError('이미 추가된 Lot');
                return;         
              }
            }              
          }

          for (let i = 0; i < lotList.length; i++) {
            this.targetLotList.push(lotList[i]);
          }
        },
        async execute(transInfo){
          var params = {};

          if(!this.constructionSiteCode){
            fn.notifyError('건설현장 선택 후 진행 필요');
            return;
          }

          if(!this.requestSeq){
            fn.notifyError('청구 번호 선택 후 진행 필요');
            return;
          }
    
          if(!this.targetLotList ){
            fn.notifyError('출고할 Lot 선택 후 진행 필요');
            return; 
          }

          params.shipTo = this.constructionSiteCode;
          params.requestMaterialSeq = this.requestSeq;
          params.lotList = JSON.stringify(this.targetLotList); 
          params.mTransTime = transInfo.mTransTime;
          
          await dsu.getAxiosOBJPost('/api/mes/MMOS/ship', params).then(res=>{
              let response = res.data;
              if(response.result == 'SUCCESS'){
                fn.notifySuccess(response.msg);
                this.clear();
              }else{
                // 아래 문구 바꿀 때 조심 MMOS.VUE MMOS Service 에 해당 인터락 걸리면 랏 다시 조회하는 메시지가 있음 함께 수정 필요
                if (response.msg.indexOf('이미 처리된 Lot, 다시 조회필요 ') != -1) {
                  let lotNumber = response.msg.split('이미 처리된 Lot, 다시 조회필요 ')[1];
                  
                  fn.alert(lotNumber + '은 출고 대상 리스트에 추가된 후 다른 화면에서 출고 처리되었거나, 진행 시간이 변경되어 다시 조회하여 리스트에 추가를 시도합니다. 리스트 추가된 후 다시 출고 실행을 부탁드립니다.', '출고 불가').then(() => {
                    for (let i = 0; i < this.targetLotList.length; i++) {
                      if (this.targetLotList[i].lotNumber == lotNumber) {
                        this.targetLotList.splice(i, 1);
                        this.$refs[this.transactionRefName].getLotList(lotNumber);
                        break;
                      }
                    }

                  });
                 
                } else {
                   fn.alert(response.msg, '출고 불가');
                }
              }
          });
        },
        clear(){
          this.requestSeq = '';
          this.targetLotList = [];
        },
        changedConstructionSiteCode(e){
          this.constructionSiteCode = e.value;
          this.requestSeqList = [];
          this.requestSeq = '';
          
          var params = {constructionSiteCode: this.constructionSiteCode,
                        useFlag: 'N'};

          dsu.getAxiosOBJGet('/api/erp/EEHM/selectRequestMaterialNoList', params).then(res=>{
            let response = res.data;
            if(response.result == 'SUCCESS'){
              this.requestSeqList = response.data;
              this.requestSeqList.unshift({seq: '0'});
            }else{
              fn.notifyError(response.msg);
            }
          });
        },
        changedRequestSeq(e){
          this.requestSeq = e.value;
        }
      }
    };
</script>

<style scoped>
  #Container{
    height: calc( 100vh - 50px );
    width: 100%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background-color:white;
  }
  #grid{
    height: calc( 100vh - 260px );
  }
  .dx-field-label{
    width: 75px;
    font-size: 14px;
    font-weight: 700;
    color: black;
  }
</style>