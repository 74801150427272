<template>
  <div id="Container">
    <div class="dx-field">
      <div class="dx-field-label">건설 현장</div>
      <div class="dx-field-value">
        <DxSelectBox
          :search-enabled="true"
          placeholder=""
          value-expr="code"
          display-expr="nameCode"
          width="350"
          :data-source="constructionSiteList"
          @value-changed="changedConstructionSiteCode"/>
      </div>
    </div>

    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :key-expr="lotNumber"
      :show-borders="true"
      :show-column-lines="true"
      :columnAutoWidth="true"
      :data-source="gridData"
      :selection="{ mode: 'multiple' }"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)">
      
      <DxHeaderFilter :visible="true"/>
      <DxSorting mode="multiple"/>
      <DxExport :enabled="true"/>
      <DxEditing :allow-updating="true" mode="cell"/>
      <DxScrolling mode="virtual"/>

      <DxColumn
        data-field="lotNumber"
        caption="Lot No."
        :header-filter="{ allowSearch: true }"
        :allowEditing="false">
      </DxColumn>

      <DxColumn
          data-field="code"
          caption="자재 코드"
          :header-filter="{ allowSearch: true }"
          :allowEditing="false">
          <DxLookup
            :data-source="materialList"
            value-expr="code"
            display-expr="codeName"
          />
        </DxColumn>    
        
        <DxColumn
          data-field="quantity"
          caption="수량"
          :allowEditing="false">
        </DxColumn>

        <DxColumn
          data-field="unit"
          caption="단위"
          :allowEditing="false">
        </DxColumn>
      
        <DxColumn
          data-field="category"
          caption="품목"
          :header-filter="{ allowSearch: true }"
          :allowEditing="false">
        </DxColumn>
        
        <DxColumn
          data-field="useDate"
          caption="자재 사용일"
          data-type="date"
          format="yyyy/MM/dd"
          :editor-options="{ showClearButton: true}">
        </DxColumn>
      </DxDataGrid>

      <DxPopup :ref="popupInputDataRefName"
               :drag-enabled="false"
               :close-on-outside-click="true"
               :show-title="true"
               :width="300"
               :height="170"
               title="자재 사용일">
               <DxDateBox
                  :value="useDate"
                  type="date"
                  display-format="yyyy/MM/dd"
                  @valueChanged="setValuesSelectedRows"
                  :showClearButton="true"
               />
                <div class='ButtonBox'>
                  <DxButton class='Button'
                      :width="120"
                      text="입력"
                      type="success"
                      styling-mode="contained"
                      @click="inputSelectedRows()"/>
                </div>
       </DxPopup>      
  </div>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxLookup,
          DxEditing,
          DxScrolling} from "devextreme-vue/data-grid";
  import {DxSelectBox} from "devextreme-vue/select-box";   
  import DxDateBox from "devextreme-vue/date-box";
  import DxPopup from "devextreme-vue/popup";     
  import DxButton from "devextreme-vue/button";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";

export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxLookup,
      DxSelectBox,
      DxEditing,
      DxPopup,
      DxDateBox,
      DxButton,
      DxScrolling
    },
    data() {
      return {
        gridRefName: 'dataGrid',
        materialList: [],
        constructionSiteList: [],
        popupInputDataRefName: 'popupInputData',
        constructionSiteCode: '',
        useDate: null,
        gridData: []
      };
    },
    mounted(){
      dsu.getAxiosMaterialList().then(res => {this.materialList = res.data.data});
      dsu.getAxiosConstructionSiteList().then(res => {this.constructionSiteList = res.data.data});
    },
    computed: {
        grid: function() {
            return this.$refs[this.gridRefName].instance;
        }
    },
    methods:{
      onExporting(e) {
        e.fileName = this.$route.meta.transName;
      },
      onToolbarPreparing(e) {
          e.toolbarOptions.items.unshift(
           {location: 'before',
            widget: 'dxButton',
            options: {
              icon: 'save',
              text: '저장',
              stylingMode:"outlined",
              onClick: this.save.bind(this)
            }},
           {location: 'before',
            widget: 'dxButton',
            options: {
              icon: 'check',
              text: '선택 입력',
              stylingMode:"outlined",
              onClick: this.showInputSelectedRows.bind(this)
            }}       
          );
      },
      setGridData(data){
        this.grid.option('dataSource', data);
      },
      changedConstructionSiteCode(e){
        var params = {shipTo: e.value, withTerminateLot: 'Y'};
        this.constructionSiteCode = e.value;
        this.setGridData([]);

        dsu.getAxiosOBJGet('/api/mes/MVLM/select', params).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            this.gridData = response.data;
          }else{
            fn.notifyError(response.msg);
          }
        });
      },
      save(e){
        e.component.option('disabled', true);

        var params = {constructionSiteCode: this.constructionSiteCode}
        var matUseInfo = [];
        
        Object.assign(matUseInfo, this.gridData);
        
        if(!params.constructionSiteCode){
          fn.notifyError('건설현장 선택 후 진행 필요');
          return;
        }

        if(matUseInfo.length == 0){
          fn.notifyError('Lot 별 사용일자 입력 후 진행 필요');
          return;
        }
     
        for (var i = 0; i < matUseInfo.length; i++) {
          matUseInfo[i].useDate = dsu.getFormatDate(matUseInfo[i].useDate);
        }

        params.matUseInfo = JSON.stringify(matUseInfo);

        dsu.getAxiosOBJPost('/api/erp/EUMC/updateConstSiteMatUseInfo', params).then(res=>{
            let response = res.data;
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);
            }else{
              fn.notifyError(response.msg);
            }
            e.component.option('disabled', false);
        });
      },
      showInputSelectedRows(){
        this.useDate = null;
        this.$refs[this.popupInputDataRefName].instance.show();
      },
      setValuesSelectedRows(e){
        this.useDate = e.value;
      },
      inputSelectedRows(){
        var selectedRowsData = this.grid.getSelectedRowsData();
      
        for (var i = 0; i < selectedRowsData.length; i++) {
          for (var j = 0; j < this.gridData.length; j++) {
            if(selectedRowsData[i].lotNumber == this.gridData[j].lotNumber){
              this.gridData[j].useDate = this.useDate;
            }
          }
        }
        
        this.setGridData(this.gridData);
        this.$refs[this.popupInputDataRefName].instance.hide();
      }
    }
  };
</script>

<style scoped>
  #Container {
    height: calc( 100vh - 40px );
    width: 100%;
    float: left;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background-color:white;}
  
  #grid {
    height: calc( 100vh - 140px );
  }
  .dx-field-label{
    width: 75px;
    font-size: 14px;
    font-weight: 700;
    color: black;
  }
  .ButtonBox{
    text-align: center;
  }

  .Button{
    margin-top: 10px;
  }
  
</style>