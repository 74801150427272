<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <Transaction :ref="transactionRefName" 
                   :hideCode="true"
                   :hideQuantity="true"
                   :hideOperation="true"
                   :hideExecute="true"
                   :withTerminateLot="true"
                   @setLotInfoExt="setLotInfoExt"
                   @clearLotInfoExt="clearLotInfoExt"/>
     
      <DxDataGrid
        :ref="gridRefName"
        :data-source="historyStore"
        :show-borders="true"
        :show-column-lines="true"
        :columnAutoWidth="true"
        :selection="{ mode: 'single' }"
        @exporting="onExporting"
        @selectionChanged="selectedRow">

        <DxSorting mode="none"/>
      
        <DxColumn data-field="lotNumber" caption="Lot No."/>
        <DxColumn data-field="transTimeStr" caption="시간"/>
        <DxColumn data-field="inspType" caption="검사 구분">
          <DxLookup
            :data-source="inspType"
            value-expr="ID"
            display-expr="Name"/>
        </DxColumn>
        <DxColumn data-field="operationStr" caption="공정"/>
        <DxColumn data-field="codeName" caption="자재 코드"/>
        <DxColumn data-field="quantity" caption="수량"/>
        <DxColumn data-field="unit" caption="단위"/>
        <DxColumn data-field="userId" caption="검사자"/>
      </DxDataGrid>   
      <DxToolbar>
        <DxItem
          :options="btnOptDownloadCOA"
          location="after"
          widget="dxButton"
        />
        <!-- <DxItem
          :options="btnOptPrintCOA"
          location="after"
          widget="dxButton"
        /> -->
      </DxToolbar>
      <pdf id="coa" :src="pdfdata" :ref="pdfRefName"></pdf>
    </DxScrollView>
  </div>
</template>

<script>
  import {DxScrollView} from "devextreme-vue/scroll-view";
  import Transaction from "../_common/TRANSACTION";
  import {DxDataGrid,
          DxSorting,
          DxColumn,
          DxLookup} from "devextreme-vue/data-grid";
  import DxToolbar, {DxItem} from "devextreme-vue/toolbar";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  import {inspType} from "../../utils/syscodedata";
  import jsPDF from "jspdf"; 
  import "jspdf-autotable";
  import pdf from "vue-pdf"

  var doc;
      
  export default {
      components: {
        DxScrollView,
        Transaction,
        DxDataGrid,
        DxSorting,
        DxColumn,
        DxLookup,
        pdf,
        DxToolbar,
        DxItem
      },
      data: function() {
        return {
          transactionRefName: 'transaction',
          gridRefName: 'grid',
          inspType: inspType,
          pdfRefName: 'pdfCOA',
          pdfdata: undefined,
          pdfTitle: '',
          btnOptDownloadCOA: {
            icon: 'download',
            onClick: this.downloadCOA.bind(this)
          },
          btnOptPrintCOA: {
            icon: 'print',
            onClick: this.printCOA.bind(this)
          }          
        };
      },
      methods: {
        setLotInfoExt(lotInfo){
          dsu.getAxiosOBJGet('/api/mes/QCOA/selectInspectionResultList', lotInfo).then(res =>{
                let data = res.data.data;
                if(res.data.result == 'SUCCESS'){
                    if(data.length == 0){
                      fn.notifyError('검사이력 없음');
                      this.$refs[this.transactionRefName].clear();
                      return;
                    }else{
                      this.setGridData(data);
                    }
                }else{
                  fn.notifyError(res.data.msg);
                }
              });
        },
        clearLotInfoExt()
        {
          this.clearPDFViewer();
          this.setGridData([]);
        },
        clearPDFViewer(){
          doc = new jsPDF('p', 'pt', 'a4');
          this.pdfdata = undefined;
          this.pdfTitle = '';
        },
        setGridData(data){
          const grid = this.$refs[this.gridRefName].instance;
          grid.option('dataSource', data);
        },
        selectedRow(event){
          
      
          this.clearPDFViewer();

          let lotInfo = event.selectedRowsData[0];

          if(!lotInfo){
            return;
          }

          const inspTypeStr = inspType.filter(function(e){
                            return e['ID'] === lotInfo.inspType;
                })[0].Name;
          
          let inspDocNo = '';

          if(lotInfo.inspType == 'IQC'){
              inspDocNo = 'IK-QP-801-02';
          }else if(lotInfo.inspType == 'SELF_INSP'){
              inspDocNo = 'IK-QP-707-30';
          }else if(lotInfo.inspType == 'OGI'){
              inspDocNo = 'IK-QP-707-22';
          }
          
          var params = {}
          params.lotNumber = lotInfo.lotNumber;
          params.transTime = lotInfo.transTime;
          
          dsu.getAxiosOBJGet('/api/mes/MISP/selectInspectionResult', params).then(res =>{
              let data = res.data.data;
              if(res.data.result == 'SUCCESS'){
                  if(data.length == 0){
                    fn.notifyError('성적서 데이터 없음');
                    this.$refs[this.transactionRefName].clear();
                    return;
                  }else{
                    const margin1st = 30;
                    const margin2nd = 40;
                  
                    const title = inspTypeStr + ' 성적서';
                    this.pdfTitle = title + '_' + lotInfo.codeName + '_' + lotInfo.lotNumber + '.pdf';
                    
                    var finalResult = '합격';
                    if(data.filter(function(e){ return e['judgement'] === 'FAIL';})[0]){
                      finalResult = '불합격';
                    }
                    var abnormalReport = data[0].holdNote;

                    doc.addFont(require('@/assets/malgun.ttf'), 'malgun', 'normal');
                    doc.setFont('malgun');
                    
                    // doc.setLineWidth(2);
                    // doc.line(30, 30, 560, 30);
                    
                    doc.autoTable({
                      theme: 'plain',
                      styles: {halign: 'center', fontSize: 20, font: "malgun"} ,
                      body: [{ content: title}]
                    });

                    doc.text('Lot 정보', 50, doc.lastAutoTable.finalY + margin1st);
                    doc.autoTable({
                      theme: 'grid',
                      styles: {font: "malgun"},
                      startY: doc.lastAutoTable.finalY + margin2nd, 
                      head: [['항목', '값']],
                      body: [
                        ['입고 Group ID', lotInfo.importGroupId],
                        ['Lot No.', lotInfo.lotNumber],
                        ['공급업체 Lot', lotInfo.mainLot],
                        ['자재 코드', lotInfo.codeName],
                        ['품목', lotInfo.category],
                        ['수량', lotInfo.quantity + ' ' + lotInfo.unit],
                        ['검사자', lotInfo.userId],
                        ['검사 시간', lotInfo.transTimeStr],
                        ['검사 공정', lotInfo.operationStr]
                      ],
                    })
              
                    doc.text('항목별 검사결과', 50, doc.lastAutoTable.finalY + margin1st);
                    
                    doc.autoTable({
                      theme: 'grid',
                      styles: {font: "malgun"},
                      startY: doc.lastAutoTable.finalY + margin2nd, 
                      columns: [
                        { header: '항목', dataKey: 'item' },
                        { header: 'LSL', dataKey: 'lsl' },
                        { header: 'USL', dataKey: 'usl' },
                        { header: '결과', dataKey: 'result' },
                        { header: '단위', dataKey: 'unit' },
                        { header: '판정', dataKey: 'judgement' }
                      ],
                      body: data,
                    });

                    doc.text('항목별 부적합 Report', 50, doc.lastAutoTable.finalY + margin1st);
                      
                    doc.autoTable({
                      theme: 'plain',
                      styles: {halign: 'left', left: 20, font: "malgun"},
                    
                      startY: doc.lastAutoTable.finalY + margin2nd, 
                      body: [{ content: abnormalReport}]
                    });
                            
                    doc.autoTable({
                      theme: 'plain',
                      styles: {halign: 'center', fontSize: 25, font: "malgun"},
                      startY: doc.lastAutoTable.finalY + margin2nd, 
                      body: [{ content: '최종 결과  : ' + finalResult}]
                    });

                    doc.text('(주)일강케이스판', 30, 800);
                    doc.text('표준문서 : ' +  inspDocNo, 385, 800);
                                          
                    this.pdfdata =  doc.output('bloburl', {filename: this.pdfTitle});
                  }

              }else{
                fn.notifyError(res.data.msg);
                this.clearLotInfoExt();

              }
            });

        },
        downloadCOA(){
          if(this.pdfdata){
            doc.save(this.pdfTitle);
          }
        },
        printCOA(){
          if(this.pdfdata){
    
            this.$refs.pdfCOA.print();
    
          }
        }
      }
    };
</script>

<style scoped>
  #Container{
    height: calc( 100vh - 50px );
    width: 100%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    background-color:white;
  }
  .coa{
    margin-top: 10px;
    width: 100%;
    max-width: 800px;
    height: 100vh;
  }
 
</style>