<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <Transaction :ref="transactionRefName" 
                   @clearLotInfoExt="clearLotInfoExt"
                   @execute="execute"/>
    
      <DxForm :form-data="formData">
        <DxGroupItem caption="선택 정보">
          <DxSimpleItem name="splitQty">
            <DxLabel text="분할 수량" />
            <template #default>
              <DxNumberBox 
                :value="formData.splitQty"
                @value-changed="changedSplitQty"
                min="1"/>
            </template>
          </DxSimpleItem>        
        </DxGroupItem>
      </DxForm>
    </DxScrollView>
  </div>
</template>

<script>
  import {DxScrollView} from "devextreme-vue/scroll-view";
  import {DxForm,
          DxGroupItem,
          DxSimpleItem,
          DxLabel} from "devextreme-vue/form";
  import {DxNumberBox} from "devextreme-vue/number-box";
  import Transaction from "../_common/TRANSACTION";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function"
 
  export default {
    components: {
      DxScrollView,
      Transaction,
      DxForm,
      DxGroupItem,
      DxSimpleItem,
      DxLabel,
      DxNumberBox
    },
    data: function() {
      return {
        transactionRefName: 'transaction',
        formData: {splitQty: ''}
      };
    },
    methods: {
      clearLotInfoExt(){
        Object.keys(this.formData).map((key) => {
            this.formData[key] ='';
        });
      },
      changedSplitQty(e){
        this.formData.splitQty = e.value;
      }, 
      async execute(transInfo, e){
        transInfo.splitQty = this.formData.splitQty;
        transInfo.quantityNew = this.formData.quantityNew;

        if(!this.formData.splitQty){
          fn.notifyError("분할 수량 입력 후 진행 필요");
          return;
        }
        
        if(this.formData.splitQty >= transInfo.quantity){
          fn.notifyError("분할 수량 초과");
          return;
        }
        
        await dsu.getAxiosOBJPost(`/api/mes/MSPL/split`, transInfo).then(res=>{
          let response = res.data;
          e.component.option('disabled', false);
          if(response.result == 'SUCCESS'){
            fn.alert(response.msg, '자재분할 완료');
                    
            fn.printLotLabel([transInfo, {lotNumber: response.data}]);
            
            this.$refs[this.transactionRefName].clear();
          }else{
            fn.notifyError(response.msg);
          }
        });
      }
    }
  };
</script>

<style scoped>
  #Container{
    height: calc( 100vh - 50px );
    width: 100%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background-color:white;
  }
</style>