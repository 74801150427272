<template>
  <DxDataGrid
    :ref="gridRefName"
    :data-source="dataSource"
    :focused-row-enabled="true"
    :columnAutoWidth="true"
    :show-borders="true"
    :show-column-lines="true"
    width="100%"
    height="100%"
    @exporting="onExporting"
    @rowDblClick="onDblClick">
  
    <DxSearchPanel :visible="true"/>
    <DxHeaderFilter :visible="true"/>
    <DxScrolling mode="infinite"/>
    <DxSorting mode="multiple"/>
    <DxExport v-if="showExport" :enabled="true"/>
 
    <DxEditing v-if="showEditing"
      :allow-adding="true"
      :allow-updating="true"
      :allow-deleting="true"
      mode="popup"/>
    
    <DxColumn v-if="showEditing" 
      type="buttons">
      <DxButton name="edit"/>
    </DxColumn>

    <DxColumn
      data-field="code"
      caption="제품 코드"
      :header-filter="{ allowSearch: true }">
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^[A-Z0-9-]{1,20}$"
        message="영문 대문자 숫자 - 로만 구성, 20자 까지 등록 가능"/>
    </DxColumn>
    
    <DxColumn
      data-field="name"
      caption="제품 명"
      :header-filter="{ allowSearch: true }">
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^.{1,30}$"
        message="30자 까지 등록 가능"/>
    </DxColumn>

    <DxColumn
      data-field="grade"
      caption="급(관급/도급/사급)">  
      <DxRequiredRule/>
      <DxLookup :data-source="productGrade"/>
    </DxColumn>

    <DxColumn
      data-field="fireproof"
      caption="내화">  
      <DxRequiredRule/>
      <DxLookup :data-source="listYN"/>
    </DxColumn>
    
    <DxColumn
      data-field="spec"
      caption="사양"
      :header-filter="{ allowSearch: true }">
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^.{1,30}$"
        message="30자 까지 등록 가능"/>
    </DxColumn>

    <DxColumn
      data-field="insulation"
      caption="단열재 여부">  
      <DxRequiredRule/>
      <DxLookup :data-source="listYN"/>
    </DxColumn>

    <DxColumn
      data-field="insulationCategory"
      caption="단열 종류">  
      <DxLookup :data-source="insulationCategory"
        :allow-clearing="true"/>
    </DxColumn>

    <DxColumn
      data-field="defaultInsulationThickness"
      caption="기본 단열 두께"
      :header-filter="{ allowSearch: true }">
      <DxPatternRule
        pattern="^.{1,30}$"
        message="30자 까지 등록 가능"/>
    </DxColumn>

    <DxColumn v-if="showCheckListId"
      data-field="checkListId"
      caption="질문 그룹">  
      <DxRequiredRule/>
      <DxLookup
        :data-source="checkListList"
        value-expr="listId"
        display-expr="listName"/>
    </DxColumn>  

    <DxColumn v-if="showOptionGroupId"
      data-field="optionGroupId"
      caption="옵션 그룹">  
      <DxLookup
        :data-source="optionGroupList"
        value-expr="groupId"
        display-expr="groupName"
        :allow-clearing="true"/>
    </DxColumn>          
     
    <DxMasterDetail v-if="showHistory"
      :enabled="true"
      template="master-detail"/>
      <template #master-detail="{ data }">
        <History :master-detail-data="data"
                :url= "`/api/erp/EPMG/selectHistory`"
                :keyName="code"
                :columns="historyColumns"/>
      </template>
  </DxDataGrid>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxLookup,
          DxRequiredRule,
          DxPatternRule,
          DxMasterDetail} from "devextreme-vue/data-grid";
  import dsu from "../../utils/dataSourceUtils";
  import {listYN,
          productGrade,
          insulationCategory} from "../../utils/syscodedata";
  import History from "../_common/SIMPLE_HISTORY";
  
  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxLookup,
      DxRequiredRule,
      DxPatternRule,
      DxMasterDetail,
      History
    },
    props: {
      showEditing: Boolean,
      showCheckListId: Boolean,
      showOptionGroupId: Boolean,
      showExport: Boolean,
      showHistory: Boolean
    },
    data() {
      return {
        dataSource: dsu.getComDSGrid('/api/erp/EPMG', 'code', '', '제품 코드'),
        gridRefName: 'grid',
        listYN: listYN,
        productGrade: productGrade,
        insulationCategory: insulationCategory,
        checkListList: [],
        optionGroupList: [],
        historyColumns: [{dataField : "transTime", caption : "변경 시간"},
                         {dataField : "name", caption : "자재 명"},
                         {dataField : "grade", caption : "급(관급/도급/사급)"},
                         {dataField : "fireproof", caption : "내화"},
                         {dataField : "spec", caption : "사양"},
                         {dataField : "insulation", caption : "단열재 여부"},
                         {dataField : "insulationCategory", caption : "단열 종류"},
                         {dataField : "defaultInsulationThickness", caption : "기본 단열 두께"},
                         {dataField : "checkListId", caption : "질문 그룹"},
                         {dataField : "optionGroupId", caption : "옵션 그룹"},
                         {dataField : "userId", caption : "사용자"},
                         {dataField : "dml", caption : "Action"}]
      };
    },
    computed: {
      grid: function () { return this.$refs[this.gridRefName].instance; }
    },    
    async mounted(){
      await dsu.getAxiosCheckListList().then(res => {this.checkListList = res.data.data});
      await dsu.getAxiosOptionGroupList().then(res => {this.optionGroupList = res.data.data});
    },
    methods:{
      onExporting(e) {
        e.fileName = this.$route.meta.transName;
      },
      onDblClick(e){
        this.$emit("rowDblClick", e.data);
      },
      clearFilter(){
        this.grid.clearFilter();
      }
    }
  };
</script>