<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <Transaction :ref="transactionRefName" 
                   @setLotInfoExt="setLotInfoExt"
                   @clearLotInfoExt="clearLotInfoExt"
                   @execute="execute"/>
      <DxForm>
        <DxGroupItem caption="선택 정보">

          <DxSimpleItem name="reserveShipTo">
            <DxLabel text="출고 예정 건설 현장" />
            <template #default>
              <DxSelectBox
                placeholder=""
                value-expr="code"
                display-expr="nameCode"
                :data-source="constructionSiteList"
                :value="formData.reserveShipTo"
                @value-changed="changedReserveShipTo"
                :show-clear-button="true"/>
            </template>
          </DxSimpleItem>   

          <DxSimpleItem name="memo">
            <DxLabel text="Memo" />
            <template #default>
              <DxTextArea
                :value="formData.memo"
                :height="300"
                :max-length="300"
                @value-changed="changedMemo"/>
            </template>
          </DxSimpleItem>
        </DxGroupItem>
      </DxForm>
    </DxScrollView>
  </div>
</template>

<script>
  import {DxScrollView} from 'devextreme-vue/scroll-view';
  import Transaction from '../_common/TRANSACTION';
  import {DxForm,
          DxGroupItem,
          DxSimpleItem,
          DxLabel} from 'devextreme-vue/form';
  import {DxSelectBox} from 'devextreme-vue/select-box';
  import {DxTextArea} from 'devextreme-vue/text-area';
  import dsu from '../../utils/dataSourceUtils';
  import fn from '../../utils/function'

  export default {
    components: {
      DxScrollView,
      Transaction,
      DxForm,
      DxGroupItem,
      DxSimpleItem,
      DxLabel,
      DxSelectBox,
      DxTextArea
    },
    data: function() {
      return {
        transactionRefName: 'transaction',
        constructionSiteList: [],
        formData: {reserveShipTo: '', memo: ''}
      };
    },
    mounted(){
      dsu.getAxiosConstructionSiteList({complete: 'N'}).then(res => {
        this.constructionSiteList = res.data.data;
        this.constructionSiteList.unshift({code: '', nameCode: ''});
      });
    },    
    methods: {
      setLotInfoExt(lotInfo){
        this.formData.reserveShipTo = lotInfo.reserveShipTo;
        this.formData.memo = lotInfo.memo;
      },
      clearLotInfoExt(){
        Object.keys(this.formData).map((key) => {
            this.formData[key] ='';
        });
      },
      changedReserveShipTo(e){
        this.formData.reserveShipTo = e.value;
      },      
      changedMemo(e){
        this.formData.memo = e.value;
      },
      async execute(transInfo){
        transInfo.reserveShipTo = this.formData.reserveShipTo;
        transInfo.memo = this.formData.memo;

        await dsu.getAxiosOBJPost(`/api/mes/MCLS/changeLotStatus`,transInfo).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            fn.notifySuccess(response.msg);
            this.$refs[this.transactionRefName].clear();
          }else{
            fn.notifyError(response.msg);
          }
        });
      }
    }
  };
</script>

<style scoped>
  #Container{
    height: calc( 100vh - 50px );
    width: 100%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background-color:white;
  }
</style>