<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <TransactionMerge :ref="transactionRefName" 
                        @setLotInfoExt="setLotInfoExt"
                        @clearLotInfoExt="clearLotInfoExt"
                        @execute="execute"/>
    
      <DxForm :ref="formRefName" 
              :form-data="formData">
        <DxGroupItem caption="병합 Lot 정보(흡수되는 Lot)">
          <DxSimpleItem
            data-field='lotNumber'
            editor-type='dxTextBox'
            :editor-options="{ readOnly: 'true'}">
            <DxLabel text="Lot No." />
          </DxSimpleItem>  
          <DxSimpleItem name="quantity">
            <DxLabel text="수량"/>
            <template #default>
              <DxTextBox
                :read-only="true"
                :value="formData.quantity + ' ' + formData.unit"/>
            </template>
          </DxSimpleItem>            
        </DxGroupItem>
      </DxForm>
    </DxScrollView>
  </div>
</template>

<script>
  import {DxScrollView} from "devextreme-vue/scroll-view";
  import {DxForm,
          DxGroupItem,
          DxSimpleItem,
          DxLabel} from "devextreme-vue/form";
  import {DxTextBox} from "devextreme-vue/text-box";
  import TransactionMerge from "../_common/TRANSACTION_MERGE";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function"
 
  export default {
    components: {
      DxScrollView,
      TransactionMerge,
      DxForm,
      DxGroupItem,
      DxSimpleItem,
      DxLabel,
      DxTextBox
    },
    data: function() {
      return {
        transactionRefName: 'transaction',
        formRefName: 'form',
        formData: {searchLot: '', quantity: '', unit: ''}
      };
    },
    methods: {
      setLotInfoExt(lotInfo){
        this.formData = lotInfo;
      },
      clearLotInfoExt(){
        this.$refs[this.formRefName].instance.resetValues();
        Object.keys(this.formData).map((key) => {
            this.formData[key] ='';
        });
      },
      async execute(targetLotInfo){
        let params = { targetLot: JSON.stringify(targetLotInfo), sourceLot: JSON.stringify(this.formData) }
        params.mTransTime = targetLotInfo.mTransTime;

        await dsu.getAxiosOBJPost('/api/mes/MMRG/merge', params).then(res=>{
          let response = res.data;

          if(response.result == 'SUCCESS'){
            fn.notifySuccess('자재 병합 완료');
            fn.printLotLabel(targetLotInfo);
            this.$refs[this.transactionRefName].clear();
          }else{
            fn.notifyError(response.msg);
          }
        });
      }
    }
  };
</script>

<style scoped>
  #Container{
    height: calc( 100vh - 50px );
    width: 100%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background-color:white;
  }
</style>