import Vue from "vue";
import Router from "vue-router";
import auth from "./auth";
import Home from "./views/home";
import Logout from "./views/logout";
import ChangePassword from "./views/change-password"
import Error_404 from "./views/_error/404";

//erp 
import EMMG from "./views/ERP/EMMG";
import EMMC from "./views/ERP/EMMC";
import EMOG from "./views/ERP/EMOG";
import EMEQ from "./views/ERP/EMEQ";
import EMSI from "./views/ERP/EMSI";
import EMMF from "./views/ERP/EMMF";
import EMMM from "./views/ERP/EMMM";
import EMHJ from "./views/ERP/EMHJ";
import EUSR from "./views/ERP/EUSR";
import EISP from "./views/ERP/EISP";
import EISM from "./views/ERP/EISM";
import EEMI from "./views/ERP/EEMI";
import EEHM from "./views/ERP/EEHM";
import EUMC from "./views/ERP/EUMC";
import ECSI from "./views/ERP/ECSI";
import EEPS from "./views/ERP/EEPS";
import EPSI from "./views/ERP/EPSI";
import ESCI from "./views/ERP/ESCI";
import PREG from "./views/ERP/PREG";
import PASM from "./views/ERP/PASM";
import PCCT from "./views/ERP/PCCT";
import PREP from "./views/ERP/PREP";
import PDRQ from "./views/ERP/PDRQ";
import PDDS from "./views/ERP/PDDS";
import PDCF from "./views/ERP/PDCF";
import PRJC from "./views/ERP/PRJC";
import ERUP from "./views/ERP/ERUP";
import ERUY from "./views/ERP/ERUY";
import ERCI from "./views/ERP/ERCI";
import ERCL from "./views/ERP/ERCL";
import ERPO from "./views/ERP/ERPO";
import EROG from "./views/ERP/EROG";
import EPMG from "./views/ERP/EPMG";

//mes
import MMIS from "./views/MES/MMIS";
import MPOU from "./views/MES/MPOU";
import MCQT from "./views/MES/MCQT";
import MSPL from "./views/MES/MSPL";
import MMRG from "./views/MES/MMRG";
import MCMT from "./views/MES/MCMT";
import MCBT from "./views/MES/MCBT";
import MMOS from "./views/MES/MMOS";
import MPLB from "./views/MES/MPLB";
import MVLM from "./views/MES/MVLM";
import MVLH from "./views/MES/MVLH";
import MVCH from "./views/MES/MVCH";
import MISP from "./views/MES/MISP";
import MISC from "./views/MES/MISC";
import QCOC from "./views/MES/QCOC";
import MPHD from "./views/MES/MPHD";
import MRHD from "./views/MES/MRHD";
import MCLS from "./views/MES/MCLS";
import MVIO from "./views/MES/MVIO";
import MIIP from "./views/MES/MIIP";
import MIIR from "./views/MES/MIIR";
import MVRM from "./views/MES/MVRM";
import MVEM from "./views/MES/MVEM";
import MVEH from "./views/MES/MVEH";
import EPOU from "./views/MES/EPOU";
import ESBM from "./views/MES/ESBM";
import EEBM from "./views/MES/EEBM";
import EEPM from "./views/MES/EEPM";
import ETCS from "./views/MES/ETCS";
import MVUL from "./views/MES/MVUL";
import MVIR from "./views/MES/MVIR";
import MVUP from "./views/MES/MVUP";
import MVPS from "./views/MES/MVPS";
import MPLS from "./views/MES/MPLS";
import QCON from "./views/MES/QCON";
import QCOA from "./views/MES/QCOA";
import defaultLayout from "./layouts/side-nav-inner-toolbar";
import simpleLayout from "./layouts/single-card";
import emptyLayout from "./layouts/empty";
import errorLayout from "./layouts/error";

import fn from './utils/function'

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    //공통
    {
      path: "/login-form",
      name: "login-form",
      meta: { requiresAuth: false },
      components: {
        layout: simpleLayout,
        content: () =>
          import("./views/login-form")
      }
    },
    {
      path: "/logout",
      name: "logout",
      meta: { requiresAuth: false },
      components: {
        layout: emptyLayout,
        content: Logout
      }
    },
    {
      path: "/",
      redirect: "/mes/home"
    },
    {
      path: "/recovery",
      redirect: "/mes/home"
    },
    {
      path: "*",
      name: "404",
      meta: { requiresAuth: false },
      components: {
        layout: errorLayout,
        content: Error_404
      }
    },
    {
      path: "/change-password",
      name: "change-password",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '비밀번호 변경'
      },
      components: {
        layout: defaultLayout,
        content: ChangePassword
      }
    },
    // {
    //   path: "/erp/home",
    //   name: "home",
    //   meta: { requiresAuth: true,
    //           site: "MES",
    //           transName: 'Home'
    //   },
    //   components: {
    //     layout: defaultLayout,
    //     content: Home
    //   }
    // },      
    {
      path: "/erp/emsi",
      name: "emsi",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '공급업체 정보'
      },
      components: {
        layout: defaultLayout,
        content: EMSI
      }
    },
    {
      path: "/erp/emmc",
      name: "emmc",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '품목 정보'
      },
      components: {
        layout: defaultLayout,
        content: EMMC
      }
    },
    {
      path: "/erp/emmf",
      name: "emmf",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 가공 정보(품목)'
      },
      components: {
        layout: defaultLayout,
        content: EMMF
      }
    },
    {
      path: "/erp/emmm",
      name: "emmm",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 가공 정보(자재)'
      },
      components: {
        layout: defaultLayout,
        content: EMMM
      }
    },
    {
      path: "/erp/emmg",
      name: "emmg",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 정보'
      },
      components: {
        layout: defaultLayout,
        content: EMMG
      }
    },
    {
      path: "/erp/emog",
      name: "emog",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '공정 정보'
      },
      components: {
        layout: defaultLayout,
        content: EMOG
      }
    },
    {
      path: "/erp/emeq",
      name: "emeq",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '장비 정보'
      },
      components: {
        layout: defaultLayout,
        content: EMEQ
      }
    },
    {
      path: "/erp/emhj",
      name: "emhj",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '건설 현장 정보'
      },
      components: {
        layout: defaultLayout,
        content: EMHJ
      }
    },
    {
      path: "/erp/eusr",
      name: "eusr",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '사원 정보'
      },
      components: {
        layout: defaultLayout,
        content: EUSR
      }
    },
    {
      path: "/erp/erci",
      name: "erci",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: 'Check Item 관리'
      },
      components: {
        layout: defaultLayout,
        content: ERCI
      }
    },
    {
      path: "/erp/ercl",
      name: "ercl",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: 'Check List 관리'
      },
      components: {
        layout: defaultLayout,
        content: ERCL
      }
    },
    {
      path: "/erp/erpo",
      name: "erpo",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '제품 Option 관리'
      },
      components: {
        layout: defaultLayout,
        content: ERPO
      }
    },
    {
      path: "/erp/erog",
      name: "erog",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '제품 Option Group 관리'
      },
      components: {
        layout: defaultLayout,
        content: EROG
      }
    },
    {
      path: "/erp/epmg",
      name: "epmg",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '제품 정보'
      },
      components: {
        layout: defaultLayout,
        content: EPMG
      }
    },
    {
      path: "/erp/eisp",
      name: "eisp",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 검사 기준(품목)'
      },
      components: {
        layout: defaultLayout,
        content: EISP
      }
    },
    {
      path: "/erp/eism",
      name: "eism",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 검사 기준(자재)'
      },
      components: {
        layout: defaultLayout,
        content: EISM
      }
    },
    {
      path: "/erp/eemi",
      name: "eemi",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '메일 수신자 정보'
      },
      components: {
        layout: defaultLayout,
        content: EEMI
      }
    },
    {
      path: "/erp/eehm",
      name: "eehm",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 청구'
      },
      components: {
        layout: defaultLayout,
        content: EEHM
      }
    },
    {
      path: "/erp/eumc",
      name: "eumc",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 사용 정보(현장)'
      },
      components: {
        layout: defaultLayout,
        content: EUMC
      }
    },
    {
      path: "/erp/ecsi",
      name: "ecsi",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '제품 검사 기준'
      },
      components: {
        layout: defaultLayout,
        content: ECSI
      }
    },
    {
      path: "/erp/eeps",
      name: "eeps",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '장비 PM 기준'
      },
      components: {
        layout: defaultLayout,
        content: EEPS
      }
    },
    {
      path: "/erp/epsi",
      name: "epsi",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '강판 시공 정보'
      },
      components: {
        layout: defaultLayout,
        content: EPSI
      }
    },
    {
      path: "/erp/esci",
      name: "esci",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '공급업체 품목 성적서'
      },
      components: {
        layout: defaultLayout,
        content: ESCI
      }
    },
    {
      path: "/erp/preg",
      name: "preg",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: 'Project 등록'
      },
      components: {
        layout: defaultLayout,
        content: PREG
      }
    },
    {
      path: "/erp/pcct",
      name: "pcct",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '고객 Contact'
      },
      components: {
        layout: defaultLayout,
        content: PCCT
      }
    },
    {
      path: "/erp/prep",
      name: "prep",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '제품 등록'
      },
      components: {
        layout: defaultLayout,
        content: PREP
      }
    },
    {
      path: "/erp/pdrq",
      name: "pdrq",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '설계 요청'
      },
      components: {
        layout: defaultLayout,
        content: PDRQ
      }
    },
    {
      path: "/erp/pdds",
      name: "pdds",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '설계'
      },
      components: {
        layout: defaultLayout,
        content: PDDS
      }
    },
    {
      path: "/erp/pdcf",
      name: "pdcf",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '설계 납품'
      },
      components: {
        layout: defaultLayout,
        content: PDCF
      }
    },
    {
      path: "/erp/prjc",
      name: "prjc",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '설계 납품 확인'
      },
      components: {
        layout: defaultLayout,
        content: PRJC
      }
    },
    {
      path: "/erp/pasm",
      name: "pasm",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '영업 담당자 지정'
      },
      components: {
        layout: defaultLayout,
        content: PASM
      }
    },
    {
      path: "/erp/erup",
      name: "erup",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 단가 정보(월)'
      },
      components: {
        layout: defaultLayout,
        content: ERUP
      }
    },
    {
      path: "/erp/eruy",
      name: "eruy",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 단가 정보(년)'
      },
      components: {
        layout: defaultLayout,
        content: ERUY
      }
    },
    //MES
    {
      path: "/mes/home",
      name: "home",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: 'Home'
      },
      components: {
        layout: defaultLayout,
        content: Home
      }
    },
    {
      path: "/mes/mmis",
      name: "mmis",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 입고'
      },
      components: {
        layout: defaultLayout,
        content: MMIS
      }
    },
    {
      path: "/mes/mpou",
      name: "mpou",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 이동'
      },
      components: {
        layout: defaultLayout,
        content: MPOU
      }
    },
    {
      path: "/mes/mcqt",
      name: "mcqt",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 수량 보정'
      },
      components: {
        layout: defaultLayout,
        content: MCQT
      }
    },
    {
      path: "/mes/mspl",
      name: "mspl",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 분할'
      },
      components: {
        layout: defaultLayout,
        content: MSPL
      }
    },
    {
      path: "/mes/mmrg",
      name: "mmrg",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 병합'
      },
      components: {
        layout: defaultLayout,
        content: MMRG
      }
    },
    {
      path: "/mes/mcmt",
      name: "mcmt",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 가공'
      },
      components: {
        layout: defaultLayout,
        content: MCMT
      }
    },
    {
      path: "/mes/mcbt",
      name: "mcbt",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 Batch 구성'
      },
      components: {
        layout: defaultLayout,
        content: MCBT
      }
    },
    {
      path: "/mes/mmos",
      name: "mmos",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 출고'
      },
      components: {
        layout: defaultLayout,
        content: MMOS
      }
    },
    {
      path: "/mes/mplb",
      name: "mplb",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '라벨 발행'
      },
      components: {
        layout: defaultLayout,
        content: MPLB
      }
    },
    {
      path: "/mes/misp",
      name: "misp",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 검사'
      },
      components: {
        layout: defaultLayout,
        content: MISP
      }
    },
    {
      path: "/mes/misc",
      name: "misc",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '제품 검사'
      },
      components: {
        layout: defaultLayout,
        content: MISC
      }
    },
    {
      path: "/mes/qcoc",
      name: "qcoc",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '제품 검사 성적서'
      },
      components: {
        layout: defaultLayout,
        content: QCOC
      }
    },
    {
      path: "/mes/mvlm",
      name: "mvlm",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 재고 현황'
      },
      components: {
        layout: defaultLayout,
        content: MVLM
      }
    },
    {
      path: "/mes/mvlh",
      name: "mvlh",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 이력'
      },
      components: {
        layout: defaultLayout,
        content: MVLH
      }
    },
    {
      path: "/mes/mvch",
      name: "mvch",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '건설 현장 자재 사용 이력'
      },
      components: {
        layout: defaultLayout,
        content: MVCH
      }
    },
    {
      path: "/mes/qcoa",
      name: "qcoa",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 검사 성적서(구)'
      },
      components: {
        layout: defaultLayout,
        content: QCOA
      }
    },
    {
      path: "/mes/qcon",
      name: "qcon",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 검사 성적서'
      },
      components: {
        layout: defaultLayout,
        content: QCON
      }
    },
    {
      path: "/mes/mphd",
      name: "mphd",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '부적합 자재 등록'
      },
      components: {
        layout: defaultLayout,
        content: MPHD
      }
    },
    {
      path: "/mes/mrhd",
      name: "mrhd",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '부적합 자재 해제'
      },
      components: {
        layout: defaultLayout,
        content: MRHD
      }
    },
    {
      path: "/mes/mcls",
      name: "mcls",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: 'Lot 속성 변경'
      },
      components: {
        layout: defaultLayout,
        content: MCLS
      }
    },
    {
      path: "/mes/mpls",
      name: "mpls",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '라벨 재 발행(날짜 별)'
      },
      components: {
        layout: defaultLayout,
        content: MPLS
      }
    },
    {
      path: "/mes/mvio",
      name: "mvio",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 입고/출고 이력'
      },
      components: {
        layout: defaultLayout,
        content: MVIO
      }
    },
    {
      path: "/mes/miip",
      name: "miip",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '재물 조사'
      },
      components: {
        layout: defaultLayout,
        content: MIIP
      }
    },
    {
      path: "/mes/miir",
      name: "miir",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '재물 조사 이력'
      },
      components: {
        layout: defaultLayout,
        content: MIIR
      }
    },
    {
      path: "/mes/mvrm",
      name: "mvrm",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 청구서'
      },
      components: {
        layout: defaultLayout,
        content: MVRM
      }
    },
    {
      path: "/mes/mvem",
      name: "mvem",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '장비 현황'
      },
      components: {
        layout: defaultLayout,
        content: MVEM
      }
    },
    {
      path: "/mes/mveh",
      name: "mveh",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '장비 이력'
      },
      components: {
        layout: defaultLayout,
        content: MVEH
      }
    },
    {
      path: "/mes/epou",
      name: "epou",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '장비 이동'
      },
      components: {
        layout: defaultLayout,
        content: EPOU
      }
    },
    {
      path: "/mes/esbm",
      name: "esbm",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: 'BM 등록'
      },
      components: {
        layout: defaultLayout,
        content: ESBM
      }
    },
    {
      path: "/mes/eebm",
      name: "eebm",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: 'BM 완료'
      },
      components: {
        layout: defaultLayout,
        content: EEBM
      }
    },
    {
      path: "/mes/eepm",
      name: "eepm",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: 'PM 결과 입력'
      },
      components: {
        layout: defaultLayout,
        content: EEPM
      }
    },
    {
      path: "/mes/etcs",
      name: "etcs",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '시험 점검'
      },
      components: {
        layout: defaultLayout,
        content: ETCS
      }
    },
    {
      path: "/mes/mvul",
      name: "mvul",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '로그인 이력'
      },
      components: {
        layout: defaultLayout,
        content: MVUL
      }
    },
    {
      path: "/mes/mvir",
      name: "mvir",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 검사 결과'
      },
      components: {
        layout: defaultLayout,
        content: MVIR
      }
    },
    {
      path: "/mes/mvup",
      name: "mvup",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: '자재 단가 정보'
      },
      components: {
        layout: defaultLayout,
        content: MVUP
      }
    },
    {
      path: "/mes/mvps",
      name: "mvps",
      meta: {
        requiresAuth: true,
        site: "MES",
        transName: 'Project 현황'
      },
      components: {
        layout: defaultLayout,
        content: MVPS
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  // let url = window.location.href;
  // if (url.includes('kspan.kr')){    
  //   if (window.location.protocol !== 'https:'){ 
  //       url = url.replace('http', 'https');
  //       window.location.href = url;
  //       return;
  //   }
  // }

  if (to.name == "logout") {
    next();
  }

  auth.loggedIn(to.name, 'Y').then(loggedIn => {
    if (to.name === "login-form" && loggedIn == 'SUCCESS') {
      next({ name: "home" });
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (loggedIn == 'NEED_CHANGE_PASSWORD') {

        fn.alert("초기 비밀번호 변경 후 사용 필요");
        if (from.name != 'change-password') {
          next({ name: "change-password" });
        }
      } else if (loggedIn != 'SUCCESS') {
        next({ name: "login-form" });
      } else {
        next({});
      }
    } else {
      next();
    }

  });
});

export default router;
