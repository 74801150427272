<template>
  <div id="Container">
      <Transaction :ref="transactionRefName" 
                   :hideOperation="true"
                   :hideShipTo="true"
                   :hideExecute="true"
                   @setEqpInfoExt="setEqpInfoExt"
                   @clearEqpInfoExt="clearEqpInfoExt"/>
     
      <History id="History"
               :ref="historyRefName"/>
  </div>
</template>

<script>
  import Transaction from "../_common/TRANSACTION_EQP";
  import History from "../_common/EQPHISTORY";

  export default {
      components: {
        Transaction,
        History
      },
      data: function() {
        return {
          transactionRefName: 'transaction',
          historyRefName: 'history'
        };
      },
      methods: {
        setEqpInfoExt(eqpInfo){
          this.$refs[this.historyRefName].searchEqphistory(eqpInfo.equipmentId);
        },
        clearEqpInfoExt()
        {
          this.$refs[this.historyRefName].clear();
        }
      }
    };
</script>

<style scoped>
  #Container{
    height: calc( 100vh - 50px );
    width: 100%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background-color:white;
  }

  #History{
    height: calc( 100vh - 600px );
    width: 100%;
  }
</style>
