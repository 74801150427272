<template>
  <div class="side-nav-inner-toolbar">
    <dx-drawer
      class="drawer"
      position="before"
      template="menu"
      :opened.sync="menuOpened"
      :opened-state-mode="drawerOptions.menuMode"
      :reveal-mode="drawerOptions.menuRevealMode"
      :min-size="drawerOptions.minMenuSize"
      :shading="drawerOptions.shaderEnabled"
      :close-on-outside-click="drawerOptions.closeOnOutsideClick"
    >
      <div class="container">
        <header-toolbar
          :menu-toggle-enabled="headerMenuTogglerEnabled"
          :toggle-menu-func="toggleMenu"
          :title="title"
          :functionName="functionName"/>
        <slot />
       </div>
      <template #menu>
        <side-nav-menu
          :compact-mode="!menuOpened"
          @click="handleSideBarClick"
          :site="title">
          <dx-toolbar id="navigation-header">
            <dx-item v-if="!isXSmall" location="before" css-class="menu-button">
              <template #default>
                <dx-button
                  icon="menu"
                  styling-mode="text"
                  @click="toggleMenu"
                />
              </template>
            </dx-item>
            <dx-item location="before" css-class="systemTitle">
              <template #default>
                <div>ILKANG {{ title }}</div>
              </template>
            </dx-item>
          </dx-toolbar>
        </side-nav-menu>
      </template>
    </dx-drawer>
  </div>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxDrawer from "devextreme-vue/drawer";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import HeaderToolbar from "../components/header-toolbar";
import SideNavMenu from "../components/side-nav-menu";


export default {
  props: {
    title: String,
    functionName: String,
    isXSmall: Boolean,
    isLarge: Boolean
  },
  methods: {
    toggleMenu(e) {
      const pointerEvent = e.event;
      pointerEvent.stopPropagation();
      if (this.menuOpened) {
        this.menuTemporaryOpened = false;
      }
      this.menuOpened = !this.menuOpened;
    },
    handleSideBarClick() {
      if (this.menuOpened === false) this.menuTemporaryOpened = true;
      this.menuOpened = true;
    }
  },
  data() {
    return {
      menuOpened: this.isLarge,
      menuTemporaryOpened: false
    };
  },
      
  computed: {
    drawerOptions() {
      const shaderEnabled = !this.isLarge;
      return {
        menuMode: this.isLarge ? "shrink" : "overlap",
        menuRevealMode: this.isXSmall ? "slide" : "expand",
        minMenuSize: this.isXSmall ? 0 : 60,
        menuOpened: this.isLarge,
        closeOnOutsideClick: shaderEnabled,
        shaderEnabled
      };
    },
    headerMenuTogglerEnabled() {
      return this.isXSmall;
    },
    getFunctionName()
    {
      return this.functionName;
    }
  },
  watch: {
    isLarge() {
      if (!this.menuTemporaryOpened) {
        this.menuOpened = this.isLarge;
      }
    },
    $route() {
      if (this.menuTemporaryOpened || !this.isLarge) {
        this.menuOpened = false;
        this.menuTemporaryOpened = false;
      }
     }
  },
  components: {
    DxButton,
    DxDrawer,
    DxToolbar,
    DxItem,
    HeaderToolbar,
    SideNavMenu
  },
  head: {
    meta: [
      { name: 'mobile-web-app-capable"', content: 'yes' },
      { name: 'apple-mobile-web-app-capable', content: 'yes' }
    ]
  }
};
</script>

<style lang="scss">
  .side-nav-inner-toolbar {
    width: 100%;
  }

  .container {
    height: 100%;
    flex-direction: column;
    display: flex;
  }

  .layout-body {
    flex: 1;
    min-height: 0;
  }

  .content {
    flex-grow: 1;
  }

  #navigation-header {
    @import "../themes/generated/variables.additional.scss";
    background-color: $base-accent;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    .menu-button .dx-icon {
      color: $base-text-color;
    }

    .screen-x-small & {
      padding-left: 20px;
    }

    .dx-theme-generic & {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  .systemTitle
  {
    margin-left: 2;
    width: 168px;
    font-size: 20px;
    font-weight: 500;
  }


</style>
