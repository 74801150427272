<template>
  <div>
    <DxDataGrid
      height="inherit"
      :ref="gridRefName"
      :data-source="historyStore"
      :show-borders="true"
      :show-column-lines="true"
      :columnAutoWidth="true"
      :focused-row-enabled="true"
      @exporting="onExporting"
      @row-updating="onRowUpdating"
      @row-updated="onRowUpdated"
      @cellDblClick="onDblClick"
      @toolbar-preparing="onToolbarPreparing($event)"
      @focusedRowChanged="onFocusedRowChanged">
      
      <DxSorting mode="none"/>
      <DxExport :enabled="true"/>
      <DxScrolling mode="infinite"/>
      <DxEditing mode="cell"
                :allow-adding="false"
                :allow-updating="true"
                :allow-deleting="false"/>
      <DxColumn data-field="transTimeStr" caption="시간" width="175" dataType ="datetime" format="yyyy-MM-dd HH:mm:ss" :editorOptions="{type:'datetime',  displayFormat:'yyyy-MM-dd HH:mm:ss'}"/>
      <DxColumn data-field="lotNumber" caption="Lot No." :allowEditing="false"/>
      <DxColumn data-field="mainLot" caption="공급업체 Lot" :allowEditing="false"/>
      <DxColumn data-field="transactionName" caption="Action" :allowEditing="false"/>
      <DxColumn data-field="operationNew" caption="공정" :allowEditing="false"/>
      <DxColumn data-field="codeNew" caption="자재 코드" :allowEditing="false"/>
      <DxColumn data-field="quantityNew" caption="수량" :allowEditing="false"/>
      <DxColumn data-field="unit" caption="단위" :allowEditing="false"/>
      <DxColumn data-field="status" caption="상태" :allowEditing="false">
        <DxLookup
          :data-source="lotStatus"
          value-expr="ID"
          display-expr="Name"/>
      </DxColumn>
      <DxColumn data-field="fromToFlag" caption="분할 Flag" :allowEditing="false"/>
      <DxColumn data-field="fromToLot" caption="분할 Lot" :allowEditing="false"/>
      <DxColumn data-field="batchId" caption="Batch ID" :allowEditing="false"/>
      <DxColumn data-field="reserveShipToName" caption="출고 예정 건설 현장" :allowEditing="false"/>
      <DxColumn data-field="shipTo" caption="출하지(건설 현장)" :allowEditing="false"/>
      <DxColumn data-field="requestMaterialSeq" caption="자재 청구 번호" :allowEditing="false"/>
      <DxColumn data-field="importTimeStr" caption="입고 일" :allowEditing="false"/>
      <DxColumn
        data-field="lotType"
        caption="Lot Type"
        :allowEditing="false">
        <DxLookup
          :data-source="lotType"
          value-expr="ID"
          display-expr="Name"
        />
      </DxColumn> 
      <DxColumn data-field="userId" caption="사용자" :allowEditing="false"/>
      <DxColumn data-field="memo" caption="Memo" width="300" :allowEditing="false"/>
      <DxColumn data-field="userCmt" caption="비고" width="300" :allowEditing="false"/>
      <DxColumn data-field="importGroupId" caption="입고 Group ID" :allowEditing="false"/>
      <DxColumn
        data-field="reasonCode"
        caption="사유 코드"
        :allowEditing="false">
        <DxLookup
          :data-source="reasonCode"
          value-expr="ID"
          display-expr="Name"
        />
      </DxColumn> 
      <DxColumn data-field="equipmentId" caption="장비" :allowEditing="false">
        <DxLookup
          :data-source="equipmentList"
          value-expr="equipmentId"
          display-expr="nameCode"/>
      </DxColumn>
      <DxColumn data-field="inputLot" caption="입고 Lot" :allowEditing="false"/>
      <DxColumn data-field="millSheetFilePath" caption="원자재 성적서" alignment="center" cell-template="mill-sheet-cell-template" 
      :allowEditing="false"/>
      <DxColumn data-field="shippingCertificateFilePath" caption="출하 성적서" alignment="center" cell-template="shipping-certificate-cell-template" 
      :allowEditing="false"/>
      <DxColumn data-field="certNo" caption="증명서 번호" :allowEditing="false"/>
      <DxColumn data-field="transTime" caption="Trans Time Org" :visible="false" :allowEditing="false"/>
      <DxMasterDetail
        id="detail"
        :enabled="true"
        template="master-detail"/>
        <template #master-detail="{data}">
          <ActionHistory :master-detail-data="data"/>
        </template>


        <template #mill-sheet-cell-template="{ data }">
            {{getFileCellValue(data.data.millSheetFilePath)}}
        </template>
        
        <template #shipping-certificate-cell-template="{ data }">
            {{getFileCellValue(data.data.shippingCertificateFilePath)}}
        </template>

    
    </DxDataGrid>
    <DxPopup :ref="popupRefName"
        :drag-enabled="false"
        :close-on-outside-click="true"
        :show-title="true"
        :width="400"
        :height="250"
        :title="popupTitle">
        <DxFileUploader
          :ref="fileUploaderRefName"
          width="400"
          name="file"
          :uploadHeaders="getRequestHeaders()"
          @uploaded="onUploaded($event)"
          upload-url="/api/mes/MMIS/uploadLotFile"
          select-button-text="PDF 파일 선택"
          accept=".pdf"
          :max-file-size="4800000"/>
    </DxPopup>
  </div>

</template>

<script>
  import {DxDataGrid,
           DxSorting,
           DxColumn,
           DxLookup,
           DxExport,
           DxMasterDetail,
           DxEditing,
           DxScrolling} from "devextreme-vue/data-grid";
  import DxPopup from "devextreme-vue/popup";
  import {DxFileUploader} from "devextreme-vue/file-uploader";     
  import ActionHistory from"./ACTION_HISTORY";
  import {lotStatus,
          lotType,
          reasonCode} from "../../utils/syscodedata"; 
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";

  export default {
    components: {
      DxDataGrid,
      DxSorting,
      DxColumn,
      DxLookup,
      DxExport,
      DxMasterDetail,
      ActionHistory,
      DxEditing,
      DxScrolling,
      DxPopup,
      DxFileUploader
    },
    props: {
      masterDetailData: {
          type: Object,
          default: () => ({})
      }
    },
    data() {
      return {
        lotStatus: lotStatus,
        gridRefName: "grid",
        lotType: lotType,
        reasonCode: reasonCode,
        equipmentList: [],
        lotNumber: "",
        popupRefName: 'popup',
        selectedInputLot: "",
        fileType: "",
        popupTitle: "",
      };
    },
    computed: {
      grid: function () { return this.$refs[this.gridRefName].instance; },
      popup: function () { return this.$refs[this.popupRefName].instance; },
      fileUploader: function () { return this.$refs[this.fileUploaderRefName].instance; }
    },
    mounted() {
      dsu.getAxiosEquipmentList().then(res => { this.equipmentList = res.data.data });
      if (this.masterDetailData.key) {
        this.searchLothistory(this.masterDetailData.key);
      }
    },      
    methods: {
      onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'edit',
            text: '원자재 성적서 등록',
            stylingMode: "outlined",
            onClick: this.showLotFileModify.bind(this, 'MILL_SHEET')
          }
        },
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'remove',
            text: '원자재 성적서 삭제',
            stylingMode: "outlined",
            onClick: this.removeLotFile.bind(this, 'MILL_SHEET')
            }
          },
          {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'edit',
            text: '출하 성적서 등록',
            stylingMode: "outlined",
            onClick: this.showLotFileModify.bind(this, 'SHIPPING_CERTIFICATE')
          }
        },
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'remove',
            text: '출하 성적서 삭제',
            stylingMode: "outlined",
            onClick: this.removeLotFile.bind(this, 'SHIPPING_CERTIFICATE')
          }}
        );
      },
      searchLothistory(lotNumber){
        this.lotNumber = lotNumber;
        dsu.getAxiosOBJGet(`/api/mes/MVLH/select`, {lotNumber: lotNumber}).then(res =>{
          this.setHistoryData(res.data.data);
        });
      },
      clear(){
        this.setHistoryData([]);
      },
      setHistoryData(data)
      {
        this.grid.option("keyExpr", ["transTime","lotNumber"]);
        this.grid.option("dataSource", data);
      },
      onExporting(e) {
        var lotNumber = this.masterDetailData.key;
        if(!lotNumber){
          lotNumber =  this.lotNumber;
        }
        e.fileName = "자재 이력_" + lotNumber;
      },
      onRowUpdated(e) {   
        var mDate = new Date(e.data.transTimeStr)
        var params = {};
        params.lotNumber = e.data.lotNumber;
        params.operation = e.data.operationNewOrg;
        params.oTransTime = e.data.transTime;
        params.mTransTime = dsu.getConvTransTime(mDate);
        params.transaction = e.data.transaction;
        params.mainLot = e.data.mainLot;
        params.fromToLot = e.data.fromToLot;

        dsu.getAxiosOBJPost('/api/mes/MVLH/update', params).then(async res=>{
          let response = res.data;

          if(response.result == 'SUCCESS'){
            fn.notifySuccess(response.msg);
          }else{
            fn.notifyError(response.msg);
          }
          this.searchLothistory(this.lotNumber);
        });
          
      },
      getFileCellValue(fileInfo) {
        if (fileInfo) {
          return 'O'
        } else {
          return "";
        }
      },
      onDblClick(e) {
        var columnName = e.column.dataField;
        if (columnName) {
            if (columnName.toUpperCase().includes('FILE') && e.value) {
                window.location.href = '/fileDownload?file=' + encodeURIComponent(e.value);
            }
        }
      },
      showLotFileModify(fileType) {
        this.fileType = fileType;
        if (!this.selectedInputLot) {
          fn.notifyError('Lot No. 선택 후 진행 필요');
          return;
        } else {
          if(fileType == 'MILL_SHEET'){
            this.popupTitle = '원자재 성적서 등록';
          } else if (fileType == 'SHIPPING_CERTIFICATE') {
            this.popupTitle = '출하 성적서 등록';
          }
          this.popup.show();
        }
      },
      async removeLotFile(fileType) {
        if (!this.selectedInputLot) {
            fn.notifyError('Lot No. 선택 후 진행 필요');
            return;
        }

        if (await fn.confirm('정말 삭제 하시겠습니까?') == false) {
          return;
        } else {
          var params = {};
          params.lotNumber = this.selectedInputLot;
          params.fileType = fileType;
          dsu.getAxiosOBJGet('/api/mes/MVLH/deleteLotFiles', params).then(async res=>{
            let response = res.data;
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);
            }else{
              fn.notifyError(response.msg);
            }
            this.searchLothistory(this.lotNumber);
          });
        }
      },
      onUploaded(e) {
        const response = JSON.parse(e.request.responseText);
        if (response.result == 'SUCCESS') {
            var params = {};
            params.lotNumber = this.selectedInputLot;  
            params.fileInfo = response.msg;
            params.fileType = this.fileType;
            
            dsu.getAxiosOBJGet('/api/mes/MVLH/insertLotFiles', params).then(async res=>{
              let response = res.data;
              if(response.result == 'SUCCESS'){
                fn.notifySuccess('등록 성공');
                this.popup.hide();
                e.component.reset();
                this.searchLothistory(this.lotNumber);
              }else{
                fn.notifyError('등록 실패' + response.msg);
              }    
            });
          }else{
            fn.notifyError('등록 실패' + response.msg);
          }
      },
      onFocusedRowChanged(e) {
        this.selectedInputLot = e.row.data.inputLot;
      },
      getRequestHeaders() {
        return { 'token': dsu.getHeaders().token, 'userId': dsu.getHeaders().userId, fileType: this.fileType };
      }
    }
  };
</script>
