<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <Transaction :ref="transactionRefName" 
                   @setLotInfoExt="setLotInfoExt"
                   @clearLotInfoExt="clearLotInfoExt"
                   @execute="execute"/>
    
      <DxForm :form-data="formData">
        <DxGroupItem caption="선택 정보">
         <DxSimpleItem name="manufacturingCode">
            <DxLabel text="가공 자재 코드" />
            <template #default>
              <DxSelectBox
                :search-enabled="true"
                placeholder=""
                value-expr="code"
                display-expr="codeName"
                :value="formData.manufacturingCode"
                :data-source="manufacturingCodeList"
                @value-changed="changedManufacturingCode"/>
            </template>
          </DxSimpleItem>    

          <DxSimpleItem name="splitQty">
            <DxLabel text="생성 수량" />
            <template #default>
              <DxNumberBox 
                :value="formData.splitQty"
                @value-changed="changedSplitQty"/>
            </template>
          </DxSimpleItem>        
          
          <DxSimpleItem name="equipmentId">
            <DxLabel text="장비" />
            <template #default>
              <DxSelectBox
                :search-enabled="true"
                placeholder=""
                value-expr="equipmentId"
                display-expr="nameCode"
                :value="formData.equipmentId"
                :data-source="equipmentList"
                @value-changed="changedEquipmentId"
                :show-clear-button="true"/>
            </template>
          </DxSimpleItem> 
       
          <DxSimpleItem name="manufacturingCode2">
              <DxLabel text="가공 자재 코드2" />
              <template #default>
                <DxSelectBox
                  :search-enabled="true"
                  placeholder=""
                  value-expr="code"
                  display-expr="codeName"
                  :value="formData.manufacturingCode2"
                  :data-source="manufacturingCodeList"
                  @value-changed="changedManufacturingCode2"/>
              </template>
            </DxSimpleItem>    

            <DxSimpleItem name="splitQty2">
              <DxLabel text="생성 수량2" />
              <template #default>
                <DxNumberBox 
                  :value="formData.splitQty2"
                  @value-changed="changedSplitQty2"/>
              </template>
            </DxSimpleItem>   

        </DxGroupItem>
      </DxForm>
    </DxScrollView>
  </div>
</template>

<script>
  import {DxScrollView} from "devextreme-vue/scroll-view";
  import Transaction from "../_common/TRANSACTION";
  import {DxForm,
          DxGroupItem,
          DxSimpleItem,
          DxLabel} from "devextreme-vue/form";
  import {DxNumberBox} from "devextreme-vue/number-box";
  import {DxSelectBox} from "devextreme-vue/select-box";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function"

  export default {
    components: {
      DxScrollView,
      Transaction,
      DxForm,
      DxGroupItem,
      DxSimpleItem,
      DxSelectBox,
      DxLabel,
      DxNumberBox
    },
    data: function() {
      return {
        transactionRefName: 'transaction',
        formData: {splitQty: '', manufacturingCode: '', equipmentId: ''},
        manufacturingCodeList: [],
        equipmentList: []
      };
    },
    methods: {
      setLotInfoExt(lotInfo){
        this.clearLotInfoExt();
        
        let params = {operation: lotInfo.operation,
                      fromCategory: lotInfo.category};
        dsu.getAxiosManufacturingMaterialList(params).then(res => { 
            let response = res.data;
            if(response.result == 'SUCCESS'){
              this.manufacturingCodeList = response.data;
            }else{
              fn.notifyError(response.msg);
              this.$refs[this.transactionRefName].clear();
            }
        });

        dsu.getAxiosEquipmentList(params).then(res => { 
            let response = res.data;
            if(response.result == 'SUCCESS'){
              this.equipmentList = response.data;
            }else{
              fn.notifyError(response.msg);
              this.$refs[this.transactionRefName].clear();
            }
           
        });
      },      
      clearLotInfoExt(){
        Object.keys(this.formData).map((key) => {
            this.formData[key] ='';
        });
        this.manufacturingCodeList = [];
        this.equipmentList = [];
      },
      changedManufacturingCode(e){
        this.formData.manufacturingCode = e.value;
      },
      changedSplitQty(e){
        this.formData.splitQty = e.value;
      }, 
      changedManufacturingCode2(e){
        this.formData.manufacturingCode2 = e.value;
      },
      changedSplitQty2(e){
        this.formData.splitQty2 = e.value;
      },       
      changedEquipmentId(e){
        this.formData.equipmentId = e.value;
      },
      async execute(transInfo){
        transInfo.manufacturingCode = this.formData.manufacturingCode;
        transInfo.splitQty = this.formData.splitQty;
        transInfo.manufacturingCode2 = this.formData.manufacturingCode2;
        transInfo.splitQty2 = this.formData.splitQty2;
        transInfo.equipmentId = this.formData.equipmentId;

        if(!this.formData.manufacturingCode){
          fn.notifyError("가공 자재 선택 후 진행 필요");
          return;
        }

        if(!this.formData.splitQty){
          fn.notifyError("생성 수량 입력 후 진행 필요");
          return;
        }

        if(!this.formData.manufacturingCode2 && this.formData.splitQty2){
          fn.notifyError("가공 자재2 선택 후 진행 필요");
          return;
        }

        if(this.formData.manufacturingCode2 && !this.formData.splitQty2){
          fn.notifyError("생성 수량2 입력 후 진행 필요");
          return;
        }        
       
        await dsu.getAxiosOBJPost(`/api/mes/MCMT/createManufacturingMaterial`, transInfo).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            fn.alert(response.msg, '자재 가공 완료');
            
            var printLot = response.data.split(',');
            for (var step = 0; step < printLot.length; step++) {
              fn.printLotLabel({lotNumber: printLot[step]});
            }
            this.$refs[this.transactionRefName].clear();
          }else{
            fn.notifyError(response.msg);
          }
        });
      }
    }
  };
</script>

<style scoped>
  #Container{
    height: calc( 100vh - 50px );
    width: 100%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background-color:white;
  }
</style>