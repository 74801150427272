<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <DxForm :ref="formRefNameSearch"
              id='formSearch'
              :form-data="searchData">
        <DxGroupItem caption="조회"
                     :col-count="3">
          <DxSimpleItem 
            data-field="listId"
            editor-type="dxSelectBox"
            :editor-options="{dataSource: this.checkListList,
                              valueExpr: 'listId',
                              displayExpr: 'listName',
                              searchEnabled: true,
                              onSelectionChanged: this.changeListId}">
            <DxLabel text="Check List"/>
          </DxSimpleItem>          
        </DxGroupItem>
      </DxForm>

      <DxToolbar>
        <DxItem
          widget="dxButton"
          :options="btnOptSave"
          location="after"/>
        <DxItem
          widget="dxButton"
          :options="btnOptDelete"
          location="after"/>          
      </DxToolbar>      

      <DxForm :ref="formRefNameData"
              :form-data="checkListInfo">
        <DxGroupItem caption="Check List 정보"
                    :col-count="3">
          <DxSimpleItem
            data-field="listId"
            editor-type="dxTextBox"
            :editor-options="{readOnly: true}">
            <DxRequiredRule message="Check List ID 필수 입력"/>
            <DxLabel text="ID"/>
          </DxSimpleItem>        
      
          <DxSimpleItem
            data-field="listName"
            editor-type="dxTextBox">
            <DxRequiredRule message="Check List 이름 필수 입력"/>
            <DxPatternRule
              pattern="^.{1,20}$"
              message="20자까지 등록 가능"/>    
            <DxLabel text="이름"/>
          </DxSimpleItem>                  
        </DxGroupItem>
      </DxForm>      

      <DxForm>
        <DxGroupItem caption="Check Item Group 정보"/>
      </DxForm>      

      <DxDataGrid
        :ref="gridGroupRefName"
        :columnAutoWidth="true"
        :show-borders="true"
        :show-column-lines="true"
        :dataSource="checkItemGroup"
        :selection="{ mode: 'single' }"
        @row-inserting="onCheckItemGroupInstring"
        @row-updating="onCheckItemGroupUpdating"
        @row-inserted="onCheckItemGroupChanged"
        @row-updated="onCheckItemGroupChanged"
        @row-removed="onCheckItemGroupRemoved">
        <DxPaging :enabled="false"/>
        <DxEditing :allow-updating="true"
                   :allow-adding="true"
                   :allow-deleting="true"
                   mode="popup"/>

        <DxColumn data-field="groupName"
                  caption="그룹 명"
                  :editor-options="{maxLength: 20}">
          <DxRequiredRule/>
        </DxColumn>

        <DxColumn
          data-field="seq"
          data-type="number"
          :editor-options="{min: 1,max: 999}"
          caption="순서">
          <DxRequiredRule/>
       </DxColumn>
      </DxDataGrid>

      <DxForm id="formCheckItems">
        <DxGroupItem caption="Check Item 정보"/>
      </DxForm>      

      <DxDataGrid
        :ref="gridRefName"
        :columnAutoWidth="true"
        :show-borders="true"
        :show-column-lines="true"
        :selection="{ mode: 'multiple' }">
        <DxPaging :enabled="false"/>
        <DxSearchPanel :visible="true"/>
        <DxHeaderFilter :visible="true"/>
        <DxSorting mode="multiple"/>
        <DxEditing :allow-updating="true" mode="cell"/>

        <DxColumn data-field="itemName"
          caption="항목"
          :allowEditing="false"/>

        <DxColumn data-field="type"
          caption="유형"
          :allowEditing="false">  
          <DxLookup
            :data-source="checkItemType"
            value-expr="ID"
            display-expr="Name"/>
        </DxColumn>

        <DxColumn data-field="list"
          caption="목록"
          :allowEditing="false"/>

        <DxColumn data-field="groupName"
          caption="그룹 명">
          <DxLookup
            :data-source="checkItemGroup"
            value-expr="groupName"
            display-expr="groupName"/>
        </DxColumn>

        <DxColumn
          data-field="seq"
          data-type="number"
          :editor-options="{min: 1,max: 999}"
          caption="순서"/>

      </DxDataGrid>
    </DxScrollView>
  </div>
</template>
<script>

import {DxScrollView} from "devextreme-vue/scroll-view";
import DxToolbar,
       {DxItem} from "devextreme-vue/toolbar";
import {DxForm,
        DxGroupItem,
        DxSimpleItem,
        DxLabel} from "devextreme-vue/form";
import {DxDataGrid,
        DxColumn,
        DxSorting,
        DxLookup,
        DxRequiredRule,
        DxSearchPanel,
        DxHeaderFilter,
        DxPatternRule,
        DxEditing,
        DxPaging} from "devextreme-vue/data-grid";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";
import {checkItemType} from "../../utils/syscodedata";

export default {
    components: {
      DxScrollView,
      DxToolbar,
      DxItem,
      DxForm,
      DxGroupItem,
      DxSimpleItem,
      DxLabel,
      DxDataGrid,
      DxColumn,
      DxSorting,
      DxLookup,
      DxRequiredRule,
      DxSearchPanel,
      DxHeaderFilter,
      DxPatternRule,
      DxEditing,
      DxPaging
    },
    data: function() {
      return {
        formRefNameSearch: 'formSearch',
        searchData: {listId: ''},
        checkListList: [],
        formRefNameData: 'formData',
        checkListInfo: {listId: '', listName: ''},
        gridGroupRefName: 'gridGroup',
        checkItemGroup: [],
        gridRefName: 'grid',
        checkItemType: checkItemType,
        btnOptSave: {
            type: 'custom',
            text: '저장',
            icon: 'save',
            stylingMode:"outlined",
            onClick: this.save.bind(this)
        },
        btnOptDelete: {
            type: 'custom',
            text: '삭제',
            icon: 'trash',
            stylingMode:"outlined",
            onClick: this.delete.bind(this)
        }       
      };
    },
    mounted(){
      this.refreshCheckListList();
      this.setGridData([]);
    },
    computed: {
      formSearch: function () {return this.$refs[this.formRefNameSearch].instance;},
      formData: function () {return this.$refs[this.formRefNameData].instance;},
      gridGroupList : function () {return this.$refs[this.gridGroupRefName].instance;},
      gridItemList: function () {return this.$refs[this.gridRefName].instance;}
    },
    methods: {
      setGridData(data){
        this.gridItemList.option('dataSource', data);
        this.gridItemList.option('keyExpr', 'itemName');
        var selectedItems = [];
        for (var i = 0; i < data.length; i++) {
          if(data[i].use == 'Y'){
            selectedItems.push(data[i].itemName);
          }
        }

        this.gridItemList.selectRows(selectedItems, true);
      },
      getGridData(){
        const dataSource = this.gridItemList.getDataSource();
        let itemList = dataSource.items();  
        return itemList;      
      },
      changeListId(e){
        this.clearData();
 
        if(!e.selectedItem){
          return;
        }

        this.checkListInfo.listId = e.selectedItem.listId;

        if(this.checkListInfo.listId != '신규'){
          this.checkListInfo.listName = e.selectedItem.listName;
        }
         
        var params = {listId: this.checkListInfo.listId};

        dsu.getAxiosOBJGet('/api/erp/ERCL/selectCheckListGroup', params).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            this.checkItemGroup = response.data;
          }else{
            fn.notifyError(response.msg);
          }
        });

        dsu.getAxiosOBJGet('/api/erp/ERCL/selectCheckListItem', params).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            this.setGridData(response.data);
          }else{
            fn.notifyError(response.msg);
          }
        });
      },
      clearData(){
        this.formData.resetValues();
        Object.keys(this.checkListInfo).map((key) => {
            this.checkListInfo[key] ='';
        });
        this.setGridData([]);
      },
      refreshCheckListList(listId){
        dsu.getAxiosCheckListList().then(res => {
          this.checkListList = res.data.data;
          this.checkListList.unshift({listId: '신규', listName: '신규'});
          this.searchData.listId = listId;
        });
      },
      save(){
        this.gridItemList.clearFilter();
        let validationResult = this.formData.validate();
        if( validationResult.status != 'valid'){
          fn.notifyError("Check List 정보 입력 후 진행 필요");
          return;
        }

        this.gridItemList.saveEditData();

        var params = JSON.parse(JSON.stringify(this.checkListInfo));
        var checkListItems = this.gridItemList.getSelectedRowsData();
        
        if(checkListItems.length == 0){
          fn.notifyError("Check Item 선택 후 진행 필요");
          return;
        }

        for( var i=0 ; i < checkListItems.length ; i++){
          if (!checkListItems[i].groupName){
            fn.notifyError("그룹 명 선택 필요 : " + checkListItems[i].itemName);
            return;
          }  
        
          if (!checkListItems[i].seq){
            fn.notifyError("순서 선택 필요 : " + checkListItems[i].itemName);
            return;
          }
        }
        
        params.checkListGroups = JSON.stringify(this.checkItemGroup);
        params.checkListItems = JSON.stringify(checkListItems);

        dsu.getAxiosOBJPost('/api/erp/ERCL/updateCheckListInfo', params).then(res=>{
            let response = res.data;
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);
              if(params.listId == '신규'){
                this.refreshCheckListList(response.data);
              }
            }else{
              fn.notifyError(response.msg);
            }
        });
      },
      async delete(){
        if(!this.checkListInfo.listId || this.checkListInfo.listId == '신규'){
          fn.notifyError("Check List 선택 후 진행 필요");
          return;
        }
             
        if(await fn.confirm('정말 삭제 하시겠습니까?') == false){
          return;
        }        

        var params = {listId: this.checkListInfo.listId};

        dsu.getAxiosOBJPost('/api/erp/ERCL/deleteCheckListInfo', params).then(res=>{
            let response = res.data;
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);
              this.refreshCheckListList();
              this.clearData();
            }else{
              fn.notifyError(response.msg);
            }
        });
      },
      onCheckItemGroupInstring(e){
        if(!this.checkDuplicateValue(e.data, true)){
            e.cancel = true;
        }
      },
      onCheckItemGroupUpdating(e){
        let data = {};
        let chkKey = false;
        Object.assign(data, e.oldData);
        Object.assign(data, e.newData);

        if((e.newData.groupName && e.newData.groupName != e.oldData.groupName)){
           chkKey = true;
        }

        if(!this.checkDuplicateValue(data, chkKey)){
           e.cancel = true;
           return;
        }   
        
        if(chkKey){
          let checkItemList = this.getGridData();
        
          for (var i = 0; i < checkItemList.length; i++) {
            if (e.oldData.groupName == checkItemList[i].groupName){
              checkItemList[i].groupName = e.newData.groupName;
            }
          }

          this.setGridData(checkItemList);
        }
      },      
      checkDuplicateValue(data, chkKey){
        for (var i = 0; i < this.checkItemGroup.length; i++) {
          if(chkKey){
            if (data.groupName == this.checkItemGroup[i].groupName){
                  fn.notifyError("이미 등록된 그룹 명");
                  return false;
            }
          }
        }
        return true;
      },
      onCheckItemGroupChanged(){
        this.gridItemList.refresh();
      },
      onCheckItemGroupRemoved(e){
        let checkItemList = this.getGridData();
        
        for (var i = 0; i < checkItemList.length; i++) {
          if (e.data.groupName == checkItemList[i].groupName){
            checkItemList[i].groupName = "";
          }
        }

        this.setGridData(checkItemList);
      }
    }
  };
</script>

<style scoped>
  #Container{
    height: calc( 100vh - 50px );
    width: 100%;
    float: left;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    background-color:white;
  }
  #formSearch{
    padding-top: 5px;
  }

  #formCheckItems{
    padding-top: 40px;
  }
</style>