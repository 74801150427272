<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <DxForm :ref="formRefNameSearch"
              :form-data="formDataSearch"
              id='formSearch'>
        <DxGroupItem caption="조회"
                     :col-count="2">
      
          <DxSimpleItem name="constructionSiteCode">
            <DxLabel text="건설 현장" />
            <template #default>
              <DxSelectBox :search-enabled="true"
                            placeholder=""
                            value-expr="code"
                            display-expr="nameCode"
                            width="400"
                            :data-source="constructionSiteList"
                            @value-changed="changedConstructionSiteCode"/>
            </template>
          </DxSimpleItem>              

          <DxSimpleItem name="requestSeq">
            <DxLabel text="청구 번호" />
            <template #default>
              <DxSelectBox :search-enabled="true"
                            :ref="selectBoxRefName"
                            placeholder=""
                            value-expr="seq"
                            display-expr="seq"
                            width="350"
                            :data-source="requestSeqList"
                            @value-changed="changedRequestSeq"/>
            </template>
          </DxSimpleItem>           

        </DxGroupItem>
      </DxForm>

      <DxToolbar>
        <DxItem
          widget="dxButton"
          :options="btnOptSave"
          location="after"/>
        <DxItem
          widget="dxButton"
          :options="btnOptDelete"
          location="after"/>          
      </DxToolbar>      

      <DxForm :ref="formRefNameData"
              :form-data="requestMaterialInfo">

        <DxGroupItem caption="청구 정보"
                    :col-count="3">
          <DxSimpleItem 
            data-field="requestDate"
            editor-type="dxDateBox"
            :editor-options="{width: '150px', displayFormat: 'yyyy/MM/dd', showClearButton: true}">
            <DxLabel text="청구 일자"
                    location="left"/>
          </DxSimpleItem>

          <DxSimpleItem 
            data-field="reviewDate"
            editor-type="dxDateBox"
            :editor-options="{width: '150px', displayFormat: 'yyyy/MM/dd', showClearButton: true}">
            <DxLabel text="검토 일자"
                    location="left"/>
          </DxSimpleItem>
       
          <DxSimpleItem 
            data-field="expectedDeliveryDate"
            editor-type="dxDateBox"
            display-format="yyyy/MM/dd"
            :editor-options="{width: '150px', displayFormat: 'yyyy/MM/dd', showClearButton: true}">
            <DxLabel text="납기 예정일"
                    location="left"/>
          </DxSimpleItem>
          
          <DxSimpleItem 
            data-field="remarkSpecial"
            editor-type="dxTextArea"
            :editor-options="{height: '110px', maxLength: '500'}">
            <DxLabel text="특기 사항"/>
          </DxSimpleItem>     

          <DxSimpleItem 
            data-field=remarkConstructionSite
            editor-type="dxTextArea"
            :editor-options="{height: '110px', maxLength: '500'}">
            <DxLabel text="현장 협의 사항"/>
          </DxSimpleItem>          
        </DxGroupItem>
      </DxForm>      

      <DxDataGrid
        id="grid"
        :ref="gridRefName"
        :columnAutoWidth="true"
        :show-borders="true"
        :show-column-lines="true"
        :selection="{ mode: 'single' }"
        :dataSource="requestMaterialList"
        @row-inserting="onRowInserting"
        @row-updating="onRowUpdating">

        <DxSearchPanel :visible="true"/>
        <DxHeaderFilter :visible="true"/>
        <DxScrolling mode="infinite"/>
        <DxSorting mode="multiple"/>
        <DxEditing
          :allow-updating="true"
          :allow-adding="true"
          :allow-deleting="true"
          mode="popup"/>
        
        <DxColumn type="buttons">
          <DxButton name="edit"/>
        </DxColumn>

        <DxColumn
          data-field="code"
          caption="자재 코드(자재 명)"
          :set-cell-value="setCellValueMaterial">  
          <DxRequiredRule/>
          <DxLookup
            :data-source="materialList"
            value-expr="code"
            display-expr="codeName"/>
        </DxColumn>

        <DxColumn
          data-field="requestQuantity"
          caption="요청 수량"
          data-type="number">
          <DxRequiredRule/>
          <DxPatternRule
            pattern="^[1-9]{1}[0-9]{0,9}$"
            message="1 ~ 9999999999 까지 등록 가능"/>  
        </DxColumn>

        <DxColumn
          data-field="spec"
          caption="규격">
          <DxPatternRule
            pattern="^.{0,30}$"
            message="30자 까지 등록 가능"/>  
        </DxColumn>

        <DxColumn
          data-field="unit"
          caption="단위"
          :allowEditing="false">  
        </DxColumn>

        <DxColumn
          data-field="procurementMethod"
          caption="조달 방법">  
          <DxRequiredRule/>
          <DxLookup
            :data-source="procurementMethodList"
            value-expr="ID"
            display-expr="Name"/>
        </DxColumn>

       <DxColumn
          data-field="remark"
          caption="비고">
          <DxPatternRule
            pattern="^.{0,50}$"
            message="50자 까지 등록 가능"/>  
        </DxColumn>

      </DxDataGrid>
    </DxScrollView>
  </div>
</template>
<script>

import {DxScrollView} from "devextreme-vue/scroll-view";
import DxToolbar,
       {DxItem} from "devextreme-vue/toolbar";
import {DxForm,
        DxGroupItem,
        DxSimpleItem,
        DxLabel} from "devextreme-vue/form";
import {DxDataGrid,
        DxColumn,
        DxSorting,
        DxLookup,
        DxRequiredRule,
        DxSearchPanel,
        DxHeaderFilter,
        DxScrolling,
        DxEditing,
        DxPatternRule} from "devextreme-vue/data-grid";
import {DxSelectBox} from "devextreme-vue/select-box";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";
import {procurementMethodList} from "../../utils/syscodedata";

export default {
    components: {
      DxScrollView,
      DxToolbar,
      DxItem,
      DxForm,
      DxGroupItem,
      DxSimpleItem,
      DxLabel,
      DxDataGrid,
      DxColumn,
      DxSorting,
      DxLookup,
      DxRequiredRule,
      DxSearchPanel,
      DxHeaderFilter,
      DxScrolling,
      DxEditing, 
      DxPatternRule,     
      DxSelectBox
    },
    data: function() {
      return {
        formRefNameSearch: 'formSearch',
        formDataSearch: {constructionSiteCode: '', seq: ''},
        constructionSiteList: [],
        requestSeqList: [],
        selectBoxRefName: 'selectBoxSeq',
        formRefNameData: 'formData',
        requestMaterialInfo: {constructionSiteCode: '', seq: '',  requestDate: '', reviewDate: '', expectedDeliveryDate: '', remarkSpecial: '', remarkConstructionSite: ''},
        requestMaterialList: [],
        materialList: [],
        procurementMethodList: procurementMethodList,
        btnOptSave: {
            type: 'custom',
            text: '저장',
            icon: 'save',
            stylingMode:"outlined",
            onClick: this.save.bind(this)
        },
        btnOptDelete: {
            type: 'custom',
            text: '삭제',
            icon: 'trash',
            stylingMode:"outlined",
            onClick: this.delete.bind(this)
        },        
        gridRefName: 'grid'
      };
    },
    mounted(){
      dsu.getAxiosConstructionSiteList({complete: 'N'}).then(res => {this.constructionSiteList = res.data.data});
      dsu.getAxiosMaterialList().then(res => {this.materialList = res.data.data});
    },
    methods: {
      save(){
        if(!this.formDataSearch.constructionSiteCode){
          fn.notifyError('건설 현장 선택 후 진행 필요');
          return;
        }
        
        if(!this.formDataSearch.seq){
          fn.notifyError('청구 번호 선택 후 진행 필요');
          return;
        }

        if(!this.requestMaterialInfo.requestDate){
          fn.notifyError('청구 일자 입력 후 진행 필요');
          return;
        }        

        if(this.requestMaterialList.length == 0){
          fn.notifyError('요청 자재 입력 후 진행 필요');
          return;
        }

        var params = JSON.parse(JSON.stringify(this.requestMaterialInfo));
        params.constructionSiteCode = this.formDataSearch.constructionSiteCode;
        params.seq = this.formDataSearch.seq;
        params.useFlag = 'N';
        params.requestDate = dsu.getFormatDate(this.requestMaterialInfo.requestDate);
        params.reviewDate = dsu.getFormatDate(this.requestMaterialInfo.reviewDate);  
        params.expectedDeliveryDate = dsu.getFormatDate(this.requestMaterialInfo.expectedDeliveryDate);  
        params.requestMaterialList = JSON.stringify(this.requestMaterialList);
                
        dsu.getAxiosOBJPost('/api/erp/EEHM/updateRequestMaterialInfo', params).then(res=>{
            let response = res.data;
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);

              if(params.seq == '신규'){
                this.changedConstructionSiteCode({value: params.constructionSiteCode}, response.data)
              }
            }else{
              fn.notifyError(response.msg);
            }
        });
      },
      async delete(){
        if(!this.formDataSearch.constructionSiteCode){
          fn.notifyError('건설 현장 선택 후 진행 필요');
          return;
        }
        
        if(!this.formDataSearch.seq || this.formDataSearch.seq == '신규' ){
          fn.notifyError('청구 번호 선택 후 진행 필요');
          return;
        }
               
        if(await fn.confirm('정말 삭제 하시겠습니까?') == false){
          return;
        }
        
        var params = {};
        params.constructionSiteCode = this.formDataSearch.constructionSiteCode;
        params.seq = this.formDataSearch.seq;

        await dsu.getAxiosOBJPost('/api/erp/EEHM/deleteRequestMaterialInfo', params).then(res=>{
            let response = res.data;
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);
              this.changedConstructionSiteCode({value: params.constructionSiteCode})
            }else{
              fn.notifyError(response.msg);
            }
        });
      },
      changedConstructionSiteCode(e, seq){
        this.formDataSearch.constructionSiteCode = e.value;
     
        this.clearData();
        
        var params = {constructionSiteCode: this.formDataSearch.constructionSiteCode};

        dsu.getAxiosOBJGet('/api/erp/EEHM/selectRequestMaterialNoList', params).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            this.requestSeqList = response.data;
            this.requestSeqList.unshift({seq: '신규'});

            if(seq){
                var selectBox = this.$refs[this.selectBoxRefName].instance;
                selectBox.option('value', seq);  
            }
          }else{
            fn.notifyError(response.msg);
          }
        });
      },
      changedRequestSeq(e){
        this.formDataSearch.seq = e.value;
        
        this.clearData();

        if(this.formDataSearch.seq != '신규'){
          var params = {constructionSiteCode: this.formDataSearch.constructionSiteCode,
                        seq: this.formDataSearch.seq,};

          dsu.getAxiosOBJGet('/api/erp/EEHM/selectRequestMaterialInfo', params).then(res=>{
            let response = res.data;
            if(response.result == 'SUCCESS'){
              this.requestMaterialInfo = JSON.parse(JSON.stringify(response.data[0]));
            }else{
              fn.notifyError(response.msg);
            }
          });
          
          dsu.getAxiosOBJGet('/api/erp/EEHM/selectRequestMaterialList', params).then(res=>{
            let response = res.data;
            if(response.result == 'SUCCESS'){
              this.requestMaterialList = response.data;
            }else{
              fn.notifyError(response.msg);
            }
          });
        }
      },
      clearData(){
        this.$refs[this.formRefNameData].instance.resetValues();
        Object.keys(this.requestMaterialInfo).map((key) => {
            this.requestMaterialInfo[key] ='';
        });

        this.requestMaterialList = [];
      },
      onRowInserting(e){
        if(!this.checkDuplicateValue(e.data, true)){
           e.cancel = true;
        }
      },
      onRowUpdating(e){
        let data = {};
        let chkKey = false;
        Object.assign(data, e.oldData);
        Object.assign(data, e.newData);

        if((e.newData.code &&  e.newData.code != e.oldData.code)){
           chkKey = true;
        }

        if(!this.checkDuplicateValue(data, chkKey)){
           e.cancel = true;
        }
      },  
      checkDuplicateValue(data, chkKey){
        for (var i = 0; i < this.requestMaterialList.length; i++) {
          if(chkKey){
            if (data.code == this.requestMaterialList[i].code){
                  fn.notifyError('이미 등록된 자재 코드');
                  return false;
            }
          }
        }
        return true;
      },
      setCellValueMaterial(newData, value) {
          newData.code = value;
          const materialInfo = this.materialList.filter(function(e){
                                    return e['code'] === value;
                              })[0];
          newData.unit = materialInfo.unit;
      }                
    }
  };
</script>

<style scoped>
  #Container{
    height: calc( 100vh - 50px );
    width: 100%;
    float: left;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    background-color:white;
  }
  #formSearch{
    padding-top: 5px;
  }
  #grid{
    height: calc( 100vh - 550px );
  }
</style>