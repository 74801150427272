<template>
  <DxDataGrid
    id="gridContainer"
    :data-source="inspectionSpecList"
    :show-borders="true"
    :show-column-lines="true"
    :columnAutoWidth="true"
    :selection="{ mode: 'single' }"
    @row-inserting="onRowInserting"
    @row-updating="onRowUpdating"
    @exporting="onExporting"
    @toolbar-preparing="onToolbarPreparing($event)">
    
    <DxSearchPanel :visible="true"/>
    <DxHeaderFilter :visible="true"/>
    <DxScrolling mode="infinite"/>
    <DxSorting mode="multiple"/>
    <DxExport :enabled="true"/>
    <DxEditing
      :allow-updating="true"
      :allow-adding="true"
      :allow-deleting="true"
      mode="popup"/>
   
    <DxColumn type="buttons">
      <DxButton name="edit"/>
    </DxColumn>
   
    <DxColumn
      data-field="operation"
      caption="공정">  
      <DxRequiredRule/>
      <DxLookup
        :data-source="operationList"
        value-expr="operation"
        display-expr="codeName"/>
    </DxColumn>
    
    <DxColumn
      data-field="category"
      caption="품목"
      :header-filter="{ allowSearch: true }">  
      <DxRequiredRule/>
      <DxLookup
        :data-source="materialCategory"
        value-expr="category"
        display-expr="category"/>
    </DxColumn>

    <DxColumn
      data-field="item"
      caption="검사 항목">  
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^[\S*].{1,13}[\S*]$|^\S{1,15}$"
        message="15자까지 입력 가능, 좌우 공백 불가"/>
    </DxColumn>
    
    <DxColumn
      data-field="method"
      caption="검사 방법">  
      <DxRequiredRule/>
      <DxLookup
        :data-source="[{'ID': 'MEASURE', 'Name': 'MEASURE'}, {'ID': 'PASS/FAIL', 'Name': 'PASS/FAIL'}]"
        value-expr="ID"
        display-expr="Name"/>
    </DxColumn>

    <DxColumn
      data-field="unit"
      caption="단위">  
      <DxLookup
        :data-source="[{'ID': 'mm', 'Name': 'mm'}, {'ID': 'g', 'Name': 'g'}]"
        value-expr="ID"
        display-expr="Name"
        :allow-clearing="true"/>
    </DxColumn>

    <DxColumn
      data-field="lsl"
      caption="LSL"
      data-type="number">
      <DxPatternRule
        pattern="^.{0,15}$"
        message="15자 까지 등록 가능"/>        
    </DxColumn>
    
    <DxColumn
      data-field="tg"
      caption="기준 값"
      data-type="number">
      <DxPatternRule
        pattern="^.{0,15}$"
        message="15자 까지 등록 가능"/>        
    </DxColumn>

    <DxColumn
      data-field="usl"
      caption="USL"
      data-type="number">
      <DxPatternRule
        pattern="^.{0,15}$"
        message="15자 까지 등록 가능"/>        
    </DxColumn>

    <DxColumn
      data-field="tolerance"
      caption="허용 오차">
      <DxPatternRule
        pattern="^.{0,30}$"
        message="30자 까지 등록 가능"/>        
    </DxColumn>
  </DxDataGrid>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxLookup,
          DxRequiredRule}from "devextreme-vue/data-grid";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxLookup,
      DxRequiredRule},
    data(){
      return {
        inspectionSpecList: [],
        materialCategory: [],
        operationList: []
      };
    },
    async mounted(){
      await dsu.getAxiosOperationList({eqpOper : 'N'}).then(res => {this.operationList = res.data.data});
      await dsu.getAxiosMaterialCategoryList().then(res => {this.materialCategory = res.data.data});
      await this.refreshData();
    },
    methods:{
        onToolbarPreparing(e) {
          e.toolbarOptions.items.unshift(
           {location: 'before',
            widget: 'dxButton',
            options: {
              width: 100,
              icon: 'save',
              text: '저장',
              stylingMode:"outlined",
              onClick: this.save.bind(this)
            }}
          );
        },
      refreshData() {
        var params = {};
        params.useFlag = 'Y'
        dsu.getAxiosOBJGet('/api/erp/EISP/select', params).then(res => {
          this.inspectionSpecList = res.data.data;
        });
      },
      onRowInserting(e){
        if(!this.validationValue(e.data, true)){
           e.cancel = true;
        }
      },
      onRowUpdating(e){
        let data = {};
        let chkKey = false;

        Object.assign(data, e.oldData);
        Object.assign(data, e.newData);
      
        if((e.newData.operation &&  e.newData.operation != e.oldData.operation)
          || (e.newData.category &&  e.newData.category != e.oldData.category)
          || (e.newData.item &&  e.newData.item != e.oldData.item)){
           chkKey = true;
        }

        if(!this.validationValue(data, chkKey)){
           e.cancel = true;
        }
      },
      onExporting(e){
        e.fileName = this.$route.meta.transName;
      },
      validationValue(data, chkKey){
        for (var i = 0; i < this.inspectionSpecList.length; i++) {
          if(chkKey){
            if (data.operation == this.inspectionSpecList[i].operation 
                && data.category == this.inspectionSpecList[i].category
                && data.item == this.inspectionSpecList[i].item){
                  fn.notifyError('중복 데이터, 공정 품목 검사항목 동일 입력 값 확인필요');
                  return false;
            }
          }

          if(data.method == 'PASS/FAIL'){
            if(data.unit){
              fn.notifyError('검사 방법이 PASS/FAIL일 경우 단위 입력 불가');
              return false;
            }

            if(data.lsl){
              fn.notifyError('검사 방법이 PASS/FAIL일 경우 LSL 입력 불가');
              return false;
            }
            
            if(data.tg){
              fn.notifyError('검사 방법이 PASS/FAIL일 경우 기준 값 입력 불가');
              return false;
            }

            if(data.usl){
              fn.notifyError('검사 방법이 PASS/FAIL일 경우 USL 입력 불가');
              return false;
            }        
            if(data.tolerance){
              fn.notifyError('검사 방법이 PASS/FAIL일 경우 허용 오차 입력 불가');
              return false;
            }
          }else{
            if(!data.unit){
              fn.notifyError('단위 미 입력');
              return false;
            }

            if(!data.lsl){
              fn.notifyError('LSL 미 입력');
              return false;
            }

            if(!data.tg){
              fn.notifyError('기준 값 미 입력');
              return false;
            }

            if(!data.usl){
              fn.notifyError('USL 미 입력');
              return false;
            }

            if(!data.tolerance){
              fn.notifyError('허용 오차 미 입력');
              return false;
            }
          }
        }
        return true;
      },
      save(){
          if(this.inspectionSpecList.length == 0)
          {
            fn.notifyError('검사정보 입력 후 진행 필요');
            return;
          }

          dsu.getAxiosOBJPost('/api/erp/EISP/update', {inspectionSpecList: JSON.stringify(this.inspectionSpecList)}).then(res => {
              let response = res.data;

              if(response.result == 'SUCCESS'){
                fn.notifySuccess(response.msg);
                this.refreshData;
              }else {
                fn.notifyError(response.msg);
              }
          });
      }
    }
  };
</script>

<style scoped>
  #gridContainer {
      height: calc( 100vh - 50px );
      width: 100%;
  }
</style>