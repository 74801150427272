<template>
  <div>
    <DxForm id="form">
      <DxGroupItem caption="설계 정보" > 
      </DxGroupItem>
    </DxForm>
        
    <DxDataGrid
      :ref="gridRefName"
      :dataSource="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      :selection="{ mode: 'single' }"
      @exporting="onExporting"
      @editorPreparing="onEditorPreparing($event)"
      @rowInserting="rowInserting($event)"
      @rowUpdating="rowUpdating($event)"
      @rowRemoving="rowRemoving($event)">
    
      <DxSearchPanel :visible="true"/>
      <DxHeaderFilter :visible="true"/>
      <DxScrolling mode="infinite"/>
      <DxSorting mode="multiple"/>
      <DxExport :enabled="false"/>
  
      <DxEditing 
        :allow-adding="showAdding"
        :allow-updating="showUpdating"
        :allow-deleting="showDeleting"
        mode="popup"/>
      
      <DxColumn type="buttons">
        <DxButton name="edit"/>
      </DxColumn>

      <DxColumn
        data-field="seq"
        caption="요청 번호"
        :allowEditing="false"
        :header-filter="{ allowSearch: true }">
        <DxRequiredRule v-if="requireSeq"/>
      </DxColumn>

      <DxColumn
        data-field="requestDate"
        caption="요청일"
        data-type="date"
        format="yyyy/MM/dd"
        :allowEditing="allowEditingRequest"
        :header-filter="{ allowSearch: true }">
        <DxFormItem :visible="allowEditingRequest"/>
        <DxRequiredRule v-if="allowEditingRequest"/>
      </DxColumn>
    
      <DxColumn
        data-field="designStage"
        caption="단계"
        :allowEditing="allowEditingDesignStage"
        :header-filter="{ allowSearch: true }">
        <DxLookup
          :data-source="designStage"
          value-expr="ID"
          display-expr="Name"/>
        <DxFormItem :visible="allowEditingDesignStage"/>
        <DxRequiredRule v-if="allowEditingDesignStage"/>
      </DxColumn>

      <DxColumn
        data-field="requestContent"
        caption="요청 내용"
        :allowEditing="allowEditingRequest"
        :header-filter="{ allowSearch: true }"
        :width="columnWidth"
        cell-template="text-area-template">
        <DxFormItem :visible="allowEditingRequest"/>
        <DxRequiredRule v-if="allowEditingRequest"/>
      </DxColumn>

      <DxColumn
        data-field="designDate"
        caption="설계일"
        data-type="date"
        format="yyyy/MM/dd"
        :allowEditing="allowEditingDesign"
        :header-filter="{ allowSearch: true }">
        <DxFormItem :visible="allowEditingDesign"/>
        <DxRequiredRule v-if="allowEditingDesign"/>
      </DxColumn>

      <DxColumn
        data-field="designContent"
        caption="설계 내용"
        :allowEditing="allowEditingDesign"
        :header-filter="{ allowSearch: true }"
        :width="columnWidth"
        cell-template="text-area-template">
        <DxFormItem :visible="allowEditingDesign"/>
        <DxRequiredRule v-if="allowEditingDesign"/>
      </DxColumn>        

      <DxColumn
        data-field="designFileUrl"
        caption="설계 파일 URL"
        :allowEditing="allowEditingDesign"      
        :header-filter="{ allowSearch: true }"
        :editor-options="{maxLength: 100}">     
        <DxFormItem :visible="allowEditingDesign"/>
        <DxRequiredRule v-if="allowEditingDesign"/> 
      </DxColumn>

      <DxColumn
        data-field="deliveryDate"
        caption="납품일"
        data-type="date"
        format="yyyy/MM/dd"
        :allowEditing="allowEditingDelivery"      
        :header-filter="{ allowSearch: true }">
        <DxFormItem :visible="allowEditingDelivery"/>
        <DxRequiredRule v-if="allowEditingDelivery"/> 
      </DxColumn>

      <DxColumn
        data-field="deliveryRemark"
        caption="납품 내용"
        :allowEditing="allowEditingDelivery"     
        :header-filter="{ allowSearch: true }"
        :width="columnWidth"
        cell-template="text-area-template">
        <DxFormItem :visible="allowEditingDelivery"/>
        <DxRequiredRule v-if="allowEditingDelivery"/> 
      </DxColumn>        
    
      <template #text-area-template="{ data }">
        <DxTextArea
          :auto-resize-enabled="true"
          :read-only="true"
          :value="data.value"
        />
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxRequiredRule,
          DxFormItem,
          DxLookup} from "devextreme-vue/data-grid";
  import DxTextArea from 'devextreme-vue/text-area';
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  import {DxForm,
          DxGroupItem} from "devextreme-vue/form";
  import {designStage} from "../../utils/syscodedata";

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxRequiredRule,
      DxFormItem,
      DxTextArea,
      DxForm,
      DxGroupItem,
      DxLookup
    },
    props: {
      projectId: String,
      showAdding: Boolean,
      showUpdating: Boolean,
      showDeleting: Boolean,
      requireSeq: Boolean,
      allowEditingDesignStage: Boolean,
      allowEditingRequest: Boolean,
      allowEditingDesign: Boolean,
      allowEditingDelivery: Boolean
    },
    data() {
      return {
         projectInfo: {},
         gridRefName: 'grid',
         dataSource: [],
         columnWidth: 520,
         designStage: designStage
      };
    },
    methods:{
      onExporting(e) {
        e.fileName = this.$route.meta.transName;
      },
      onEditorPreparing: function (e) { 
        if (e.dataField == 'requestContent' ||
            e.dataField == 'designContent' ||
            e.dataField == 'deliveryRemark'){
            e.editorName = 'dxTextArea';
            e.editorOptions.width= 500;
            e.editorOptions.height = 200;
            e.editorOptions.maxLength = 300;
        }
      },
      refreshData(params){
        this.projectInfo = params;
        dsu.getAxiosOBJGet('/api/erp/PDRQ/selectProjectDesignData', params).then(res =>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            this.dataSource = response.data;
          }else{
            fn.notifyError(response.msg);
          }
        });
      },
      async rowInserting(e){
        var params = e.data;
        
        if(!this.projectInfo.projectId){
          this.dataSource = [];
          fn.notifyError("Project 선택 후 진행 필요");
          return;
        }

        params.projectId = this.projectInfo.projectId;
        
        for(var key in params) {
          if(key.includes('Date')){
              params[key] = dsu.getFormatDate(params[key]);
          }
        }

        dsu.getAxiosOBJGet('/api/erp/PDRQ/insertProjectDesignData', params).then(res => {
          let response = res.data;
          this.refreshData(this.projectInfo);
          if(response.result == 'SUCCESS'){
            fn.notifySuccess(response.msg);
          }else {
            fn.notifyError(response.msg);
          }
        });        
      },
      rowUpdating(e){
        var params = {};

        Object.assign(params, e.oldData);
        Object.assign(params, e.newData);

        params.projectId = this.projectInfo.projectId;
        
        for(var key in params) {
          if(key.includes('Date')){
              params[key] = dsu.getFormatDate(params[key]);
          }
        }

        dsu.getAxiosOBJGet('/api/erp/PDRQ/updateProjectDesignData', params).then(res => {
          let response = res.data;
          this.refreshData(this.projectInfo);
          if(response.result == 'SUCCESS'){
            fn.notifySuccess(response.msg);
          }else {
            fn.notifyError(response.msg);
          }
        });   
      },
      rowRemoving(e){
        var params = {};
        params.projectId = this.projectInfo.projectId;
        params.seq = e.data.seq;
        
        dsu.getAxiosOBJGet('/api/erp/PDRQ/deleteProjectDesignData', params).then(res => {
          let response = res.data;
          this.refreshData(this.projectInfo);
          if(response.result == 'SUCCESS'){
            fn.notifySuccess(response.msg);
          }else {
            fn.notifyError(response.msg);
          }
        });  
      }
    }
  };
</script>

<style scoped>
  #form{
    margin-top: 40px;
  }
</style>
