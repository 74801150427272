
import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import 'es6-promise/auto';

import Vue from "vue";
import App from "./App";
import router from "./router";
import axios from 'axios';

// import VueHead from 'vue-head'
// Vue.use(VueHead)

Vue.config.productionTip = false;
Vue.prototype.$EventBus = new Vue();
Vue.prototype.$Http = axios;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");



