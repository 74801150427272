<template>
  <div id="Container">
      <Transaction :ref="transactionRefName" 
                   :hideCode="true"
                   :hideQuantity="true"
                   :hideOperation="true"
                   :hideExecute="true"
                   :withTerminateLot="true"
                   :hideTimeEdit="true"
                   @setLotInfoExt="setLotInfoExt"
                   @clearLotInfoExt="clearLotInfoExt"/>
     
      <History id="History"
               :ref="historyRefName"/>
  </div>
</template>

<script>
  import Transaction from "../_common/TRANSACTION";
  import History from "../_common/LOTHISTORY";

  export default {
      components: {
        Transaction,
        History
      },
      data: function() {
        return {
          transactionRefName: 'transaction',
          historyRefName: 'history'
        };
      },
      methods: {
        setLotInfoExt(lotInfo){
          this.$refs[this.historyRefName].searchLothistory(lotInfo.lotNumber);
        },
        clearLotInfoExt()
        {
          this.$refs[this.historyRefName].clear();
        }
      }
    };
</script>

<style scoped>
  #Container{
    height: calc( 100vh - 50px );
    width: 100%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background-color:white;
  }

  #History{
    height: calc( 100vh - 330px );
    width: 100%;
  }
</style>
