<template>
  <DxDataGrid
    :ref="gridRefName"
    id="gridContainer"
    :data-source="inspectionSpecList"
    :show-borders="true"
    :show-column-lines="true"
    :columnAutoWidth="true"
    :selection="{ mode: 'single' }"
    @row-inserting="onRowInserting"
    @row-updating="onRowUpdating"
    @row-removing="onRowRemoving"
    @exporting="onExporting">
    
    <DxSearchPanel :visible="true"/>
    <DxHeaderFilter :visible="true"/>
    <DxScrolling mode="virtual"/>
    <DxSorting mode="multiple"/>
    <DxExport :enabled="true"/>
    <DxEditing
      :allow-updating="true"
      :allow-adding="true"
      :allow-deleting="true"
      mode="popup"/>
   
    <DxColumn type="buttons">
      <DxButton name="edit"/>
    </DxColumn>
   
    <DxColumn
      data-field="operation"
      caption="공정">  
      <DxRequiredRule/>
      <DxLookup
        :data-source="operationList"
        value-expr="operation"
        display-expr="codeName"/>
    </DxColumn>
    
    <DxColumn
      data-field="code"
      caption="자재 코드(자재 명)"
      :header-filter="{ allowSearch: true }">  
      <DxRequiredRule/>
      <DxLookup
        :data-source="materialList"
        value-expr="code"
        display-expr="codeName"/>
    </DxColumn>

    <DxColumn
      data-field="item"
      caption="검사 항목">  
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^[\S*].{1,13}[\S*]$|^\S{1,15}$"
        message="15자까지 입력 가능, 좌우 공백 불가"/>
    </DxColumn>
    
    <DxColumn
      data-field="method"
      caption="검사 방법">  
      <DxRequiredRule/>
      <DxLookup
        :data-source="[{'ID': 'MEASURE', 'Name': 'MEASURE'}, {'ID': 'PASS/FAIL', 'Name': 'PASS/FAIL'}]"
        value-expr="ID"
        display-expr="Name"/>
    </DxColumn>

    <DxColumn
      data-field="unit"
      caption="단위">  
      <DxLookup
        :data-source="[{'ID': 'mm', 'Name': 'mm'}, {'ID': 'g', 'Name': 'g'}]"
        value-expr="ID"
        display-expr="Name"
        :allow-clearing="true"/>
    </DxColumn>

    <DxColumn
      data-field="lsl"
      caption="LSL"
      data-type="number">
      <DxPatternRule
        pattern="^.{0,15}$"
        message="15자 까지 등록 가능"/>        
    </DxColumn>
    
    <DxColumn
      data-field="tg"
      caption="기준 값"
      data-type="number">
      <DxPatternRule
        pattern="^.{0,15}$"
        message="15자 까지 등록 가능"/>        
    </DxColumn>

    <DxColumn
      data-field="usl"
      caption="USL"
      data-type="number">
      <DxPatternRule
        pattern="^.{0,15}$"
        message="15자 까지 등록 가능"/>        
    </DxColumn>

    <DxColumn
      data-field="tolerance"
      caption="허용 오차">
      <DxPatternRule
        pattern="^.{0,30}$"
        message="30자 까지 등록 가능"/>        
    </DxColumn>
  </DxDataGrid>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxLookup,
          DxRequiredRule}from "devextreme-vue/data-grid";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxLookup,
      DxRequiredRule},
    data(){
      return {
        gridRefName: 'grid',
        inspectionSpecList: [],
        materialList: [],
        operationList: [],
        useFlag: "USE"
      };
    },
    async mounted(){
      await dsu.getAxiosOperationList({eqpOper : 'N'}).then(res => {this.operationList = res.data.data});
      await dsu.getAxiosMaterialList().then(res => {this.materialList = res.data.data});
      await this.refreshData();
    },
  methods: {
      // onToolbarPreparing(e) {
      //   e.toolbarOptions.items.unshift({
      //     location: 'before',
      //     widget: 'dxSelectBox',
      //     options: {
      //       items: [{
      //         value: 'ALL',
      //         text: '전체'
      //       },
      //       {
      //         value: 'USE',
      //         text: '사용'
      //       },
      //       {
      //         value: 'NO_USE',
      //         text: '미사용'
      //       }],
      //       displayExpr: 'text',
      //       valueExpr: 'value',
      //       value: 'USE',
      //       onValueChanged: this.changedUse.bind(this)
      //     }
      //   });
      // },
      changedUse(e) {
        this.useFlag = e.value;
        this.refreshData();
      },
      refreshData() {
        var params = {};
        if (this.useFlag == 'USE') {
          params.useFlag = 'Y';
        } else if (this.useFlag == 'NO_USE') {
          params.useFlag = 'N';
        }
              
        const grid = this.$refs[this.gridRefName].instance;  
        grid.beginCustomLoading("Data Loading...");
        dsu.getAxiosOBJGet('/api/erp/EISM/select', params).then(res => {
          grid.endCustomLoading();
          this.inspectionSpecList = res.data.data;
        });
      },
      onRowInserting(e){
        if(!this.validationValue(e.data, true)){
          e.cancel = true;
        }else{
          this.save(e.data);
        }
      },
      onRowUpdating(e){
        let data = {};

        Object.assign(data, e.oldData);
        Object.assign(data, e.newData);
      
        if((e.newData.operation &&  e.newData.operation != e.oldData.operation)
          || (e.newData.code &&  e.newData.code != e.oldData.code)
          || (e.newData.item &&  e.newData.item != e.oldData.item)){
          e.cancel = true;
          fn.notifyError('공정/자재/검사항목 변경 불가능(키값 변경 불가)');
          return;
        }

        if(!this.validationValue(data, false)){
           e.cancel = true;
        }else{
          this.save(data);
        }
      },
      onRowRemoving(e){
        dsu.getAxiosOBJPost('/api/erp/EISM/delete', e.data).then(res => {
            let response = res.data;
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);

            }else {
              fn.notifyError(response.msg);
              this.refreshData;
            }
        });
      },
      onExporting(e){
        e.fileName = this.$route.meta.transName;
      },
      validationValue(data, chkKey){
        for (var i = 0; i < this.inspectionSpecList.length; i++) {
          if(chkKey){
            if (data.operation == this.inspectionSpecList[i].operation 
                && data.code == this.inspectionSpecList[i].code
                && data.item == this.inspectionSpecList[i].item){
                  fn.notifyError('중복 데이터, 공정 품목 검사항목 동일 입력 값 확인필요');
                  return false;
            }
          }
          
          if(data.method == 'PASS/FAIL'){
            if(data.unit){
              fn.notifyError('검사 방법이 PASS/FAIL일 경우 단위 입력 불가');
              return false;
            }

            if(data.lsl){
              fn.notifyError('검사 방법이 PASS/FAIL일 경우 LSL 입력 불가');
              return false;
            }

            if(data.tg){
              fn.notifyError('검사 방법이 PASS/FAIL일 경우 기준 값 입력 불가');
              return false;
            }

            if(data.usl){
              fn.notifyError('검사 방법이 PASS/FAIL일 경우 USL 입력 불가');
              return false;
            }
            if(data.tolerance){
              fn.notifyError('검사 방법이 PASS/FAIL일 경우 허용 오차 입력 불가');
              return false;
            }
          }else{
            if(!data.unit){
              fn.notifyError('단위 미 입력');
              return false;
            }

            if(!data.lsl){
              fn.notifyError('LSL 미 입력');
              return false;
            }

            if (!data.tg) {
              fn.notifyError('기준 값 미 입력');
              return false;
            }

            if(!data.usl){
              fn.notifyError('USL 미 입력');
              return false;
            }

            if(!data.tolerance){
              fn.notifyError('허용 오차 미 입력');
              return false;
            }
          }
        }
        return true;
      },
      save(data){
        dsu.getAxiosOBJPost('/api/erp/EISM/insert', data).then(res => {
            let response = res.data;
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);
            }else {
              fn.notifyError(response.msg);
              this.refreshData;
            }
        });
      }
    }
  };
</script>

<style scoped>
  #gridContainer {
      height: calc( 100vh - 50px );
      width: 100%;
  }
</style>
