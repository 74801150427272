<template>
  <DxDataGrid
    id="gridContainer"
    :ref="gridRefName"
    :data-source="dataSource"
    :focused-row-enabled="true"
    :selection="{ mode: 'single' }"
    :columnAutoWidth="true"
    :show-borders="true"
    :show-column-lines="true"
    @exporting="onExporting"
    @init-new-row="onInitNewRow"
    @editing-start="onEditingStart"
    @cellDblClick="onDblClick"
    @toolbar-preparing="onToolbarPreparing($event)"
    @editorPreparing="onEditorPreparing($event)">
  
    <DxSearchPanel :visible="true"/>
    <DxHeaderFilter :visible="true"/>
    <DxScrolling mode="infinite"/>
    <DxSorting mode="multiple"/>
    <DxExport :enabled="true"/>

    <DxEditing
      :allow-updating="true"
      :allow-adding="true"
      :allow-deleting="true"
      mode="popup"/>
    
    <DxColumn type="buttons">
      <DxButton name="edit"/>
    </DxColumn>

    <DxColumn
      data-field="code"
      caption="자재 코드"
      :header-filter="{ allowSearch: true }">
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^[A-Z0-9-]{1,20}$"
        message="영문 대문자 숫자 - 로만 구성, 20자 까지 등록 가능"/>
    </DxColumn>
    
    <DxColumn
      data-field="name"
      caption="자재 명"
      :header-filter="{ allowSearch: true }">
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^.{1,30}$"
        message="30자 까지 등록 가능"/>
    </DxColumn>

    <DxColumn
      data-field="category"
      caption="품목">  
      <DxRequiredRule/>
      <DxLookup
        :data-source="materialCategory"
        value-expr="category"
        display-expr="category"/>
    </DxColumn>

    <DxColumn
      data-field="unit"
      caption="단위">     
      <DxRequiredRule/>
      <DxLookup :data-source="materialUnit" />
    </DxColumn>

    <DxColumn
      data-field="color"
      caption="색상"
      :editor-options="editorOptions"
      cell-template="colorsCellTemplate">     
      <DxLookup
        :data-source="colors"
        value-expr="ID"
        display-expr="ID"
        :allow-clearing="true">
       </DxLookup>
    </DxColumn>

    <DxColumn
      data-field="use"
      caption="사용">  
      <DxRequiredRule/>
      <DxLookup
        :data-source="useFlag"
        value-expr="ID"
        display-expr="Name"/>
    </DxColumn>

    <DxColumn
      data-field="irViSpec"
      caption="외관 검사 기준"
      :header-filter="{ allowSearch: true }"
      width="250px"
      height="70px"
      cell-template="text-area-template">
    </DxColumn>
   
    <DxColumn
      data-field="irSamplingSpec"
      caption="Sampling 기준"
      :header-filter="{ allowSearch: true }">
      <DxPatternRule
        pattern="^.{1,50}$"
        message="50자 까지 등록 가능"/>
    </DxColumn>
    
    <DxColumn
      data-field="irRemarks"
      caption="검사성적서 비고"
      :header-filter="{ allowSearch: true }">
      <DxPatternRule
        pattern="^.{1,50}$"
        message="50자 까지 등록 가능"/>
    </DxColumn>
     
    <DxColumn
      data-field="irViSampleQty"
      caption="외관 검사 시료 수"
      dataType="number"
      :editorOptions="{ min: 1, max: 999, showClearButton: true}"
      :header-filter="{ allowSearch: true }">
    </DxColumn>

    <DxColumn
      data-field="irMiSampleQty"
      caption="측정 검사 시료 수"
      dataType="number"
      :editorOptions="{ min: 1, max: 999, showClearButton: true}"
      :header-filter="{ allowSearch: true }">
    </DxColumn>

    <DxColumn
      data-field="irMeasureEquipment"
      caption="측정기"
      :header-filter="{ allowSearch: true }">
      <DxPatternRule
        pattern="^.{1,50}$"
        message="50자 까지 등록 가능"/>
    </DxColumn>
    
    <DxColumn
      data-field="irTestAgency"
      caption="성능시험 기관"
      :header-filter="{ allowSearch: true }">
      <DxPatternRule
        pattern="^.{1,30}$"
        message="30자 까지 등록 가능"/>
    </DxColumn>

    <DxColumn
      data-field="irTestDate"
      caption="성능시험 일자"
      dataType="date"
      format="yyyy/MM/dd"
      :editorOptions="{displayFormat: 'yyyy/MM/dd' , showClearButton: true}"
      :header-filter="{allowSearch: true }">
    </DxColumn>

    <DxColumn
       data-field="irTestCertFileId"
       caption="성능시험 성적서"
       :header-filter="{ allowSearch: false }"
       cellTemplate="file-cell-template"
       edit-cell-template="file-edit-template">
    </DxColumn>

    <DxColumn
       data-field="irSpecImageFileId"
       caption="검사 기준 이미지"
       width="250px"
       :header-filter="{ allowSearch: false }"
       cellTemplate="image-cell-template"
      edit-cell-template="image-edit-template">
    </DxColumn>

    <DxColumn
      data-field="irDocNo"
      caption="검사성적서 문서번호"
      :header-filter="{ allowSearch: true }">
      <DxPatternRule
        pattern="^.{1,30}$"
        message="30자 까지 등록 가능"/>
    </DxColumn>
     
    <DxMasterDetail
      :enabled="true"
      template="master-detail"/>
    <template #master-detail="{ data }">
      <History :master-detail-data="data"
              :url= "`/api/erp/EMMG/selectHistory`"
              :keyName="code"
              :columns="historyColumns"/>
    </template>

    <template #colorsItemTemplate="{ data }">
      <div v-if="data != null">
        <img class="status-icon middle" :src="require('@/assets/colors/' + data.ID + '.png')">
        <span class="middle">{{ data.ID }}</span>
      </div>
    </template>
    
    <template #colorsCellTemplate="{ data }">
      <div v-if="data.data.color != null">
        <img class="status-icon-cell middle" :src="require('@/assets/colors/' + data.data.color + '.png')">
        <span class="middle">{{ data.data.color }}</span>
      </div>           
    </template>

    <template #image-cell-template="{data}">
       <img v-if="data.data.irSpecImageFileId"
            class='irSpecImageFileId' 
            :src="getImageURL(data.data.irSpecImageFileId)"/>
     </template>

    <template #image-edit-template="{data}">
      <div>
        <img v-if="selectedIrSpecFileId" 
            class='irSpecImageFileId'
            :src="getImageURL(selectedIrSpecFileId)"/>
        <div class="actionContainer">
          <DxButton v-if="selectedIrSpecFileId"
                text="삭제"
                class="button"
                icon="remove"
                width="90"
                @click="deleteIrSpecImage(data)"/>
          <DxFileUploader
              width="400"
              name="file"
              :ref="fileUploaderIrSpecRefName"
              :uploadHeaders="irSpecUploadHeader"
              @uploaded="onUploadedIrSpecIamge($event, data)"
              upload-url="/api/erp/EMMG/uploadIrSpecImage"
              select-button-text="이미지 선택"
              accept="image/*"
              :max-file-size="4800000"/>
        </div>
      </div> 
     </template>  

      <template #file-cell-template="{ data }">
        {{data.data.irTestCertFileName}}
      </template>

      <template #file-edit-template="{data}">
        <div>
          <div class="uploadedFileName">{{selectedIrTestCertFileName}}
            <DxButton v-if="selectedIrTestCertFileName"
              class="button"
              text="삭제"
              icon="remove"
              width="90"
              @click="deleterTestCertFileId(data)"/>
          </div>
          <DxFileUploader
            :ref="fileUploaderIrTestCertRefName"
            width="400"
            name="file"
            :uploadHeaders="irTestCertUploadHeader"
            @uploaded="onUploadedIrTestCert($event, data)"
            upload-url="/api/erp/EMMG/uploadIrTestCert"
            select-button-text="PDF 파일 선택"
            accept=".pdf"
            :max-file-size="4800000"/>
        </div>
      </template>  

      <template #text-area-template="{ data }">
        <DxTextArea
          :auto-resize-enabled="true"
          :read-only="true"
          :value="data.value"
        />
      </template>
  </DxDataGrid>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxLookup,
          DxRequiredRule,
          DxPatternRule,
          DxMasterDetail
        } from "devextreme-vue/data-grid";
  import { DxFileUploader } from "devextreme-vue/file-uploader";     
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  import {materialUnit,
          colors} from "../../utils/syscodedata";
  import History from "../_common/SIMPLE_HISTORY";
  import DxButton from "devextreme-vue/button";
  import DxTextArea from 'devextreme-vue/text-area';
  
  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxLookup,
      DxRequiredRule,
      DxPatternRule,
      DxMasterDetail,
      History,
      DxFileUploader,
      DxButton,
      DxTextArea
    },
  computed: {
      grid: function () { return this.$refs[this.gridRefName].instance; },
      fileUploaderIrSpec: function () { return this.$refs[this.fileUploaderIrSpecRefName].instance; },
      fileUploaderIrTestCert: function () { return this.$refs[this.fileUploaderIrTestCertRefName].instance; }
    },
    data() {
      return {
        gridRefName:'grid',
        dataSource: dsu.getComDSGrid('/api/erp/EMMG', 'code', '', '자재 코드'),
        materialUnit: materialUnit,
        materialCategory: [],
        useFlag: [{'ID': 'Y', 'Name': 'Y'}, {'ID': 'N', 'Name': 'N'}],
        colors: colors,
        selectedIrSpecFileId: '',
        selectedIrTestCertFileName: '',
        fileUploaderIrTestCertRefName: 'fileUploaderIrTestCert',
        fileUploaderIrSpecRefName: 'fileUploaderIrSpec',
        irSpecUploadHeader: { 'token': dsu.getHeaders().token, 'userId': dsu.getHeaders().userId, 'fileType': 'IR_SPEC_IMAGE' },
        irTestCertUploadHeader: { 'token': dsu.getHeaders().token, 'userId': dsu.getHeaders().userId, 'fileType': 'IR_TEST_CERT'},
        editorOptions: { itemTemplate: 'colorsItemTemplate'},
        historyColumns: [{dataField: "transTime", caption : "변경 시간"},
                         {dataField: "name", caption : "자재 명"},
                         {dataField: "category", caption : "품목"},
                         {dataField: "unit", caption: "단위" },
                         {dataField: "use", caption: "사용" },
                         {dataField: "color", caption : "색상", cellTemplate : "colorsCellTemplate"},
                         {dataField: "irViSpec", caption: "외관 검사 기준"},
                         {dataField: "irSamplingSpec", caption: "Sampling 기준"},
                         {dataField: "irRemarks", caption: "검사성적서 비고"},
                         {dataField: "irViSampleQty", caption: "외관 검사 시료 수"},
                         {dataField: "irMiSampleQty", caption: "측정 검사 시료 수"},
                         {dataField: "irMeasureEquipment", caption: "측정기"},
                         {dataField: "irTestAgency", caption: "성능시험 기관"},
                         {dataField: "irTestDate", caption: "성능시험 일자" },
                         {dataField: "irTestCertFileId", caption: "성능시험 성적서", cellTemplate : "fileIdCellTemplate"},
                         {dataField: "irSpecImageFileId", caption: "검사 기준 이미지", cellTemplate: "imageCellTemplate" },
                         {dataField: "irDocNo", caption: "검사성적서 문서번호" },
                         {dataField: "userId", caption: "사용자" },
                         {dataField: "dml", caption : "Action"}]
      };
    },
    async mounted(){
      await dsu.getAxiosMaterialCategoryList().then(res => {this.materialCategory = res.data.data});
    },
  methods: {
    onEditorPreparing: function (e) { 
      if (e.dataField == 'irViSpec'){
          e.editorName = 'dxTextArea';
          e.editorOptions.height = 70;
          e.editorOptions.maxLength = 50;
      }
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'copy',
          text: '성적서 정보 복사(품목 전체)',
          stylingMode: "outlined",
          onClick: this.copyIRInfo.bind(this)
        }
      });
    },
    onExporting(e) {
      e.fileName = this.$route.meta.transName;
    },
    getImageURL(fileId) {
      return encodeURI('/api/displayImage?fileId=' + fileId);
    },
    onUploadedIrSpecIamge(e, data) {
      const response = JSON.parse(e.request.responseText);
      if (response.result == 'SUCCESS') {
        data.setValue(response.msg, response.msg);
        this.selectedIrSpecFileId = response.msg;
      } else {
        fn.notifyError('업로드 실패');
        e.component.option('uploadedMessage', "업로드 실패");
      }
      e.component.reset();
    },
    onUploadedIrTestCert(e, data) {
      const response = JSON.parse(e.request.responseText);
      if (response.result == 'SUCCESS') {
        const sepPos = response.msg.lastIndexOf('/');
        var fileInfo = response.msg.split('/');
        let fileId = fileInfo[fileInfo.length - 1];
        this.selectedIrTestCertFileName = response.msg.substring(0, sepPos);
        data.setValue(fileId, fileId);
      } else {
        fn.notifyError('업로드 실패');
        e.component.option('uploadedMessage', "업로드 실패");
      }
      e.component.reset();
    },
    onInitNewRow() {
      this.selectedIrSpecFileId = '';
      this.selectedIrTestCertFileName = '';
    },
    deleteIrSpecImage(data) {
      data.setValue('');
      this.selectedIrSpecFileId = '';
      this.fileUploaderIrSpec.reset();
    },
    onEditingStart(e) {
      this.selectedIrSpecFileId = e.data.irSpecImageFileId;
      this.selectedIrTestCertFileName = e.data.irTestCertFileName;
    },
    onDblClick(e){
      fn.downLoadFileFromId(e);
    },
    deleterTestCertFileId(data) {
      data.setValue('');
      this.selectedIrTestCertFileName = '';
      this.fileUploaderIrTestCert.reset();
    },
    copyIRInfo(e) {
      let selectedRows = this.grid.getSelectedRowsData();
      if(selectedRows.length == 0){
        fn.notifyError('원본 자재 선택 후 진행 필요');
        return;
      }
      e.component.option('disabled', true);
      var params = {};
      params.code = selectedRows[0].code;
 
      dsu.getAxiosOBJPost('/api/erp/EMMG/copyIRInfo', params).then(res => {
        e.component.option('disabled', false);
        let response = res.data;
        if (response.result == 'SUCCESS') {
          fn.notifySuccess(response.msg);
          this.grid.refresh();
        }
        else {
          fn.notifyError(response.msg);
        }
      });
    }
  }
  };
</script>

<style lang="scss">
    #gridContainer {
      height: calc( 100vh - 50px );
      width: 100%;
    }

    .status-icon-cell {
      height: 25px;
      display: inline-block;
      margin-right: 8px;
    }

    .middle {
      vertical-align: middle;
    }

    .irSpecImageFileId {
      max-width: 350px;
    }

    .actionContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    
    .uploadedFileName{
      width: 100%;
      padding-left: 12px;
      padding-top: 10px;
      font-size: 16px;
      font-weight: bold;
      .button{
        margin-left: 10px;
      }
    }
  </style>