<template>
  <DxDataGrid
    id="gridContainer"
    :ref="gridRefName"
    :data-source="unitPriceList"
    :columnAutoWidth="true"
    :show-borders="true"
    :show-column-lines="true"
    :selection="{ mode: 'single' }">
    
    <DxSearchPanel :visible="true"/>
    <DxHeaderFilter :visible="true"/>
    <DxScrolling mode="infinite"/>
    <DxSorting mode="multiple"/>

    <DxColumn
      data-field="item"
      caption="품명"
      :header-filter="{ allowSearch: true }"/>

    <DxColumn
      data-field="unitPriceYear"
      :caption="captionCYear"
      :format="{precision: 3}"
      :header-filter="{ allowSearch: true }"/>

    <DxColumn
      data-field="unitPriceP2"
      :caption="captionP2Month"
      :format="{precision: 3}"
      :header-filter="{ allowSearch: true }"/>
    
    <DxColumn
      data-field="unitPriceP1"
      :caption="captionP1Month"
      :format="{precision: 3}"
      :header-filter="{ allowSearch: true }"/>
    
    <DxColumn
      data-field="unitPrice"
      :caption="captionCMonth"
      :format="{precision: 3}"
      :header-filter="{ allowSearch: true }"/>

    <DxColumn
      data-field="gapYear"
      caption="고정 단가 차이(원)"
      cell-template="gapYearCellTemplate"
      :header-filter="{ allowSearch: true }"/>

    <DxColumn
      data-field="gapMonth"
      caption="이전 단가 차이(원)"
      cell-template="gapMonthCellTemplate"
      :header-filter="{ allowSearch: true }"/>
    
    <DxColumn
      data-field="gapMonthPercent"
      caption="이전 단가 차이(%)"
      cell-template="gapMonthPercentCellTemplate"
      :header-filter="{ allowSearch: true }"/>

    <DxMasterDetail
      :enabled="true"
      template="master-detail"/>
    
    <template #master-detail="{ data }">
      <Detail :master-detail-data="data"
              :url= "`/api/erp/ERUP/selectUnitPriceInfoMonthDetail`"
              :keyName="targetMonth"
              :conKeyName1="'item'"
              :conKeyValue1="data.data.item"
              :columns="detailColumns"/>
    </template> 
  
    <template #gapYearCellTemplate="{ data }">
      <div v-if="!data.data.gapYear">
        {{data.data.gapYear}}
      </div>
      <div v-else-if="data.data.gapYear > 0" class="unitPricePlus">
        {{data.data.gapYear.toLocaleString("ko-KR", "currency")}}
      </div>
      <div v-else class="unitPriceMinus">
        {{data.data.gapYear.toLocaleString("ko-KR", "currency")}}
      </div>
    </template>

    <template #gapMonthCellTemplate="{ data }">
      <div v-if="!data.data.gapMonth">
        {{data.data.gapMonth}}
      </div>
      <div v-else-if="data.data.gapMonth > 0" class="unitPricePlus">
        {{data.data.gapMonth.toLocaleString("ko-KR", "currency")}}
      </div>
      <div v-else class="unitPriceMinus">
        {{data.data.gapMonth.toLocaleString("ko-KR", "currency")}}
      </div>
    </template>

       <template #gapMonthPercentCellTemplate="{ data }">
      <div v-if="!data.data.gapMonthPercent">
        {{data.data.gapMonthPercent}}
      </div>
      <div v-else-if="data.data.gapMonthPercent > 0" class="unitPricePlus">
        {{data.data.gapMonthPercent.toLocaleString("ko-KR", "currency")}}
      </div>
      <div v-else class="unitPriceMinus">
        {{data.data.gapMonthPercent.toLocaleString("ko-KR", "currency")}}
      </div>
    </template>
    
    
    
  </DxDataGrid>
</template>

<script>




  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxHeaderFilter,
          DxSearchPanel,
          DxMasterDetail} from "devextreme-vue/data-grid";
  import Detail from "../_common/DETAIL";
  import dsu from "../../utils/dataSourceUtils";

  var cDate  = new Date();
  var p1Date  = new Date();
  var p2Date  = new Date();

  cDate.setMonth(cDate.getMonth() + 1);
  var cMonth = cDate.getMonth();

  p1Date.setMonth(p1Date.getMonth());
  var p1Month = p1Date.getMonth();

  p2Date.setMonth(p2Date.getMonth() - 1);
  var p2Month = p2Date.getMonth();

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxHeaderFilter,
      DxSearchPanel,
      DxMasterDetail,
      Detail
    },
    data() {
      return {
        unitPriceList: [],
        gridRefName: 'grid',
        captionCYear: cDate.getFullYear() + '년 고정 단가(원)' ,
        captionP2Month: p2Month + '월 단가(원)',
        captionP1Month: p1Month + '월 단가(원)',
        captionCMonth: cMonth + '월 단가(원)',
        detailColumns: [{dataField : "targetMonth", caption : "년/월"},
                        {dataField : "unitPrice", caption : "단가(원)", format: {precision: 3}},
                        {dataField : "gap", caption : "이전 단가 차이(원)", format: {precision: 3}}]
      };
    }, 
    mounted(){
      this.refreshData();
    },
    computed: {
      grid: function () {return this.$refs[this.gridRefName].instance;}
    },
    methods:{
      refreshData(){
        dsu.getAxiosOBJGet('/api/mes/MVUP/selectLastUnitPriceListMonth').then(res => {
          this.unitPriceList = res.data.data;
        });
      }
    }
  };
</script>

<style scoped>
  #gridContainer {
    height: calc( 100vh - 50px );
    width: 100%;
  }

  .unitPricePlus{
    color: red;
  }

  .unitPriceMinus{
    color: blue;
  }
</style>