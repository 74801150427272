<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <ProjectList @loadSelectedData="loadSelectedData"/>
      
      <DxForm id="form">
        <DxGroupItem caption="제품 정보" > 
        </DxGroupItem>
      </DxForm>

      <DxDataGrid
        id="gridContainer"
        :ref="gridRefName"
        :columnAutoWidth="true"
        :show-borders="true"
        :show-column-lines="true"
        :selection="{ mode: 'single' }">
        
        <DxSearchPanel :visible="true"/>
        <DxHeaderFilter :visible="true"/>
        <DxSorting mode="multiple"/>

        <DxColumn data-field="code" caption="제품 코드" />
        <DxColumn data-field="name" caption="제품 명" />
        <DxColumn data-field="grade" caption="급(관급/도급/사급)" />  
        <DxColumn data-field="fireproof" caption="내화" />
        <DxColumn data-field="spec" caption="사양" />
        <DxColumn data-field="insulation" caption="단열재 여부" />
        <DxColumn data-field="insulationCategory" caption="단열 종류" />  
        <DxColumn data-field="defaultInsulationThickness" caption="기본 단열 두께" />
      </DxDataGrid>

      <DesignManager 
        :ref="designManagerRefName"
        :showAdding="true"
        :showUpdating="true"
        :showDeleting="true"
        :requireSeq="false"
        :allowEditingDesignStage="true"
        :allowEditingRequest="true"
        :allowEditingDesign="false"
        :allowEditingDelivery="false"
      />
    </DxScrollView>
  </div>
</template>

<script>
  import DxScrollView from "devextreme-vue/scroll-view";
  import ProjectList from "../_common/PROJECT_LIST";
  import DesignManager from "../_common/DESIGN_MANAGER";
  import {DxDataGrid,
          DxColumn,
          DxSorting,
          DxHeaderFilter,
          DxSearchPanel} from "devextreme-vue/data-grid";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  import {DxForm,
          DxGroupItem} from "devextreme-vue/form";

  export default {
    components:{
      DxScrollView,
      ProjectList, 
      DesignManager,
      DxDataGrid,
      DxColumn,
      DxSorting,
      DxHeaderFilter,
      DxSearchPanel,
      DxForm,
      DxGroupItem
    },    
    data(){
      return {
        projectInfo: {},
        gridRefName: 'grid',
        designManagerRefName: 'design'
      };
    },
    computed: {
      grid: function () { return this.$refs[this.gridRefName].instance; }
    },    
    methods:{
      loadSelectedData(params){
        params.step = this.step;
        this.projectInfo = params;
        this.$refs[this.designManagerRefName].refreshData(params);

        dsu.getAxiosOBJGet('/api/erp/PREP/selectProjectProductData', params).then(res =>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            this.grid.option('dataSource', response.data);  
          }else{
            fn.notifyError(response.msg);
          }
        });
      }
    }
  };
</script>

<style scoped>
  #Container {
    height: calc( 100vh - 50px );
    width: 100%;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background-color:white;
  }

  #form{
    margin-top: 40px;
  }
</style>