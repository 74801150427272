<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <ProjectList @loadSelectedData="loadSelectedData"/>

      <DxForm id="form">
        <DxGroupItem caption="제품 정보" > 
        </DxGroupItem>
      </DxForm>

      <DxDataGrid
        id="gridContainer"
        :ref="gridRefName"
        :columnAutoWidth="true"
        :show-borders="true"
        :show-column-lines="true"
        :selection="{ mode: 'single' }"
        @toolbar-preparing="onToolbarPreparing($event)"
        @rowRemoving="rowRemoving($event)">
        
        <DxSearchPanel :visible="true"/>
        <DxHeaderFilter :visible="true"/>
        <DxScrolling mode="infinite"/>
        <DxSorting mode="multiple"/>
        <DxEditing :allow-deleting="true"/>
   
        <DxColumn type="buttons">
          <DxButton name="edit"/>
        </DxColumn>
        <DxColumn data-field="code" caption="제품 코드" />
        <DxColumn data-field="name" caption="제품 명" />
        <DxColumn data-field="grade" caption="급(관급/도급/사급)" />  
        <DxColumn data-field="fireproof" caption="내화" />
        <DxColumn data-field="spec" caption="사양" />
        <DxColumn data-field="insulation" caption="단열재 여부" />
        <DxColumn data-field="insulationCategory" caption="단열 종류" />  
        <DxColumn data-field="defaultInsulationThickness" caption="기본 단열 두께" />

        <DxMasterDetail
          :enabled="true"
          template="master-detail"/>
          <template #master-detail="{ data }">
            <ProductDetail :projectId="data.data.projectId"
                    :step="step"
                    :seq="data.data.seq"
                    :code="data.data.code"
                    :optionGroupId="data.data.optionGroupId"
                    :checkListId="data.data.checkListId"/>
           </template>    
      </DxDataGrid>

      <DxPopup :ref="popupRefName"
            :drag-enabled="false"
            :close-on-outside-click="true"
            :show-title="true"
            :title="'제품 추가'">
        <ProductManager 
          :ref="productManagerRefName"
          :showEditing="false"
          :showCheckListId="false"
          :showOptionGroupId="false"
          :showExport= "false"
          :showHistory="false"
          @rowDblClick="addProduct"/>
      </DxPopup>

      <DxToolbar id="toolbar">
        <DxItem 
          widget="dxButton"
          :options="{type: 'custom', icon: 'save', text: '완료', stylingMode: 'outlined', onClick: this.complete.bind(this)}"
          location="center"/>
        <DxItem
          widget="dxButton"
          :options="{type: 'custom', icon: 'remove', text: '미 완료', stylingMode: 'outlined', onClick: this.inComplete.bind(this)}"
          location="center"/>
      </DxToolbar>
    </DxScrollView>
  </div>
</template>

<script>
  import DxScrollView from "devextreme-vue/scroll-view";
  import ProjectList from "../_common/PROJECT_LIST";
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxMasterDetail} from "devextreme-vue/data-grid";  
  import {DxForm,
          DxGroupItem} from "devextreme-vue/form";
  import DxToolbar,
         {DxItem} from "devextreme-vue/toolbar";
  import DxPopup from "devextreme-vue/popup";
  import ProductManager from "../../views/_common/PRODUCT_MANAGER";
  import ProductDetail from "../../views/_common/PRODUCT_DETAIL";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  export default {
    components:{
      DxScrollView,
      ProjectList,
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxMasterDetail,
      DxForm,
      DxGroupItem,
      DxToolbar,
      DxItem,
      DxPopup,
      ProductManager,
      ProductDetail
    },    
    data(){
      return {
        step: 3,
        gridRefName: 'grid',
        popupRefName: 'popup',
        productManagerRefName: 'projectManager',

        projectInfo: {}
      };
    }, 
    computed: {
      grid: function () { return this.$refs[this.gridRefName].instance; },
      popup: function () { return this.$refs[this.popupRefName].instance; },
      productManager: function () { return this.$refs[this.productManagerRefName]; } 
    },
    methods:{
      onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
          {location: 'before',
          widget: 'dxButton',
          options: {
            icon: 'add',
            text: '제품 추가',
            stylingMode:"outlined",
            onClick: this.showAddProduct.bind(this)
          }}
        );
      },      
      loadSelectedData(params){
        this.reset();
        params.step = this.step;
        this.projectInfo = params;

        dsu.getAxiosOBJGet('/api/erp/PREP/selectProjectProductData', params).then(res =>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            this.grid.option('dataSource', response.data);  
          }else{
            fn.notifyError(response.msg);
          }
        });
      },
      showAddProduct(){
        this.productManager.clearFilter();
        this.popup.show();
      },
      addProduct(data){
        this.popup.hide();
        if(!this.projectInfo.projectId){
          fn.notifyError('Project 선택 후 진행 필요');
          return;
        }

        var params = {};
        params.projectId = this.projectInfo.projectId;
        params.step = this.step;
        params.code = data.code;
        
        dsu.getAxiosOBJGet('/api/erp/PREP/insertProjectProductData', params).then(res => {
            let response = res.data;
            if(response.result == 'SUCCESS'){
              this.loadSelectedData(this.projectInfo);
              fn.notifySuccess(response.msg);
            }else {
              fn.notifyError(response.msg);
            }
        });
      },
       rowRemoving(e){
        var params = {};
        params.projectId = this.projectInfo.projectId;
        params.step = this.step;
        params.seq = e.data.seq;
        
        e.cancel = true; 
        dsu.getAxiosOBJGet('/api/erp/PREP/deleteProjectProductData', params).then( res => {
            let response = res.data;
            if(response.result == 'SUCCESS'){
              this.loadSelectedData(this.ProjectInfo);
              fn.notifySuccess(response.msg);
            }else {
              fn.notifyError(response.msg);
            }
        });
      },
      complete(){
        if(!this.projectInfo.projectId){
          fn.notifyError('Project 선택 후 진행 필요');
          return;
        }

        if (this.grid.option('dataSource').length == 0){
          fn.notifyError('제품 등록 후 진행 필요');
          return;
        }

        var params = this.projectInfo;
        params.step = this.step;
        params.projectDatas = JSON.stringify({status: 'COMPLETE'});
        
        
        dsu.getAxiosOBJPost('/api/erp/PREG/updateProjectData', params).then(res => {
            let response = res.data;
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);
            }else {
              fn.notifyError(response.msg);
            }
        });
        
      },
      inComplete(){
        if(!this.projectInfo.projectId){
          fn.notifyError('Project 선택 후 진행 필요');
          return;
        }

        var params = this.projectInfo;
        params.step = this.step;
        params.projectDatas = JSON.stringify({status: 'INCOMPLETE'});
        
        dsu.getAxiosOBJPost('/api/erp/PREG/updateProjectData', params).then(res => {
            let response = res.data;
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);
            }else {
              fn.notifyError(response.msg);
            }
        });
      },
      reset(){
        this.grid.option('dataSource', []);  
      }
    }
  };
</script>

<style scoped>
  #Container {
      height: calc( 100vh - 50px );
      width: 100%;
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      background-color:white;
  }

  #form{
    margin-top: 40px;
  }

  #toolbar{
    margin-top: 30px;
  }

  #masterDetail{
    background-color: white;
  }
</style>