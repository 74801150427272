<template>
  <div class="login_form_container">
    <form id="from" class="login-form" @submit.prevent="onSubmit()">
      <DxForm :form-data="formData" :disabled="loading">
        <DxItem
          data-field='current'
          editor-type='dxTextBox'
          :editor-options="{ stylingMode: 'filled', placeholder: '현재 비밀번호', mode: 'password' }">
          <DxRequiredRule message="현재 비밀번호 필수 입력" />
          <DxLabel :visible="false"/>
        </DxItem>

        <DxItem
          data-field='new'
          editor-type='dxTextBox'
          :editor-options="{ stylingMode: 'filled', placeholder: '새 비밀번호', mode: 'password' }">
          <DxRequiredRule message="새 비밀번호 필수 입력" />
          <DxLabel :visible="false"/>
        </DxItem>

        <DxItem
          data-field='newAgain'
          editor-type='dxTextBox'
          :editor-options="{ stylingMode: 'filled', placeholder: '새 비밀번호 확인', mode: 'password' }">
          <DxRequiredRule message="새 비밀번호 확인 필수 입력" />
          <DxLabel :visible="false"/>
        </DxItem>
    
        <DxButtonItem>
          <DxButtonOptions
            width="100%"
            type="default"
            template="signInTemplate"
            :use-submit-behavior="true">
          </DxButtonOptions>
        </DxButtonItem>
        <template #signInTemplate>
          <div>
            <span class="dx-button-text">
              <dx-load-indicator v-if="loading" width="24px" height="24px" :visible="true" />
              <span v-if="!loading">비밀번호 변경</span>
            </span>
          </div>
        </template>
      </DxForm>
    </form>
  </div>
</template>

<script>
import auth from "../auth";
import fn from "../utils/function";
import DxLoadIndicator from "devextreme-vue/load-indicator";
import DxForm, {
  DxItem,
  DxRequiredRule,
  DxLabel,
  DxButtonItem,
  DxButtonOptions
} from "devextreme-vue/form";


export default {
  data() {
    return {
      formData: {},
      loading: false
    };
  },
  methods: {
     onSubmit: async function() {
      this.loading = true;
      
      if(this.formData.new != this.formData.newAgain){
        fn.notifyError('변경 비밀번호가 일치하지 않습니다.');
        this.loading = false;
        return;
      }

      await auth.changePassword(this.formData)
                .then( result => {
                                    if(result) {
                                        this.$router.push("/mes/home");
                                    }
                                    this.loading = false;
                                  }
                      );
     }
  },
  components: {
    DxLoadIndicator,
    DxForm,
    DxRequiredRule,
    DxItem,
    DxLabel,
    DxButtonItem,
    DxButtonOptions
  }
}
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

#from{
  width: 400px;
  display: inline-block;
  background-color: white;
  padding: 20px;
}

.login_form_container{
  text-align: center;
  margin-top: 30px;
}

.login-form {
  .link {
    text-align: center;
    font-size: 16px;
    font-style: normal;

    a {
      text-decoration: none;
    }
  }

  .form-text {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  }

}


</style>
