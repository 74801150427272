<template>
  <div id="gridContainer">
    <DxToolbar>
      <DxItem :options="dateBoxOptionsStart"
              location="center"
              locate-in-menu="auto"
              widget="dxDateBox"/>        
      <DxItem :options="dateBoxOptionsEnd"
              location="center"
              locate-in-menu="auto"
              widget="dxDateBox"/>   
      <DxItem :options="buttonOptionsSearch"
              location="center"
              locate-in-menu="never"
              widget="dxButton"/>                    
    </DxToolbar>

    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :show-borders="true"
      :show-column-lines="true"
      :columnAutoWidth="true"
      :focused-row-enabled="true"
      @exporting="onExporting">

      <DxSearchPanel :visible="true"/>
      <DxHeaderFilter :visible="true"/>
      <DxScrolling mode="infinite"/>
      <DxSorting mode="multiple"/>
      <DxExport :enabled="true"/>
      <DxColumn
        data-field="userId"
        caption="ID">
      </DxColumn>
      
      <DxColumn
        data-field="userName"
        caption="이름">
      </DxColumn>
   
      <DxColumn
        data-field="branch"
        caption="지사">  
      </DxColumn>
    
      <DxColumn
        data-field="dept"
        caption="부서">  
      </DxColumn>
    
      <DxColumn
        data-field="loginCnt"
        caption="로그인 횟수">
      </DxColumn>

      <DxMasterDetail
        id='detail'
        :enabled="true"
        template="master-detail"/>
        <template #master-detail="{data}">
          <Detail :master-detail-data="data"/>
        </template>      
    </DxDataGrid>
   
  </div>
</template>

<script>
  import DxToolbar, {DxItem} from "devextreme-vue/toolbar";
  import {DxDataGrid,
          DxScrolling,
          DxColumn,
          DxSorting,
          DxSearchPanel,
          DxHeaderFilter,
          DxExport,
          DxMasterDetail,} from "devextreme-vue/data-grid";
  import Detail from "../_common/LOGIN_HST_DETAIL";
  import fn from "../../utils/function";
  import dsu from "../../utils/dataSourceUtils";

  var endDate = new Date();
  var startDate = new Date();
  startDate.setDate(endDate.getDate() - 7);
  var defaultStartDate = startDate;
  var defaultEndDate = endDate;
 
  export default {
    components: {
      DxToolbar,
      DxItem,
      DxDataGrid,
      DxScrolling,
      DxColumn,
      DxSorting,
      DxSearchPanel,
      DxHeaderFilter,
      DxExport,
      DxMasterDetail,
      Detail
    },
    data() {
      return {
        gridRefName: 'dataGrid',
        searchCondition: {startDate: defaultStartDate, endDate: defaultEndDate},
        loginHstList: [],
        dateBoxOptionsStart: {
          width: 180,
          value: defaultStartDate,
          text: "시작",
          displayFormat:" 시작 :  yyyy/MM/dd",
          onValueChanged: (args) => {
            this.searchCondition.startDate = args.value;
          }
        },   
        dateBoxOptionsEnd: {
          width: 180,
          value: defaultEndDate,
          text: "시작",
          displayFormat:" 종료 :  yyyy/MM/dd",
          onValueChanged: (args) => {
            this.searchCondition.endDate = args.value;
          }
        },
        buttonOptionsSearch: {
          icon: 'search',
          text: '조회',
          stylingMode: "outlined",
          onClick: this.search.bind()
        }       
      };
    },
    methods: {
      search(){
        var params = JSON.parse(JSON.stringify(this.searchCondition));
        params.startDate = dsu.getFormatDate(this.searchCondition.startDate);
        params.endDate = dsu.getFormatDate(this.searchCondition.endDate);

        dsu.getAxiosOBJGet('/api/mes/MVUL/selectLoginCntByUser', params).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            this.setGridData(response.data);
          }else{
            fn.notifyError(response.msg);
          }
        });
      },
      onExporting(e) {
        e.fileName = this.$route.meta.transName;
      },
      setGridData(data)
      {
        const grid = this.$refs[this.gridRefName].instance;
        grid.option('keyExpr', 'userId');
        grid.option('dataSource', data);
      }
    }   
  };
</script>

<style scoped>
  #gridContainer {
    height: calc( 100vh - 50px );
    width: 100%;}
  #grid {
    width: 100%;
    height: calc( 100vh - 101px )}
  #detail {
    width: 100%;}
</style>