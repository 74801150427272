<template>
  <DxDataGrid
    id="gridContainer"
    :data-source="productSpecInfoList"
    :show-borders="true"
    :show-column-lines="true"
    :columnAutoWidth="true"
    :selection="{ mode: 'single' }"
    @row-inserting="onRowInserting"
    @row-updating="onRowUpdating"
    @exporting="onExporting"
    @toolbar-preparing="onToolbarPreparing($event)">
    
    <DxSearchPanel :visible="true"/>
    <DxHeaderFilter :visible="true"/>
    <DxScrolling mode="infinite"/>
    <DxSorting mode="multiple"/>
    <DxExport :enabled="true"/>
    <DxEditing
      :allow-updating="true"
      :allow-adding="true"
      :allow-deleting="true"
      mode="popup"/>
   
    <DxColumn type="buttons">
      <DxButton name="edit"/>
    </DxColumn>

    <DxColumn
      data-field="product"
      caption="제품">  
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^[\S*].{1,28}[\S*]$|^\S{1,30}$"
        message="30자까지 입력 가능, 좌우 공백 불가"/>
    </DxColumn>

    <DxColumn
      data-field="productSpec"
      caption="제품 제원">  
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^[\S*].{1,28}[\S*]$|^\S{1,30}$"
        message="30자까지 입력 가능, 좌우 공백 불가"/>
    </DxColumn>

    <DxColumn
      data-field="firstTapagiRadius"
      caption="최초 타파기 반지름(R)">  
      <DxPatternRule
        pattern="^[\S*].{1,28}[\S*]$|^\S{1,30}$"
        message="30자까지 입력 가능, 좌우 공백 불가"/>
    </DxColumn>

    <DxColumn
      data-field="tapagiRadius"
      caption="타파기 반지름(R)">  
      <DxPatternRule
        pattern="^[\S*].{1,28}[\S*]$|^\S{1,30}$"
        message="30자까지 입력 가능, 좌우 공백 불가"/>
    </DxColumn>

    <DxColumn
      data-field="tapagiSafetyFactor"
      caption="타파기 안전율(R)">  
      <DxPatternRule
        pattern="^[\S*].{1,28}[\S*]$|^\S{1,30}$"
        message="30자까지 입력 가능, 좌우 공백 불가"/>
    </DxColumn>

    <DxColumn
      data-field="naturalLoadDeflection"
      caption="자연하중 처짐">  
      <DxPatternRule
        pattern="^[\S*].{1,28}[\S*]$|^\S{1,30}$"
        message="30자까지 입력 가능, 좌우 공백 불가"/>
    </DxColumn>
    
    <DxColumn
      data-field="roll3Bending"
      caption="3롤 밴딩기">  
      <DxPatternRule
        pattern="^[\S*].{1,28}[\S*]$|^\S{1,30}$"
        message="30자까지 입력 가능, 좌우 공백 불가"/>
    </DxColumn>

    <DxColumn
      data-field="naturalDeflection"
      caption="자연 처짐">  
      <DxPatternRule
        pattern="^[\S*].{1,28}[\S*]$|^\S{1,30}$"
        message="30자까지 입력 가능, 좌우 공백 불가"/>
    </DxColumn>

    <DxColumn
      data-field="remark"
      caption="비고">  
      <DxPatternRule
        pattern="^[\S*].{1,98}[\S*]$|^\S{1,100}$"
        message="100자까지 입력 가능, 좌우 공백 불가"/>
    </DxColumn>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxRequiredRule,
          DxPatternRule}from "devextreme-vue/data-grid";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxRequiredRule,
      DxPatternRule},
    data(){
      return {
        productSpecInfoList: []
      };
    },
    async mounted(){
      await this.refreshData();
    },
    methods:{
        onToolbarPreparing(e) {
          e.toolbarOptions.items.unshift(
           {location: 'before',
            widget: 'dxButton',
            options: {
              width: 100,
              icon: 'save',
              text: '저장',
              stylingMode:"outlined",
              onClick: this.save.bind(this)
            }}
          );
        },
      refreshData(){
        dsu.getAxiosOBJGet('/api/erp/EPSI/select').then(res => {
          this.productSpecInfoList = res.data.data;
        });
      },
      onRowInserting(e){
        if(!this.validationValue(e.data, true)){
           e.cancel = true;
        }
      },
      onRowUpdating(e){
        let data = {};
        let chkKey = false;

        Object.assign(data, e.oldData);
        Object.assign(data, e.newData);
      
        if((e.newData.product &&  e.newData.product != e.oldData.product)
          || (e.newData.productSpec &&  e.newData.productSpec != e.oldData.productSpec)){
           chkKey = true;
        }

        if(!this.validationValue(data, chkKey)){
           e.cancel = true;
        }
      },
      onExporting(e){
        e.fileName = this.$route.meta.transName;
      },
      validationValue(data, chkKey){
        for (var i = 0; i < this.productSpecInfoList.length; i++) {
          if(chkKey){
            if (data.product == this.productSpecInfoList[i].product 
                && data.productSpec == this.productSpecInfoList[i].productSpec){
                  fn.notifyError('중복 데이터, 제품, 제품 제원 동일 입력 값 확인필요');
                  return false;
            }
          }
        }
        return true;
      },
      save(){
          if(this.productSpecInfoList.length == 0)
          {
            fn.notifyError('강판 시공 정보 입력 후 진행 필요');
            return;
          }

          dsu.getAxiosOBJPost('/api/erp/EPSI/update', {productSpecInfoList: JSON.stringify(this.productSpecInfoList)}).then(res => {
              let response = res.data;

              if(response.result == 'SUCCESS'){
                fn.notifySuccess(response.msg);
                this.refreshData;
              }else {
                fn.notifyError(response.msg);
              }
          });
      }
    }
  };
</script>

<style scoped>
  #gridContainer {
      height: calc( 100vh - 50px );
      width: 100%;
  }
</style>
