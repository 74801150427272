<template>
  <div id="Container">
    <DxScrollView direction="vertical">
      <ProjectList @loadSelectedData="loadSelectedData"/>

      <DesignManager 
        :ref="designManagerRefName"
        :showAdding="false"
        :showUpdating="true"
        :showDeleting="false"
        :requireSeq="true"
        :allowEditingRequest="false"
        :allowEditingDesign="false"
        :allowEditingDelivery="true"
      />
    </DxScrollView>
  </div>
</template>

<script>
  import DxScrollView from "devextreme-vue/scroll-view";
  import ProjectList from "../_common/PROJECT_LIST";
  import DesignManager from "../_common/DESIGN_MANAGER";

  export default {
    components:{
      DxScrollView,
      ProjectList,
      DesignManager
    },    
    data(){
      return {
        projectInfo: {},
        designManagerRefName: 'design'
      };
    },
    methods:{
      loadSelectedData(params){
        params.step = this.step;
        this.projectInfo = params;
        this.$refs[this.designManagerRefName].refreshData(params);
      }
    }
  };
</script>

<style scoped>
  #Container {
    height: calc( 100vh - 50px );
    width: 100%;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background-color:white;
  }
</style>