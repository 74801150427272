<template>
   <header class="header-component">
    <dx-toolbar class="header-toolbar">
      <dx-item
        :visible="menuToggleEnabled"
        location="before"
        css-class="menu-button"
      >
        <template #default>
          <dx-button
            icon="menu"
            styling-mode="text"
            @click="toggleMenuFunc"
          />
        </template>
      </dx-item>
      <dx-item
        location="before"
        css-class="transName"
      >
        <template #default>
            <div class='description'>{{functionName}} </div> 
        </template>

      </dx-item>
      <dx-item
        location="after"
        locate-in-menu="auto"
        menu-item-template="menuUserItem"
      >
        <template #default>
          <div>
            <dx-button
              class="user-button authorization"
              height="100%"
              styling-mode="text"
            >
              <user-panel :user="user" :menu-items="userMenuItems" menu-mode="context" />
            </dx-button>
          </div>
        </template>
      </dx-item>
      <template #menuUserItem>
        <user-panel
          :user="user"
          :menu-items="userMenuItems"
          menu-mode="list"
        />
      </template>
    </dx-toolbar>
  </header>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import UserPanel from "./user-panel";
import auth from "../auth";

export default {
  props: {
    menuToggleEnabled: Boolean,
    toggleMenuFunc: Function,
    title: String,
    functionName: String
  },
  created() {
    auth.getUser().then((e) => this.user = e.data);
  },
  data() {
    return {
      user: { },
      userMenuItems: [
        {
          text: "비밀번호 변경",
          icon: "key",
          onClick: this.onChangePasswordClick
        },
        {
          text: "Logout",
          icon: "runner",
          onClick: this.onLogoutClick
        },
        {
          text: "Version : 23",
          icon: "info",
        }
      ]
    };
  },
  methods: {
    onLogoutClick() {    
      auth.logOut();
    },
    onChangePasswordClick() {
      if(this.$router.currentRoute.path != '/change-password'){
        this.$router.push({
          path: "/change-password"
        });
      }
    }
  },
  watch: {
    compactMode() {
      if (this.compactMode) {
        this.treeView.collapseAll();
      } else {
        this.updateSelection();
      }
     }
  },
  components: {
    DxButton,
    DxToolbar,
    DxItem,
    UserPanel
  }
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
@import "../dx-styles.scss";

.header-component {
  flex: 0 0 auto;
  float: top;
  z-index: 999;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  
  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    color: $base-accent;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
}
.transName
{
  width: 240px;
  .description
  {
    padding-left: 20px;
    font-size: 20px;
    font-weight: 700;
  }
}
.header-toolbar
{
  background-color: rgba(255, 255, 255, 0.78);
  z-index: 99999;
  box-shadow: 0px 1px;
}

</style>
