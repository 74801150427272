<template>
  <div id="Container">
    <DxDataGrid
      id="gridContainer"
      :ref="gridRefName"
      :data-source="supplierCategoryList"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      :selection="{ mode: 'single' }"
      @toolbar-preparing="onToolbarPreparing($event)"
      @cellDblClick="onDblClick">
    
      <DxSearchPanel :visible="true"/>
      <DxHeaderFilter :visible="true"/>
      <DxScrolling mode="infinite"/>
      <DxSorting mode="multiple"/>
  
      <DxColumn
        data-field="supplier"
        caption="공급업체"
        :header-filter="{ allowSearch: true }">
      </DxColumn>
      
      <DxColumn
        data-field="category"
        caption="품목"
        :header-filter="{ allowSearch: true }">
      </DxColumn>

      <DxColumn
        data-field="file0001"
        caption="성적서"
        alignment="center"
        cell-template="fileCellTemplate">
      </DxColumn>
     
      <template #fileCellTemplate="{ data }">
          <span v-if="data.column.dataField.includes('file') && data.data[data.column.dataField]" class="dx-icon-download"/>
      </template>
      
      <DxMasterDetail
        :enabled="true"
        template="master-detail"/>
      <template #master-detail="{ data }">
        <History :master-detail-data="data"
                 :url= "`/api/erp/ESCI/selectSupplierCategoryInfoHst`"
                 :key1="data.data.supplier"
                 :key2="data.data.category"
                 :columns="historyColumns"/>
      </template>
      
    </DxDataGrid>
    
    <DxPopup :ref="popupUploadFileRefName"
              :drag-enabled="false"
              :close-on-outside-click="true"
              :show-title="true"
              :width="400"
              :height="465"
              title="파일 등록">
  
          <form id="form"
                :ref="formRefName"
                method="post"
                action="/uploadFiles"
                enctype="multipart/form-data">
          
            <div class="fileuploader-container">
              <div class="dx-fieldset">
                <div class="dx-field">
                  <div class="dx-field-label">공급업체</div>
                  <DxSelectBox
                    :ref="supplierRefName"
                    :search-enabled="true"
                    placeholder="선택"
                    value-expr="name"
                    display-expr="name"
                    width="350"
                    :data-source="supplierList"/>
                </div> 
                <div class="dx-field">
                  <div class="dx-field-label">품목</div>
                  <DxSelectBox
                    :ref="categoryRefName"
                    :search-enabled="true"
                    placeholder="선택"
                    value-expr="category"
                    display-expr="category"
                    width="350"
                    :data-source="materialCategory"/>
                </div> 

                <div class="dx-field">
                  <DxFileUploader
                    :ref="fileUploadRefName"
                    name="file"
                    select-button-text="파일 선택"
                    upload-mode="useForm"/>
                </div>
              </div>
            </div>
            <div class='buttonContainer'>
              <DxButton
                id="btnSave"
                class="button"
                text="등록"
                icon="save"
                width="130"
                @click="fileUpload"/>
            </div>            
          </form>
        
      </DxPopup>
  </div>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxHeaderFilter,
          DxSearchPanel,
          DxMasterDetail} from "devextreme-vue/data-grid";
  import {DxSelectBox} from "devextreme-vue/select-box";
  import DxPopup from "devextreme-vue/popup";
  import {DxFileUploader} from "devextreme-vue/file-uploader";       
  import DxButton from "devextreme-vue/button";
  import History from "../_common/SIMPLE_HISTORY";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxHeaderFilter,
      DxSearchPanel,
      DxMasterDetail,
      DxSelectBox,
      DxPopup,
      DxFileUploader,
      History,
      DxButton
    },
    data() {
      return {
        supplierCategoryList: [],
        supplierList: [],
        materialCategory: [],
        gridRefName: 'gridRefName',
        formRefName: 'formRefName',
        popupUploadFileRefName: 'popupUploadFileRefName',
        supplierRefName: 'supplierRefName',
        categoryRefName: 'categoryRefName',
        fileUploadRefName: 'fileUploadRefName',
        historyColumns: [{dataField: "transTime", caption: "변경 시간"},
                         {dataField: "file0001", caption: "성적서", alignment: "center", cellTemplate: "fileCellTemplate"},
                         {dataField: "userId", caption: "사용자"},
                         {dataField: "dml", caption: "Action"}]
      };
    },
    async mounted(){
        await dsu.getAxiosMaterialCategoryList().then(res => {this.materialCategory = res.data.data});
        await dsu.getAxiosSupplierList().then(res => {this.supplierList = res.data.data});
        await this.refreshData();
    },
    computed: {
      grid: function () { return this.$refs[this.gridRefName].instance; },
      popupUpload: function() { return this.$refs[this.popupUploadFileRefName].instance; },      
      supplier: function() { return this.$refs[this.supplierRefName].instance; },
      category: function() { return this.$refs[this.categoryRefName].instance; },
      fileUploader: function() { return this.$refs[this.fileUploadRefName].instance; }
    },
    methods:{
      refreshData(){
        dsu.getAxiosOBJGet('/api/erp/ESCI/selectSupplierCategoryInfo').then(res => {
          this.supplierCategoryList = res.data.data;
        });
      },
      onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
          {location: 'before',
          widget: 'dxButton',
          options: {
            icon: 'upload',
            text: '파일 등록',
            stylingMode:"outlined",
            onClick: this.showFileUpload.bind(this)
          }},
          {location: 'before',
            widget: 'dxButton',
            options: {
            icon: 'remove',
            text: '파일 삭제',
            stylingMode:"outlined",
            onClick: this.fileDelete.bind(this)
          }}       
        );
      },
      showFileUpload(){
        this.supplier.reset();
        this.category.reset();
        this.fileUploader.reset();
        let selectedRows = this.grid.getSelectedRowsData();

        if(selectedRows.length != 0){
          this.supplier.option('value', selectedRows[0].supplier);
          this.category.option('value', selectedRows[0].category);
        }

        this.popupUpload.show();
      },
      async fileUpload(e){
        var params = {};

        params.supplier = this.supplier.option('value');
        params.category = this.category.option('value');
    
        if(!params.supplier){
           fn.notifyError('공급업체 미 선택');
           return;
        }

        if(!params.category){
           fn.notifyError('품목 미 선택');
           return;
        }
            
        var fileObj = this.fileUploader.option('value');
    
        if(fileObj.length  == 0){
           fn.notifyError('파일 미 선택');
           return;
        }
        
        if (fileObj[0].size > 104857600){
           fn.notifyError('최대용량 초과(100mb)');
           return;
        }

        const form = this.$refs[this.formRefName];
        e.component.option('disabled', true);
        await dsu.getAxiosOBJPostMultiPart('/api/erp/ESCI/uploadFile', params, form).then(res=>{
            e.component.option('disabled', false);
            let response = res.data;
            if(response.result == 'SUCCESS'){
              this.refreshData();
              fn.notifySuccess(response.msg);
              this.popupUpload.hide();
            }else{
              fn.notifyError(response.msg);
            }
        });
      },
      async fileDelete(){
        let selectedRows = this.grid.getSelectedRowsData();

        if(selectedRows.length == 0){
          fn.notifyError("파일 선택 후 진행 필요");
          return;
        }

        if(await fn.confirm('정말 삭제 하시겠습니까?<br><br><br>공급업체 : ' + selectedRows[0].supplier + '<br>품목 : ' + selectedRows[0].category) == false){
           return;
        } 
        
        await dsu.getAxiosOBJPost('/api/erp/ESCI/deleteFile', selectedRows[0]).then(res=>{
            let response = res.data;
            if(response.result == 'SUCCESS'){
              this.refreshData();
              fn.notifySuccess(response.msg);
              this.popupUpload.hide();
            }else{
              fn.notifyError(response.msg);
            }
        });
      },
      onDblClick(e){
        fn.downLoadFile(e);
      }
    }
  };
</script>

<style scoped>
 #Container {
    height: calc( 100vh - 50px );
    width: 100%;}
  
  #gridContainer {
    height: calc( 100vh - 50px );
    width: 100%;}

  .dx-field-label{
    width: 120px;
    font-size: 14px;
    font-weight: 400;
    color: black;
  }

  .dx-field-value{
    font-size: 14px;
    font-weight: 400;
    color: black;
  }
  
  #form {
      max-width: 600px;
      margin: auto;
  }

  .fileuploader-container{
      border: 1px solid #d3d3d3;
      text-align: center;
      height: 320px;
  }
  
  .buttonContainer{
    padding-top: 15px;
    text-align: center;
  }
</style>