import { render, staticRenderFns } from "./EPMG.vue?vue&type=template&id=741aab34&scoped=true&"
import script from "./EPMG.vue?vue&type=script&lang=js&"
export * from "./EPMG.vue?vue&type=script&lang=js&"
import style0 from "./EPMG.vue?vue&type=style&index=0&id=741aab34&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "741aab34",
  null
  
)

export default component.exports