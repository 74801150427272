<template>
  <DxDataGrid
    id="gridContainer"
    :data-source="dataSource"
    :focused-row-enabled="true"
    :columnAutoWidth="true"
    :show-borders="true"
    :show-column-lines="true"
    @exporting="onExporting">
    
    <DxSearchPanel :visible="true"/>
    <DxHeaderFilter :visible="true"/>
    <DxScrolling mode="infinite"/>
    <DxSorting mode="multiple"/>
    <DxExport :enabled="true"/>
    <DxEditing
      :allow-adding="true"
      :allow-deleting="true"
      mode="popup"/>
    
    <DxColumn type="buttons">
      <DxButton name="edit"/>
    </DxColumn>
    
    <DxColumn
      data-field="name"
      caption="공급업체"
      :header-filter="{ allowSearch: true }">
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^[\S*].{1,13}[\S*]$|^\S{1,15}$"
        message="15자까지 입력 가능, 좌우 공백 불가"
      />
    </DxColumn>

    <DxMasterDetail
      :enabled="true"
      template="master-detail"
    />
      <template #master-detail="{ data }">
        <History :master-detail-data="data"
                :url= "`/api/erp/EMSI/selectHistory`"
                :keyName="operation"
                :columns="historyColumns"/>
      </template>    
    </DxDataGrid>
</template>

<script>
  import { DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxRequiredRule,
          DxPatternRule,
          DxMasterDetail} from "devextreme-vue/data-grid";
  import dsu from "../../utils/dataSourceUtils";
  import History from "../_common/SIMPLE_HISTORY";

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxRequiredRule,
      DxPatternRule,
      DxMasterDetail,
      History
    },
    data() {
      return {
        dataSource: dsu.getComDSGrid('/api/erp/EMSI', 'name', '', '공급업체'),
        historyColumns: [{dataField : "transTime", caption : "변경 시간"},
                         {dataField: "name", caption: "공급업체"},
                         {dataField: "userId", caption: "사용자"},
                         {dataField: "dml", caption: "Action"}]
      };
    },
    methods:{
       onExporting(e) {
          e.fileName = this.$route.meta.transName;
        }
    }
  };
</script>

<style scoped>
  #gridContainer {
      height: calc( 100vh - 50px );
      width: 100%;
  }
</style>