<template>
  <div id="Container">
    <SearchCondition id="searchCondition"
                     :ref="searchConditionRefName" 
                     :showStartDate="true"
                     :showEndDate="true"
                     :showCategory="true"
                     :showCode="true"
                     :showOperation="true"
                     @search="search"/>

    <DxDataGrid id="gridContainer"
                :ref="gridRefName"
                :data-source="dataSource"
                :show-borders="true"
                :show-column-lines="true"
                :columnAutoWidth="true"
                :selection="{ mode: 'single' }"
                @exporting="onExporting"
                @cellDblClick="onDblClick">

            <DxSearchPanel :visible="true"/>
            <DxHeaderFilter :visible="true"/>
            <DxScrolling mode="infinite"/>
            <DxSorting mode="multiple"/>
            <DxExport :enabled="true"/>
                      
            <DxColumn
              data-field="lotNumber"
              caption="Lot No."
              :header-filter="{ allowSearch: true }">
            </DxColumn>
            
            <DxColumn
              data-field="operation"
              caption="공정"
              :header-filter="{ allowSearch: true }">
            </DxColumn>

            <DxColumn
              data-field="category"
              caption="품목"
              :header-filter="{ allowSearch: true }">
            </DxColumn>    

            <DxColumn
              data-field="code"
              caption="자재 코드"
              :header-filter="{ allowSearch: true }">
            </DxColumn>                     

            <DxColumn
              data-field="item"
              caption="검사 항목"
              :header-filter="{ allowSearch: true }">
            </DxColumn>  

            <DxColumn
              data-field="method"
              caption="검사 방법"
              :header-filter="{ allowSearch: true }">
            </DxColumn>         
                        
            <DxColumn
              data-field="unit"
              caption="단위"
              :header-filter="{ allowSearch: true }">
            </DxColumn>       

            <DxColumn
              data-field="lsl"
              caption="LSL"
              :header-filter="{ allowSearch: true }">
            </DxColumn>    
            
            <DxColumn
              data-field="tg"
              caption="기준 값"
              :header-filter="{ allowSearch: true }">
            </DxColumn>    
            
            <DxColumn
              data-field="usl"
              caption="USL"
              :header-filter="{ allowSearch: true }">
            </DxColumn>    

            <DxColumn
              data-field="tolerance"
              caption="허용 오차"
              :header-filter="{ allowSearch: true }">  
            </DxColumn>

            <DxColumn
              data-field="result"
              caption="결과1"
              :header-filter="{ allowSearch: true }">
            </DxColumn>        
            
            <DxColumn
              data-field="result2"
              caption="결과2"
              :header-filter="{ allowSearch: true }">
            </DxColumn>      

            <DxColumn
              data-field="result3"
              caption="결과3"
              :header-filter="{ allowSearch: true }">
            </DxColumn>  
            
            <DxColumn
              data-field="average"
              caption="평균"
              :header-filter="{ allowSearch: true }">
            </DxColumn>      
        
            <DxColumn
              data-field="judgement"
              caption="판정"
              :header-filter="{ allowSearch: true }">
            </DxColumn>    

            <DxColumn
              data-field="transTime"
              caption="검사 시간"
              :header-filter="{ allowSearch: true }">
            </DxColumn>    
            
            <DxColumn
              data-field="userId"
              caption="검사자"
              :header-filter="{ allowSearch: true }">
            </DxColumn>              
            
            <DxColumn
              data-field="transTimeRaw"
              :visible="false">
            </DxColumn>              
    </DxDataGrid>
    
    <DxPopup :ref="popupNumberRefName"
                :drag-enabled="false"
                :close-on-outside-click="true"
                :show-title="true"
                :width="300"
                :height="200"
                title="결과 입력">
                <div class='Item'>항목: {{currentRow.item}}</div>
                <DxNumberBox :value="numberValue"
                             @valueChanged="setNumberValue"/> 
                <div class='ButtonBox'>
                  <DxButton class='Button'
                      :width="120"
                      text="입력"
                      type="success"
                      styling-mode="contained"
                      @click="setResult('')"/>
                </div>
       </DxPopup>

       <DxPopup :ref="popupPassFailRefName"
                :drag-enabled="false"
                :close-on-outside-click="true"
                :show-title="true"
                :width="300"
                :height="230"
                title="결과 입력">
                <div class='Item'>항목 : {{currentRow.item}}</div>
                <div class='ButtonBox'>
                  <DxButton class='Button'
                    :width="120"
                    text="Pass"
                    type="success"
                    styling-mode="contained"
                    @click="setResult('PASS')"/>
                  <DxButton class='Button'
                    :width="120"
                    text="Fail"
                    type="danger"
                    styling-mode="contained"
                    @click="setResult('FAIL')"/>
                </div>
       </DxPopup>
  </div>
</template>

<script>
  import SearchCondition from "../_common/SEARCH_CONDITION";
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxSearchPanel} from "devextreme-vue/data-grid";
  import DxPopup from "devextreme-vue/popup";
  import {DxNumberBox} from "devextreme-vue/number-box";
  import DxButton from "devextreme-vue/button";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";

  export default {
      components: {
        SearchCondition,
        DxDataGrid,
        DxColumn,
        DxScrolling,
        DxSorting,
        DxExport,
        DxHeaderFilter,
        DxSearchPanel,
        DxPopup,
        DxNumberBox,
        DxButton
      },
      data: function() {
        return {
          searchConditionRefName: 'searchCondition',
          gridRefName: 'grid',
          popupNumberRefName: 'popupNumber',
          popupPassFailRefName: 'popupPassFail',
          currentRow:{},
          numberValue: '',
        };
      },
      computed: {
        grid: function () {
          return this.$refs[this.gridRefName].instance;
        }
      },    
      methods: {
        search(data){
          // this.grid.option('dataSource', []);
          dsu.getAxiosOBJGet('/api/mes/MVIR/select', data).then(res=>{
            let response = res.data;
            if(response.result == 'SUCCESS'){
              this.grid.option('dataSource', response.data);
            }else{
              fn.notifyError(response.msg);
            }
          });
        },
        onExporting(e) {
            e.fileName = this.$route.meta.transName;
        },
        onDblClick(e) {
          this.targetResultField = e.column.dataField;
          this.currentRow = e.row.data;
          if (this.targetResultField.includes('result')) {
            if (!this.currentRow[this.targetResultField]) {
              this.currentRow[this.targetResultField] = '';
            }
            if (this.currentRow.method == 'PASS/FAIL') {
              this.$refs[this.popupPassFailRefName].instance.show();
            } else {
              this.$refs[this.popupNumberRefName].instance.show();
            }
          }
        },
        setNumberValue(e){
          this.numberValue = e.value;
        },
        setGridData(data){
          const grid = this.$refs[this.gridRefName].instance;
          grid.option('dataSource', data);
        },
        getGridData(){
          const grid = this.$refs[this.gridRefName].instance;
          var dataSource = grid.getDataSource();
          return JSON.parse(JSON.stringify(dataSource.items()));
        },
        setResult(result){
          let finalResult = '';

          this.$refs[this.popupNumberRefName].instance.hide();
          this.$refs[this.popupPassFailRefName].instance.hide();
          
          if(result == ''){
            finalResult = this.numberValue;
            this.numberValue ='';
          }else{
            finalResult = result;
          }

          if (!finalResult)
          {
            return;
          }
        
          var inspectionResultList = this.getGridData();
          for (var i = 0; i < inspectionResultList.length; i++) {
            if(inspectionResultList[i].item == this.currentRow.item && inspectionResultList[i].lotNumber == this.currentRow.lotNumber && inspectionResultList[i].operation == this.currentRow.operation && inspectionResultList[i].transTime == this.currentRow.transTime){
              if (inspectionResultList[i].method == 'PASS/FAIL') {
                inspectionResultList[i].result = finalResult;
                inspectionResultList[i].result2 = finalResult;
                inspectionResultList[i].result3 = finalResult;
              } else {
                inspectionResultList[i][this.targetResultField] = finalResult;
              }

              if(inspectionResultList[i].result && inspectionResultList[i].result2 && inspectionResultList[i].result3){
                if (inspectionResultList[i].method == 'PASS/FAIL') {
                  if(inspectionResultList[i].result == 'PASS' && inspectionResultList[i].result2 == 'PASS' && inspectionResultList[i].result3 == 'PASS'){
                    inspectionResultList[i].judgement = 'PASS';
                  } else {
                    inspectionResultList[i].judgement = 'FAIL';
                  } 
                } else {
                  if (inspectionResultList[i].lsl <= inspectionResultList[i].result && inspectionResultList[i].usl >= inspectionResultList[i].result
                      && inspectionResultList[i].lsl <= inspectionResultList[i].result2 && inspectionResultList[i].usl >= inspectionResultList[i].result2
                      && inspectionResultList[i].lsl <= inspectionResultList[i].result3 && inspectionResultList[i].usl >= inspectionResultList[i].result3
                  ){
                    inspectionResultList[i].judgement = 'PASS';
                  }else{
                    inspectionResultList[i].judgement = 'FAIL';
                  }
                  let decimalPlaces = fn.getDecimalPlaces(inspectionResultList[i].lsl); 

                  inspectionResultList[i].average = (parseFloat(inspectionResultList[i].result) + parseFloat(inspectionResultList[i].result2) + parseFloat(inspectionResultList[i].result3)) / 3;
                  inspectionResultList[i].average = inspectionResultList[i].average.toFixed(decimalPlaces + 1);
                }
              } else {
                inspectionResultList[i].judgement = '';
                inspectionResultList[i].average = '';
              }
              var params = JSON.parse(JSON.stringify(inspectionResultList[i]));
              dsu.getAxiosOBJPost('/api/mes/MVIR/updateInspectionResultHistory', params).then(res => {
                  let response = res.data;
                  if(response.result == 'SUCCESS'){
                    fn.notifySuccess(response.msg);
                    this.setGridData(inspectionResultList);
                  }else {
                    fn.notifyError(response.msg);
                  }
              });
            }
          }
          
       
        }
      }
    };
</script>

<style scoped>
  #Container{
    height: calc(100vh - 50px);
    width: 100%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background-color:white;
  }

  #gridContainer{
    height: calc(100vh - 280px);
  }
  
  .Item{
    margin-bottom: 15px;
  }

  .ButtonBox{
    text-align: center;
  }

  .Button{
    margin: 7px;
  }
</style>
