<template>
  <div id="ProductOptionGroup">
    <DxForm>
      <DxGroupItem caption="Option" > 
      </DxGroupItem>
    </DxForm>

    <DxDataGrid
      :ref="gridRefName"
      :columnAutoWidth="true"
      :columnWidth="'auto'"
      :columnMinWidth="400"
      :show-borders="true"
      :show-column-lines="true"
      :selection="{ mode: 'single' }"
      @toolbar-preparing="onToolbarPreparing($event)">
    
      <DxSearchPanel :visible="true"/>
      <DxHeaderFilter :visible="true"/>
      <DxScrolling mode="infinite"/>
      <DxSorting mode="multiple"/>
      
      <DxEditing :allow-updating="true" mode="cell"/>
      
      <DxColumn data-field="optionName"
        caption="항목"
        :allowEditing="false"/>

       <DxColumn data-field="quantity"
        data-type="number"
        caption="수량"/>
    </DxDataGrid>

    <DxForm id="form"
      :ref="formRefName"
      :items="formItems"
      :width="'800px'">
    </DxForm>
  </div>
</template>

<script>
  import {DxForm,
          DxGroupItem} from "devextreme-vue/form";
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel} from "devextreme-vue/data-grid";
  import 'devextreme-vue/lookup';  
  import 'devextreme-vue/tag-box';  
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  
  export default {
    components: {
      DxForm,
      DxGroupItem,
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel
    },
    props: {
      projectId: String,
      step: String,
      seq: String,
      code: String,
      optionGroupId: String,
      checkListId: String
    },
    data() {
      return {
        gridRefName: 'grid',
        formRefName: 'form',
        onToolbarPreparing(e) {
          e.toolbarOptions.items.unshift(
            {location: 'before',
            widget: 'dxButton',
            options: {
              icon: 'save',
              text: '저장',
              stylingMode:"outlined",
              onClick: this.saveOptionGroup.bind(this)
            }},
            {location: 'before',
            widget: 'dxButton',
            options: {
              icon: 'trash',
              text: '삭제',
              stylingMode:"outlined",
              onClick: this.deleteOptionGroup.bind(this)
            }}
          );
        },
        formItems: []   
      };
    },
    mounted(){
      this.refreshOptionGroupData();
      this.refreshCheckListData();
    },
    computed: {
      grid: function (){return this.$refs[this.gridRefName].instance;},
      form: function (){return this.$refs[this.formRefName].instance;},
    },    
    methods:{
      refreshOptionGroupData(){
        var params = {};
        params.projectId = this.projectId;
        params.seq = this.seq;
        params.optionGroupId = this.optionGroupId;

        dsu.getAxiosOBJGet('/api/erp/PREP/selectProjectProductOptionData', params).then(res =>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            this.grid.option('dataSource', response.data);  
          }else{
            fn.notifyError(response.msg);
          }
        });
      },
      saveOptionGroup(){
        this.grid.saveEditData();
        var orgOptionList = this.grid.option("dataSource");
        var finalOptionList = [];
        for(var i = 0 ; i < orgOptionList.length ; i++ ){
          if(orgOptionList[i].quantity){
            finalOptionList.unshift(JSON.parse(JSON.stringify(orgOptionList[i])));
          }
        }
        
        if(finalOptionList.length == 0){
          fn.notifyError("Option 수량 입력 후 저장 필요");         
          return;
        }

        var params = {};
        params.projectId = this.projectId;
        params.step = this.step;
        params.seq = this.seq;
        params.optionDatas = JSON.stringify(finalOptionList);

        dsu.getAxiosOBJPost('/api/erp/PREP/updateProjectProductOptionData', params).then(res=>{
            let response = res.data;
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);
              this.refreshOptionGroupData();
            }else{
              fn.notifyError(response.msg);
            }
        });
      },
      async deleteOptionGroup(){
        if(await fn.confirm('정말 삭제 하시겠습니까?') == false){
          return;
        } 
            
        var params = {};
        params.projectId = this.projectId;
        params.step = this.step;
        params.seq = this.seq;
        
        await dsu.getAxiosOBJPost('/api/erp/PREP/deleteProjectProductOptionData', params).then(res=>{
            let response = res.data;
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);
              this.refreshOptionGroupData();
            }else{
              fn.notifyError(response.msg);
            }
        });
      },refreshCheckListData(){
        var params = {};
        params.projectId = this.projectId;
        params.seq = this.seq;
        params.checkListId = this.checkListId;

        dsu.getAxiosOBJGet('/api/erp/PREP/selectProjectProductCheckListData', params).then(res =>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            let data = response.data;
            let groupName = "";
            var chekItems = [];

            this.formItems = [{itemType: 'group',
                               caption: "Check List"}];

            for (var i=0 ; i<data.length ; i++){
              
              if(groupName && groupName != data[i].groupName){
                this.formItems.push({itemType: 'group',
                                     caption: groupName,
                                     items: chekItems});
                chekItems = [];
              }
              groupName = data[i].groupName;
              
              var item = {};
              item.dataField = data[i].itemName;
              item.label = {text: data[i].itemName};
              if(data[i].necessary == 'Y'){
                item.isRequired = true;
              }
              
              if (data[i].type == 'TEXT'){
                item.editorType = 'dxTextBox';
                item.editorOptions={maxLength: 50,
                                    value: data[i].value};
              
              }else if(data[i].type == 'TEXT_AREA'){
                item.editorType = 'dxTextArea';
                item.editorOptions={height: '200px',
                                    maxLength: 500,
                                    value: data[i].value};
              
              }else if(data[i].type == 'NUMBER'){
                item.editorType = 'dxNumberBox';
                item.editorOptions={min: 1,
                                    max: 9999999999,
                                    value: data[i].value};
              
              }else if(data[i].type == 'DATE'){
                item.editorType = 'dxDateBox';
                item.editorOptions={format:'yyyy/MM/dd',
                                    value: data[i].value};
              
              }else if(data[i].type == 'OPTION'){
                item.editorType = 'dxLookup';
                item.editorOptions={dataSource: data[i].list.split('|'),
                                    value: data[i].value};
              
              }else if(data[i].type == 'CHECK'){

                var orgValue = data[i].value;
                var finalValue = [];

                if (orgValue){
                  if (orgValue.includes('|')){
                    finalValue = orgValue.split('|');
                  }else{
                    finalValue.push(orgValue);
                  }
                }

                item.editorType = 'dxTagBox';
                item.editorOptions={dataSource: data[i].list.split('|'),
                                    showSelectionControls: true,
                                    applyValueMode: 'useButtons',
                                    value: finalValue}
              }
              
              chekItems.push(item);
            }

            this.formItems.push({itemType: 'group',
                                  caption: groupName,
                                  items: chekItems});

            this.formItems.push({itemType: 'button',
                                 horizontalAlignment: 'center',
                                 buttonOptions:{text: '저장',
                                                icon: 'save',
                                                onClick: this.saveCheckList.bind(this)}});
           }else{
            fn.notifyError(response.msg);
          }
        });
      },
      async saveCheckList(){
        let validationResult =  this.form.validate();
        if( validationResult.status != 'valid'){
          return;
        }
   
        var checkListDatas = [];
        var formData = this.form.option('formData');
        
        for(var key in formData) {
          var data = {};
          data.itemName = key;
          if (Array.isArray(formData[key])){
            data.value = await formData[key].join('|');
          }else
          {
            var type = this.form.getEditor(key).option('type');
            if(type == 'date'){
              data.value = dsu.getFormatDate(formData[key]);
            }else{
              data.value = await formData[key];
            }
          }
          checkListDatas.push(data);
        }

        var params = {};
        params.projectId = this.projectId;
        params.step = this.step;
        params.seq = this.seq;
        params.checkListDatas = JSON.stringify(checkListDatas);

        dsu.getAxiosOBJPost('/api/erp/PREP/updateProjectProductCheckListData', params).then(res=>{
            let response = res.data;
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);
              this.refreshCheckListData();
            }else{
              fn.notifyError(response.msg);
            }
        });
      }
    }
  };
</script>

<style scoped>
  #ProductOptionGroup{
    background-color: white;
  }

  #form{
    margin-top: 50px;
  }
</style>

