<template>
  <DxDataGrid
    id="gridContainer"
    :data-source="dataSource"
    :focused-row-enabled="true"
    :columnAutoWidth="true"
    :show-borders="true"
    :show-column-lines="true"
     @exporting="onExporting">
  
    <DxSearchPanel :visible="true"/>
    <DxHeaderFilter :visible="true"/>
    <DxScrolling mode="infinite"/>
    <DxSorting mode="multiple"/>
    <DxExport :enabled="true"/>

    <DxEditing
      :allow-updating="true"
      :allow-adding="true"
      :allow-deleting="true"
      mode="popup"/>
    
    <DxColumn type="buttons">
      <DxButton name="edit"/>
    </DxColumn>

    <DxColumn
      data-field="category"
      caption="품목"
      :header-filter="{ allowSearch: true }">
      <DxRequiredRule/>
      <DxPatternRule
        pattern="^[A-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣-_()]{1,15}$"
        message="영문 대문자, 한글, 숫자 - _ () 로만 구성, 15자 까지 등록 가능"
      />
    </DxColumn>
    
    <DxColumn
      data-field="grade"
      caption="등급">  
      <DxLookup
        :data-source="materialGrade"
        :allow-clearing="true"
      />
    </DxColumn>

    <DxColumn
      data-field="type"
      caption="내화 구분">  
      <DxLookup
        :data-source="materialType"
        :allow-clearing="true"
      />
    </DxColumn>

    <DxColumn
      data-field="use"
      caption="사용">  
      <DxRequiredRule/>
      <DxLookup
        :data-source="useFlag"
        value-expr="ID"
        display-expr="Name"
        :allow-clearing="true"/>
    </DxColumn>

    <DxMasterDetail
      :enabled="true"
      template="master-detail"
    />
    <template #master-detail="{ data }">
      <History :master-detail-data="data"
              :url= "`/api/erp/EMMC/selectHistory`"
              :keyName="category"
              :columns="historyColumns"/>
    </template>
  </DxDataGrid>
</template>

<script>
  import { DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxLookup,
          DxRequiredRule,
          DxPatternRule,
          DxMasterDetail} from "devextreme-vue/data-grid";
  import dsu from "../../utils/dataSourceUtils";
  import {materialGrade,
          materialType} from "../../utils/syscodedata";
  import History from "../_common/SIMPLE_HISTORY";

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxLookup,
      DxRequiredRule,
      DxPatternRule,
      DxMasterDetail,
      History
    },
    data() {
      return {
        dataSource: dsu.getComDSGrid('/api/erp/EMMC', 'category', '', '품목'),
        materialGrade: materialGrade,
        materialType: materialType,
        useFlag: [{'ID': 'Y', 'Name': 'Y'}, {'ID': 'N', 'Name': 'N'}],
        historyColumns: [{dataField : "transTime", caption : "변경 시간"},
                         {dataField : "grade", caption : "등급"},
                         {dataField: "use", caption: "사용" },
                         {dataField : "type", caption : "내화 구분"},
                         {dataField : "userId", caption : "사용자"},
                         {dataField : "dml", caption : "Action"}]
      };
    },
    methods:{
      onExporting(e) {
          e.fileName = this.$route.meta.transName;
      }
    }
  };
</script>

<style scoped>
  #gridContainer {
      height: calc( 100vh - 50px );
      width: 100%;
  }
</style>