<template>
  <div id="Container">
    <div class="dx-field">
      <div class="dx-field-label">건설 현장</div>
      <div class="dx-field-value">
        <DxSelectBox
          :search-enabled="true"
          placeholder=""
          value-expr="code"
          display-expr="nameCode"
          width="350"
          :data-source="constructionSiteList"
          @value-changed="changedConstructionSiteCode"/>
      </div>
    </div>

    <DxDataGrid
      id="gridContainer"
      :ref="gridRefName"
      :key-expr="lotNumber"
      :focused-row-enabled="true"
      :show-borders="true"
      :show-column-lines="true"
      :columnAutoWidth="true"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)">

      <DxSearchPanel :visible="true"/>
      <DxHeaderFilter :visible="true"/>
      <DxScrolling mode="infinite"/>
      <DxSorting mode="multiple"/>
      <DxExport :enabled="true"/>
      <DxColumnChooser :enabled="true" mode="select" />
      <DxGrouping :auto-expand-all="expanded"/>
      
      <DxColumn
        data-field="shipDate"
        caption="출고 날짜">
      </DxColumn>

      <DxColumn
        data-field="lotNumber"
        caption="Lot No."
        :header-filter="{ allowSearch: true }">
      </DxColumn>

      <DxColumn
        data-field="code"
        caption="자재 코드"
        :group-index="0"
        :header-filter="{ allowSearch: true }">
        <DxLookup
          :data-source="materialList"
          value-expr="code"
          display-expr="codeName"
        />
      </DxColumn>    
      
      <DxColumn
        data-field="quantity"
        caption="수량">
      </DxColumn>

      <DxColumn
        data-field="unit"
        caption="단위">
      </DxColumn>
    
      <DxColumn
        data-field="category"
        caption="품목"
        :header-filter="{ allowSearch: true }">
      </DxColumn>
      
      <DxColumn
        data-field="supplier"
        caption="공급업체"
        :header-filter="{ allowSearch: true }">
      </DxColumn>
      
      <DxColumn
        data-field="mainLot"
        caption="공급업체 Lot"
        :header-filter="{ allowSearch: true }">
      </DxColumn>
  
      <DxColumn
        data-field="batchId"
        caption="Batch ID"
        :header-filter="{ allowSearch: true }">
      </DxColumn>
      
      <DxColumn
        data-field="requestMaterialSeq"
        caption="자재 청구 번호">
      </DxColumn>

      <DxColumn
        data-field="useDate"
        caption="자재 사용일"
        data-type="date"
        format="yyyy/MM/dd">
      </DxColumn>
      
      <DxSummary>
          <DxGroupItem
            column="quantity"
            summary-type="sum"
            display-format=" 총 수량: {0} "
          />
      </DxSummary>    

      <DxMasterDetail
        id='detail'
        :enabled="true"
        template="master-detail"/>
        <template #master-detail="{ data }">
          <History :master-detail-data="data"/>
        </template>
    </DxDataGrid>
  </div>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxSearchPanel,
          DxLookup,
          DxMasterDetail,
          DxColumnChooser,
          DxGrouping,
          DxSummary,
          DxGroupItem} from "devextreme-vue/data-grid";
  import {DxSelectBox} from "devextreme-vue/select-box";        
  import {lotStatus,
          lotType} from "../../utils/syscodedata";
  import History from "../_common/LOTHISTORY";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";

export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxSearchPanel,
      DxLookup,
      DxMasterDetail,
      DxColumnChooser,
      DxGrouping,
      DxSummary,
      DxGroupItem,
      DxSelectBox,
      History
    },
    data() {
      return {
        gridRefName: 'dataGrid',
        materialList: [],
        constructionSiteList: [],
        lotStatus: lotStatus,
        lotType: lotType,
        expanded: true
      };
    },
    mounted(){
      dsu.getAxiosMaterialList().then(res => {this.materialList = res.data.data});
      dsu.getAxiosConstructionSiteList().then(res => {this.constructionSiteList = res.data.data});
    },
    methods:{
      onExporting(e) {
        e.fileName = this.$route.meta.transName;
      },
      onToolbarPreparing(e) {
          e.toolbarOptions.items.unshift({
            location: 'before',
            widget: 'dxSelectBox',
            options: {
              items: [{
                value: 'shipDate',
                text: '그룹 : 출고 날짜'
              },
              {
                value: 'code',
                text: '그룹 : 자재 코드'
              },
              {
                value: 'category',
                text: '그룹 : 품목'
              },
              {
                value: 'requestMaterialSeq',
                text: '그룹 : 자재 청구 번호'
              }],
              displayExpr: 'text',
              valueExpr: 'value',
              value: 'shipDate', 
              onValueChanged: this.groupChanged.bind(this)
            }
          });
      },
      groupChanged(e) {
        this.$refs[this.gridRefName].instance.clearGrouping();
        this.$refs[this.gridRefName].instance.columnOption(e.value, 'groupIndex', 0);
      },
      setGridData(data){
        const grid = this.$refs[this.gridRefName].instance;
        grid.option('keyExpr', 'lotNumber');
        grid.option('dataSource', data);
      },
      changedConstructionSiteCode(e){
        var params = {shipTo: e.value, withTerminateLot: 'Y'};
        this.setGridData([]);

        dsu.getAxiosOBJGet('/api/mes/MVLM/select', params).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            this.setGridData(response.data);
            this.$refs[this.gridRefName].instance.clearGrouping();
            this.$refs[this.gridRefName].instance.columnOption('shipDate', 'groupIndex', 0);
          }else{
            fn.notifyError(response.msg);
          }
        });
      }
    }
  };
</script>

<style scoped>
  #Container {
    height: calc( 100vh - 50px );
    width: 100%;
    float: left;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    background-color:white;}

  #gridContainer {
    height: calc( 100vh - 140px );
    width: 100%;}
 
  #detail {
    width: 100%;}

  .dx-field-label{
    width: 75px;
    font-size: 14px;
    font-weight: 700;
    color: black;
  }
</style>